import React, { useState, useMemo, useCallback, useEffect } from "react";

import Header from "../../../../components/blocks/header";

import { useGetTools } from "../../../../hooks/marketing/useTools";

import { Button as Btn } from "../../../../@/components/ui/button";
import { ReloadIcon } from "@radix-ui/react-icons";
import DataTable from "../../../../components/tables/tableEvents/data-table";
import toastService from "../../../../services/toastService";
import { MoreVertical, SquarePen, Eye } from "lucide-react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../../@/components/ui/dropdown-menu";
import { Checkbox } from "../../../../@/components/ui/checkbox";
import LoadingButton from "../../../../components/common/loadingButton";
import ProgressLoader from "../../../../components/common/progressLoader";
import TransitionsModal from "../../../../components/modals/mod/CustomModal";
import CreateTool from "./CreateTool";
import {
  useCreateTool,
  useUpdateTool,
} from "../../../../hooks/marketing/useTools";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import dayjs from "dayjs";
const handleCopyClick = (url: any) => {
  navigator.clipboard
    .writeText(url)
    .then(() => {
      toastService.success("URL copied to clipboard");
    })
    .catch((error) => {
      toastService.error("Failed to copy URL to clipboard");
    });
};

const Tools = () => {
  const { tools, isPending, baseUrl } = useGetTools();
  // filter out tools with null type
  const filteredTools = tools.filter((tool: any) => tool.tooltype !== null);

  const [progress, setProgress] = useState(0);
  const handleProgressChange = (progress: any) => {
    setProgress(progress);
  };
  const { mutate, isPending: isCreating } = useCreateTool(handleProgressChange);

  const [isOpen, setIsOpen] = useState(false);
  const [sample, setSample] = useState<File | null>(null);
  const [asset, setAsset] = useState<File | null>(null);

  const [viewTool, setViewTool] = useState<any>(null);
  const [editTool, setEditTool] = useState<any>(null);
  const [toolId, setToolId] = useState<string | number>("");

  const { mutateUpdate, isPending: isUpdating } = useUpdateTool(toolId);
  // const closeModal = () => setIsOpen(false);

  const openModal = useCallback((data: any) => {
    if (data) {
      setEditTool(data);

      setIsOpen(true);
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        id: "select",
        header: ({ table }: { table: any }) => (
          <Checkbox
            checked={
              table.getIsAllPageRowsSelected() ||
              (table.getIsSomePageRowsSelected() && "indeterminate")
            }
            onCheckedChange={(value) => {
              table.toggleAllPageRowsSelected(!!value);
              //   console.log(table.options.data)
            }}
            aria-label="Select all"
          />
        ),
        cell: ({ row }: { row: any }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => {
              row.toggleSelected(!!value);
              //   console.log(row.original);
            }}
            aria-label="Select row"
          />
        ),
        enableSorting: false,
        enableHiding: false,
      },
      {
        accessorKey: "name",
        header: "Guide name",
        cell: ({ row }: { row: any }) => (
          <div
            className={
              row.getValue("name") ?? "flex items-center justify-center"
            }
          >
            <span className=" capitalize">{row.getValue("name") ?? "N/A"}</span>
          </div>
        ),
      },
      {
        accessorKey: "tooltype",
        header: "Guide type",
        cell: ({ row }: { row: any }) => (
          <div
            className={
              row.getValue("tooltype") ?? "flex items-center justify-center"
            }
          >
            <span className=" capitalize">
              {row.getValue("tooltype") ?? "N/A"}
            </span>
          </div>
        ),
      },
      {
        accessorKey: "clicks",
        header: "Number of clicks",
      },
      {
        accessorKey: "appointments",
        header: "Number of appointments",
      },
      // {
      //   accessorKey: "created",
      //   header: "Date",
      // },

      {
        accessorKey: "url",
        header: " ",
        cell: ({ row }: { row: any }) => {
          const link = row.getValue("url") as string;
          const addProtocolToUrl = (url: string) => {
            if (!url.includes("http")) {
              return `https://${url}`;
            }
            return url;
          };
          return (
            <Btn
              onClick={() => handleCopyClick(addProtocolToUrl(link))}
              variant="default"
              className="app-btn !rounded-lg !mt-0"
            >
              Share link
            </Btn>
          );
        },
      },
      {
        accessorKey: "id",
        header: " ",
        cell: ({ row }: { row: any }) => {
          return (
            <div>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Btn variant="ghost" className="h-8 w-8 p-0">
                    <span className="sr-only">Open menu</span>
                    <MoreVertical className="h-4 w-4" />
                  </Btn>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem
                    className="cursor-pointer"
                    onClick={() => {
                      //   navigator.clipboard.writeText(id);
                      console.log(row.original);
                      // setIsEdit(true);
                      openModal(row.original);
                      // setIsOpen(true);
                    }}
                  >
                    <SquarePen className="mr-2 h-4 w-4" /> Edit
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem
                    onClick={() => {
                      setViewTool(row.original);
                    }}
                    className="cursor-pointer"
                  >
                    <Eye className="mr-2 h-4 w-4" /> View
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          );
        },
      },
    ],
    [openModal]
  );

  const schema = z.object({
    name: z.string({
      message: "Please enter a valid name",
    }),
    duration: z.string({
      message: "Please enter a valid duration",
    }),
    startdate: z.date({
      message: "Please enter a valid date",
    }),
    tooltype: z.string({
      message: "Please select a type",
    }),
  });

  const form = useForm({
    resolver: zodResolver(schema),
    mode: "onChange",
    defaultValues: {
      name: "",
      duration: "",
      startdate: new Date(),
      tooltype: "Quick Guide By LifeBank (PDF)",
    },
  });

  const { setValue, watch } = form;

  const selectedToolType = watch("tooltype");

  useEffect(() => {
    const handleEditImage = async (imgUrl: string) => {
      try {
        const response = await fetch(`https://${baseUrl}${imgUrl}`);
        const blob = await response.blob();
        const file = new File([blob], imgUrl, { type: blob.type });
        return file;
      } catch (error) {
        console.error("Error fetching and uploading image:", error);
      }
    };

    if (editTool) {
      console.log(editTool.tooltype);
      setToolId(editTool.id);
      setValue("name", editTool.name);
      setValue("duration", editTool.duration);
      setValue("startdate", new Date(editTool.releasedate));
      setValue("tooltype", editTool.tooltype);
      handleEditImage(editTool.samplefilename).then((file) => {
        setSample(file || null);
      });
      handleEditImage(editTool.filename).then((file) => {
        setAsset(file || null);
      });
    }
  }, [baseUrl, editTool, setValue]);

  const onSubmit = async (data: z.infer<typeof schema>) => {
    const date = dayjs(new Date(data.startdate)).format("YYYY/MM/DD");
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("duration", data.duration);
    formData.append("startdate", date);
    formData.append("tooltype", data.tooltype);
    if (asset) {
      formData.append("asset", asset);
    }

    if (sample) {
      formData.append("imageSample", sample);
    }

    if (editTool) {
      console.log(asset);
      console.log(sample);
      mutateUpdate(formData, {
        onSuccess: () => {
          form.reset();
          setSample(null);
          setAsset(null);
          setEditTool(null);
          setIsOpen(false);
        },

        onError: (error: any) => {
          console.log(error);
        },
      });
      return;
    }

    mutate(formData, {
      onSuccess: () => {
        form.reset();
        setSample(null);
        setAsset(null);
        setIsOpen(false);
        setProgress(0);
      },

      onError: (error: any) => {
        setProgress(0);
      },
    });
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    form.reset();
    setEditTool(null);
    setSample(null);
    setAsset(null);
  };

  const handleCloseViewTools = () => {
    setViewTool(null);
    form.reset();
  };

  const createTool = () => {
    setIsOpen(true);
    // form.reset();
  };
  const handleBackdropClose = () => setViewTool(null);
  return (
    <React.Fragment>
      <ProgressLoader progress={progress} />
      <Header title={`Tools`} />

      <div className="hospitals-container">
        <div className="">
          {isPending ? (
            <LoadingButton
              visible={isPending}
              message="Fetching Events... Please wait"
            />
          ) : (
            <DataTable
              columns={columns}
              data={filteredTools.reverse()}
              onClick={createTool}
              btnTitle="ADD NEW TOOL"
            />
          )}
        </div>
      </div>

      {/* add tools modal */}

      <TransitionsModal
        open={isOpen}
        setOpen={setIsOpen}
        handleCloseModal={handleCloseModal}
        title={editTool ? "Edit Tool" : "Add New Tool"}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          bgcolor: "white",
          boxShadow: 24,
          borderRadius: "10px",
        }}
        footer={
          <div className="flex items-center justify-center w-1/2 gap-3">
            <Btn
              className="transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-210 w-full active:translate-y-2 active:scale-95"
              disabled={form.formState.isSubmitting || isCreating || isUpdating}
              type="button"
              onClick={() => {
                form.handleSubmit(onSubmit)();
              }}
            >
              {(isCreating || isUpdating) && (
                <ReloadIcon className="animate-spin mr-2 h-4 w-4" />
              )}
              {isCreating
                ? "Adding..."
                : isUpdating
                ? "Updating..."
                : editTool
                ? "Update Tool"
                : "Add Tool"}
            </Btn>
          </div>
        }
      >
        <CreateTool
          form={form}
          setSample={setSample}
          sample={sample}
          asset={asset}
          setAsset={setAsset}
          selectedToolType={selectedToolType}
        />
      </TransitionsModal>
      <TransitionsModal
        open={!!viewTool}
        setOpen={setViewTool}
        handleCloseModal={handleCloseViewTools}
        handleBackdropClose={handleBackdropClose}
        title="Tool Information"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "white",
          boxShadow: 24,
          borderRadius: "10px",
        }}
      >
        <div className="w-full border rounded-md">
          <div className="flex items-center justify-between w-full border-b">
            <p className="px-4 py-3 bg-[#A0A0A0A0] font-bold text-[0.8rem] md:text-base w-1/3 text-start rounded-tl-md">
              Guide Name:
            </p>
            <p className="px-4 py-3 flex-1 w-full text-start capitalize">
              {viewTool?.name}
            </p>
          </div>
          <div className="flex items-center justify-between  w-full border-b ">
            <p className="px-4 py-3 bg-[#A0A0A0A0] font-bold text-[0.8rem] md:text-base w-1/3 text-start">
              Go live date:
            </p>
            <p className="px-4 py-3 flex-1 w-full text-start">
              {viewTool?.releasedate}
            </p>
          </div>
          <div className="flex items-center justify-between  w-full border-b ">
            <p className="px-4 py-3 bg-[#A0A0A0A0] font-bold text-[0.8rem] md:text-base w-1/3 text-start">
              Type of tool:
            </p>
            <p className="px-4 py-3 flex-1 w-full text-start">
              {viewTool?.tooltype ?? "N/A"}
            </p>
          </div>
          <div className="flex items-center justify-between  w-full border-b bg-[#A0A0A0A0] ">
            <p className="font-bold text-[0.8rem] md:text-base w-1/3 text-center">
              Upload Preview:
            </p>
            <div className="p-2 flex-1 w-full text-start bg-white">
              <div className="h-80 max-h-44 flex justify-center ">
                <img
                  className="rounded-md w-full object-fit max-h-full"
                  src={`https://${baseUrl}${viewTool?.samplefilename}`}
                  alt="preview"
                />
              </div>
            </div>
          </div>
        </div>
      </TransitionsModal>
    </React.Fragment>
  );
};

export default Tools;
