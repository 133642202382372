import React from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  exodus: string | number;
}

const ExodusArena = ({ exodus }: Props) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/exodus");
  };

  const handleVisit = () => {
    navigate("/exodus/log-a-contact");
  };
  const handleNote = () => {
    navigate("/exodus/send-a-note");
  };
  const handleDrip = () => {
    navigate("/exodus/send-a-drip");
  };
  const reviewActiveSchedule = () => {
    console.log(`Active`);
    navigate("/hospital-review-active-schedule");
  };

  return (
    <div
      className="bg-white p-4 flex flex-col justify-start items-start
            shadow-md w-full rounded-lg"
    >
      <div className="flex flex-col sm:flex-row justify-between items-center gap-6 w-full">
        <h1 className="lg:text-3xl font-bold text-purple-900">EXODUS ARENA</h1>
        <div className="border-t-4 border-purple-900 w-32 "></div>
      </div>

      <button
        onClick={handleNavigate}
        className="text-black text-lg font-bold mt-4"
      >
        You have{" "}
        <span className="text-purple-900 font-bold italic cursor-pointer">
          {/* {details?.total_exodus_count || 0} Hospitals in Exodus */}{" "}
          {exodus} Hospitals in Exodus
        </span>
      </button>
      <button
        onClick={handleNavigate}
        className="text-black text-lg text-left font-bold underline underline-offset-2 cursor-pointer "
      >
        Click here to Convert to Active &gt;&gt;
      </button>

      <div className="flex flex-col sm:flex-row gap-4 mt-4 justify-between w-full">
        <button
          className="flex-grow h-[80px] w-full sm:flex-grow-0 border-2 border-purple-900 text-purple-900 px-2 py-2 rounded-lg font-extrabold text-[16px]"
          onClick={handleDrip}
        >
          Send Drip
        </button>
        <button
          className="flex-grow h-[80px] w-full sm:flex-grow-0 border-2 border-purple-900 text-purple-900 px-2 py-2 rounded-lg font-extrabold text-[16px]"
          onClick={handleVisit}
        >
          Schedule a Visit
        </button>
      </div>

      <div className="flex flex-col md:flex-row justify-center items-center mt-4 w-full gap-2">
        <button
          className="border-2 h-[80px]  border-purple-900 text-purple-900 px-4 py-4 rounded-lg font-extrabold text-[16px] w-full"
          onClick={reviewActiveSchedule}
        >
          Review Active Schedule
        </button>

        <button
          className="border-2 h-[80px] border-purple-900 text-purple-900 px-4 py-4 rounded-lg font-extrabold text-[16px] w-full"
          onClick={handleNote}
        >
          Send note
        </button>
      </div>
    </div>
  );
};

export default ExodusArena;
