import { useState } from "react";

import {
  useGetEquipmentList,
  useRequestEquipment,
} from "../../../../hooks/purchasingOrder/usePurchaseOrder";
import { useLocation, useNavigate } from "react-router-dom";
import RequestItem from "./RequestItem";
import { useSelector } from "react-redux";
import toastService from "../../../../services/toastService";
import { SyncLoader } from "react-spinners";

export const EquipmentList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { item, name } = location.state;
  const { equipmentList, isPending } = useGetEquipmentList(item);

  const handleClick = (data: { equipmentName: string; id: string }) => {
    navigate(`/purchasing-orders/category/buy-equipment/${item}/${data.id}`, {
      state: { data },
    });
  };

  const { mutateRequestEquipment, isPending: isRequesting } =
    useRequestEquipment();
  const [inputText, setInputText] = useState("");

  const hospitalId = useSelector(
    (state: { cart: any }) => state.cart.hospital_id
  );
  const data = {
    hospital_id: hospitalId,
    items: inputText,
  };

  const handleSubmit = async () => {
    mutateRequestEquipment(data, {
      onSuccess: () => {
        toastService.success(
          "Request sent successfully. We will notify you when the item is available"
        );
        setInputText("");
        navigate("/purchasing-orders/category/buy-equipment");
      },
      onError: (error: { message: string }) => {
        toastService.error("Failed to send request");
      },
    });
  };
  if (isPending) {
    return (
      <div>
        <div className="fixed top-0 bottom-0 left-0 right-0 flex mx-auto items-center justify-center h-dvh">
          <div className="bg-gray-700 absolute inset-0 opacity-50"></div>
          <SyncLoader size={30} color="blue" className="relative" />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-5">
      {equipmentList.length === 0 ? (
        <RequestItem
          itemName={name}
          handleSubmit={handleSubmit}
          setInputText={setInputText}
          inputText={inputText}
          isPending={isRequesting}
        />
      ) : (
        <>
          {equipmentList.map((item: any) => (
            <div
              key={item.id}
              className="flex flex-col h-[156px] justify-between p-2 bg-white rounded-lg shadow-md hover:shadow-lg transition w-full"
            >
              <div className="flex gap-3">
                <div className="">
                  <img
                    src={`https://lab.lifebank.ng/labx/api/v1/orders/image/equipments/${item.imageUrl}`}
                    alt={item.equipmentName}
                    className="flex-none w-20 h-20 rounded-md"
                  />
                </div>
                <div className="">
                  <p className="font-bold text-blue-600 underline lg:text-2xl pb-2">
                    {item.equipmentName}
                  </p>
                  <span className="font-bold leading-none tracking-widest text-black">
                    {item.description}
                  </span>
                </div>
              </div>
              <div className="flex justify-end">
                <button
                  className="border text-white bg-blue-600 rounded-md px-4 py-2 hover:bg-blue-700 focus:outline-none"
                  onClick={() => handleClick(item)}
                >
                  View
                </button>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};
