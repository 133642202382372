import httpService from "./httpService";
import queryString from "query-string";

export const getOnboardData = (city, id) => {
  return httpService.get("/acquisition/onboard/" + city);
};

export const onboardHospital = (data, handleProgressChange) => {
  const options = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    onUploadProgress: (progressEvent) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  return httpService.put(
    "/manage/hos/onboard",
    queryString.stringify(data),
    options
  );
};

export const addFeedback = (data, handleProgressChange) => {
  const options = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    onUploadProgress: (progressEvent) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  return httpService.post(
    "/manage/extra/feedback",
    queryString.stringify(data),
    options
  );
};

export const addComplain = (data, handleProgressChange) => {
  const options = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    onUploadProgress: (progressEvent) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  return httpService.post(
    "/manage/complain/visit",
    queryString.stringify(data),
    options
  );
};

export const getHistory = (hospitalId) => {
  return httpService.get("/admin/" + hospitalId);
};

export const getPitches = (userId) => {
  return httpService.get("/manage/agent/onboard/" + userId);
};

const onboardService = {
  addComplain,
  addFeedback,
  getHistory,
  getOnboardData,
  getPitches,
  onboardHospital,
};

export default onboardService;
