import React from "react";

import Modal from "../common/modal";

import ScheduleForm from "./scheduleForm";
import { scheduleActive } from "../../services/appointmentService";

const MaintenanceForm = (props) => {
  const { hospitalid, handleState, handleClose } = props;
  return (
    <Modal handleClose={handleClose}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ScheduleForm
          hospital_id={hospitalid}
          handlePush={handleState}
          width={true}
          scheduleApi={scheduleActive}
        />
      </div>
    </Modal>
  );
};

export default MaintenanceForm;
