import React, { useState } from "react";
import Button from "../../../../components/common/button";
import Modal from "../../../../components/common/modal";
import appointmentService from "../../../../services/appointmentService";
import toastService from "../../../../services/toastService";
import ProgressLoader from "../../../../components/common/progressLoader";

const Email = (props) => {
  const { selectedPitch, selectedResponse, handlePrev, handleNext } = props;
  const [mail, setMail] = useState(null);
  const [progress, setProgress] = useState(0);

  const handleMail = (data) => {
    setMail(data);
  };

  const handleProgressChange = (progress) => {
    setProgress(progress);
  };

  const postData = async () => {
    const res = await appointmentService.sendEmail(
      selectedPitch.hospital_id,
      handleProgressChange
    );
    if (res.data.status !== "success") throw new Error(res.data.message);
    if (res.data.status === "success") handleNext();
  };

  const handleSendMail = (e) => {
    e.preventDefault();
    toastService.promise(postData(), {
      loading: "sending mail...",
      success: () =>
        `Email sent to ${selectedPitch.name} sucessfully!`,
      error: (e) =>
        e?.response?.data?.message || e?.message || "Could not send email!",
    });
  };

  return (
    <div>
      <ProgressLoader progress={progress} />
      <div className="pitch-data">
        <span className="label">Welcome email</span>
        <div>
          {selectedResponse?.email?.status === "Yes" && (

           

<span className="nerve" onClick={handleSendMail}>
{selectedResponse?.email_skip === "Imported" ? (
  `You can skip this email for ${selectedPitch.name}, as it was imported and does not require any action on your part.`
) : (
  ` Email has been sent to ${selectedPitch.name}`
)}
</span>
          )}
          {selectedResponse?.email?.status === "No" && (
            <span
              className="link"
              onClick={() => handleMail(selectedResponse?.email)}
            >
              view email
            </span>
          )}
          {selectedResponse?.email?.status === "No" && (
            <span className="check_button" onClick={handleSendMail}>
              send email
            </span>
          )}
        </div>
      </div>
      <div className="checklist-button">
        <Button label="Prev" onClick={handlePrev} />
        {selectedResponse?.email?.status === "Yes" && (

          <Button label="Skip" onClick={handleNext} />
        )}
      </div>

      {mail && (
        <Modal handleClose={() => setMail(null)}>
          <div className="row">
            <div dangerouslySetInnerHTML={{ __html: mail?.data }} />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Email;
