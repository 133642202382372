import { useEffect, useState } from "react";
import { ApiResponse as Response, Hospital } from "../../ts-utils/types";

import utilities from "../../utils/utilities";

import hospitalService from "../../services/hospitalService";
import toastService from "../../services/toastService";

import { useGlobalContext } from "../../contexts/globalContext";

function withHospitals(Component: any): (props: any) => JSX.Element {
  return function WithHospitals(props): JSX.Element {
    const [hospitals, setHospitals] = useState<Hospital>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { city } = useGlobalContext();
    const { user } = useGlobalContext();

    const getHospitals = async (): Promise<void> => {
      try {
        setIsLoading(true);

        const response: Response = await hospitalService.getAllHospitals(
          city,
          user?.id
        );
        console.log(response);
        const result: any = response.data;
        const data: any[] = utilities.mapKeyToNumber(result.hospitals, "id");
        console.log(data);
        
        setHospitals(data);
        setIsLoading(false);
      } catch (ex: any) {
        const message: string = ex?.response?.data?.description ?? ex.message;
        toastService.error(message);

        setIsLoading(false);
      }
    };

    useEffect(() => {
      getHospitals();

      // eslint-disable-next-line
    }, [city]);

    return <Component hospitals={hospitals} loading={isLoading} {...props} />;
  };
}

export default withHospitals;
