import { CountryModel } from "../ts-utils/interfaces";

const countries: CountryModel[] = [
  {
    id: "1",
    imageUrl: "https://flagcdn.com/72x54/ng.png",
    label: "One Hospital Nigeria",
    country: "Nigeria",
    color: "#008751",
    countryUrl: "https://one.lifebank.ng",
    testUrl: "https://one.lifebanktest.com",
    liteApiUrl: "https://plus.lifebank.ng/lite/v2/api",
    currency: "NGN",
  },
  {
    id: "2",
    imageUrl: "https://flagcdn.com/72x54/et.png",
    label: "One Hospital Ethiopia",
    country: "Ethiopia",
    color: "#fcdd09",
    countryUrl: "https://one.lifebank.et",
    testUrl: "https://one.lifebanktest.com",
    liteApiUrl: "https://plus.lifebank.et/lite/v2/api",
    currency: "ETB",
  },
  {
    id: "3",
    imageUrl: "https://flagcdn.com/72x54/ke.png",
    label: "One Hospital Kenya",
    country: "Kenya",
    color: "#922529",
    countryUrl: "https://one.lifebank.ke",
    testUrl: "https://one.lifebanktest.com",
    liteApiUrl: "https://plus.lifebank.ke/lite/v2/api",
    currency: "KES",
  },
];

export default countries;
