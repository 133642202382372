import React from "react";
import Joi from "joi-browser";

import Form from "../common/form";
import LoadingButton from "../common/loadingButton";
import Modal from "../common/modal";
import ProgressLoader from "../common/progressLoader";

import WithType from "../hoc/withFacilityType";
import WithSize from "../hoc/withFacilitySize";

import { updateHospital } from "../../services/prospectsService";
import toastService from "../../services/toastService";

class EditProspectForm extends Form {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        name: "",
        phone: "",
        email: "",
        facility: "",
        contact_name: "",
        hospital_size: "",
        socials: "",
        bed: "",
        address: "",
      },
      errors: {},
      progress: 0,
    };

    this.schema = {
      name: Joi.string()
        .trim()
        .regex(/^[a-zA-Z_ ]+$/, "hospital name")
        .min(4)
        .required()
        .label("Hospital Name"),
      phone: Joi.string()
        .trim()
        .regex(this.getPhonePattern(), "valid phone number")
        .required()
        .label("Phone Number"),
      email: Joi.string().email().required().label("Email Address"),
      facility: Joi.string().required().label("Facility Type"),
      contact_name: Joi.string()
        .trim()
        .regex(/^[a-zA-Z_ ]+$/, "contact name")
        .allow(null, "")
        .required()
        .label("Contact Name"),
      hospital_size: Joi.string().required().label("Facility Size"),
      socials: Joi.string().allow(null, "").required().label("Social Media"),
      bed: Joi.number().min(0).required().label("Number of Beds"),
      address: Joi.string().min(4).required().label("Street Address"),
    };
  }

  componentDidMount() {
    this.mapHospitalData();
  }

  mapHospitalData = () => {
    const data = { ...this.state.data };
    const { selectedProspect } = this.props;

    data.name = selectedProspect.name ?? "";
    data.phone = selectedProspect.phone ?? "";
    data.email = selectedProspect.email ?? "";
    data.socials = selectedProspect.socials ?? "";
    data.bed = selectedProspect.bed ?? "";
    data.address = selectedProspect.address ?? "";
    data.facility = selectedProspect.facility ?? "";
    data.contact_name = selectedProspect.contact_name ?? "";
    data.hospital_size = selectedProspect.hospital_size ?? "";

    this.setState({ data, hospital: selectedProspect });
  };

  handleProgressChange = (progress) => {
    this.setState({ progress });
  };

  getApiData = () => {
    const { data, hospital } = this.state;

    const hospital_id = hospital.id.toString();

    const apiData = {
      ...hospital,
      ...data,
      id: hospital_id,
      hospital_id,
      no_of_beds: data.bed,
    };

    return apiData;
  };

  postData = async () => {
    const apiData = this.getApiData();

    const res = await updateHospital(apiData, this.handleProgressChange);
    if (!res.data.ok) throw new Error(res.data.description);

    this.props.handleClose();
  };

  doSubmit = () => {
    toastService.promise(this.postData(), {
      loading: "Updating data...",
      success: () => "Updated Successfully",
      error: (e) =>
        e?.response?.data?.description ||
        e.message ||
        "Could not edit facility",
    });
  };

  render() {
    const { facilitySize, facilityType, handleClose, loading } = this.props;
    const { progress } = this.state;

    return (
      <Modal handleClose={handleClose}>
        <ProgressLoader progress={progress} />
        <div className="container">
          <form onSubmit={this.handleSubmit}>
            <h3 className="modal-title">Update Facility Information</h3>

            <div className="row">
              <div className="col-lg">
                {this.renderInput({
                  type: "text",
                  name: "name",
                  placeholder: "e.g. Doren Hospital",
                  compulsory: true,
                  disabled: true,
                })}
              </div>
              <div className="col-lg">
                {this.renderInput({
                  type: "number",
                  name: "phone",
                  placeholder: "e.g. 08142317489",
                  compulsory: true,
                })}
              </div>
            </div>

            {this.renderInput({
              type: "text",
              name: "contact_name",
              placeholder: "Contact Name e.g. John Doe",
            })}

            <div className="row">
              <div className="col-lg">
                {this.renderInput({
                  type: "email",
                  name: "email",
                  placeholder: "e.g. johndoe@gmail.com",
                  compulsory: true,
                })}
              </div>
              <div className="col-lg">
                {this.renderInput({
                  type: "text",
                  name: "socials",
                  placeholder: "Social Handle e.g. @dorenhospital",
                })}
              </div>
            </div>

            {this.renderInput({
              type: "number",
              name: "bed",
              placeholder: "Number of Beds e.g. 6",
              compulsory: true,
            })}

            <div className="row">
              <div className="col-lg">
                {this.renderSelect({
                  name: "facility",
                  placeholder: "Facility Type",
                  options: facilityType,
                  compulsory: true,
                })}
              </div>
              <div className="col-lg">
                {this.renderSelect({
                  name: "hospital_size",
                  placeholder: "Facility Size",
                  options: facilitySize,
                  compulsory: true,
                })}
              </div>
            </div>

            {this.renderTextArea({
              name: "address",
              placeholder: "10 John Doe Street",
            })}

            <div className="d-flex justify-content-end">
              <LoadingButton visible={loading} message="Please wait" />
              {!loading && this.renderButton("Update")}
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

export default WithSize(WithType(EditProspectForm));
