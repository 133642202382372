import React from 'react'
import ExodusHeader from '../../../../components/exodusComponent/ExodusHeader';

const ReviewActiveSchedule = () => {
    return (
        <div className="w-full">
            <ExodusHeader Text={'Review Active Schedule'} subText={''} />
            <div className="flex h-screen w-11/12 mx-auto items-center justify-center">
                <p className="text-2xl">Coming soon!</p>
            </div>
        </div>
    )
}

export default ReviewActiveSchedule;