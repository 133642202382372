function addComma(x) {
  return x?.toString()?.replace?.(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function removeComma(num) {
  return num?.toString()?.replace(/,/g, "");
}


// eslint-disable-next-line
export default { addComma, removeComma};
