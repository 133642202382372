import http from "./httpService";

export const getAllHospitals = async (
  userid: string,
  page: number,
  limit: number,
  status: string = "",
  name: string
) => {
  const queryParams = new URLSearchParams();

  // userid=${userid}&name=${name}&page=${page}&limit=${limit}&status=${status}

  if (userid !== undefined && userid !== null && userid !== "") {
    queryParams.append("userid", (userid as string).toString());
  }

  if (page !== undefined && page !== null && page !== 0) {
    queryParams.append("page", page.toString());
  }

  if (limit !== undefined && limit !== null && limit !== 0) {
    queryParams.append("limit", limit.toString());
  }

  if (status !== undefined && status !== null && status !== "") {
    queryParams.append("status", status);
  }

  if (name !== undefined && name !== null && name !== "") {
    queryParams.append("name", name);
  }
  return await http.get(
    `/hospital_success/success/all?${queryParams.toString()}`
  );
};

export const getHospital = async (id: string) => {
  return await http.get(`/hospital_success/success/one/${id}`);
};

export const getHospitalHistory = async (id: string) => {
  return await http.get(`/hospital_success/success/one/status-history/${id}`);
};

export const createCallLog = async (data: any, hospital_ref: string) => {
  const options = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  };
  return await http.post(
    `/hospital_success/success/log-call/${hospital_ref}`,
    data,
    options
  );
};

export const callCount = async (ref_id: string, status: string) => {
  return await http.get(
    `/hospital_success/success/one/count-calls/${ref_id}?status=${status}`
  );
};

export const getStatusHistoryCalls = async (id: string) => {
  return await http.get(`/hospital_success/success/one/status-history/${id}`);
};

export const currentActiveSchedule = async (hospital_ref: string) => {
  return await http.get(
    `/hospital_success/success/one/active-schedule/${hospital_ref}`
  );
};

export const updateDangerHospitals = async (data: any) => {
  const payload = {
    maintenance_issue_resolved: data.maintenance_issue_resolved,
    manual_issue_resolved: data.manual_issue_resolved,
    complaint_issue_resolved: data.complaint_issue_resolved,
    latedelivery_issue_resolved: data.latedelivery_issue_resolved,
  };

  return await http.put(
    `/hospital_success/success/danger-hospital/${data.refId}/update-health`,
    payload
  );
};

export const getDangerTrack = async (data: any) => {
  return await http.get(
    `/hospital_success/success/danger-hospital-track/${data}`
  );
};

export const updateDangerTrack = async (data: any) => {
  return await http.put(
    `/hospital_success/success/danger-hospital-track/${data.ref}/update`,
    {
      records: { ...data.record },
    }
  );
};

// Confirm mastery Pre active health

const confirmMastery = async (data: any) => {
  return await http.put(
    `/hospital_success/success/confirm-mastery/${data.hospital_ref}`,
    data.data
  );
};

const completeOnboarding = async (data: any) => {
  return await http.post(
    `/hospital_success/success/complete-onboarding/${data.hospital_ref}`,
    data.data
  );
};

// Newly active hospital
const completeSchedule = async (data: any) => {
  const payload = {
    goal_met: data.goal_met,
    nerve_goal_met: data.nerve_goal_met,
    geo_location: data.geo_location,
  };

  return await http.post(
    `/hospital_success/success/complete-schedule/${data.hospital_ref}`,
    payload
  );
};

const completeMastery = async (hospital_ref: any) => {
  return await http.post(
    `/hospital_success/success/add-value-hospital/complete`,
    hospital_ref
  );
};

const checkMissedMaintenance = async (hospital_ref: any) => {
  return await http.get(
    `/hospital_success/success/one/${hospital_ref}/check-missed-maintenace`
  );
};

const checkManualOrder = async (hospital_ref: any) => {
  return await http.get(
    `/hospital_success/success/one/${hospital_ref}/check-manual-order`
  );
};

const getProcurementCycle = async (hospital_ref: any) => {
  return await http.get(
    `/hospital_success/success/one/${hospital_ref}/pre-active-hospital/procurement-cycle`
  );
};

const getSingleHospital = async (hospitalId: string) => {
  return http.get("/admin/" + hospitalId);
};

const hospitalsService = {
  getAllHospitals,
  getHospital,
  getHospitalHistory,
  createCallLog,
  callCount,
  getStatusHistoryCalls,
  currentActiveSchedule,
  updateDangerHospitals,
  getDangerTrack,
  updateDangerTrack,
  confirmMastery,
  completeOnboarding,
  completeSchedule,
  completeMastery,
  checkMissedMaintenance,
  checkManualOrder,
  getProcurementCycle,
  getSingleHospital,
};

export default hospitalsService;
