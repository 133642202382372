import React from "react";

interface prop {
    message: string;
    visible: boolean;
}

const ExodusLoading = ({ visible = true, message }: prop) => {
    if (!visible) return null;

    return (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-white bg-opacity-75 z-50">
            <div className="text-[#2f49d1]">
                <i className="fa fa-spinner fa-spin text-6xl"></i>
                <span className="text-2xl">{message}...</span>
            </div>
        </div>
    );
};

export default ExodusLoading;
