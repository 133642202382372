import React from "react";
import Joi from "joi-browser";

import Form from "../common/form";
import ProgressLoader from "../common/progressLoader";

import { forgetPassword } from "../../services/authService";
import { promise as toastPromise } from "../../services/toastService";

class ForgotPassForm extends Form {
  state = {
    data: {
      email: "",
    },
    errors: {},
    progress: 0,
  };

  schema = {
    email: Joi.string().email().required().label("Email Address"),
  };

  getErrorMessage = (ex) => {
    return ex?.response?.data?.message ?? ex.message ?? "An error occured";
  };

  handleProgressChange = (progress) => {
    this.setState({ progress });
  };

  postData = async () => {
    const { email } = this.state.data;
    await forgetPassword(email, this.handleProgressChange);
  };

  doSubmit = () => {
    toastPromise(this.postData(), {
      loading: "Please wait...",
      success: () =>
        "Password changed successfully. Check your email for new password",
      error: this.getErrorMessage,
    });
  };

  render() {
    return (
      <React.Fragment>
        <ProgressLoader progress={this.state.progress} />

        <form onSubmit={this.handleSubmit}>
          {this.renderInput({
            type: "text",
            name: "email",
            placeholder: "e.g. johndoe@gmail.com",
            label: "Email Address",
          })}

          {this.renderButton("Submit")}
        </form>
      </React.Fragment>
    );
  }
}

export default ForgotPassForm;
