import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

export const QueryProvider = ({ children }: { children: React.ReactNode }) => {
  const queryClient = new QueryClient();
  // {
  //   defaultOptions: {
  //     queries: {
  //       refetchOnWindowFocus: false,
  //       retry: false,
  //     },
  //   },
  // }

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
