import React from "react";
import Joi from "joi-browser";

import Form from "../common/form";
import LoadingButton from "../common/loadingButton";
import Modal from "../common/modal";
import ProgressLoader from "../common/progressLoader";

import withCities from "../hoc/withCities";
import WithSize from "../hoc/withFacilitySize";
import WithType from "../hoc/withFacilityType";
import WithHospitals from "../hoc/withHospitals";



import os from "../../services/onboardService";
import toastService from "../../services/toastService";

class ComplainPitch extends Form {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        complain: "",
      },
      errors: {},
      progress: 0,
    };

    this.schema = {
      complain: Joi.string().min(4).required().label("Complaint Description"),
    };
  }

  

  handleProgressChange = (progress) => {
    this.setState({ progress });
  };

  handleEvent = () => {
    const data = { ...this.state.data };

    this.setState({ data });
  };

  getApiData = () => {
    const { data } = this.state;
    const { selectedPitch } = this.props;

    const formData = {
      complain: data.complain,
      hospital_id: selectedPitch.hospital_id,
    };

    return formData;
  };

  postData = async () => {
    const formData = this.getApiData();

    const res = await os.addComplain(formData, this.handleProgressChange);
    if (res.data.status !== "success") throw new Error(res.data.message);

    console.log("form", res);

    this.props.handleClose();
  };

  doSubmit = () => {
    toastService.promise(this.postData(), {
      loading: "Uploading data...",
      success: () => "Complaint has been created",
      error: (e) =>
        e?.response?.data?.message ||
        e?.message ||
        "Could not log this complain!",
    });
  };

  render() {
    const { progress } = this.state;
    const { loading } = this.props;

    return (
      <Modal {...this.props}>
        <ProgressLoader progress={progress} />
        <form onSubmit={this.handleSubmit}>
          <h2 className="form-header">
            Fields marked <i className="fa fa-asterisk error"></i> are required
          </h2>
          <div className="row">
            <div className="col-lg">
              {this.renderTextArea({
                name: "complain",
                placeholder: "e.g. Hospital is too far",
                label: "Complaint Description",
                compulsory: true,
              })}
            </div>
          </div>

          <div className="d-flex justify-content-end ">
            <LoadingButton visible={loading} message="Please wait" />
            {!loading && this.renderButton("Submit")}
          </div>
        </form>
      </Modal>
    );
  }
}

const customComponent = WithHospitals(WithSize(WithType(ComplainPitch)));

export default withCities(customComponent);
