import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { HashLoader } from "react-spinners";

import {
  useGetOrderHistory,
  useTrackOrder,
  useConfirmDelivery,
} from "../../../../hooks/purchasingOrder/usePurchaseOrder";
import { Button } from "../../../../@/components/ui/button";
import OrderTracker from "./OrderTracker";
import Header from "../../../../components/blocks/header";

const TrackingPage = () => {
  const navigate = useNavigate();
  const { user_id, order_id, order_type } = useParams();

  const {
    orderHistory,
    isPending: isHistory,
    hospitalInfo,
  } = useGetOrderHistory(order_id as string, order_type as string);

  const [hospital] = hospitalInfo;
  console.log(hospital);

  const { track, isPending: isTracking } = useTrackOrder(
    order_id as string,
    orderHistory.dispatch as string,
    order_type as string
  );

  console.log(track);

  const { mutateDelivery, isPending } = useConfirmDelivery();

  const isOrderStatusNull = Object.values(track).every(
    (value) => value === null
  );
  const isDelivered = track["delivered"] !== null;

  const confirmDelivery = async () => {
    const apiData = {
      id: order_id as string,
      product: order_type as string,
    };
    mutateDelivery(apiData);
  };

  const backClick = () => {
    navigate("/purchasing-orders");
  };
  return (
    <div>
      {isHistory ? (
        <div className="flex justify-center items-center w-full">
          <HashLoader color={"blue"} size={50} />
        </div>
      ) : (
        <>
          <Header title={`Tracking Order ID - ${order_id}`} />
          <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-5">
            <div className="flex flex-col justify-start items-start w-full rounded-md shadow-md p-4 h-full">
              {/* <h1 className="text-2xl font-bold mt-2 mb-4">
                Order ID: {order_id}
              </h1> */}
              <OrderTracker orderStatus={track} />
              {!isOrderStatusNull && (
                <Button
                  onClick={isDelivered ? confirmDelivery : undefined}
                  className="text-white font-medium hover:text-gray-500 hover:opacity-85"
                >
                  {isDelivered ? "Confirm Delivery" : "Ongoing"}
                </Button>
              )}
            </div>
            <div className="flex flex-col justify-start items-center w-full rounded-md shadow-md p-4">
              <h1 className="text-2xl text-center font-bold mt-2 mb-4 uppercase">
                Order Details
              </h1>

              <div className="w-2/3 mb-5">
                <h3 className="text-center font-semibold">Quantity</h3>
                <p className="text-base text-center border rounded-md p-3">
                  {order_type === "oxygen" ? (
                    <>
                      {orderHistory.qty}X Units of {orderHistory.cylinder} OF
                      OXYGEN
                    </>
                  ) : (
                    <>
                      {orderHistory.order_amt}X - PINTS OF {orderHistory.b_type}{" "}
                      {orderHistory.blood}
                    </>
                  )}
                </p>
              </div>

              <div className="w-2/3 mb-5">
                <h3 className="text-center font-semibold">Delivery Address</h3>
                <p className="text-base text-center border rounded-md p-3">
                  {hospital.address}
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TrackingPage;
