import React, { useState } from "react";
import ExodusHeader from "../../../../components/exodusComponent/ExodusHeader";
import TitleDash from "../../../../components/exodusComponent/TitleDash";
import { MenuItem, Select } from "@mui/material";
// import RadioButton from "../../../../components/exodusComponent/RadioButton";
// import { MdOutlineMail, MdOutlineTextsms } from "react-icons/md";
// import { BsWhatsapp } from "react-icons/bs";
import { IoCopyOutline } from "react-icons/io5";
import ExodusButton from "../../../../components/exodusComponent/ExodusButton";
import Modal from "../../../../components/exodusComponent/Modal";
import ExodusLoading from "../../../../components/exodusComponent/ExodusLoding";

import { success, error } from "../../../../services/toastService";

import { useGetExodus, useSendNote } from "../../../../hooks/useExodus";
import { useGlobalContext } from "../../../../contexts/globalContext";
const SendANote = () => {
  const { user } = useGlobalContext();
  const { mutateSendNote, isPending } = useSendNote();
  const { exodus: exo } = useGetExodus(user?.id as string);

  const [selectedOption, setSelectedOption] = useState("");
  const [selectedFile, setSelectedFile] = useState(null); 
  const [note, setNote] = useState("");
  const [noteRequiredError, setNoteRequiredError] = useState(false);
  const [fileRequiredError, setFileRequiredError] = useState(false);
  const [optionRequiredError, setOptionRequiredError] = useState(false); 
  const [showModal, setShowModal] = useState(false);

  const handleOptionChange = (event: any) => {
    setSelectedOption(event.target.value);

    setOptionRequiredError(false);
  };

  const handleFileChange = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setFileRequiredError(false);
  };

  const handleNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNoteRequiredError(false);
    setNote(event.target.value);
  };

  const handleSubmit = async () => {
    if (!selectedOption) {
      return setOptionRequiredError(true);
    }
    // if (!selectChanel) {
    //   return setChanelError(true);
    // }
    if (!note.trim()) {
      return setNoteRequiredError(true);
    }
    // if (!selectedFile) {
    //   return setFileRequiredError(true);
    // }
    if (optionRequiredError || noteRequiredError) {
      return;
    }

    const formData = new FormData();
    formData.append("message_mode", selectedOption);
    formData.append("rep_id", user?.id as string);
    // @ts-ignore
    if (selectedFile) {
      formData.append("sendnote_file", selectedFile);
    }
    formData.append("draft_note", note);

    mutateSendNote(formData, {
      onSuccess: (data: { data: { description: string; }; }) => {
        success(data?.data?.description ?? "Note sent successfully");
        setShowModal(true);
      },
      onError: (err: any) => {
        console.log(err?.response?.data?.description);
        error(err?.response?.data?.description ?? "An error occurred");
      },
    });
  };

  return (
    <div>
      {showModal && <Modal text="You note was sent successfully" />}
      <ExodusLoading message={"Loading"} visible={isPending} />
      <ExodusHeader Text="Send a Note" subText="| Exodus Arena > Send a Note" />
      <div className="w-10/12 mx-auto">
        <TitleDash text="Find a hospital and send a note to them" />
      </div>

      <div className="bg-[#FFFFFF] shadow-white shadow-lg rounded w-full mx-auto p-4  mb-2">
        <div className="w-11/12 mx-auto mb-4">
          <p className="font-bold text-[18px] mb-2">Find Hospital</p>
          <div>
            <Select
              className="w-full h-[50px]"
              value={selectedOption}
              onChange={handleOptionChange}
            >
              {exo?.hospital_details_dash?.map((item: any) => (
                <MenuItem
                  value={item?.hospital_details?.name}
                  key={item?.ref_id}
                >
                  {item?.hospital_details?.name}
                </MenuItem>
              ))}
            </Select>
            <p>
              {optionRequiredError && (
                <span className="text-red-500">Please select a hospital</span>
              )}
            </p>
          </div>

          {/* <p className="font-bold text-[18px] mb-4 mt-4">
            Select Message mode below
          </p> */}

          {/* <div className="flex w-8/12 justify-between items-center  mt-2 mb-4">
            <span className="flex justify-center items-center">
              <RadioButton
                id="Email"
                value="email"
                checked={selectChanel === "email"}
                onChange={() => setSelectChanel("email")}
                label="Email"
              />
              <div className="flex ">
                <MdOutlineMail size={40} />
              </div>
            </span>
            <span className="flex justify-center items-center">
              <RadioButton
                id="SMS"
                value="sms"
                checked={selectChanel === "sms"}
                onChange={() => setSelectChanel("sms")}
                label="SMS"
              />
              <div className="flex ">
                <MdOutlineTextsms size={40} />
              </div>
            </span>
            <span className="flex justify-center items-center">
              <RadioButton
                id="Whatsapp"
                value="whatsapp"
                checked={selectChanel === "whatsapp"}
                onChange={() => setSelectChanel("whatsapp")}
                label="whatsapp"
              />
              <div className="flex mr-2">
                <BsWhatsapp size={40} />
              </div>
            </span>
          </div>
          {chanelError && <p className="text-red-500">Please select a mode</p>} */}

          <div className="mt-4 mb-4">
            <p className="mb-4 font-bold text-[18px]">Draft Note</p>
            <textarea
              onChange={handleNoteChange}
              name="postContent"
              placeholder="Enter note..."
              className="w-full h-[150px] p-2 border border-black shadow-white shadow-sm rounded"
            />
            {noteRequiredError && (
              <p className="text-red-500">Note is required</p>
            )}
          </div>
          <div>
            <div className="w-full mx-auto mb-4">
              <label
                htmlFor="fileInput"
                className="flex items-center cursor-pointer"
              >
                <IoCopyOutline size={25} />
                <span className="ml-3 text-1xl">Attach File</span>
                {/* @ts-ignore */}
                <p className="ml-2 text-[#4D26A0]"> {selectedFile?.name}</p>
              </label>
              <input
                id="fileInput"
                className="text-[#4D26A0]"
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              {fileRequiredError && (
                <p className="text-red-500">File is required</p>
              )}
            </div>
          </div>
          <div className=" w-3/12 flex ml-auto">
            <ExodusButton onClick={handleSubmit} text="Send Note" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendANote;
