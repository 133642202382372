import React, { Dispatch, SetStateAction } from "react";
import { UploadIcon } from "@radix-ui/react-icons";
import toastService from "../../../../services/toastService";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../@/components/ui/form";
import { Textarea } from "../../../../@/components/ui/textarea";

export default function CreateDazzle({
  form,
  sample,
  setSample,
}: {
  form: any;
  sample: File | null;
  setSample: Dispatch<SetStateAction<File | null>>; 
}) {
  const uploadSample = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const maxSize = 1024 * 1024 * 10;
      if (file.size <= maxSize) {
        setSample(file);
      } else {
        console.log("error");
        toastService.error(
          "File size exceeds the limit, please choose an image less than 10MB"
        );
      }
    }
  };

  return (
    <Form {...form}>
      <form className="w-full px-3">
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem className="w-full">
              <div className="flex flex-col  gap-3 w-full">
                <FormLabel className="text-start">Dazzle Description</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="Describe what's special about your dazzle & other important details."
                    className="resize-none w-full"
                    {...field}
                  />
                </FormControl>
              </div>
              <div className="flex items-center justify-end">
                <FormMessage />
              </div>
            </FormItem>
          )}
        />

        <div className="flex items-start flex-col space-y-5 col-span-2">
          <FormLabel className="font-semibold ">Upload</FormLabel>
          <div className="upload">
            <label
              htmlFor="sampleImage"
              className="flex items-center justify-center h-80  max-h-72 bg-[#C4C4C426] border-dashed border-2 w-full "
            >
              {sample ? (
                <img
                  className="rounded-md object-fit max-h-full"
                  src={URL.createObjectURL(sample)}
                  alt="preview"
                />
              ) : (
                <div className="">
                  <UploadIcon className="w-20 h-20" />
                </div>
              )}
              <input
                name="sampleImage"
                type="file"
                id="sampleImage"
                accept=".jpeg, .png, .jpg"
                className="upload-input"
                onChange={uploadSample}
              />
            </label>
          </div>
        </div>
      </form>
    </Form>
  );
}
