import config from "../utils/config";
import httpService from "./httpService";

export const getLeadershipBoard = (apiBaseUrl, city) => {
  return httpService.get(apiBaseUrl + "/auto/leaderboard/" + city);
};

export const getRevenue = (city) => {
  const finalUrl = city ? `/${city}` : "";
  return httpService.get(config.liteApiUrl + "/auto/mtd" + finalUrl);
};

export const getUnfulfilled = (apiUrl) => {
  return httpService.get(apiUrl + "/auto/unfulfilled");
};

export const getData = (userId) => {
  return httpService.get("/admin/dashboard/data/" + userId);
};

export const getFeed = (userId) => {
  return httpService.get("/admin/news/feed/" + userId);
};

export const getCountryRevenue = (apiUrl) => {
  return httpService.get(apiUrl);
};

export const getCities = (country) => {
  const url = config.countriesApiUrl + "/countries/states";

  return httpService.post(url, { country });
};

export const setTarget = (data, handleProgressChange) => {
  const options = {
    onUploadProgress: (progressEvent) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  return httpService.post("/admin/target/monthly", data, options);
};

export const sendFeedback = (data, handleProgressChange) => {
  const options = {
    onUploadProgress: (progressEvent) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  return httpService.post("/support/", data, options);
};

export const sendFeedbackEmail = (data, handleProgressChange) => {
  const options = {
    onUploadProgress: (progressEvent) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  const apiData = {
    service_id: config.emailJsServiceID,
    template_id: config.emailJsTemplateID,
    user_id: config.emailJsUserID,
    template_params: {
      Page: data.url,
      user: data.user_id,
      email: data.email,
      message: data.request,
    },
  };

  return httpService.post(config.emailJsApiUrl, apiData, options);
};

const dashboardService = {
  getCities,
  getData,
  getFeed,
  getLeadershipBoard,
  getRevenue,
  getUnfulfilled,
  getCountryRevenue,
  sendFeedback,
  sendFeedbackEmail,
  setTarget,
};

export default dashboardService;
