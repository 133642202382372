import React, { useState } from "react";
import Joi from "joi-browser";
import Modal from "../common/modal";
import ProgressLoader from "../common/progressLoader";
import {
  sendFeedback,
  sendFeedbackEmail,
} from "../../services/dashboardService";
import toastService from "../../services/toastService";
import { useGlobalContext } from "../../contexts/globalContext";
const SendFeedback = (props) => {
  const [data, setData] = useState({ request: "" });
  const [errors, setErrors] = useState({});
  const [progress, setProgress] = useState(0);

  const { user } = useGlobalContext();

  const schema = {
    request: Joi.string().required().label("Feedback"),
  };

  const handleProgressChange = (progress) => setProgress(progress);

  const getApiData = () => {
    const { id: user_id, email } = user;
    const apiData = { ...data, user_id, email, url: window.location.href };
    return apiData;
  };

  const sendToDatabase = async (apiData) => {
    let result = await sendFeedback(apiData, handleProgressChange);
    if (result.data.status !== "success") return false;
    return true;
  };

  const sendToEmail = async (apiData) => {
    let res = await sendFeedbackEmail(apiData, handleProgressChange);
    if (res.status !== 200) return false;
    return true;
  };

  const postData = async () => {
    let apiData = getApiData();
    let dataBaseSuccessful = await sendToDatabase(apiData);
    if (!dataBaseSuccessful) throw new Error("Could not send feedback to DB!");
    let emailSuccessful = await sendToEmail(apiData);
    if (!emailSuccessful) throw new Error("Could not send feedback to email!");
    props.handleClose();
  };

  const doSubmit = () => {
    toastService.promise(postData(), {
      loading: "Uploading Feedback...",
      success: () => "Feedback Sent Successfully",
      error: (e) =>
        e?.response?.data?.message || e.message || "Could not send feedback",
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validate();
    setErrors(errors || {});
    if (errors) return;
    doSubmit();
  };

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(data, schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const handleChange = ({ currentTarget: input }) => {
    const newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  };

  const renderTextArea = ({ name, label, placeholder }) => (
    <div className="form-group mb-3 px-2">
      <label htmlFor={name}>{label}</label>
      <textarea
        value={data[name]}
        onChange={handleChange}
        id={name}
        name={name}
        className="form-control"
        placeholder={placeholder}
        rows={5}
      />
      {errors[name] && <div className="alert alert-danger">{errors[name]}</div>}
    </div>
  );

  const renderButton = (label) => (
    <button disabled={validate()} className="btn btn-primary">
      {label}
    </button>
  );

  return (
    <Modal {...props}>
      <ProgressLoader progress={progress} />
      <form onSubmit={handleSubmit}>
        <h3 className="modal-title text-center primary">
          Send <span className="error">Feedback</span>
        </h3>

        {renderTextArea({
          name: "request",
          placeholder: "e.g. I can't add hospitals",
          label: "Feedback",
        })}

        <div className="modal-buttons">{renderButton("Send")}</div>
      </form>
    </Modal>
  );
};

export default SendFeedback;
