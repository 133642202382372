import React, { useState } from "react";
import _ from "lodash";

import CustomVideoPlayer from "../../../components/blocks/customVideoPlayer";
import Header from "../../../components/blocks/header";
import Pagination from "../../../components/common/pagination";
import PDFButton from "../../../components/blocks/pdfButton";
import VideoButton from "../../../components/blocks/videoButton";

import utilities from "../../../utils/utilities";

const Tutorials = () => {
  const [tutorialUrl, setTutorialUrl] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const pageSize = 6;

  const getPaginatedData = () => {
    const sorted = _.orderBy(tutorials, ["id"], ["asc"]);
    const paginated = utilities.paginate(sorted, currentPage, pageSize);

    return { totalCount: tutorials.length, paginated };
  };

  const { totalCount, paginated } = getPaginatedData();

  return (
    <React.Fragment>
      <Header title="Support Tutorials" />

      <CustomVideoPlayer
        url={tutorialUrl}
        handleClose={() => setTutorialUrl(null)}
      />

      <div className="support-container">
        {paginated.map((tutorial) => {
          let isVideo = tutorial.type === "video";
          let isDocument = tutorial.type === "document";

          return (
            <div className="support-container-item" key={tutorial.id}>
              <div className="support-container-item-inner">
                <div className="inner-container">
                  <h4>{tutorial.title}</h4>
                  <p>{tutorial.subtitle}</p>

                  {isVideo && (
                    <VideoButton onClick={() => setTutorialUrl(tutorial.src)} />
                  )}

                  {isDocument && (
                    <PDFButton title={tutorial.title} href={tutorial.href} />
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="sb-pagination-container">
        <Pagination
          itemsCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </React.Fragment>
  );
};

const tutorials = [
  {
    id: 1,
    type: "video",
    src: "https://res.cloudinary.com/daes0mma0/video/upload/v1641388866/Tutorial%20/CEOS-_Country_Manager_brtynt.mp4",
    subtitle: "Video tutorial on how to navigate the system as a Country CEO",
    title: "CEOs - Country Manager",
  },
  {
    id: 2,
    type: "video",
    src: "https://res.cloudinary.com/daes0mma0/video/upload/v1641391144/Tutorial%20/Untitled_sdd3hf.mp4",
    subtitle: "Video tutorial on how to navigate the system as a Sales Lead",
    title: "Sales Lead",
  },
  {
    id: 3,
    type: "video",
    src: "https://res.cloudinary.com/daes0mma0/video/upload/v1641388814/Tutorial%20/Sales-Rep_sphjna.mp4",
    subtitle:
      "Video tutorial on how to navigate the system as a Sales Representative",
    title: "Sales Rep",
  },
  {
    id: 4,
    type: "document",
    href: "/documents/feedback_and_complaints.pdf",
    subtitle: "How to send feedback and complaints to the tech team",
    title: "Feedback and Complaints",
  },
  {
    id: 5,
    type: "document",
    href: "/documents/positions_and_levels.pdf",
    subtitle:
      "View access-level privileges and functionalities based on user roles",
    title: "Positions and Levels",
  },
];

export default Tutorials;
