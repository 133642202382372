import { SelectOption } from "../../ts-utils/interfaces";

const Select = (props: any) => {
  const { name, label, options, error, compulsory, ...rest } = props;

  return (
    <div className="form-field">
      {label && (
        <label htmlFor={name} className="form-field-label">
          {compulsory && <i className="fa fa-asterisk error"></i>} {label}
        </label>
      )}

      <select name={name} id={name} {...rest} className="form-field-input">
        <option value="">{props.placeholder}</option>
        {options.map((option: SelectOption) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>

      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default Select;
