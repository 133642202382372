import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import ExodusHeader from "../../../../components/exodusComponent/ExodusHeader";

import { IoCopyOutline } from "react-icons/io5";
import { success, error } from "../../../../services/toastService";
import { GoDash } from "react-icons/go";
import TransitionsModal from "../../../../components/modals/mod/CustomModal";
import LoadingButton from "../../../../components/common/loadingButton";
import { useGlobalContext } from "../../../../contexts/globalContext";

import { useCreateCallLog } from "../../../../hooks/useHospital";

const HospitalCall = () => {
  const history = useNavigate();
  const { user } = useGlobalContext();
  const location = useLocation() as any;
  const { name, id, mode, cord, ref_id } = location?.state;

  const { mutateCallLog, isPending } = useCreateCallLog(ref_id);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [note, setNote] = useState("");
  const [selectedOption, setSelectedOption] = useState(
    "Change active Schedule"
  );

  const [modal, setModal] = useState(false);
  const [noteRequiredError, setNoteRequiredError] = useState(false);
  const [fileRequiredError, setFileRequiredError] = useState(false);
  const [optionRequiredError, setOptionRequiredError] = useState(false);

  const handleFileChange = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setFileRequiredError(false);
  };

  const handleNoteChange = (event: any) => {
    setNote(event.target.value);
    setNoteRequiredError(false);
  };

  const handleSelectChange = (event: any) => {
    setSelectedOption(event.target.value);
    setOptionRequiredError(false);
  };

  const dropDown = [
    {
      name: "Needs Nerve Training",
    },
    {
      name: "To learn Nerve",
    },
    {
      name: "Re-pitch",
    },
    {
      name: "Will place an order",
    },
  ];

  const handleSuccessModal = async () => {
    if (!note) {
      setNoteRequiredError(true);
      return;
    }
    if (!selectedOption) {
      setOptionRequiredError(true);
      return;
    }
    if (!selectedFile) {
      setFileRequiredError(true);
      return;
    }
    const location = [];
    if (cord) {
      location.push(parseFloat(cord.split(",")[0]));
      location.push(parseFloat(cord.split(",")[1]));
    }
    const formData = new FormData();

    formData.append("log_note", note);
    formData.append("meeting_result", selectedOption);

    formData.append("file", selectedFile);
    formData.append("customer_success_rep_ref", user?.id as string);
    formData.append("hospital_id", id);
    formData.append("hospital_ref", ref_id);
    formData.append("mode_of_contact", mode);
    if (cord) {
      formData.append("geo_location", `[${location}]`);
    }

    mutateCallLog(formData, {
      onSuccess: (data) => {
        setModal(true);
        success(data?.data?.message, "success");
      },
      onError: (err: any) => {
        console.log(err);
        error(err?.message, "error");
      },
    });
  };
  return (
    <div className="w-full">
      <LoadingButton
        message={"Submiting data... Please wait"}
        visible={isPending}
      />
      {modal && (
        <TransitionsModal open={modal} setOpen={setModal}>
          <div className="flex flex-col mx-auto mb-4 items-center h-3/5 justify-center">
            <p className="text-2xl mb-8 text-center">
              Your call has been logged successfully
            </p>
            <button
              className="border border-red-500 p-2 flex mx-auto  items-center justify-center w-[100px] cursor-pointer"
              onClick={() => {
                setModal(false);
                history(`/hospitals/${id}`);
              }}
            >
              <p>Okay</p>
            </button>
          </div>
        </TransitionsModal>
      )}
      <ExodusHeader
        Text={"Log a call"}
        subText={`| Hospital > Log a Visit > ${name} > Log a call`}
      />

      <>
        <div className="w-10/12 flex flex-row m-4 items-center">
          <p className="text-8xl text-[#49209F]">
            <GoDash />
          </p>
          <p className="text-2xl">Log a call</p>
        </div>

        <div className="w-11/12 mx-auto bg-[#FFFFFF] shadow-white shadow-lg mb-5 p-4">
          <div className="mx-auto w-11/12 p-1">
            <p className="pb-4 text-2xl">Log notes</p>
            <textarea
              name="postContent"
              placeholder="log notes"
              className="w-full h-[150px] p-2 border border-black shadow-white shadow-lg rounded"
              value={note}
              onChange={handleNoteChange}
            />
            {noteRequiredError && (
              <p className="text-red-500">Note is required</p>
            )}
          </div>

          <div className="w-11/12 mx-auto mb-4">
            <label
              htmlFor="fileInput"
              className="flex items-center cursor-pointer"
            >
              <IoCopyOutline size={25} />
              <span className="ml-3 text-1xl">Attach File</span>
              <p className="text-[#4D26A0] pl-2 pr-2">
                {selectedFile && selectedFile?.name}{" "}
              </p>
            </label>
            <input
              id="fileInput"
              type="file"
              accept="image/jpeg, image/png, .pdf"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            {fileRequiredError && (
              <p className="text-red-500">File is required</p>
            )}
          </div>
          <div className="w-11/12 mx-auto">
            <p className="mb-2"> File meeting result</p>
            <select
              className="w-full border border-black rounded p-2"
              value={selectedOption}
              onChange={handleSelectChange}
            >
              {dropDown.map((ele) => {
                return (
                  <option
                    key={ele.name}
                    className="border border-black rounded"
                  >
                    {ele.name}
                  </option>
                );
              })}
            </select>
            {optionRequiredError && (
              <p className="text-red-500">Meeting result is required</p>
            )}

            <div className="w-full mt-4 ">
              <button
                disabled={isPending}
                className="w-full text-2xl font-bold text-[white] bg-[#4D26A0] rounded p-2"
                onClick={handleSuccessModal}
              >
                {isPending ? "Submitting" : "Submit Note"}
              </button>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default HospitalCall;
