import { useEffect, useState } from "react";

import { getHistory } from "../services/onboardService.js";
import { error as toastError } from "../services/toastService";

const useHospitalHistory = (id) => {
  const [history, setHistory] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getHospitalHistory = async () => {
    try {
      setLoading(true);

      const { data } = await getHistory(id);
      const fetchedHistory = data?.[0]?.history ?? {};

      setHistory(fetchedHistory);

      setLoading(false);
      setError(false);
    } catch (error) {
      const errorMessage = error?.response?.data?.description ?? error.message;
      toastError(errorMessage);

      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    getHospitalHistory();

    // eslint-disable-next-line
  }, []);

  return { history, loading, error };
};

export default useHospitalHistory;
