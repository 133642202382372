import { jsPDF } from "jspdf";
import toastService from "./toastService";

export default function saveFile(board) {
  if (!board.length) return toastService.error("There are no leaders!");

  const doc = new jsPDF({
    putOnlyUsedFonts: true,
    format: "a4",
    orientation: "landscape",
  });

  const headers = Object.keys(board[0]);

  doc.setFontSize(14);
  doc.setTextColor(171, 60, 60);
  doc.table(1, 1, board, headers, { autoSize: true });

  doc.save("leadership_board.pdf");
}
