import { Control, Controller, FieldErrors } from "react-hook-form";
import InputField from "../../common/input";
import RadioButton from "../../common/radio";
import { AddValueData } from "./types";
import CustomField from "../../common/CustomField";

type Props = {
  control: Control<AddValueData, any>;
  errors: FieldErrors<AddValueData>;
};

export default function ReferralCallLog({ control, errors }: Props) {
  return (
    <>
      <CustomField
        title="Date"
        style={{ paddingTop: "10px" }}
        value={
          <Controller
            control={control}
            name={`referral.date`}
            render={({ field: { onChange, value, name } }) => (
              <InputField
                style={{ width: "100%", border: "none", background: "#f1f1f1" }}
                autoFocus={false}
                name={name}
                onChange={onChange}
                placeholder="date"
                value={value}
                type="dateTime-local"
                error={errors?.referral?.date?.message || null}
              />
            )}
          />
        }
      />
      <CustomField
        title="Name of Hospital Agent"
        style={{ paddingTop: "10px" }}
        value={
          <Controller
            control={control}
            name={`referral.hospital_agent`}
            render={({ field: { onChange, value, name } }) => (
              <InputField
                style={{ width: "100%", border: "none", background: "#f1f1f1" }}
                autoFocus={false}
                name={name}
                onChange={onChange}
                placeholder="John Doe"
                value={value}
                type="text"
                error={errors?.referral?.hospital_agent?.message || null}
              />
            )}
          />
        }
      />

      <>
        <CustomField
          title={
            <span className="font-semibold">
              Questions to Ask Sales Agent :
            </span>
          }
          style={{ paddingTop: "10px" }}
        />
        <CustomField
          title="Have you heard of LifeBanks’s Hospital Referral"
          value={
            <Controller
              control={control}
              name={`referral.heard_of_referral`}
              render={({ field: { onChange, value, name } }) => (
                <div className="flex gap-4">
                  <RadioButton
                    name={name}
                    onChange={onChange}
                    value="1"
                    checked={value === "1"}
                    message="Yes"
                  />
                  <RadioButton
                    name={name}
                    onChange={onChange}
                    value="0"
                    checked={value === "0"}
                    message="No"
                  />
                </div>
              )}
            />
          }
        />
        <CustomField
          title="Will you like to join our Hospital Referral Program"
          style={{ paddingTop: "10px" }}
          value={
            <Controller
              control={control}
              name={`referral.join_referral`}
              render={({ field: { onChange, value, name } }) => (
                <div className="flex gap-4">
                  <RadioButton
                    name={name}
                    onChange={onChange}
                    value="1"
                    checked={value === "1"}
                    message="Yes"
                  />
                  <RadioButton
                    name={name}
                    onChange={onChange}
                    value="0"
                    checked={value === "0"}
                    message="No"
                  />
                </div>
              )}
            />
          }
        />
        <CustomField
          title="If yes, share information on LifeBank Hospital Referral."
          value=""
        />
        <CustomField
          title="Are you committing to joining our Hospital Referral Program"
          value={
            <Controller
              control={control}
              name={`referral.commit_to_referral`}
              render={({ field: { onChange, value, name } }) => (
                <div className="flex gap-4">
                  <RadioButton
                    name={name}
                    onChange={onChange}
                    value="1"
                    checked={value === "1"}
                    message="Yes"
                  />
                  <RadioButton
                    name={name}
                    onChange={onChange}
                    value="0"
                    checked={value === "0"}
                    message="No"
                  />
                </div>
              )}
            />
          }
        />
        <CustomField
          title="If no, we will like to hear your feedback on why"
          style={{ paddingTop: "10px" }}
          value={
            <Controller
              control={control}
              name={`referral.why_not`}
              render={({ field: { onChange, value, name } }) => (
                <InputField
                  style={{
                    width: "100%",
                    border: "none",
                    background: "#f1f1f1",
                  }}
                  autoFocus={false}
                  name={name}
                  onChange={onChange}
                  placeholder="type answer here"
                  value={value}
                  type="text"
                  error={errors?.referral?.why_not?.message || null}
                />
              )}
            />
          }
        />
      </>
    </>
  );
}
