interface TableButtonProps {
  onClick: () => void;
  buttonClass: string;
  label: string;
}

const TableButton = (props: TableButtonProps): JSX.Element => {
  const { onClick, buttonClass, label }: TableButtonProps = props;

  return (
    <button className={buttonClass} onClick={onClick}>
      {label}
    </button>
  );
};

export default TableButton;
