interface ConditionalProps {
  isVisible: boolean;
  children: any;
}

const Conditional = (props: ConditionalProps) => {
  if (!props.isVisible) return null;
  return props.children;
};

export default Conditional;
