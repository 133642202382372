import React, { useEffect, useLayoutEffect, useState } from "react";
import { ComponentProps, Hospital, ApiResponse } from "../../ts-utils/types";
import {
  PaginatedData,
  SortColumn,
  TableColumn,
} from "../../ts-utils/interfaces";

import _ from "lodash";

import Conditional from "../blocks/conditional";
import LoadingButton from "../common/loadingButton";
import Pagination from "../common/pagination";
import Table from "../common/table";
import TableButton from "../blocks/tableButton";

import utilities from "../../utils/utilities";

import { getNerveClaims } from "../../services/prospectsService";
import { error as toastError } from "../../services/toastService";
import ConfirmClaim from "../forms/confirmClaim";
import { useGlobalContext } from "../../contexts/globalContext";

const ClaimsTable: React.FC = (props: ComponentProps) => {
  const [sortColumn, setSortColumn] = useState<SortColumn>(initialSortColumn);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [unprospected, setUnprospected] = useState<Hospital[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [confirmClaim, setConfirmClaim] = useState<Hospital | null>(null);

  const { onCountChange, search, status, searchKey } = props;
  const { city, user } = useGlobalContext();

  const pageSize: number = 10;

  const handleConfirmClaim = (hospital: Hospital): void => {
    setConfirmClaim(hospital);
  };

  const getUnprospected = async (): Promise<void> => {
    try {
      setLoading(true);

      const res: ApiResponse = await getNerveClaims(city);

      const fetchedHospitals: Hospital[] = res.data as any;

      setUnprospected(fetchedHospitals);
      setLoading(false);
    } catch (e: any) {
      const errorMessage: string = e?.response?.data?.description ?? e.message;

      toastError(errorMessage);
      setLoading(false);
    }
  };

  const getSearchMatch = (hospital: Hospital): boolean => {
    let searchBy: string = searchKey ? searchKey : "name";
    let item: string | null = hospital[searchBy];
    if (!item) return false;

    return item.toLowerCase().startsWith(search.toLowerCase());
  };

  const getStatusMatch = (hospital: Hospital): boolean => {
    return hospital.status?.toLowerCase?.() === status.toLowerCase();
  };

  const getColumns = (): TableColumn[] => {
    const permissionColumns = [
      {
        path: "ref_id",
        label: "",
        content: (hospital: Hospital) => (
          <TableButton
            buttonClass="status primary"
            onClick={(): void => handleConfirmClaim(hospital)}
            label="Process"
          />
        ),
      },
    ];

    return [...tableColumns, ...permissionColumns];
  };

  const getPaginatedData = (): PaginatedData => {
    let filtered: Hospital[] = unprospected;

    if (search) filtered = filtered.filter(getSearchMatch);
    if (status) filtered = filtered.filter(getStatusMatch);

    let sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    let paginated = utilities.paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, paginated };
  };

  const paginatedData: PaginatedData = getPaginatedData();
  const columns: TableColumn[] = getColumns();

  useEffect(() => {
    getUnprospected();

    // eslint-disable-next-line
  }, [city]);

  useLayoutEffect(() => {
    const myInterval = setInterval(getUnprospected, 15000);
    return () => {
      // should clear the interval when the component unmounts
      clearInterval(myInterval);
    };
  }, []);

  useEffect(() => {
    onCountChange(paginatedData.totalCount);

    // eslint-disable-next-line
  }, [paginatedData.totalCount]);

  return (
    <React.Fragment>
      <Conditional isVisible={!!confirmClaim}>
        <ConfirmClaim
          selectedProspect={confirmClaim}
          handleClose={() => handleConfirmClaim(null)}
          userId={user?.id ?? ""}
        />
      </Conditional>

      <div className="activity-feed fade-in">
        <LoadingButton
          visible={loading}
          message="Fetching information, please wait"
        />

        {/* <Conditional isVisible={!loading}> */}
        <React.Fragment>
          <Table
            columns={columns}
            data={paginatedData.paginated}
            sortColumn={sortColumn}
            onSort={setSortColumn}
          />

          <div className="sb-pagination-container">
            <Pagination
              itemsCount={paginatedData.totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </React.Fragment>
        {/* </Conditional> */}

        {paginatedData.paginated.length === 0 && (
          <div className="no-items">
            <p>No Information to show</p>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

const tableColumns: TableColumn[] = [
  {
    path: "id",
    label: "ID",
  },
  {
    path: "name",
    label: "Hospital Name",
    // content: (h: Hospital) => <Link to={`/hospitals/${h.id}`}>{h.name}</Link>,
  },
  {
    path: "email",
    label: "Email Address",
  },
  {
    path: "phone",
    label: "Phone Number",
  },
];

const initialSortColumn: SortColumn = { path: "id", order: "desc" };
export default ClaimsTable;
