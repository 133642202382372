import React, { ReactNode, useState } from "react";
import CustomPagination from "../../../../components/common/pagination/Pagination";

export type ProductTableColumn = {
  header: string;
  accessor: string;
  className?: string;
};

export type SortOrder = "asc" | "desc";
type TableProps<T> = {
  data: T[];
  columns: ProductTableColumn[];
  renderCell: (item: T, column: ProductTableColumn) => ReactNode;
  totalPages?: any;
  currentPage: number;
  goToPage?: any;
};

function ProductTable<T>({
  data,
  columns,
  renderCell,
  totalPages,
  currentPage,
  goToPage,
}: TableProps<T>) {
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<SortOrder>("asc");

  // Function to handle sorting
  const handleSort = (column: ProductTableColumn) => {
    if (sortColumn === column.accessor) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column.accessor);
      setSortOrder("asc");
    }
  };

  // Sort the data based on sortColumn and sortOrder
  const sortedData = React.useMemo(() => {
    if (!sortColumn) return data;

    return [...data].sort((a, b) => {
      const aValue = a[sortColumn as keyof T];
      const bValue = b[sortColumn as keyof T];

      if (aValue < bValue) return sortOrder === "asc" ? -1 : 1;
      if (aValue > bValue) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });
  }, [data, sortColumn, sortOrder]);

  return (
    <>
      <table>
        <thead>
          <tr>
            {columns?.map((column) => (
              <th
                role="button"
                key={column.accessor}
                onClick={() => handleSort(column)}
                style={{ cursor: "pointer" }}
                className={column.className}
              >
                {column.header}{" "}
                {sortColumn === column.accessor
                  ? sortOrder === "asc"
                    ? "↑"
                    : "↓"
                  : ""}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData?.map((item, index) => (
            <tr key={index}>
              {columns?.map((column) => (
                <td data-label={column.header} key={column.accessor}>
                  {renderCell(item, column)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {data?.length > 0 ? (
        <CustomPagination
          totalPages={totalPages}
          currentPage={currentPage}
          goToPage={goToPage && goToPage}
        />
      ) : (
        <div className="flex items-center justify-center h-full mx-auto w-full text-center mt-5">
          <p className="text-center">No Hospitals</p>
        </div>
      )}
    </>
  );
}

export default ProductTable;
