import React from "react";
import { DangerMasteryData } from "./types";
import { Control, Controller, FieldErrors } from "react-hook-form";
import InputField from "../../common/input";
import RadioButton from "../../common/radio";
import CustomField from "../../common/CustomField";
import LogVisit from "../../modals/logVisit";
import Button from "../../common/button";

type Props = {
  control: Control<DangerMasteryData, any>;
  errors: FieldErrors<DangerMasteryData>;
  setLogData: React.Dispatch<
    React.SetStateAction<{
      location: string;
    }>
  >;
  logData: any;
  position: any;
  setPosition: any;
};

export default function HotComplaintLog({
  control,
  errors,
  logData,
  setLogData,
  position,
  setPosition,
}: Props) {
  return (
    <>
      <CustomField
        title="Date"
        style={{ paddingTop: "10px" }}
        value={
          <Controller
            control={control}
            name={`hot_complaint.date`}
            render={({ field: { onChange, value, name } }) => (
              <InputField
                style={{ width: "100%", border: "none", background: "#f1f1f1" }}
                autoFocus={false}
                name={name}
                onChange={onChange}
                placeholder="date"
                value={value}
                type="dateTime-local"
                error={errors?.hot_complaint?.date?.message || null}
              />
            )}
          />
        }
      />
      <CustomField
        title="Name of Hospital Agent"
        style={{ paddingTop: "10px" }}
        value={
          <Controller
            control={control}
            name={`hot_complaint.hospital_agent`}
            render={({ field: { onChange, value, name } }) => (
              <InputField
                style={{ width: "100%", border: "none", background: "#f1f1f1" }}
                autoFocus={false}
                name={name}
                onChange={onChange}
                placeholder="John Doe"
                value={value}
                type="text"
                error={errors?.hot_complaint?.hospital_agent?.message || null}
              />
            )}
          />
        }
      />

      <CustomField
        title="Confirm Hospital Visit (GPS Log Location)"
        value={
          <Button
            onClick={() => {
              setPosition(true);
            }}
            label="Log Your Location"
            type="button"
            style={{ marginTop: "0px", width: "40%" }}
          />
        }
      />
      {position && (
        <>
          <LogVisit
            handleClose={() => {
              setPosition(false);
            }}
            changePage={() => setPosition(false)}
            setLogData={setLogData}
          />
        </>
      )}
      {logData.location && position === false && (
        <>
          <CustomField
            title={
              <span className="font-semibold">
                Questions to Ask Sales Agent :
              </span>
            }
            style={{ paddingTop: "10px" }}
          />
          <CustomField
            title="Do you have a complaint?"
            value={
              <Controller
                control={control}
                name={`hot_complaint.complaint`}
                render={({ field: { onChange, value, name } }) => (
                  <div className="flex gap-4">
                    <RadioButton
                      name={name}
                      onChange={onChange}
                      value="1"
                      checked={value === "1"}
                      message="Yes"
                    />
                    <RadioButton
                      name={name}
                      onChange={onChange}
                      value="0"
                      checked={value === "0"}
                      message="No"
                    />
                  </div>
                )}
              />
            }
          />
          <CustomField
            title="Please what is your complaint"
            style={{ paddingTop: "10px" }}
            value={
              <Controller
                control={control}
                name={`hot_complaint.complaint_detail`}
                render={({ field: { onChange, value, name } }) => (
                  <InputField
                    style={{
                      width: "100%",
                      border: "none",
                      background: "#f1f1f1",
                    }}
                    autoFocus={false}
                    name={name}
                    onChange={onChange}
                    placeholder="type complaint here"
                    value={value}
                    type="text"
                    error={
                      errors?.hot_complaint?.complaint_detail?.message || null
                    }
                  />
                )}
              />
            }
          />
          <CustomField
            title="Do you have any issues with our Products or Service"
            style={{ paddingTop: "10px" }}
            value={
              <Controller
                control={control}
                name={`hot_complaint.issue_with_product`}
                render={({ field: { onChange, value, name } }) => (
                  <InputField
                    style={{
                      width: "100%",
                      border: "none",
                      background: "#f1f1f1",
                    }}
                    autoFocus={false}
                    name={name}
                    onChange={onChange}
                    placeholder="type question here"
                    value={value}
                    type="text"
                    error={
                      errors?.hot_complaint?.issue_with_product?.message || null
                    }
                  />
                )}
              />
            }
          />
          <CustomField
            title="Are you having any issues with Nerve"
            style={{ paddingTop: "10px" }}
            value={
              <Controller
                control={control}
                name={`hot_complaint.issue_with_nerve`}
                render={({ field: { onChange, value, name } }) => (
                  <InputField
                    style={{
                      width: "100%",
                      border: "none",
                      background: "#f1f1f1",
                    }}
                    autoFocus={false}
                    name={name}
                    onChange={onChange}
                    placeholder="type question here"
                    value={value}
                    type="text"
                    error={
                      errors?.hot_complaint?.issue_with_nerve?.message || null
                    }
                  />
                )}
              />
            }
          />
          <CustomField
            title="How will you like me to assist you with solving your complaint"
            style={{ paddingTop: "10px" }}
            value={
              <Controller
                control={control}
                name={`hot_complaint.assist_with_complaint`}
                render={({ field: { onChange, value, name } }) => (
                  <InputField
                    style={{
                      width: "100%",
                      border: "none",
                      background: "#f1f1f1",
                    }}
                    autoFocus={false}
                    name={name}
                    onChange={onChange}
                    placeholder="type question here"
                    value={value}
                    type="text"
                    error={
                      errors?.hot_complaint?.assist_with_complaint?.message ||
                      null
                    }
                  />
                )}
              />
            }
          />
          <CustomField
            title="Is there anything else I can help you with"
            style={{ paddingTop: "10px" }}
            value={
              <Controller
                control={control}
                name={`hot_complaint.anything_to_help`}
                render={({ field: { onChange, value, name } }) => (
                  <InputField
                    style={{
                      width: "100%",
                      border: "none",
                      background: "#f1f1f1",
                    }}
                    autoFocus={false}
                    name={name}
                    onChange={onChange}
                    placeholder="type question here"
                    value={value}
                    type="text"
                    error={
                      errors?.hot_complaint?.anything_to_help?.message || null
                    }
                  />
                )}
              />
            }
          />
        </>
      )}
    </>
  );
}
