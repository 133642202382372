import axios from "axios";
import countryService from "./countryService";

axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
axios.defaults.headers.put["Content-Type"] = "application/json";

axios.interceptors.request.use((config) => {
  return config;
});

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) console.log(error);

  return Promise.reject(error);
});

const savedCountryUrl: string | null = countryService.getCountryUrl();

const resetBaseUrl = (baseUrl: string) => {
  axios.defaults.baseURL = baseUrl + "/api/v1";
};

const updateBaseUrl = (baseUrl: string | undefined) => {
  axios.defaults.baseURL = baseUrl + "/v1";
};
if (savedCountryUrl === "https://one.lifebanktest.com") {
  updateBaseUrl(savedCountryUrl as string);
} else {
  resetBaseUrl(savedCountryUrl as string);
}

const httpService = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
  resetBaseUrl,
};

export default httpService;
export { resetBaseUrl, updateBaseUrl };
