import { createBrowserRouter, RouteObject, Navigate } from "react-router-dom";

import App from "../App";
import authRoutes from "../modules/authentication/routes";
import dashboardRoutes from "../modules/dashboard/routes";
import navigationRoutes from "./navigationRoutes";
import NotFoundPage from "../modules/authentication/view/pageNotFound";

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Navigate to={navigationRoutes.selectCountry} replace />,
      },
    ],
  },
  ...authRoutes,
  ...dashboardRoutes,
  // catch all routes
  {
    path: "*",
    element: <Navigate to="/404" replace />,
  },
  {
    path: "/404",
    element: <NotFoundPage />,
  },
];

const router = createBrowserRouter(routes);

export default router;
