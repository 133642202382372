import React from "react";
import Header from "../../../../components/blocks/header";

import { useGlobalContext } from "../../../../contexts/globalContext";
import { useNerveMessage } from "../../../../hooks/usePipeline";
import ProductTable, { ProductTableColumn } from "../exodus/ProductTable";
import { Hospital } from "../../../../ts-utils/types";
import { Link } from "react-router-dom";

const NerveUsage = () => {
  const { user } = useGlobalContext();
  const { messages, isPending } = useNerveMessage(user?.id as string);

  function renderCell(hospital: Hospital, column: ProductTableColumn) {
    switch (column.accessor) {
      case "name":
        return (
          <Link
            className="text-[#49209F]  underline font-bold"
            to={`/hospitals/${hospital?.hospital_id}`}
          >
            {hospital?.name}
          </Link>
        );

      default:
        return <span>{hospital[column.accessor]}</span>;
    }
  }
  const columns: ProductTableColumn[] = [
    { header: "Hospital", accessor: "name" },
    { header: "Date", accessor: "tym" },
    { header: "Message Content", accessor: "message" },
    // { header: "Action", accessor: "action" },
  ];
  return (
    <React.Fragment>
      <Header title="Your Pending Messages" />
      <div className="score-card">
        {isPending ? (
          <p>Loading...</p>
        ) : (
          <>
            {/* <div className="max-w-screen-2xl mx-auto px-4 md:px-8 mb-5">
              <>
                <ProductTable
                  data={messages}
                  columns={columns}
                  renderCell={renderCell}
                  totalPages={paginate?.totalPages}
                  currentPage={currentPage}
                  goToPage={goToPage}
                />
              </>
            </div> */}
            <table>
              <thead>
                <tr>
                  <th>Hospital</th>
                  <th>Date</th>
                  <th>Message Content</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(messages) && messages.length > 0 ? (
                  messages?.map(
                    (message: {
                      id: string;
                      name: string;
                      tym: string;
                      message: string;
                    }) => (
                      <tr key={message.id}>
                        <td>{message.name}</td>
                        <td>{message.tym}</td>
                        <td>{message.message}</td>
                        <td>
                          {/* <button onClick={() => handleButtonClick(message.id)}>
                      Read
                    </button> */}
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <>
                    <tr>
                      <td colSpan={4} style={{ textAlign: "center" }}>
                        You Currently don't have any pending Message
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default NerveUsage;
