import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../@/components/ui/form";
import { Input } from "../../../../@/components/ui/input";
import { Textarea } from "../../../../@/components/ui/textarea";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { Dispatch, SetStateAction, useState } from "react";

import { useGetHospitalValues } from "../../../../hooks/marketing/useDrips";

const typeOptions = [
  { value: "PDF", label: "PDF" },
  { value: "Video", label: "Video" },
  { value: "Picture", label: "Picture" },
];

const newHospitalValues = [
  {
    value: "How to use Nerve",
    label: "How to use Nerve",
  },
  {
    value: "Hospitals who switched ",
    label: "Hospitals who switched ",
  },
  {
    value: "Ways to delight your patient",
    label: "Ways to delight your patient",
  },
  {
    value: "New Vendors Added",
    label: "New Vendors Added ",
  },
  {
    value: "Personal Value",
    label: "Personal Value",
  },
  {
    value: "Business Value",
    label: "Business Value",
  },
  {
    value: "Exceptional Deliveries",
    label: "Exceptional Deliveries",
  },
  {
    value: "Useful Tools",
    label: "Useful Tools",
  },
];

export default function CreateDrip({
  form,
  platformOptions,
  selectedPlatforms,
  asset,
  setAsset,
  selectedDripType,
}: {
  form: any;
  platformOptions: any;
  selectedPlatforms: any;
  asset: File | null;
  setAsset: Dispatch<SetStateAction<File | null>>;
  selectedDripType: {
    value: string;
  };
}) {
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
      ["link", "image", "video"],
      ["clean"], // remove formatting button
      ["undo", "redo"], // add undo and redo buttons
    ],
    history: {
      delay: 1000,
      maxStack: 50,
      userOnly: true,
    },
  };

  const formats = [
    "header",
    "font",
    "list",
    "bullet",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "align",
    "color",
    "background",
    "link",
    "image",
    "video",
  ];

  const { hospitalValue } = useGetHospitalValues();
  // https://one.lifebanktest.com/v1/marketing/drip/type
  const value = hospitalValue
    ? hospitalValue?.map((hospital: any) => ({
        value: hospital.name,
        label: hospital.name,
      }))
    : [];

  console.log(value);

  const [assetType, setAssetType] = useState("");
  const [previewURL, setPreviewURL] = useState("");
  const [key, setKey] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const maxSizeLimits: { [key: string]: number } = {
    image: 5 * 1024 * 1024, // 5MB
    pdf: 10 * 1024 * 1024, // 10MB
    video: 50 * 1024 * 1024, // 50MB
  };

  const getAcceptAttribute = () => {
    switch (selectedDripType?.value?.toLowerCase()) {
      case "pdf":
        return ".pdf";
      case "video":
        return ".mp4, .avi, .mov";
      case "picture":
      default:
        return ".jpeg, .png, .jpg";
    }
  };

  const uploadAsset = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file) {
        const fileType = file.type;
        let typeCategory = "";

        if (fileType.startsWith("image/")) {
          typeCategory = "picture";
        } else if (fileType === "application/pdf") {
          typeCategory = "pdf";
        } else if (fileType.startsWith("video/")) {
          typeCategory = "video";
        }

        const maxSize = maxSizeLimits[typeCategory];

        if (file.size > maxSize) {
          setErrorMessage(
            `File size exceeds the maximum allowed size of ${
              maxSize / (1024 * 1024)
            }MB.`
          );
          return;
        } else {
          setErrorMessage("");
        }

        setAsset(file);
        setAssetType(typeCategory);

        if (previewURL) {
          URL.revokeObjectURL(previewURL);
        }

        const newPreviewURL = URL.createObjectURL(file);
        setPreviewURL(newPreviewURL);
        setKey((prevKey) => prevKey + 1);
      }
    }
  };

  const renderPreview = () => {
    if (!previewURL) return null;

    switch (assetType) {
      case "picture":
        return (
          <img
            className="rounded-md object-fit max-h-full"
            src={previewURL}
            alt="preview"
          />
        );
      case "pdf":
        return (
          <embed
            src={previewURL}
            type="application/pdf"
            width="100%"
            height="100%"
          />
        );
      case "video":
        return (
          <video className="rounded-md object-fit max-h-full" controls>
            <source src={previewURL} type={asset?.type} />
            Your browser does not support the video tag.
          </video>
        );
      default:
        return null;
    }
  };

  return (
    <Form {...form}>
      <form className="grid grid-cols-2 gap-3 w-full px-3">
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem className="flex items-start flex-col">
              <FormLabel htmlFor="title" className="font-semibold ">
                Drip title
              </FormLabel>
              <FormControl>
                <Input id="title" placeholder="Enter Drip title" {...field} />
              </FormControl>
              <div className="flex items-center justify-end">
                <FormMessage />
              </div>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="value"
          render={({ field }) => (
            <FormItem className="flex flex-col items-start">
              <FormLabel className="font-semibold ">Hospital value</FormLabel>
              <Select
                {...field}
                options={newHospitalValues}
                placeholder="Select Hospital value"
                className="w-full"
              />
              <FormMessage />
            </FormItem>
          )}
        />{" "}
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem className="w-full col-span-full">
              <div className="flex flex-col  gap-3 w-full">
                <FormLabel className="text-start font-semibold">
                  Drip Description
                </FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="Describe what's special about your drip & other important details."
                    className="resize-none w-full"
                    {...field}
                  />
                </FormControl>
              </div>
              <div className="flex items-center">
                <FormMessage />
              </div>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="type"
          render={({ field }) => (
            <FormItem className="flex flex-col items-start">
              <FormLabel className="font-semibold ">Type of Drip</FormLabel>
              <Select
                {...field}
                options={typeOptions}
                placeholder="Select Drip type"
                className="w-full"
              />

              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="platform"
          render={({ field }) => (
            <FormItem className="flex flex-col items-start">
              <FormLabel className="font-semibold ">Channel</FormLabel>
              <Select
                {...field}
                isMulti
                options={platformOptions}
                value={platformOptions.filter((option: { value: any }) =>
                  field.value.includes(option.value)
                )}
                placeholder="Select Platform"
                className="w-full"
                onChange={(selectedOptions) => {
                  field.onChange(
                    selectedOptions
                      ? selectedOptions.map((option) => option.value)
                      : []
                  );
                }}
              />

              <FormDescription className=" ">
                You can select multiple Platforms
              </FormDescription>
              <FormDescription className="text-red-500">
                {form.formState.errors.platform &&
                  form.formState.errors.platform.message}
              </FormDescription>
            </FormItem>
          )}
        />
        {selectedPlatforms.includes("WhatsApp") && (
          <FormField
            name="whatsappMessage"
            control={form.control}
            render={({ field }) => (
              <FormItem className="w-full col-span-full">
                <FormLabel
                  htmlFor="whatsappMessage"
                  className="font-semibold block text-sm text-start"
                >
                  WhatsApp Message
                </FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    id="whatsappMessage"
                    rows={4}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="Enter WhatsApp message"
                  />
                </FormControl>
                <div className="flex items-center">
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />
        )}
        {selectedPlatforms.includes("Email") && (
          <FormField
            name="emailMessage"
            control={form.control}
            render={({ field }) => (
              <FormItem className="w-full col-span-full">
                <FormLabel
                  htmlFor="emailMessage"
                  className="font-semibold block text-sm text-start"
                >
                  Email Message
                </FormLabel>
                <FormControl>
                  <ReactQuill
                    {...field}
                    modules={modules}
                    formats={formats}
                    id="emailMessage"
                    className="mt-1"
                    placeholder="Enter Email message"
                  />
                  {/* <CustomTextEditor
                    {...field}
                    onChange={(value: string) => {
                      field.onChange(value);
                    }}
                  /> */}
                </FormControl>
                <div className="flex items-center">
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />
        )}
        {selectedPlatforms.includes("SMS") && (
          <FormField
            name="smsMessage"
            control={form.control}
            render={({ field }) => (
              <FormItem className="w-full col-span-full">
                <FormLabel
                  htmlFor="smsMessage"
                  className="font-semibold block text-sm text-start"
                >
                  SMS Message (max 280 characters)
                </FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    id="smsMessage"
                    rows={4}
                    maxLength={280}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="Enter SMS message"
                  />
                </FormControl>
                <div className="flex items-center">
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />
        )}{" "}
        <FormItem className="flex items-start flex-col">
          <FormLabel htmlFor="duration" className="font-semibold ">
            Asset
          </FormLabel>
          <FormControl>
            <Input
              type="file"
              id="sampleAsset"
              accept={getAcceptAttribute()}
              onChange={uploadAsset}
            />
          </FormControl>
          {errorMessage && (
            <div className="text-red-500 mt-2">{errorMessage}</div>
          )}
          <div className="flex items-center justify-end">
            <FormMessage />
          </div>
        </FormItem>
        <div className="upload">
          <div
            key={key}
            className="flex items-center justify-center h-80 max-h-72 bg-[#C4C4C426] border-dashed border-2 w-full"
          >
            {asset ? (
              renderPreview()
            ) : (
              <div className="uppercase text-2xl text-gray-300 font-extrabold">
                Asset Preview
                {/* <UploadIcon className="w-20 h-20" /> */}
              </div>
            )}
          </div>
        </div>
      </form>
    </Form>
  );
}
