import React, { useState } from "react";
import _ from "lodash";

import utilities from "../../utils/utilities";

import LoadingButton from "../common/loadingButton";
import NoItems from "../blocks/noItems";
import Pagination from "../common/pagination";
import PitchHistory from "../modals/pitchHistory";
import ProspectedInfo from "../modals/prospectedInfo";
import Table from "../common/table";
import TableButton from "../blocks/tableButton";
 
import { Link, useNavigate } from "react-router-dom"; 
import ComplainPitch from "../forms/complainPitch";

const PitchTable = ({
  search,
  searchKey,
  assignedHospitals,
  isLoading,
  showSearch,
}) => {
  const history = useNavigate();

  const [sortColumn, setSortColumn] = useState(initialSortColumn);
  const [currentPage, setCurrentPage] = useState(1);
  const [pitchHistory, setPitchHistory] = useState(null);
  const [pitchComplain, setPitchComplain] = useState(null);
  // const [telesalesPitch, setSaleType] = useState(null);
  const [hospitalToView, setHospitalToView] = useState(null);

  const pageSize = 8;

  const handleHospitalSelect = (hospital) => {
    setHospitalToView(hospital);
  };

  // const handleSaleType = (pitch) => {
  //   setSaleType(pitch);
  // };

  const handlePitch = (pitch) => {
    setPitchHistory(pitch);
  };

  const handleComplain = (pitch) => {
    setPitchComplain(pitch);
  };

  const getSearchMatch = (item) => {
    const searchBy = searchKey ? searchKey : "";
    return item[searchBy].toLowerCase().startsWith(search.toLowerCase());
  };

  const getTableColumns = () => {
    return [
      {
        path: "hospital_id",
        label: "Hospital ID",
      },
      {
        path: "name",
        label: "Hospital Name",
        content: (h) => <Link to={`/hospitals/${h.hospital_id}`}>{h.name}</Link>,
      },
      {
        path: "email",
        label: "Email Address",
      },
      {
        path: "phone",
        label: "Phone Number",
      },
      {
        path: "address",
        label: "Address",
      },
      // {
      //   path: "_",
      //   label: "",
      //   content: (c) => (
      //     <TableButton
      //       buttonClass="status primary"
      //       onClick={() => handleHospitalSelect(c)}
      //       label="View"
      //     />
      //   ),
      // },
      // {
      //   path: "__",
      //   label: "",
      //   content: (c) => (
      //     <TableButton
      //       buttonClass="status questions"
      //       onClick={() => handleComplain(c)}
      //       label="Complain"
      //     />
      //   ),
      // },
      {
        path: "___",
        label: "",
        content: (c) => (
          <TableButton
            buttonClass="status primary"
            // onClick={() => {
            //   console.log("c", c);
            //   handleSaleType(c);
            // }}
            onClick={() => {
              window.sessionStorage.setItem("pitchInfo", c.hospital_id);
              history("/info");
            }}
            label="Pitch"
          />
        ),
      },
    ];
  };

  const paginatedData = () => {
    let filtered = assignedHospitals;
    if (search) filtered = assignedHospitals.filter(getSearchMatch);

    let sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    let paginated = utilities.paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, paginated };
  };

  const { totalCount, paginated } = paginatedData();

  return (
    <React.Fragment>
      {hospitalToView && (
        <ProspectedInfo
          selectedProspect={hospitalToView}
          handleClose={() => handleHospitalSelect(null)}
        />
      )}

      {pitchHistory && (
        <PitchHistory
          selectedPitch={pitchHistory}
          handleClose={() => handlePitch(null)}
        />
      )}

      {pitchComplain && (
        <ComplainPitch
          selectedPitch={pitchComplain}
          handleClose={() => handleComplain(null)}
        />
      )}

      {/* {telesalesPitch && (
        <SaleType
          selectedPitch={telesalesPitch}
          handleClose={() => handleSaleType(null)}
        />
      )} */}

      <div className="activity-feed fade-in">
        {isLoading ? (
          <LoadingButton visible message="Fetching pitches" />
        ) : (
          <React.Fragment>
            <Table
              columns={getTableColumns()}
              data={paginated}
              sortColumn={sortColumn}
              onSort={setSortColumn}
            />

            <div className="sb-pagination-container">
              <Pagination
                itemsCount={totalCount}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>

            <NoItems
              isVisible={paginated.length === 0}
              label="No items to show"
            />
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

const initialSortColumn = {
  path: "hospital_id",
  order: "desc",
};

export default PitchTable;
