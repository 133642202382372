import React, { useState } from "react";
import { Appointment, ComponentProps, Hospital } from "../../ts-utils/types";
import {
  PaginatedData,
  SortColumn,
  TableColumn,
} from "../../ts-utils/interfaces";
import { Link } from "react-router-dom";

import _ from "lodash";

import LoadingButton from "../common/loadingButton";
import Pagination from "../common/pagination";
import Table from "../common/table";

// import withAppointments from "../hoc/withAppointment";

import utilities from "../../utils/utilities";
import NoItems from "../blocks/noItems";
import AppointmentInfo from "../modals/appointmentInfo";
import ProspectQuestions from "../modals/prospectQuestions";

import { useGlobalContext } from "../../contexts/globalContext";

const SalesAppointmentTable = (props: ComponentProps) => {
  const [sortColumn, setSortColumn] = useState<SortColumn>(initialSortColumn);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hospitalToView, setHospitalToView] = useState<Hospital | null>(null);
  const [appointmentToView, setAppointmentToView] =
    useState<Appointment | null>(null);

  const pageSize: number = 7;

  const { user } = useGlobalContext();
  const {
    category,
    appointments,
    loading,
    search,
    searchKey,
    status,
    // onCategoryChange,
  } = props;
  console.log(appointments, "appointments");
  const handleHospitalToView = (hospital: Hospital): void => {
    setHospitalToView(hospital);
  };

  const handleAppointmentToView = (appointment: Appointment): void => {
    setAppointmentToView(appointment);
  };

  const getAgentMatch = (hospital: Hospital): boolean => {
    return hospital.rep === user?.id;
  };

  const getSearchMatch = (hospital: Hospital): boolean => {
    let searchBy: string = searchKey ? searchKey : "name";
    let searchValue: string | number = hospital[searchBy] ?? "";

    return searchValue
      .toString()
      .toLowerCase()
      .startsWith(search.toLowerCase());
  };

  const getStatusMatch = (hospital: Hospital): boolean => {
    return hospital.status?.toLowerCase?.() === status.toLowerCase();
  };

  const getPaginatedData = (): PaginatedData => {
    let filtered: Hospital[] = appointments ?? [];
    let isAgentOrder: boolean = category === "1";

    if (search) filtered = appointments.filter(getSearchMatch);
    if (status) filtered = filtered.filter(getStatusMatch);
    if (isAgentOrder) filtered = filtered.filter(getAgentMatch);

    let sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    let paginated = utilities.paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, paginated };
  };

  const getColumns = (): TableColumn[] => {
    // const viewQuestions: TableColumn = {
    //   path: "_",
    //   label: "Self prospecting Questions",
    //   content: (h: Appointment): JSX.Element => (
    //     <button
    //       className="status questions"
    //       onClick={() => handleAppointmentToView(h)}
    //     >
    //       View
    //     </button>
    //   ),
    // };

    const viewHospitalInformation: TableColumn = {
      path: "_",
      label: "Assign Agent",
      content: (h: Hospital): JSX.Element => (
        <button
          className="status primary"
          onClick={() => handleHospitalToView(h)}
        >
          Assign
        </button>
      ),
    };

    return [...columns, viewHospitalInformation];
  };

  const { totalCount, paginated }: PaginatedData = getPaginatedData();

  // useEffect(() => {
  //   onCategoryChange(totalCount);

  //   // eslint-disable-next-line
  // }, [totalCount]);

  return (
    <React.Fragment>
      {hospitalToView && (
        <AppointmentInfo
          selectedProspect={hospitalToView}
          handleClose={() => handleHospitalToView(null)}
        />
      )}

      {appointmentToView && (
        <ProspectQuestions
          selectedProspect={hospitalToView}
          handleClose={() => handleAppointmentToView(null)}
        />
      )}

      <div className="activity-feed fade-in">
        {loading ? (
          <LoadingButton
            visible={loading}
            message="Fetching Appointments... Please wait"
          />
        ) : (
          <React.Fragment>
            <Table
              columns={getColumns()}
              data={paginated}
              sortColumn={sortColumn}
              onSort={setSortColumn}
            />

            {appointments?.length > pageSize && (
              <div className="sb-pagination-container">
                <Pagination
                  itemsCount={totalCount}
                  pageSize={pageSize}
                  currentPage={currentPage}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            )}
          </React.Fragment>
        )}

        <NoItems
          isVisible={paginated?.length === 0}
          label="No appointments to show"
        />
      </div>
    </React.Fragment>
  );
};

const columns: TableColumn[] = [
  {
    path: "id",
    label: "ID",
  },
  // {
  //   path: "contact_name",
  //   label: "Contact",
  //   content: (h: Hospital) => (
  //     <span className="text-capitalize">{h.contact_name}</span>
  //   ),
  // },
  {
    path: "name",
    label: "Hospital Name",
    content: (h: Hospital) => (
      <Link to={`/hospitals/${h.hosid}`}>{h.name}</Link>
    ),
  },
  {
    path: "contact_name",
    label: "Contact Person",
    // content: (h: Hospital) => <Link to={`/hospitals/${h.id}`}>{h.name}</Link>,
  },
  {
    path: "phone",
    label: "Contact Number",
  },
  {
    path: "email",
    label: "Contact Email",
  },

  {
    path: "address",
    label: "Address",
  },
  {
    path: "appointmentdate",
    label: "Appointment Date",
  },
  {
    path: "appointmenttime",
    label: "Appointment Time",
  },
  {
    path: "preference",
    label: "Prospecting Mode",
  },
];

const initialSortColumn: SortColumn = { path: "id", order: "desc" };
export default SalesAppointmentTable;

// SalesAppointmentTable.propTypes = {
//   canEditPitch: PropTypes.func.isRequired,
// };
