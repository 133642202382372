import React from "react";

import Modal from "../common/modal";
import withAppointments from "../hoc/withAppointment";

const ProspectQuestions = (props) => {
  const { handleClose } = props;

  return (
    <Modal handleClose={handleClose}>
      <h3 className="modal-title">Self Prospecting Questions</h3>
      <div>
        <p className="modal-subheader">Order History</p>
        <ul className="question-modal-items no-list">
          <li className="question-modal-items-single">
            <span>
              <i className="fa fa-question"></i>{" "}
              <span>
                In the last month, how many units of blood and blood products
                did you order?
              </span>{" "}
            </span>
            <span>
              <i className="fa fa-reply"></i> <span></span>{" "}
            </span>
          </li>
          <li className="question-modal-items-single">
            <span>
              <i className="fa fa-question"></i>{" "}
              <span>
                In the last month, how many oxygen cylinder did you order?
              </span>{" "}
            </span>
            <span>
              <i className="fa fa-reply"></i> <span></span>{" "}
            </span>
          </li>
          <li className="question-modal-items-single">
            <span>
              <i className="fa fa-question"></i>{" "}
              <span>
                In the last month, how many consumable products did you order?
              </span>{" "}
            </span>
            <span>
              <i className="fa fa-reply"></i> <span></span>{" "}
            </span>
          </li>
          <li className="question-modal-items-single">
            <span>
              <i className="fa fa-question"></i>{" "}
              <span>
                What's your procurement cycle?(Daily,Weekly,Monthly or Anually)
              </span>{" "}
            </span>
            <span>
              <i className="fa fa-reply"></i> <span></span>{" "}
            </span>
          </li>
          <li className="question-modal-items-single">
            <span>
              <i className="fa fa-question"></i>{" "}
              <span>
                What is your payment cycle?(Daily, Weekly, Monthly, or Annually)
              </span>{" "}
            </span>
            <span>
              <i className="fa fa-reply"></i> <span></span>{" "}
            </span>
          </li>
        </ul>
      </div>

      <div>
        <p className="modal-subheader">Current Supplies</p>
        <ul className="question-modal-items no-list">
          <li className="question-modal-items-single">
            <span>
              <i className="fa fa-question"></i>{" "}
              <span>
                What are the challenges or difficulties you face procuring
                Medical supplies like Blood, Oxygen or Medical consumable
              </span>{" "}
            </span>
            <span>
              <i className="fa fa-reply"></i> <span></span>{" "}
            </span>
          </li>
          <li className="question-modal-items-single">
            <span>
              <i className="fa fa-question"></i>{" "}
              <span>
                What's the one thing you would change about your deliveries
              </span>{" "}
            </span>
            <span>
              <i className="fa fa-reply"></i> <span></span>{" "}
            </span>
          </li>
          <li className="question-modal-items-single">
            <span>
              <i className="fa fa-question"></i>{" "}
              <span>How important is this for you?</span>{" "}
            </span>
            <span>
              <i className="fa fa-reply"></i> <span></span>{" "}
            </span>
          </li>
        </ul>
      </div>

      <div>
        <p className="modal-subheader">Working With Lifebank</p>
        <ul className="question-modal-items no-list">
          <li className="question-modal-items-single">
            <span>
              <i className="fa fa-question"></i>{" "}
              <span>
                How open are you to testing or working with us at lifeBank?
              </span>{" "}
            </span>
            <span>
              <i className="fa fa-reply"></i> <span></span>{" "}
            </span>
          </li>
          <li className="question-modal-items-single">
            <span>
              <i className="fa fa-question"></i>{" "}
              <span>Could you try LifeBank in a week?(Yes/No)</span>{" "}
            </span>
            <span>
              <i className="fa fa-reply"></i> <span></span>{" "}
            </span>
          </li>
        </ul>
      </div>
    </Modal>
  );
};

export default withAppointments(ProspectQuestions);
