// @ts-ignore
import Joi from "joi-browser";

import LoadingButton from "../common/loadingButton";
import Form from "../common/form";
import Modal from "../common/modal";

import CityContext from "../../contexts/useCityContext";
import withCities from "../hoc/withCities";

class ChangeCityForm extends Form {
  state = {
    data: { city: "" },
    errors: {},
  };

  static contextType = CityContext;

  schema = {
    city: Joi.string()
      .trim()
      .regex(/^[a-zA-Z_ ]+$/, "city")
      .required()
      .label("City"),
  };

  componentDidMount(): void {
    this.mapCityToData();
  }

  mapCityToData = (): void => {
    const data: any = { ...this.state.data };
    // data.city = this.context.city;

    this.setState({ data });
  };

  doSubmit = (): void => {
    this.props.handleClose();
  };

  render(): JSX.Element {
    const { loading, handleClose } = this.props;

    return (
      <Modal handleClose={handleClose}>
        <form onSubmit={this.handleSubmit}>
          {this.renderSelect({
            name: "city",
            placeholder: "See city to view data",
            label: "Select City",
            options: this.props.cities,
            compulsory: true,
          })}

          {loading ? (
            <LoadingButton message="Fetching states..." visible />
          ) : (
            this.renderButton("Change")
          )}
        </form>
      </Modal>
    );
  }
}

export default withCities(ChangeCityForm);
