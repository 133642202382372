import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/blocks/header";
import appointmentService from "../../../services/appointmentService";
import toastService from "../../../services/toastService";
import LoadingButton from "../../../components/common/loadingButton";
import ScheduleForm from "../../../components/forms/scheduleForm";

const PreOnboardInfo = () => {
  const history = useNavigate();

  const [pitchInfo, setPitchInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const info = window.sessionStorage.getItem("scheduleInfo");

  const selectedPitch = JSON.parse(info);

  const navigate = (path) => {
    history(path);
  };

  const getInfo = async () => {
    try {
      setIsLoading(true);

      const response = await appointmentService.getSinglePendingRequests(
        selectedPitch.id
      );
      const result = response.data?.data?.[0];

      setPitchInfo(result);

      setIsLoading(false);
    } catch (ex) {
      const message = ex?.response?.data?.description ?? ex.message;
      toastService.error(message);

      setIsLoading(false);
    }
  };

  useEffect(() => {
    getInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePush = () => {
    history("/onboard");
  };

  return (
    <div>
      <div onClick={() => navigate("/requests")} className="back">
        <i className={"fa fa-arrow-left"}></i> Back
      </div>
      <LoadingButton visible={isLoading} message="Please wait" />

      {!isLoading && (
        <>
          <Header title={`Schedule for ${pitchInfo?.name}`} />
          <ScheduleForm
            hospital_id={pitchInfo.hospital_id}
            handlePush={handlePush}
          />
        </>
      )}
    </div>
  );
};

export default PreOnboardInfo;
