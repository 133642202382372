import React, { useState } from "react";

import AddFacilitySize from "../../../../components/forms/addFacilitySize";
import AddFacilityType from "../../../../components/forms/addFacilityType";
import SearchQuery from "../../../../components/forms/searchQuery";

import Button from "../../../../components/common/button";
import Header from "../../../../components/blocks/header";

import FacilitySizeTable from "../../../../components/tables/facilitySizeTable";
import FacilityTypeTable from "../../../../components/tables/facilityTypeTable";

import { getPrivileges } from "../../../../services/authService";

import {
  useGetFacilityTypes,
  useGetFacilitySizes,
} from "../../../../hooks/useHospital";

const Facility = () => {
  const { facilityTypes, isPending } = useGetFacilityTypes();
  const { facilitySizes, isPending: isPendingSize } = useGetFacilitySizes();
  console.log(facilityTypes, facilitySizes);
  const [search, setSearch] = useState("");
  const [active, setActive] = useState("1");
  const [count, setCount] = useState(0);
  const [addFacilitySize, setAddFacilitySize] = useState(false);
  const [addFacilityType, setAddFacilityType] = useState(false);

  const handleAddFacilitySize = () => setAddFacilitySize((prev) => !prev);
  const handleAddFacilityType = () => setAddFacilityType((prev) => !prev);
  const handleCount = (count: React.SetStateAction<number>) => setCount(count);

  const checkTitle = () => {
    let title;

    switch (active) {
      case "1":
        title = "Facility Type";
        break;
      case "2":
        title = "Facility Size";
        break;
      default:
        title = "Facility";
    }

    return title;
  };

  const checkActive = () => ({
    isFirstActive: active === "1",
    isSecondActive: active === "2",
  });

  const privileges = getPrivileges();

  const { isFirstActive, isSecondActive } = checkActive();

  return (
    <React.Fragment>
      <Header title={checkTitle() + " - " + count} />

      {privileges.addFacility && addFacilitySize && (
        <AddFacilitySize
          handleClose={handleAddFacilitySize}
          facilitySize={facilitySizes}
        />
      )}

      {privileges.addFacility && addFacilityType && (
        <AddFacilityType
          handleClose={handleAddFacilityType}
          facilityType={facilityTypes}
        />
      )}

      <div className="prospect-buttons">
        <Button
          label="Facility Type"
          className={isFirstActive ? "app-btn active" : "app-btn inactive"}
          onClick={() => setActive("1")}
        />
        <Button
          label="Facility Size"
          className={isSecondActive ? "app-btn active" : "app-btn inactive"}
          onClick={() => setActive("2")}
        />
      </div>

      <div className="hospitals-container">
        <div className="hospitals-container-top ">
          <SearchQuery
            name="search"
            placeholder="Search"
            value={search}
            onChange={(e: {
              currentTarget: { value: React.SetStateAction<string> };
            }) => setSearch(e.currentTarget.value)}
          />

          <div className="right-options">
            {privileges.addFacility && isFirstActive && (
              <Button
                icon="plus"
                label="Add Facility Type"
                onClick={handleAddFacilityType}
              />
            )}
            {privileges.addFacility && isSecondActive && (
              <Button
                icon="plus"
                label="Add Facility Size"
                onClick={handleAddFacilitySize}
              />
            )}
          </div>
        </div>
        <div className="hospitals-container-bottom table-responsive">
          {isFirstActive && (
            <FacilityTypeTable
              search={search}
              onCountChange={handleCount}
              facilityType={facilityTypes}
              loading={isPending}
            />
          )}
          {isSecondActive && (
            <FacilitySizeTable
              search={search}
              onCountChange={handleCount}
              facilitySize={facilitySizes}
              loading={isPendingSize}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Facility;
