import React, { useState } from "react";
import Button from "../../../../components/common/button";
import Modal from "../../../../components/common/modal";
import { ReactComponent as UploadIcon } from "../../../../assets/svg/upload.svg";
import appointmentService from "../../../../services/appointmentService";
import toastService from "../../../../services/toastService";
import ProgressLoader from "../../../../components/common/progressLoader";
import { Document, Page, pdfjs } from "react-pdf";

// Set the worker URL for the react-pdf package (should be imported only once)
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const SLA = (props) => {
  const { selectedPitch, selectedResponse, handlePrev, handleNext } = props;
  const [offer, setOffer] = useState(null);
  const [sla, setSla] = useState(null);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [progress, setProgress] = useState(0);

  const handleProgressChange = (progress) => {
    setProgress(progress);
  };

  const handleOffer = (value) => {
    setOffer(value);
  };

  const handleSla = (value) => {
    setSla(value);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile.name);
  };

  const postFile = async () => {
    // Create a FormData object
    const formData = new FormData();
    formData.append("sla", file);

    const res = await appointmentService.uploadSla(
      selectedPitch.hospital_id,
      formData,
      handleProgressChange
    );
    if (res.data.status !== "success") throw new Error(res.data.message);
    if (res.data.status === "success") handleNext();
  };

  const uploadFile = (e) => {
    e.preventDefault();
    toastService.promise(postFile(), {
      loading: "uploading document...",
      success: () =>
        `SLA For ${selectedPitch.name} has been completed, and one thing discount has been applied to their Nerve Account.`,
      error: (e) =>
        e?.response?.data?.message || e?.message || "Could not send email!",
    });
  };

  const [isPdf, setIsPdf] = useState(false);

  const handleLoadSuccess = () => {
    setIsPdf(true);
  };

  const handleImageError = () => {
    setIsPdf(true); // If image fails to load, assume it's a PDF
  };

  return (
    <div>
      <ProgressLoader progress={progress} />
      <div className="pitch-data">
        <span className="label">Check for documentation</span>
        {selectedResponse?.sla?.status === "Yes" && (
          <span
            className="link"
            onClick={() => handleSla(selectedResponse.sla.data)}
          >
            view SLA
          </span>
        )}
        {selectedResponse?.sla?.status === "No" && (
          <span className="link" onClick={() => handleOffer(selectedPitch)}>
            click to upload
          </span>
        )}
      </div>
      <div className="checklist-button">
        {/* {selectedResponse?.sla?.status === "No" && ( */}
        <Button label="Prev" onClick={handlePrev} />
        {/* )} */}
        {selectedResponse?.sla?.status === "Yes" && (
          <Button label="Next" onClick={handleNext} />
        )}
      </div>

      {offer && (
        <Modal handleClose={() => setOffer(null)}>
          <div className="row">
            <div className="col-lg">
              <div className="upload-container">
                <label htmlFor="file" className="form-field-label">
                  Offer Letter:
                </label>
                <div className="upload">
                  <label htmlFor="file" className="upload-label">
                    {file ? (
                      <div>{fileName}</div>
                    ) : (
                      <>
                        <UploadIcon className="upload-icon" />
                      </>
                    )}
                  </label>
                  <input
                    name="file"
                    type="file"
                    id="file"
                    accept=".jpeg, .png, .jpg, .pdf"
                    className="upload-input"
                    onChange={handleFileChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <Button label="Upload" onClick={uploadFile} />
        </Modal>
      )}

      {sla && (
        <Modal handleClose={() => setSla(null)}>
          <div className="row">
            <div className="col-lg">
              <div>
                {isPdf ? (
                  <a href={sla} target="_blank" rel="noopener noreferrer">
                    <Document file={sla} onLoadSuccess={handleLoadSuccess}>
                      <Page pageNumber={1} />
                    </Document>
                  </a>
                ) : (
                  <a href={sla} target="_blank" rel="noopener noreferrer">
                    <img src={sla} alt="Item" onError={handleImageError} />
                  </a>
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default SLA;
