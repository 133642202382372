import React, { useEffect, useState } from "react";
import Header from "../../../components/blocks/header";
import pipelineService from "../../../services/pipelineService";
import toastService from "../../../services/toastService";
import { useGlobalContext } from "../../../contexts/globalContext";
const LeadsMessages = () => {
  const [messages, setMessages] = useState([]);
  const { user } = useGlobalContext();
  const [loading, setLoading] = useState(true); // Add a loading state

  useEffect(() => {
    // Make an API request to get messages
    const fetchData = async () => {
      try {
        const response = await pipelineService.getMessages(user?.city);
        setMessages(response.data.data); // Assuming the API response contains an array of messages
        setLoading(false); // Set loading to false when data is available
      } catch (error) {
        toastService.error("You Currently don't have any pending Message");
        setLoading(false); // Set loading to false in case of an error
      }
    };

    fetchData();
  }, [user?.city]);

  const handleButtonClick = async (messageId: any) => {
    try {
      // const response = await pipelineService.readMessages(messageId);
      toastService.success("This has been read");

      window.location.reload();
    } catch (error) {
      // console.error("Error fetching message:", error);
    }
  };

  return (
    <React.Fragment>
      <Header title="Your Pending Messages" />
      <div className="score-card">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <table>
            <thead>
              <tr>
                <th>Message ID</th>
                <th>Hospital</th>
                <th>Sender</th>
                <th>Message Content</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {messages.map(
                (message: {
                  id: string;
                  name: string;
                  agent: string;
                  message: string;
                }) => (
                  <tr key={message.id}>
                    <td>{message.id}</td>
                    <td>{message.name}</td>
                    <td>{message.agent}</td>
                    <td>{message.message}</td>
                    <td>
                      <button onClick={() => handleButtonClick(message.id)}>
                        Read
                      </button>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        )}
      </div>
    </React.Fragment>
  );
};

export default LeadsMessages;
