import React, { useState } from "react";
import {
  PaginatedData,
  SortColumn,
  TableColumn,
} from "../../ts-utils/interfaces";

import { orderBy } from "lodash";

import ComplaintsModal from "../modals/complaints";
import LoadingButton from "../common/loadingButton";
import NoItems from "../blocks/noItems";
import Pagination from "../common/pagination";
import Table from "../common/table";
import TableButton from "../blocks/tableButton";

import authService from "../../services/authService";
import utilities from "../../utils/utilities";

const ComplaintsTable = ({
  category,
  search,
  status,
  complaints,
  loading,
}: {
  category: string;
  search: string;
  status: string;
  complaints: any[];
  loading: boolean;
}) => {
  const [sortColumn, setSortColumn] = useState<SortColumn>(initialSortColumn);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedComplaint, setSelectedComplaint] = useState<object | null>(
    null
  );

  const privileges = authService.getPrivileges();
  const pageSize: number = 8;

  const matchesActiveCategory = (complaint: any): boolean => {
    return complaint.category_id === category;
  };

  const matchesSearchResult = (complaint: any): boolean => {
    const searchCriteria: string = status ? status : "name";

    return complaint[searchCriteria]
      .toLowerCase()
      .startsWith(search.toLowerCase());
  };

  const getPaginatedData = (): PaginatedData => {
    let filtered: any[] = complaints;

    if (search) filtered = complaints.filter(matchesSearchResult);
    if (category) filtered = complaints.filter(matchesActiveCategory);

    const sorted: any[] = orderBy(
      filtered,
      [sortColumn.path],
      [sortColumn.order]
    );
    const paginated: any[] = utilities.paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, paginated };
  };

  const handleComplaintSelect = (complaint: any): void => {
    setSelectedComplaint(complaint);
  };

  const columns: TableColumn[] = [
    {
      path: "complaint_id",
      label: "Complaint ID",
    },
    {
      path: "name",
      label: "Hospital Name",
    },
    {
      path: "incident_date",
      label: "Incident Date",
    },
    {
      path: "contact_name",
      label: "Contact Name",
    },
    {
      path: "contact_phone_number",
      label: "Contact Phone Number",
    },
    {
      path: "category",
      label: "Category",
    },
    {
      path: "complain",
      label: "Complaint Summary",
    },
    {
      path: "",
      label: "View More",
      content: (c) =>
        privileges.resolveComplaint && (
          <TableButton
            buttonClass="status primary"
            onClick={() => handleComplaintSelect(c)}
            label="More"
          />
        ),
    },
  ];

  const { totalCount, paginated }: PaginatedData = getPaginatedData();

  return (
    <React.Fragment>
      {privileges.resolveComplaint && selectedComplaint && (
        <ComplaintsModal
          selectedComplaint={selectedComplaint}
          handleClose={() => handleComplaintSelect(null)}
        />
      )}

      <div className="activity-feed fade-in">
        <LoadingButton visible={loading} message="Fetching Complaints" />

        {!loading && (
          <React.Fragment>
            <Table
              columns={columns}
              data={paginated}
              sortColumn={sortColumn}
              onSort={setSortColumn}
            />

            <div className="sb-pagination-container">
              <Pagination
                itemsCount={totalCount}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>

            <NoItems
              isVisible={paginated.length === 0}
              label="No complaints to show"
            />
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

const initialSortColumn: SortColumn = {
  path: "complaint_id",
  order: "desc",
};

export default ComplaintsTable;
