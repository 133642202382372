import React, { useState } from "react";
import { getPrivileges } from "../../../services/authService";

import Conditional from "../../../components/blocks/conditional";
import Header from "../../../components/blocks/header";
import ProspectsTable from "../../../components/tables/prospectsTable";
import Select from "../../../components/common/select";
import SearchQuery from "../../../components/forms/searchQuery";
import UnprospectedTable from "../../../components/tables/unprospectedTable";
import UnassignedTable from "../../../components/tables/unassignedTable";

const Prospects = () => {
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [searchKey, setSearchKey] = useState("name");
  const [active] = useState("2");
  const [count, setCount] = useState(0);

  const handleCount = (newCount) => {
    setCount(newCount);
  };

  const checkTitle = () => {
    switch (active) {
      case "1":
        return "Unprospected (One Hospital)";
      case "2":
        return "Unprospected (Nerve)";
      case "3":
        return "Prospected";
      case "4":
        return "Unassigned";
      default:
        return "Unprospected";
    }
  };

  const checkActiveCategory = () => ({
    firstActive: active === "1",
    secondActive: active === "2",
    thirdActive: active === "3",
    fourthActive: active === "4",
  });

  const activeCategory = checkActiveCategory();
  const { viewUnprospected, viewProspected, viewUnassigned } = getPrivileges();

  return (
    <React.Fragment>
      <Header title={checkTitle() + " - " + count} />

      {/* <div className="prospect-buttons">
        {viewUnprospected && (
          <Button
            label="Unprospected (One Hospital)"
            className={getButtonClassName(activeCategory.firstActive)}
            onClick={() => setActive("1")}
          />
        )}

        {viewUnprospected && (
          <Button
            label="Unprospected (Nerve)"
            className={getButtonClassName(activeCategory.secondActive)}
            onClick={() => setActive("2")}
          />
        )}

        
        {viewProspected && (
          <Button
            label="Prospected"
            className={getButtonClassName(activeCategory.thirdActive)}
            onClick={() => setActive("3")}
          />
        )}

        {viewUnassigned && (
          <Button
            label="Unassigned"
            className={getButtonClassName(activeCategory.fourthActive)}
            onClick={() => setActive("4")}
          />
        )}
      </div> */}

      <div className="hospitals-container">
        <div className="hospitals-container-top">
          <SearchQuery
            name="search"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
          />

          <div className="right-options">
            <Select
              name="search_key"
              placeholder="Search By"
              options={searchKeys}
              value={searchKey}
              onChange={(e) => setSearchKey(e.currentTarget.value)}
            />

            <Select
              name="status"
              placeholder="Hospital Status"
              value={status}
              options={statusCategories}
              onChange={(e) => setStatus(e.currentTarget.value)}
            />
          </div>
        </div>

        <div className="hospitals-container-bottom table-responsive">
          <Conditional
            isVisible={
              viewUnprospected &&
              (activeCategory.firstActive || activeCategory.secondActive)
            }
          >
            <UnprospectedTable
              search={search}
              searchKey={searchKey}
              status={status}
              onCountChange={handleCount}
              activeCategory={active}
            />
          </Conditional>

          {viewProspected && activeCategory.thirdActive && (
            <ProspectsTable
              search={search}
              searchKey={searchKey}
              status={status}
              onCountChange={handleCount}
            />
          )}
          {viewUnassigned && activeCategory.fourthActive && (
            <UnassignedTable
              search={search}
              searchKey={searchKey}
              status={status}
              onCountChange={handleCount}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

const statusCategories = [
  { id: "New", name: "New" },
  { id: "Prospect", name: "Prospect" },
  { id: "Pitch", name: "Pitch" },
  { id: "Onboard", name: "Onboard" },
  { id: "Exodus", name: "Exodus" },
  { id: "Active", name: "Active" },
  { id: "Inactive", name: "Inactive" },
  { id: "Hibernate", name: "Hibernate" },
];

const searchKeys = [
  { id: "name", name: "Name" },
  { id: "email", name: "Email" },
  { id: "phone", name: "Phone Number" },
  { id: "address", name: "Address" },
];

export default Prospects;
