import React from "react";
import Joi from "joi-browser";

import Form from "../common/form";
import LoadingButton from "../common/loadingButton";
import Modal from "../common/modal";
import ProgressLoader from "../common/progressLoader";

import { getSaleAgents, reassignAgent } from "../../services/agent.service";
import {
  error as toastError,
  promise as toastPromise,
} from "../../services/toastService";

import utilities from "../../utils/utilities";

class ReassignAgentForm extends Form {
  state = {
    data: {
      assignee: "",
      pitch_method: "",
    },
    errors: {},
    agents: [],
    pitchTypes: [
      { id: "direct", name: "Direct" },
      { id: "telesales", name: "Telesale" },
    ],
  };

  schema = {
    assignee: Joi.string().min(1).required().label("Assignee"),
    pitch_method: Joi.string().min(1).required().label("Pitch Method"),
  };

  async componentDidMount() {
    await this.fetchAgents();
  }

  handleProgressChange = (progress) => {
    this.setState({ progress });
  };

  fetchAgents = async () => {
    const { selectedProspect } = this.props;

    try {
      this.setState({ loading: true });

      const response = await getSaleAgents(selectedProspect.state);
      const agents = this.mapAgents(response.data.data).filter(
        (a) => a.id !== selectedProspect.user_id
      );

      this.setState({ agents, loading: false });
    } catch (error) {
      toastError("Could not fetch sales reps");
      this.setState({ loading: false });
    }
  };

  mapAgents = (agents) => {
    return utilities.mapItemsFromObjects(agents).map((a) => ({
      id: a.id,
      name: a.username,
    }));
  };

  getApiData = () => {
    const { assignee, pitch_method } = this.state.data;
    const { hospital_id, pitch_id } = this.props.selectedProspect;

    const apiData = { user_id: assignee, hospital_id, pitch_id, pitch_method };
    return apiData;
  };

  postData = async () => {
    const apiData = this.getApiData();

    const response = await reassignAgent(apiData, this.handleProgressChange);
    if (!response.data.ok) throw new Error(response.data.message);

    this.props.handleClose();
  };

  doSubmit = () =>
    toastPromise(this.postData(), {
      loading: "Re-assigning rep...",
      success: () => "Rep re-ssigned Successfully!",
      error: (e) =>
        e?.response?.data?.message || e.message || "Could not re-assign rep",
    });

  render() {
    const { handleClose, selectedProspect } = this.props;
    const { agents, loading, progress, pitchTypes } = this.state;

    return (
      <Modal handleClose={handleClose}>
        <ProgressLoader progress={progress} />
        <LoadingButton visible={loading} message="Fetching reps" />

        {!loading && (
          <form onSubmit={this.handleSubmit}>
            <h3 className="modal-title text-center primary">
              Re-assign <span className="error">{selectedProspect.name}</span>{" "}
              to Rep
            </h3>

            {this.renderSelect({
              name: "assignee",
              placeholder: "Select Rep",
              options: agents,
            })}

            {this.renderSelect({
              name: "pitch_method",
              placeholder: "Select Pitch Method",
              options: pitchTypes,
            })}

            <div className="modal-buttons">
              {this.renderButton("Re-assign")}
            </div>
          </form>
        )}
      </Modal>
    );
  }
}

export default ReassignAgentForm;
