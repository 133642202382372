import React, { useEffect, useState, useCallback } from "react";
import { ComponentProps, Hospital } from "../../ts-utils/types";
import {
  PaginatedData,
  SortColumn,
  TableColumn,
} from "../../ts-utils/interfaces";
import { Link, useNavigate } from "react-router-dom";

import _ from "lodash";

import EditProspectForm from "../forms/editProspectForm";
import ExtraFeedback from "../forms/addExtraFeedback";
import LoadingButton from "../common/loadingButton";
import ProspectedInfo from "../modals/prospectedInfo";
import Table from "../common/table";

import utilities from "../../utils/utilities";
import NoItems from "../blocks/noItems";
import HospitalScoreCard from "../modals/hospitalScoreCard";
import Modal from "../modals/Modal";
import CustomPagination from "../common/pagination/Pagination";
import HospitalHistory from "../modals/HospitalHistory";
import applyFilters from "../../helpers/urlState";
import { useGlobalContext } from "../../contexts/globalContext";
import {
  useGetFacilityTypes,
  useGetFacilitySizes,
} from "../../hooks/useHospital";

const ActiveScheduleTable = (props: ComponentProps) => {
  const navigate = useNavigate();
  const [sortColumn, setSortColumn] = useState<SortColumn>(initialSortColumn);
  const [hospitalToView, setHospitalToView] = useState<Hospital | null>(null);
  // const [hospitalCard, setHospitalCard] = useState<Hospital | null>(null);
  const [hospitalToEdit, setHospitalToEdit] = useState<Hospital | null>(null);
  const [hospitalInfoToEdit, setHospitalInfoToEdit] = useState<Hospital | null>(
    null
  );
  const [hospitalRef, setHospitalRef] = useState("");
  const [hospitalStatus] = useState("");

  const { facilityTypes } = useGetFacilityTypes();
  const { facilitySizes } = useGetFacilitySizes();

  const { user } = useGlobalContext();

  const {
    category,
    hospitals,
    loading,
    success,
    search,
    searchKey,
    status,
    currentPage,
    setPage,
    paginate,
    limit: pageSize,
    // canEditPitch,
    onCategoryChange,
  } = props;

  const [openModal, setOpenModal] = useState(false);

  const handleHospitalToView = (hospital: Hospital): void => {
    setHospitalToView(hospital);
    setOpenModal(!openModal);
  };

  // const handleHospitalCard = (hospital: Hospital): void => {
  //   setHospitalCard(hospital);
  // };

  const handleEditSelect = (hospital: Hospital): void => {
    setHospitalInfoToEdit(hospital);
  };

  const getAgentMatch = useCallback(
    (hospital: Hospital): boolean => {
      return hospital.rep === user?.id;
    },
    [user?.id]
  );

  const getSearchMatch = useCallback(
    (hospital: Hospital): boolean => {
      let searchBy: string = searchKey ? searchKey : "name";
      let searchValue: string | number = hospital[searchBy] ?? "";

      return searchValue
        .toString()
        .toLowerCase()
        .startsWith(search.toLowerCase());
    },
    [search, searchKey]
  );

  const getStatusMatch = useCallback(
    (hospital: Hospital): boolean => {
      return (
        hospital.current_status?.toLowerCase?.() === status?.tag?.toLowerCase()
      );
    },
    [status?.tag]
  );

  const getPaginatedData = useCallback(() => {
    let filtered: Hospital[] = hospitals;
    let isAgentOrder: boolean = category === "1";
    if (search && user?.position !== "customer_success")
      filtered = hospitals.filter(getSearchMatch);
    if (status?.tag) filtered = filtered.filter(getStatusMatch);

    if (isAgentOrder) filtered = filtered.filter(getAgentMatch);
    let sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    let paginated = utilities.paginate(sorted, 1, pageSize);
    return { totalCount: filtered.length, paginated };
  }, [
    hospitals,
    category,
    search,
    user?.position,
    getSearchMatch,
    status?.tag,
    getStatusMatch,
    getAgentMatch,
    sortColumn.path,
    sortColumn.order,
    pageSize,
  ]);

  const getColumns = (): TableColumn[] => {
    // const hospitalName: TableColumn = {
    //   path: "name",
    //   label: "Hospital Name",
    //   content: (h: Hospital): JSX.Element => (
    //     <>
    //       {user?.position === "customer_success" ? (
    //         <button
    //           className="text-start hover:text-blue-400"
    //           onClick={() => {
    //             setHospitalRef(h.ref_id);
    //             setHospitalStatus(h.current_status);
    //           }}
    //         >
    //           {h.name}
    //         </button>
    //       ) : (
    //         // @ts-ignore
    //         <Link to={`/hospitals/${h.hospitalid ?? h.ref_id}`}>{h.name}</Link>
    //       )}
    //     </>
    //   ),
    // };

    const viewHospitalInformation: TableColumn = {
      path: "_",
      label: "",
      content: (h: Hospital): JSX.Element => (
        <button
          className="status primary disabled:!bg-slate-400"
          onClick={() => {
            window.sessionStorage.setItem("activeHospitals", JSON.stringify(h));
            navigate(`/active-schedules?id=${h.id}`);
          }}
        >
          Process
        </button>
      ),
    };

    const viewHospitalCard: TableColumn = {
      path: "_",
      label: "",
      content: (h: Hospital): JSX.Element => (
        <button
          disabled={!h?.ref_id}
          className="status primary disabled:!bg-slate-400"
          onClick={() => handleHospitalToView(h)}
        >
          View
        </button>
      ),
    };

    return user?.position === "customer_success"
      ? [...customerSuccessColumns, viewHospitalCard]
      : [
          ...activeHospitalColumns,
          //   editHospitalInfo,
          viewHospitalInformation,
        ];
  };

  const { paginated }: PaginatedData = getPaginatedData();

  const totalItems = paginate?.totalItems;

  useEffect(() => {
    onCategoryChange(totalItems);

    // eslint-disable-next-line
  }, [totalItems]);

  useEffect(() => {
    const pageFromQuery =
      Number(new URLSearchParams(window.location.search).get("page")) || 1;

    if (paginated.length === 0 && !loading) {
      if (paginated.length > 0) {
        applyFilters(
          {
            search: paginated.length > 0 ? search : "",
            status: status.tag,
            page:
              pageFromQuery > paginate?.totalPages ? 1 : paginate?.totalPages,
          },
          navigate,
          "active-hospitals"
        );
      }

      setPage(1);
    }
  }, [
    navigate,
    loading,
    paginate?.totalPages,
    paginated,
    search,
    setPage,
    status.tag,
  ]); // Include paginated as a dependency

  const goToPage = (page: number) => {
    setPage(Math.min(Math.max(page, 1), totalItems));
    applyFilters(
      {
        search: search,
        status: status.tag,
        page: Math.min(Math.max(page, 1), totalItems),
      },
      navigate,
      "active-hospitals"
    );
  };

  return (
    <React.Fragment>
      <Modal
        open={openModal}
        className="z-50 flex items-center justify-center overflow-hidden"
      >
        {user?.position !== "customer_success" && (
          <ProspectedInfo
            selectedProspect={hospitalToView}
            facilityType={facilityTypes}
            facilitySize={facilitySizes}
            handleClose={() => handleHospitalToView(null)}
          />
        )}

        {user?.position === "customer_success" && (
          <HospitalScoreCard
            selectedProspect={hospitalToView}
            handleClose={() => setOpenModal(!openModal)}
          />
        )}
      </Modal>
      <Modal
        open={!!hospitalRef}
        className="z-50 flex items-center justify-center overflow-hidden"
      >
        {user?.position === "customer_success" && (
          <HospitalHistory
            hospitalRef={hospitalRef}
            hospitalStatus={hospitalStatus}
            handleClose={() => setHospitalRef("")}
          />
        )}
      </Modal>
      <Modal open={!!hospitalInfoToEdit}>
        <EditProspectForm
          selectedProspect={hospitalInfoToEdit}
          handleClose={() => handleEditSelect(null)}
        />
      </Modal>

      {hospitalToEdit && (
        <ExtraFeedback
          hospital={hospitalToEdit}
          handleClose={() => setHospitalToEdit(null)}
        />
      )}

      <div className="activity-feed fade-in">
        {loading ? (
          <LoadingButton
            visible={loading}
            message="Fetching Active Hospitals... Please wait"
          />
        ) : (
          <React.Fragment>
            <Table
              columns={getColumns()}
              data={paginated}
              sortColumn={sortColumn}
              onSort={setSortColumn}
            />
            {!loading && (
              <div className="sb-pagination-container mb-5">
                {/* <Pagination
                  itemsCount={paginate?.totalItems}
                  pageSize={10}
                  currentPage={+paginate?.currentPage}
                  onPageChange={(page) => {
                    setPage(page);
                  }}
                /> */}
                {success && paginated.length > 0 && (
                  <CustomPagination
                    goToPage={goToPage}
                    currentPage={currentPage}
                    totalPages={paginate?.totalPages}
                  />
                )}
              </div>
            )}
          </React.Fragment>
        )}

        <NoItems
          isVisible={paginated.length === 0 && !loading}
          label={`No ${status?.title || ""} hospitals to show`}
        />
      </div>
    </React.Fragment>
  );
};

const customerSuccessColumns: TableColumn[] = [
  {
    path: "name",
    label: "Hospital Name",
    content: (h: Hospital) => (
      <>
        <Link to={`/active-hospitals/${h.id}`}>{h.name}</Link>
      </>
    ),
  },
  {
    path: "address",
    label: "Address",
  },
  {
    path: "state",
    label: "State",
  },
  {
    path: "orders",
    label: "Number of Orders",
  },
  {
    path: "amount",
    label: "Amount",
  },
  {
    path: "frequency",
    label: "Frequency",
    content: (h: Hospital) => <p className="capitalize">{h?.frequency}</p>,
  },
  {
    path: "current_status",
    label: "Current Status",
    content: (h: Hospital) => (
      <div className="flex justify-end md:justify-normal">
        {" "}
        <p
          className={
            "capitalize md:!mx-0 md:!px-0 md:w-32 gap-1 md:text-center hospital-status " +
            (h?.current_status || "").toLowerCase()
          }
        >
          <span> {h?.current_status || ""}</span>{" "}
          <span>
            {h?.current_status === "danger" && h?.current_status_open === "0"
              ? "track"
              : h?.current_status === "danger"
              ? "Open"
              : h?.current_status !== "newly-active" &&
                h?.current_status !== "loyal"
              ? "Closed"
              : ""}
          </span>
        </p>
      </div>
    ),
  },
  {
    path: "previous_status",
    label: "Previous Status",
    content: (h: Hospital) => (
      <div className="flex justify-end md:justify-normal">
        {" "}
        <p
          className={
            "capitalize md:!mx-0 md:!px-0 md:w-32 gap-1 md:text-center hospital-status " +
            (h?.previous_status || "").toLowerCase()
          }
        >
          {h.previous_status}
        </p>
      </div>
    ),
  },
];
const activeHospitalColumns: TableColumn[] = [
  {
    path: "name",
    label: "Hospital Name",
    content: (h: Hospital) => (
      <>
        {/*@ts-ignore*/}
        <Link to={`/hospitals/${h.hospitalid}`}>{h.name}</Link>
      </>
    ),
  },
  {
    path: "address",
    label: "Address",
  },
  {
    path: "state",
    label: "State",
  },
  {
    path: "orders",
    label: "Number of Orders",
  },
  {
    path: "amount",
    label: "Amount",
  },
  {
    path: "procurement_cycle",
    label: "Frequency",
    content: (h: Hospital) => <p className="capitalize">{h?.frequency}</p>,
  },
  {
    path: "status",
    label: "Status",
    content: (h: Hospital) => (
      <button className={"status " + h.status?.toLowerCase?.()}>
        {h.status}
      </button>
    ),
  },
];

const initialSortColumn: SortColumn = { path: "id", order: "desc" };
export default ActiveScheduleTable;

// ActiveScheduleTable.propTypes = {
//   canEditPitch: PropTypes.func.isRequired,
// };
