import React from "react";

const NotFoundPage: React.FC = () => {
  return (
    <div className="flex justify-center flex-col items-center h-screen">
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
      <button
        className="
        bg-blue-500
        hover:bg-blue-700
        text-white
        font-bold
        py-2
        px-4
        rounded
        mt-4
      "
      >
        {/* <a href="/home">Go back to home</a> */}
        Go back to home
      </button>
    </div>
  );
};

export default NotFoundPage;
