import { ComponentProps } from "../../ts-utils/types";
import { SelectOption, TableColumn } from "../../ts-utils/interfaces";

import DisableAgentButton from "../blocks/disableAgentButton";
import ViewScoreCardButton from "../blocks/viewScoreCardButton";

import { useGlobalContext } from "../../contexts/globalContext";
import { useGetCityAgent } from "../../hooks/agent/useAgent";

function withAgents(Component: any): (props: any) => JSX.Element {
  return function WithAgents(props: ComponentProps): JSX.Element {
    const { city } = useGlobalContext();
    const { mappedAgents, isPending } = useGetCityAgent(city as string);

    const getAgentsForSelectOption = (): SelectOption[] => {
      return mappedAgents?.map((agent) => ({
        id: agent.id,
        name: agent.username,
      }));
    };

    return (
      <Component
        agents={mappedAgents}
        tableColumns={columns}
        loading={isPending}
        agentsForSelectOption={getAgentsForSelectOption()}
        {...props}
      />
    );
  };
}

const columns: TableColumn[] = [
  { path: "id", label: "ID" },
  {
    path: "username",
    label: "Agent Name",
  },
  {
    path: "position",
    label: "Position",
  },
  {
    path: "city",
    label: "City",
  },
  {
    path: "country",
    label: "Country",
  },
  {
    path: "total_assigned",
    label: "Total Assigned Hospitals",
  },
  {
    path: "total_active",
    label: "Total Active Hospitals",
  },
  {
    path: "conversion",
    label: "Conversion Rate (%)",
    content: (a: any) => `${a.conversion ?? 0}%`,
  },
  {
    path: "_",
    label: "",
    content: (a: any) => <DisableAgentButton agent={a} />,
  },
  {
    path: "password",
    label: "",
    content: (a: any) => <ViewScoreCardButton agent={a} />,
  },
];

export default withAgents;
