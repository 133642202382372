import { Dispatch, Middleware } from "@reduxjs/toolkit";
import { ECommerce } from "@causalfoundry/js-sdk";
import { CartItem, CartState } from "../../ts-utils/interfaces";
interface AddToCartAction {
  type: string;
  payload: {
    id: string;
    qty: string | number;
    price: string;
    brand?: string;
    quantityType?: string;
  };
}

interface RemoveFromCartAction {
  type: string;
  payload: { id: string | number };
}

type CartAction =
  | AddToCartAction
  | RemoveFromCartAction /* | OtherActionTypes */;

function isAddToCartAction(action: unknown): action is AddToCartAction {
  return (action as AddToCartAction).type === "cart/addToCart";
}
// function isAction(action: unknown): action is Action {
//   return (
//     typeof action === "object" &&
//     action !== null &&
//     "type" in action &&
//     "payload" in action &&
//     typeof (action as Action).type === "string"
//   );
// }
export const log_cart: Middleware<
  {},
  { cart: CartState },
  Dispatch<CartAction>
> = (api) => (next) => (action: CartAction | unknown) => {
  if (isAddToCartAction(action)) {
    const cart = JSON.parse(JSON.stringify(api?.getState()?.cart));

    const type = action.type;
    const payload = action.payload;

    if (type === "cart/addToCart") {
      const cart_price = [...cart.items, payload].reduce((acc, curr) => {
        return acc + parseFloat(curr.price) * parseInt(curr.qty, 10);
      }, 0);
      logCart(cart.id, payload, cart_price, "add_item");
    } else if (type === "cart/removeFromCart") {
      let item = cart.items.find(
        (item: { id: string | number }) => item.id === payload.id
      );
      if (item) {
        item.qty = 0;
        const cart_price = cart.items.reduce(
          (
            acc: number,
            curr: { price: string; id: string | number; qty: string | number }
          ) => {
            return (
              acc +
              parseFloat(curr.price) *
                parseInt(curr.id === payload.id ? "0" : curr.qty.toString(), 10)
            );
          },
          0
        );
        logCart(cart.id, item, cart_price, "remove_item");
      }
    } else if (type === "cart/clearCart") {
      // do nothing for clear cart
    } else if (type === "cart/incrementItemQuantity") {
      let item = cart.items.find(
        (item: { id: string }) => item.id === payload.id
      );
      if (item) {
        item.qty = parseInt(item.qty) + 1;
        const cart_price = cart.items.reduce(
          (acc: number, curr: { qty: string; id: string; price: string }) => {
            const qty = parseInt(curr.qty) + (curr.id === payload.id ? 1 : 0);
            return acc + parseFloat(curr.price) * qty;
          },
          0
        );
        logCart(cart.id, item, cart_price, "add_item");
      }
    } else if (type === "cart/decrementItemQuantity") {
      let item = cart.items.find(
        (item: { id: string }) => item.id === payload.id
      );
      if (item) {
        item.qty = parseInt(item.qty) - 1;
        const cart_price = cart.items.reduce(
          (acc: number, curr: { qty: string; id: string; price: string }) => {
            const qty = parseInt(curr.qty) - (curr.id === payload.id ? 1 : 0);
            return acc + parseFloat(curr.price) * qty;
          },
          0
        );
        logCart(cart.id, item, cart_price, "remove_item");
      }
    } else if (type === "cart/addToCartMultiple") {
      if (Array.isArray(payload)) {
        const cart_price = [...cart.items, ...payload].reduce((acc, curr) => {
          return acc + parseFloat(curr.price) * parseInt(curr.qty, 10);
        }, 0);
        payload.forEach((item) => {
          logCart(cart.id, item, cart_price, "add_item");
        });
      }
    } else if (type === "cart/updateItemQuantity") {
      const item = cart.items.find(
        (item: { id: any }) => item.id === payload.id
      );
      if (item) {
        item.qty = payload.qty;
        const cart_price = cart.items.reduce(
          (acc: number, curr: { price: string; qty: number }) => {
            return acc + parseFloat(curr.price) * curr.qty;
          },
          0
        );
        logCart(cart.id, item, cart_price, "update_item");
      }
    } else if (type === "cart/updateItemBrand") {
      const item = cart.items.find(
        (item: { id: any }) => item.id === payload.id
      );
      if (item) {
        item.brand = payload.brand;
        const cart_price = cart.items.reduce(
          (acc: number, curr: { price: string; qty: number }) => {
            return acc + parseFloat(curr.price) * curr.qty;
          },
          0
        );
        logCart(cart.id, item, cart_price, "update_item");
      }
    } else if (type === "cart/updateItemPacks") {
      const item = cart.items.find(
        (item: { id: any }) => item.id === payload.id
      );
      if (item) {
        item.quantityType = payload.quantityType;
        const cart_price = cart.items.reduce(
          (acc: number, curr: { price: string; qty: number }) => {
            return acc + parseFloat(curr.price) * curr.qty;
          },
          0
        );
        logCart(cart.id, item, cart_price, "update_item");
      }
    }
  }

  return next(action);
};

const logCart = (
  id: string,
  item: CartItem,
  cart_price: string | number,
  action: string
) => {
  const cartEvent = {
    id,
    action,
    cart_price,
    currency: "NGN",
    item: {
      id: item.id,
      type: "medical_equipment",
      quantity: parseInt(item.qty as string),
      price: parseFloat(item.price) * parseInt(item.qty as string, 10),
      currency: "NGN",
    },
  };
  ECommerce.logCartEvent(cartEvent);
};
