import LoginForm from "../../../components/forms/loginForm";

import config from "../../../utils/config.js";
import { useNavigate, Navigate } from "react-router-dom";
import navigationRoutes from "../../../routes/navigationRoutes";
import { useGlobalContext } from "../../../contexts/globalContext";
import AuthenticationLayout from "./Layout";
// const journey = ["Found", "Acquired", "Maintain", "Growth"];

const Login = (props) => {
  navigator.serviceWorker?.getRegistrations().then(function (registrations) {
    for (let registration of registrations) {
      registration.unregister();
      // console.log(registration, "unregistered");
    }
  });
  //return null;
  const navigate = useNavigate();

  const { country } = useGlobalContext();

  let userType = window.localStorage.getItem("userType");
  if (country && !userType) {
    return <Navigate to={navigationRoutes.userType} />;
  } else if (!country && userType) {
    return <Navigate to={navigationRoutes.selectCountry} />;
  } else if (!country && !userType) {
    return <Navigate to={navigationRoutes.selectCountry} />;
  }
  return (
    <AuthenticationLayout>
      <div className="login">
        <div className="login-inner">
          <h2 className="login-inner-title">
            Welcome to One Hospital, a platform to take all LifeBank hospitals
            through our customer journey
          </h2>
          {/* <p className="login-inner-title">Customer Journey</p> */}

          {/* <div className="login-journey">
          {journey.map((j, index) => (
            <div className="login-journey-inner" key={index}>
              {index > 0 && index < journey.length ? (
                <div className="login-journey-inner-line"></div>
              ) : null}
              <div className="login-journey-inner-item">{j}</div>
            </div>
          ))}
        </div> */}

          <p className="login-inner-subtitle">
            Sign in to your account to continue
          </p>

          <div className="form-container">
            <LoginForm
              {...props}
              handlePush={() => navigate("/forgot-password")}
            />
          </div>

          <div className="download-apk">
            <p>
              Download APK
              <a
                href={config.oneHospitalAPKUrl}
                rel="noopener noreferrer nofollow"
                target="_blank"
              >
                Here
              </a>
            </p>
          </div>
        </div>
      </div>
    </AuthenticationLayout>
  );
};

export default Login;
