import React, { useState } from "react";

import AddProduct from "../../../components/forms/addProduct";
import SearchQuery from "../../../components/forms/searchQuery";
import ProductListTable from "../../../components/tables/productListTable";

import Button from "../../../components/common/button";
import Header from "../../../components/blocks/header";

import withProductList from "../../../components/hoc/withProductList";

import { getPrivileges } from "../../../services/authService";

const Products = (props) => {
  const [search, setSearch] = useState("");
  const [addProduct, setAddProduct] = useState(false);

  const handleAddProduct = () => setAddProduct((prev) => !prev);

  const privileges = getPrivileges();

  return (
    <React.Fragment>
      <Header title={"Products - " + props.productList.length} />

      {privileges.addFacility && addProduct && (
        <AddProduct {...props} handleClose={handleAddProduct} />
      )}

      <div className="hospitals-container">
        <div className="hospitals-container-top">
          <SearchQuery
            name="search"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
          />

          <div className="right-options">
            {privileges.addProductType && (
              <Button
                icon="plus"
                label="Add Product"
                onClick={handleAddProduct}
              />
            )}
          </div>
        </div>
        <div className="hospitals-container-bottom table-responsive">
          <ProductListTable search={search} {...props} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default withProductList(Products);
