import ExodusHeader from "../../../../components/exodusComponent/ExodusHeader";
import useSortableTable from "../../../../components/exodusComponent/TableSort";
import { useSingleExodus } from "../../../../hooks/useExodus";

const SingleExodusOrder = () => {
  const idString = localStorage.getItem("exodus_order_id");
  const id = idString ? JSON.parse(idString) : null;
  const { singleExodus } = useSingleExodus(id);

  const data = [{}];

  const { sortBy, sortDirection, handleSort } = useSortableTable(
    data,
    // @ts-ignore
    "complainID"
  );

  const convertDate = (time: number) => {
    const date = new Date(time * 1000);
    const formattedDate = date.toLocaleDateString("en-US");
    return formattedDate;
  };

  const converTime = (time: number) => {
    const date = new Date(time * 1000);

    const formattedTime = date.toLocaleTimeString("en-US");
    return formattedTime;
  };

  return (
    <div className="h-screen w-full">
      <ExodusHeader
        Text={"All orders"}
        subText={singleExodus?.hospital_summary[0].name}
      />

      <div className="w-11/12 mx-auto flex mt-8">
        <table>
          <thead className="mt-4">
            <tr className="mt-4 ">
              <th
                onClick={() => handleSort("complainID")}
                className="cursor-pointer font-extrabold text-[18px]"
              >
                Order ID
                {sortBy === "complainID" && (
                  <span>{sortDirection === "asc" ? " ▲" : " ▼"}</span>
                )}
              </th>
              <th className="font-extrabold text-[18px]">Order date</th>
              <th className="font-extrabold text-[18px]">Product</th>
              <th className="font-extrabold text-[18px]">Quantity</th>
              <th className="font-extrabold text-[18px]">Price</th>
              <th className="font-extrabold text-[18px]">Delivery Time</th>
            </tr>
          </thead>
          <tbody className="bg-[#FFFFFF] h-[160px] shadow-white shadow-sm rounded">
            {singleExodus?.orders.map((ele: any) => (
              <tr>
                <td className="ml-8">{ele.order_id}</td>
                <td>{convertDate(ele.order_date)}</td>
                <td>{ele.product}</td>
                <td>{ele.quantity}</td>
                <td>{ele.price}</td>
                <td>{converTime(ele.delivery_time)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SingleExodusOrder;
