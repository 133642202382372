import httpService from "./httpService";

export const getHibernatedHospitals = (city) => {
  return httpService.get("/manage/hibernate/hospitals/" + city);
};

export const getUnsuccessfulPitches = (city) => {
  return httpService.get("/manage/unsuccessful/" + city);
};

export const addTelesales = (data, handleProgressChange) => {
  const options = {
    onUploadProgress: (progressEvent) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  return httpService.post(`/manage/${data.type}/hospital`, data, options);
};

const leadService = {
  addTelesales,
  getHibernatedHospitals,
  getUnsuccessfulPitches,
};

export default leadService;
