import PropTypes from "prop-types";
import usePagination from "../../hooks/usePagination";

const Pagination = ({ itemsCount, pageSize, currentPage, onPageChange }) => {
  const pagination = usePagination(itemsCount, pageSize);
  if (!pagination?.pagesToDisplay) return null;
  return (
    <nav>
      <ul id="ohw-pagination">
        {pagination.previousRangeVisible && (
          <PaginationButtonItem
            icon="left"
            onClick={pagination.onPreviousRange}
          />
        )}

        {pagination.pagesToDisplay.map((page) => (
          <NumberPaginationItem
            key={page}
            isActive={page === currentPage}
            page={page}
            onClick={() => onPageChange(page)}
          />
        ))}

        {pagination.nextRangeVisible && (
          <PaginationButtonItem icon="right" onClick={pagination.onNextRange} />
        )}
      </ul>
    </nav>
  );
};

const PaginationButtonItem = ({ icon, onClick }) => {
  return (
    <li className="ohw-page-item">
      <button className="ohw-page-link" onClick={onClick}>
        <i className={"fa fa-chevron-" + icon}></i>
      </button>
    </li>
  );
};

const NumberPaginationItem = ({ onClick, page, isActive }) => {
  return (
    <li className={isActive ? "ohw-page-item active-page" : "ohw-page-item"}>
      <button className="ohw-page-link" onClick={onClick}>
        {page}
      </button>
    </li>
  );
};

Pagination.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
