import React from 'react';


interface Props {
    id: string,
    value: string,
    checked: any,
    onChange: any,
    label?: any,
}

const RadioButton = ({ id, value, checked, onChange, label }: Props) => {
    return (
        <div className="flex items-center justify-center">
            <input
                className="text-2xl h-[40px] w-[40px] text-[green]  ml-2 pl-2 pr-2 border-2 border-green-500 focus:border-green-700"
                type="radio"
                id={id}
                value={value}
                checked={checked}
                onChange={onChange}
            />
            <label htmlFor={id} className="text-2xl pl-2 pr-2">{label}</label>
        </div>
    );
}

export default RadioButton;