import React from "react";
function TimePicker(props) {
  const { name, value, onChange, error } = props;

  const startHour = 8;
  const endHour = 18;

  return (
    <div className="form-field">
      <p className="date-picker-label">
        {props.compulsory && <i className="fa fa-asterisk error"></i>}
        {props.label}
      </p>
      <select
        name={name}
        onChange={onChange}
        value={value || ""}
        className="form-field-input"
      >
        <option value="">Select time</option>
        {Array.from(
          { length: endHour - startHour + 1 },
          (_, i) => i + startHour
        ).map((hour) => (
          <React.Fragment key={hour}>
            <option value={`${hour}:00`}>
              {hour % 12 || 12}:00 {hour < 12 ? "AM" : "PM"}
            </option>
            {hour !== endHour && (
              <option value={`${hour}:30`}>
                {hour % 12 || 12}:30 {hour < 12 ? "AM" : "PM"}
              </option>
            )}
          </React.Fragment>
        ))}
      </select>

      {error && <div>{error}</div>}
    </div>
  );
}

export default TimePicker;
