import { useLayoutEffect, useState } from "react";
import { ApiResponse as Response, Appointment } from "../../ts-utils/types";

import utilities from "../../utils/utilities";

import appointmentService from "../../services/appointmentService";
import toastService from "../../services/toastService";

import { useGlobalContext } from "../../contexts/globalContext";

function withAppointments(Component: any): (props: any) => JSX.Element {
  return function WithAppointments(props): JSX.Element {
    const [appointments, setAppointments] = useState<Appointment>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { user } = useGlobalContext();

    const getAppointments = async (): Promise<void> => {
      try {
        setIsLoading(true);
        if (user?.position === "sales_lead") {
          const response: Response = await appointmentService.getAllAcquisition(
            user.city
          );
          const result: any = response.data;
          const data: any[] = utilities.mapKeyToNumber(result.data, "id");

          setAppointments(data);
        } else {
          const response: Response =
            await appointmentService.getAllAppointments(user?.id);
          const result: any = response.data;
          console.log("result", result)
          const data: any[] = utilities.mapKeyToNumber(result.data, "id");

          setAppointments(data);
        }
        setIsLoading(false);
      } catch (ex: any) {
        const message: string = ex?.response?.data?.description ?? ex.message;
        toastService.error(message);

        setIsLoading(false);
      }
    };

    useLayoutEffect(() => {
      getAppointments();
    }, []);

    return (
      <Component appointments={appointments} loading={isLoading} {...props} />
    );
  };
}

export default withAppointments;
