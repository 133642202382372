import React, { ReactNode } from "react";

interface CardProps {
  title: string;
  icon?: ReactNode;
  borderColor?: string;
  hoverClass?: string;
  width?: string;
  iconColor?: any;
  textColor: string;
  cardColor?: string;
  onClick?: any;
  iconSize?: string;
  image?: string;
  height?: any;
  iconOnTop?: boolean;
  iconPosition?: string;
  textPosition?: string;
  centerHorizontal?: string;
  centerVertically?: string;
}

const OrderCard = ({
  title,
  icon,
  borderColor,
  hoverClass,
  width,
  iconColor,
  cardColor,
  height,
  textColor,
  onClick,
  iconSize,
  image,
  iconOnTop = true,
  iconPosition,
  textPosition,
}: CardProps) => {
  const cardClasses = `border-solid shadow-xl flex flex-col ${
    cardColor || "bg-[white]"
  }  justify-between mx-auto item-center mx-auto border ${
    borderColor || "border-gray-300"
  }  rounded-xl p-4 ${hoverClass || "hover:bg-consumableGreen"}`;
  const iconStyle = `flex text-${iconColor || "gray-600"} ${
    iconSize || "w-20"
  } ${iconPosition || "ml-auto"} object-fit`;

  const titleStyle = `text-2xl font-bold   mb-8 ${textColor || "text-black"} ${
    textPosition || "items-center "
  }`;
  //
  return (
    <div className={`${width || "w-full"}`} onClick={onClick}>
      <div
        className={`${cardClasses}  cursor-pointer `}
        style={{ height: height }}
      >
        {iconOnTop ? (
          <>
            {image ? (
              <img src={image} alt={title} className={iconStyle} />
            ) : (
              <div className={iconStyle}>{icon}</div>
            )}
            <h3 className={titleStyle}>{title}</h3>
          </>
        ) : (
          <>
            <h3 className={titleStyle}>{title}</h3>
            {image ? (
              <div
                style={{
                  justifyContent: "flex-end",
                }}
              >
                <img
                  src={image}
                  alt={title}
                  className={iconStyle}
                  style={{
                    justifyContent: "flex-end",
                    height: "200px",
                    width: "200px",
                  }}
                />
              </div>
            ) : (
              <div className={iconStyle}>{icon}</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default OrderCard;
