import { AxiosProgressEvent } from "axios";
import httpService from "./httpService";

export const getComplaints = (city: string) => {
  return httpService.get("/manage/complaint/list/" + city);
};

export const getHospitalComplaints = (hospitalId: string): Promise<any> => {
  return httpService.get("/manage/hospitals/complaint/list/" + hospitalId);
};

export const getComplaintCategories = () => {
  return httpService.get("/manage/categories");
};

export const addComplaint = (
  data: any,
  handleProgressChange: (arg0: number) => any
) => {
  const options = {
    onUploadProgress: (progressEvent: AxiosProgressEvent) =>
      handleProgressChange(
        (progressEvent.loaded * 100) / (progressEvent.total ?? 0)
      ),
  };

  return httpService.post("/manage/complaint", data, options);
};

export const resolveComplaint = (
  complaint: { complaint_id: any; hospital_id: any; return: any },
  handleProgressChange: { (progress: any): void; (arg0: number): any }
) => {
  const options = {
    onUploadProgress: (progressEvent: AxiosProgressEvent) =>
      handleProgressChange(
        (progressEvent.loaded * 100) / (progressEvent.total ?? 0)
      ),
  };

  const apiData = {
    complaint_id: "",
    hospital_id: "",
    return: "",
  };

  apiData.complaint_id = complaint.complaint_id;
  apiData.hospital_id = complaint.hospital_id;
  apiData.return = complaint.return;

  return httpService.put("/manage/complaint/resolve", apiData, options);
};

const complaintService = {
  addComplaint,
  getComplaints,
  getComplaintCategories,
  getHospitalComplaints,
  resolveComplaint,
};

export default complaintService;
