import Chart from "react-apexcharts";
import { getPrivileges } from "../../services/authService";

import Checkbox from "../common/checkbox";
import Modal from "../common/modal";

import useBreakdown from "../../hooks/useBreakdown";

import utilities from "../../utils/utilities";

const HospitalVisits = ({
  handleClose,
  data = [],
  targets = [],
  targetExceeded = false,
}: {
  handleClose: any;
  data?: any[];
  targets?: any[];
  targetExceeded?: boolean;
}) => {
  const targetKey = "visited_hospitals";
  const breakdown = useBreakdown({ data, targets, targetKey });
  const privileges = getPrivileges();

  console.log(breakdown, "breakdown");
  console.log(data, "data");

  return (
    <Modal handleClose={handleClose}>
      <h3 className="modal-title">Monthly Total Hospital Visits - YTD</h3>

      <div className="chart-modal-inner">
        <div className="d-flex justify-content-between w-100">
          <p className="chart-figure">
            <span className={targetExceeded ? "profit" : "loss"}>
              ({breakdown.setMonthTarget})
            </span>{" "}
            Target for {utilities.getMonthLabel()}
          </p>

          {privileges.viewTargetData && (
            <Checkbox
              message="View Target Data"
              name="view_target"
              checked={breakdown.isTargetVisible}
              onChange={breakdown.toggleTargetVisibility}
            />
          )}
        </div>

        <Chart
          options={breakdown.chartOptions}
          series={breakdown.chartData}
          type="line"
          width="500"
        />
      </div>
    </Modal>
  );
};

export default HospitalVisits;
