import React, { useState } from "react";

import Header from "../../../../components/blocks/header";
import Select from "../../../../components/common/select";
import SearchQuery from "../../../../components/forms/searchQuery";
import PitchTable from "../../../../components/tables/pitchTable";
import { useGetPipeline } from "../../../../hooks/usePipeline";

import { useQueryClient } from "react-query";

import { useGlobalContext } from "../../../../contexts/globalContext";

const searchKeys = [
  { id: "name", name: "Name" },
  { id: "email", name: "Email" },
  { id: "phone", name: "Phone" },
];
const Pitch = () => {
  const queryClient = useQueryClient();
  const { user } = useGlobalContext();
  const [search, setSearch] = useState("");
  const [searchKey, setSearchKey] = useState("name");

  const { pitches } = useGlobalContext();
  console.log(pitches);

  React.useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ["pipeline"],
    });
  }, []);

  const { pipeline, isLoading } = useGetPipeline(user.city, user.id);
  console.log(pipeline);

  return (
    <React.Fragment>
      <Header title="Pitch" />

      <div className="hospitals-container">
        <div className="hospitals-container-top">
          <SearchQuery
            name="search"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
          />

          <div className="right-options">
            <Select
              name="filter"
              placeholder="Search By"
              value={searchKey}
              options={searchKeys}
              onChange={(e) => setSearchKey(e.currentTarget.value)}
            />
          </div>
        </div>

        <div className="hospitals-container-bottom table-responsive">
          <PitchTable
            search={search}
            searchKey={searchKey}
            assignedHospitals={pipeline?.pitches ?? []}
            isLoading={isLoading}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Pitch;
