import React, { useEffect, useState } from "react";

import AddHospitalForm from "../../../components/forms/addHospitalForm";
import Header from "../../../components/blocks/header";
import SearchQuery from "../../../components/forms/searchQuery";

import authService from "../../../services/authService";
import MaintenanceTable from "../../../components/tables/maintenanceTable";
import { useGetMaintenance } from "../../../hooks/sales/useAppointment";

const MaintenanceSchedule = () => {
  const { maintenance, isPending } = useGetMaintenance();

  const [category, setCategory] = useState("0"); 
  const [search, setSearch] = useState("");
  const [hospitalsCount, setHospitalsCount] = useState(0);
  const [addHospital, setAddHospital] = useState(false);
  // const [categories, setCategories] = useState<{ id: string; name: string }[]>(
  //   []
  // );

  const privileges = authService.getPrivileges();

  const handleAddHospital = () => {
    setAddHospital((prev) => !prev);
  };

  const handleCategories = () => {
    const newCategories = [];

    const allHospitals = { id: "0", name: "All Hospitals" };
    const myHospitals = { id: "1", name: "My Hospitals" };

    const initialPrivilege = privileges.allHospitals
      ? allHospitals.id
      : myHospitals.id;

    if (privileges.allHospitals) newCategories.push(allHospitals);
    if (privileges.myHospitals) newCategories.push(myHospitals);

    setCategory(initialPrivilege);
    // setCategories(newCategories);
  };

  // const canEditPitch = (hospitalStatus: string) => {
  //   if (!hospitalStatus) return false;

  //   let index = statusCategories.findIndex((sc) => {
  //     return sc.id === hospitalStatus.toLowerCase();
  //   });

  //   return index >= 2;
  // };

  useEffect(() => {
    handleCategories();

    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <Header title={`Maintenance Schedule - ${hospitalsCount}`} />

      {privileges.createHospital && addHospital && (
        <AddHospitalForm handleClose={handleAddHospital} />
      )}

      <div className="hospitals-container">
        <div className="hospitals-container-top">
          <SearchQuery
            name="search"
            placeholder="Search"
            value={search}
            onChange={(e: {
              currentTarget: { value: React.SetStateAction<string> };
            }) => setSearch(e.currentTarget.value)}
          />
        </div>
        <div className="hospitals-container-bottom table-responsive">
          <MaintenanceTable
            hospitals={maintenance}
            loading={isPending}
            category={category}
            search={search} 
            // canEditPitch={canEditPitch}
            // searchKey={searchKey}
            onCategoryChange={(count) => setHospitalsCount(count)}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

// const statusCategories = [
//   { id: "new", name: "New" },
//   { id: "prospect", name: "Prospect" },
//   { id: "pitch", name: "Pitch" },
//   { id: "onboard", name: "Onboard" },
//   { id: "exodus", name: "Exodus" },
//   { id: "active", name: "Active" },
//   { id: "inactive", name: "Inactive" },
//   { id: "hibernate", name: "Hibernate" },
// ];

// const searchKeys = [
//   { id: "name", name: "Name" },
//   { id: "email", name: "Email" },
//   { id: "phone", name: "Phone" },
//   { id: "address", name: "Address" },
// ];

export default MaintenanceSchedule;
