import React from 'react'

type Props = {
  goBack?: () => void
}

export default function BackButton({goBack}: Props) {
  return (
    <div onClick={() => goBack?.()} className="flex gap-2 cursor-pointer items-center group w-max">
      <svg className="group-hover:fill-blue-600" xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z"/></svg>
      <span className="font-semibold group-hover:text-blue-600">Back</span>
    </div>
  )
}