import React, { useEffect, useState } from "react";

import AddHospitalForm from "../../../../components/forms/addHospitalForm";
import Header from "../../../../components/blocks/header";
import SearchQuery from "../../../../components/forms/searchQuery";
import authService from "../../../../services/authService";
import ActiveScheduleTable from "../../../../components/tables/activeScheduleTable";
import GeneralArrowBox from "../../../../components/blocks/GeneralArrowBox";

import {
  useGetHospitals,
  useActiveHospital,
} from "../../../../hooks/useHospital";

import { useNavigate, useLocation } from "react-router-dom";
import applyFilters from "../../../../helpers/urlState";
import { useGlobalContext } from "../../../../contexts/globalContext";

const hospitalStatuses = [
  {
    title: "Pre Active",
    tag: "pre-active",
    color: "rgb(44, 180, 225)",
    icon: "",
    sortKey: "",
    data: "",
  },
  {
    title: "Newly Active",
    tag: "newly-active",
    color: "crimson",
    icon: "",
    sortKey: "",
    data: "",
  },
  {
    title: "Danger",
    tag: "danger",
    color: "lightcoral",
    icon: "",
    sortKey: "",
    data: "",
  },
  {
    title: "Add Value",
    tag: "add-value",
    color: "coral",
    icon: "",
    sortKey: "",
    data: "",
  },
  {
    title: "Check In",
    tag: "check-in",
    color: "sandybrown",
    icon: "",
    sortKey: "",
    data: "",
  },
  {
    title: "Upsell",
    tag: "up-sell",
    color: "lightgreen",
    icon: "",
    sortKey: "",
    data: "",
  },
  {
    title: "Loyal",
    tag: "loyal",
    color: "violet",
    icon: "",
    sortKey: "",
    data: "",
  },
];

const ActiveSchedules = () => {
  const history = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialStatus = searchParams.get("status");
  const page = searchParams.get("page");
  const initialSearch = searchParams.get("search");

  const [currentPage, setPage] = useState(page || 1);
  const limit = 10;
  // const [category, setCategory] = useState("0");
  const [search, setSearch] = useState(initialSearch ?? "");
  const [status, setStatus] = useState({
    title: "",
    tag: initialStatus ?? "",
    color: "",
    icon: "",
    sortKey: "",
    data: "",
  });
  const [hospitalsCount, setHospitalsCount] = useState(0);
  const [addHospital, setAddHospital] = useState(false);
  // const [categories, setCategories] = useState([]);

  const { user } = useGlobalContext();

  const {
    activeHospitals,
    activePaginate,
    isSuccess: activeSuccess,
    isLoading: activeLoading,
  } = useActiveHospital();

  const { hospitals, isSuccess, isLoading, paginate } = useGetHospitals(
    currentPage,
    limit,
    status.tag,
    search
  );
  // transform hospitals if status is Exodus update to pre-active
  const transformedHospitals = hospitals.map((hospital) => {
    if (hospital.status === "Exodus") {
      hospital.current_status = "pre-active";
    }
    if (hospital.status === "Exodus") {
      hospital.previous_status = "pre-active";
    }
    return hospital;
  });

  const privileges = authService.getPrivileges();

  const handleAddHospital = () => {
    setAddHospital((prev) => !prev);
  };

  const handleCategories = () => {
    const newCategories = [];

    const allHospitals = { id: "0", name: "All Hospitals" };
    const myHospitals = { id: "1", name: "My Hospitals" };

    // const initialPrivilege = privileges.allHospitals
    //   ? allHospitals.id
    //   : myHospitals.id;

    if (privileges.allHospitals) newCategories.push(allHospitals);
    if (privileges.myHospitals) newCategories.push(myHospitals);

    // setCategory(initialPrivilege);
    // setCategories(newCategories);
  };

  useEffect(() => {
    handleCategories();

    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <Header
        title={`${
          status.title ? status.title : "Active Schedule Hospitals"
        } - ${hospitalsCount ?? 0}`}
      />

      {privileges.createHospital && addHospital && (
        <AddHospitalForm handleClose={handleAddHospital} />
      )}

      <div className="hospitals-container">
        <div className="hospitals-container-top">
          <SearchQuery
            name="hospital-search"
            placeholder="Search"
            value={search}
            onChange={(e) => {
              setSearch(e.currentTarget.value);
              applyFilters(
                {
                  search: e.currentTarget.value,
                  status: status.tag,
                  page: currentPage,
                },
                history,
                "active-hospitals"
              );
            }}
          />
        </div>
        <div className="hospitals-container-bottom table-responsive">
          <>
            {user.position === "customer_success" ? (
              <ActiveScheduleTable
                hospitals={transformedHospitals}
                loading={isLoading}
                success={isSuccess}
                paginate={paginate}
                currentPage={+page}
                setPage={setPage}
                limit={limit}
                // category={category}
                search={search}
                status={status}
                // initialStatus={initialStatus}
                // canEditPitch={canEditPitch}
                // searchKey={searchKey}
                onCategoryChange={(count) => setHospitalsCount(count)}
              />
            ) : (
              <ActiveScheduleTable
                hospitals={activeHospitals}
                loading={activeLoading}
                success={activeSuccess}
                paginate={activePaginate}
                currentPage={+page}
                setPage={setPage}
                limit={limit}
                // category={category}
                search={search}
                status={status}
                // canEditPitch={canEditPitch}
                // searchKey={searchKey}
                onCategoryChange={(count) => setHospitalsCount(count)}
              />
            )}
          </>
        </div>
        {user.position === "customer_success" && (
          <GeneralArrowBox
            pipelineItems={hospitalStatuses}
            activeCategory={status}
            onActiveChange={(item) => {
              setStatus((prev) => (prev?.tag === item.tag ? "" : item));
              applyFilters(
                {
                  search: search,
                  status: item.tag,
                  page: currentPage,
                },
                history,
                "active-hospitals"
              );
              // window scroll to top
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default ActiveSchedules;
