import Chart from "react-apexcharts";

import LoadingButton from "../common/loadingButton";
import Modal from "../common/modal";

import withProductList from "../hoc/withProductList";
import utilities from "../../utils/utilities";

const UnfulfilledOrders = ({
  loading,
  productList,
  unfulfilled,
  handleClose,
}: {
  loading: boolean;
  productList: any[];
  unfulfilled: any[];
  handleClose: () => void;
}) => {
  const labels = productList.map((p) => p.name);
  const chartColors = productList.map((p) => p.color);

  const getLabelValues = () => {
    return labels.map((l) => {
      const labelMatch = unfulfilled.find(
        (u) => u.category.toLowerCase() === l.toLowerCase()
      );

      return labelMatch ? +labelMatch.monthCount : 0;
    });
  };

  const pieData = {
    labels,
    datasets: [
      {
        label: "# of Flights",
        data: getLabelValues(),
        backgroundColor: chartColors,
        borderWidth: 0,
      },
    ],
  };
  const chartOptions = {
    labels,
    options: {
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "right",
            },
          },
        },
      ],
    },
  };
  return (
    <>
      <Modal handleClose={handleClose}>
        <h3 className="modal-title">
          Monthly Unfulfilled Orders for{" "}
          <span>{utilities.getMonthLabel()}</span>
        </h3>

        <div className="chart-modal-inner">
          {loading ? (
            <LoadingButton visible message="Please wait" />
          ) : (
            <article className="general-stats">
              <div className="chart">
                <Chart
                  type="pie"
                  options={chartOptions}
                  series={pieData.datasets[0].data}
                  height={350}
                />
              </div>
            </article>
          )}
        </div>
      </Modal>
    </>
  );
};

export default withProductList(UnfulfilledOrders);
