import React, { useState } from "react";
import _ from "lodash";

import LoadingButton from "../common/loadingButton";
import Pagination from "../common/pagination";
import Table from "../common/table";

import utilities from "../../utils/utilities";

const ProductListTable = ({ productList, loading, search }) => {
  const [sortColumn, setSortColumn] = useState({ path: "id", order: "asc" });
  const [currentPage, setCurrentPage] = useState(1);

  const pageSize = 10;

  const paginatedData = () => {
    let filtered = productList;

    if (search)
      filtered = productList.filter((item) =>
        item.name.toLowerCase().startsWith(search.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const paginated = utilities.paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, paginated };
  };

  const columns = [
    {
      path: "id",
      label: "ID",
    },
    {
      path: "name",
      label: "Product Name",
    },
    {
      path: "color",
      label: "Product Color",
      content: (p) => (
        <div className="product-color">
          <div
            className="product-color-display"
            style={{ backgroundColor: p.color }}
          ></div>
          <div className="product-color-value">{p.color}</div>
        </div>
      ),
    },
  ];

  const { totalCount, paginated } = paginatedData();

  return (
    <React.Fragment>
      <div className="activity-feed fade-in">
        <LoadingButton
          visible={loading}
          message="Fetching products... Please wait"
        />
        {!loading && (
          <React.Fragment>
            <Table
              columns={columns}
              data={paginated}
              sortColumn={sortColumn}
              onSort={setSortColumn}
            />

            <div className="sb-pagination-container">
              <Pagination
                itemsCount={totalCount}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          </React.Fragment>
        )}

        {paginated.length === 0 && (
          <div className="no-items">
            <p>No products to show</p>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ProductListTable;
