import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCurrentUser } from "../../../services/authService";
import navigationRoutes from "../../../routes/navigationRoutes";

export default function AuthenticationLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const checkAuthentication = async () => {
      const isAuthenticated = !!getCurrentUser();

      if (isAuthenticated) {
        // User is already logged in, redirect to the dashboard
        navigate(navigationRoutes.home);
      } else {
        // User is not authenticated
        setLoading(false);
      }
    };

    checkAuthentication();
  }, [navigate]);
  return <div>{loading ? "" : children}</div>;
}
