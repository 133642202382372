import React, { useEffect } from "react";

const Modal = ({ children, handleClose }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        handleClose();
      }
    };

    const handleClickOutside = (event) => {
      if (
        event.target.classList.contains("ohw-modal") ||
        event.target.classList.contains("close")
      ) {
        handleClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClose]);

  const handleModalColese = () =>{
    console.log("close modal")
    return handleClose ()
  }

  return (
    <div className="ohw-modal">
      <div className="ohw-modal-inner fade-in shadow">
        <button className="close" onClick={handleModalColese}>
          &#10005;
        </button>
        <div className="ohw-modal-inner-body">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
