import React from "react";
import Joi from "joi-browser";

import Form from "../common/form";
import LoadingButton from "../common/loadingButton";
import Modal from "../common/modal";
import ProgressLoader from "../common/progressLoader";

import withCities from "../hoc/withCities";
import WithSize from "../hoc/withFacilitySize";
import WithType from "../hoc/withFacilityType";
import WithHospitals from "../hoc/withHospitals";



import hs from "../../services/hospitalService";
import toastService from "../../services/toastService";
import CustomDatePicker from "../common/datePicker";

class AddAppointmentForm extends Form {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        name: "",
        phone: "",
        email: "",
        contact_name: "",
        address: "",
        state: "",
        additional_info: "",
        hospital_size: "",
        facility: "",
      },
      errors: {},
      progress: 0,
    };

    this.schema = {
      name: Joi.string().min(4).required().label("Facility Name"),
      phone: Joi.string()
        .trim()
        .regex(this.getPhonePattern(), "valid phone number")
        .required()
        .label("Phone Number"),
      email: Joi.string().email().required().label("Email Address"),
      contact_name: Joi.string()
        .trim()
        .regex(/^[a-zA-Z_ ]+$/, "contact name")
        .allow(null, "")
        .label("Contact Name"),
      address: Joi.string().min(4).required().label("Street Address"),
      facility: Joi.string().required().label("Facility Type"),
      hospital_size: Joi.string().required().label("Facility Size"),
      state: Joi.string()
        .trim()
        .regex(/^[a-zA-Z_ ]+$/, "state")
        .required()
        .label("State"),
      additional_info: Joi.string().allow(null, "").label("Additional Note"),
    };
  }

  

  getApiData = () => {
    const { data } = this.state;
    const { user } = this.context;

    const apiData = { ...data, user_id: user.id, channel: "onehp" };
    return apiData;
  };

  getHospitalMatch = (key, value) => {
    try {
      const { hospitals } = this.props;

      return hospitals.find((h) =>
        h[key].toLowerCase().startsWith(value.toLowerCase())
      );
    } catch (error) {
      return null;
    }
  };

  handleProgressChange = (progress) => {
    this.setState({ progress });
  };

  handleHospitalChange = (e) => {
    const input = e.currentTarget;

    const data = { ...this.state.data };
    const errors = { ...this.state.errors };

    const errorMessage = this.validateProperty(input);
    const hospitalMatch = this.getHospitalMatch(input.name, input.value);

    data[input.name] = input.value;
    errors[input.name] = hospitalMatch
      ? `Match Found: ${hospitalMatch.name}!`
      : errorMessage;

    this.setState({ data, errors });
  };

  postData = async () => {
    const apiData = this.getApiData();

    const res = await hs.addHospital(apiData, this.handleProgressChange);
    if (res.data.status !== "success") throw new Error(res.data.message);

    this.props.handleClose();
  };

  doSubmit = () => {
    toastService.promise(this.postData(), {
      loading: "Uploading data...",
      success: () => "Hospital Added Successfully!",
      error: (e) =>
        e?.response?.data?.message || e?.message || "Could not add facility!",
    });
  };

  render() {
    const { progress, ...rest } = this.state;
    const { loading } = this.props;

    return (
      <Modal {...this.props}>
        <ProgressLoader progress={progress} />
        <form onSubmit={this.handleSubmit}>
          <h2 className="form-header">
            Fields marked <i className="fa fa-asterisk error"></i> are required
          </h2>
          <div className="row">
            <div className="col-lg">
              {this.renderInput({
                type: "text",
                name: "name",
                placeholder: "e.g. Doren Hospital",
                label: "Facility Name",
                onChange: this.handleHospitalChange,
                compulsory: true,
              })}
            </div>
            <div className="col-lg">
              {this.renderInput({
                type: "text",
                name: "phone",
                placeholder: "e.g. 08142317489",
                label: "Phone Number",
                onChange: this.handleHospitalChange,
                compulsory: true,
                autoComplete: "off",
              })}
            </div>
          </div>

          {this.renderInput({
            type: "email",
            name: "email",
            placeholder: "e.g. johndoe@gmail.com",
            onChange: this.handleHospitalChange,
            label: "Email Address",
            compulsory: true,
          })}

          {/* <div className="row">
            <div className="col-lg">
              {this.renderSelect({
                name: "hospital_size",
                label: "Facility Size",
                placeholder: "Select Facility Size",
                options: facilitySize,
                compulsory: true,
              })}
            </div>
            <div className="col-lg">
              {this.renderSelect({
                name: "facility",
                label: "Facility Type",
                placeholder: "Select Facility Type",
                options: facilityType,
                compulsory: true,
              })}
            </div>
          </div> */}

          {this.renderInput({
            type: "text",
            name: "contact_name",
            placeholder: "e.g. John Doe",
            label: "Contact Name",
          })}

          <div className="row">
            <div className="col-lg">
              {this.renderInput({
                type: "text",
                name: "address",
                placeholder: "10 John Doe Street",
                label: "Street Address",
                compulsory: true,
              })}
            </div>
            <div className="col-lg">
              {this.renderSelect({
                name: "state",
                placeholder: "Select State",
                label: "State",
                options: this.props.cities,
                compulsory: true,
              })}
            </div>
          </div>

          <div className="row">
            <div className="col-lg">
              <CustomDatePicker
                label="Appointment Date"
                maxDate={rest.today}
                selectedDate={rest.incidentDate}
                onDateChange={this.handleDateChange}
              />
            </div>
          </div>

          <div className="d-flex justify-content-end ">
            <LoadingButton visible={loading} message="Please wait" />
            {!loading && this.renderButton("Add")}
          </div>
        </form>
      </Modal>
    );
  }
}

const customComponent = WithHospitals(WithSize(WithType(AddAppointmentForm)));

export default withCities(customComponent);
