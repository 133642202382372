import React from 'react'
import {motion, AnimatePresence} from "framer-motion"

type Props = {
  className?: string
  children: any
  closeModal?: () => void
  open: boolean
  style?: React.CSSProperties
}

export default function Modal({children, className, style, closeModal, open}: Props) {

  const handleClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const el = e.target as HTMLDivElement
    const isModal = el.classList.contains("custom-modal")
    isModal && closeModal?.()
  }

  return (
    <AnimatePresence mode="wait">
      {
        open &&
        <motion.div exit={{opacity: 0}} initial={{opacity: 0}} animate={{opacity: 1}} className={`fixed top-0 left-0 custom-modal bg-[#00000080] w-full h-full ${className}`} style={style} onClick={handleClose}>
          {children}
        </motion.div>
      }
    </AnimatePresence>
  )
}