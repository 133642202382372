import React, { useEffect, useState } from "react";
import { Hospital } from "../../ts-utils/types";
import {
  PaginatedData,
  SortColumn,
  TableColumn,
} from "../../ts-utils/interfaces";
import { Link, useNavigate } from "react-router-dom";

import _ from "lodash";

import EditProspectForm from "../forms/editProspectForm";
import ExtraFeedback from "../forms/addExtraFeedback";
import LoadingButton from "../common/loadingButton";
import ProspectedInfo from "../modals/prospectedInfo";
import Table from "../common/table";

import utilities from "../../utils/utilities";
import NoItems from "../blocks/noItems";
import HospitalScoreCard from "../modals/hospitalScoreCard";
import { useGlobalContext } from "../../contexts/globalContext";

import {
  useGetFacilityTypes,
  useGetFacilitySizes,
} from "../../hooks/useHospital";
import applyFilters from "../../helpers/urlState";
import CustomPagination from "../common/pagination/Pagination";
const MaintenanceTable = (props: {
  category: string;
  hospitals: Hospital[];
  loading: boolean;
  search?: string;
  searchKey?: string;
  canEditPitch?: (pitch: Hospital) => boolean;
  onCategoryChange: (count: number) => void;
}) => {
  const navigate = useNavigate();
  const [sortColumn, setSortColumn] = useState<SortColumn>(initialSortColumn);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hospitalToView, setHospitalToView] = useState<Hospital | null>(null);
  const [hospitalToEdit, setHospitalToEdit] = useState<Hospital | null>(null);
  const [hospitalInfoToEdit, setHospitalInfoToEdit] = useState<Hospital | null>(
    null
  );

  const { facilityTypes } = useGetFacilityTypes();
  const { facilitySizes } = useGetFacilitySizes();

  const pageSize: number = 7;

  const { user } = useGlobalContext();
  const {
    category,
    hospitals,
    loading,
    search,
    searchKey,
    // canEditPitch,
    onCategoryChange,
  } = props;

  const handleHospitalToView = (hospital: Hospital): void => {
    setHospitalToView(hospital);
  };

  const handleEditSelect = (hospital: Hospital): void => {
    setHospitalInfoToEdit(hospital);
  };

  const getAgentMatch = (hospital: Hospital): boolean => {
    return hospital.rep === user?.id;
  };

  const getSearchMatch = (hospital: Hospital): boolean => {
    let searchBy: string = searchKey ? searchKey : "name";
    let searchValue: string | number = hospital[searchBy] ?? "";

    return searchValue
      .toString()
      .toLowerCase()
      .startsWith((search as string).toLowerCase());
  };

  const getPaginatedData = (): PaginatedData => {
    let filtered: Hospital[] = hospitals;
    let isAgentOrder: boolean = category === "1";

    if (search) filtered = hospitals.filter(getSearchMatch);
    if (isAgentOrder) filtered = filtered.filter(getAgentMatch);

    let sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    let paginated = utilities.paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, paginated };
  };

  const getColumns = (): TableColumn[] => {
    // const viewHospitalInformation: TableColumn = {
    //   path: "_",
    //   label: "",
    //   content: (h: Hospital): JSX.Element => (
    //     <button
    //       className="status primary"
    //       onClick={() => {
    //         history("/active-schedules");
    //         // handleHospitalToView(h)
    //       }}
    //     >
    //       Complete
    //     </button>
    //   ),
    // };

    return [
      ...columns,
      //   editHospitalInfo,
      // viewHospitalInformation,
    ];
  };

  const { totalCount, paginated }: PaginatedData = getPaginatedData();

  useEffect(() => {
    onCategoryChange(totalCount);

    // eslint-disable-next-line
  }, [totalCount]);
  useEffect(() => {
    const pageFromQuery =
      Number(new URLSearchParams(window.location.search).get("page")) || 1;
    setCurrentPage(pageFromQuery);
  }, [currentPage]);

  const goToPage = (page: number) => {
    setCurrentPage(Math.min(Math.max(page, 1), totalCount));
    applyFilters(
      {
        page: Math.min(Math.max(page, 1), totalCount),
      },
      navigate,
      "maintenance"
    );
  };
  return (
    <React.Fragment>
      {hospitalToView && user?.position !== "customer_success" && (
        <ProspectedInfo
          selectedProspect={hospitalToView}
          facilityType={facilityTypes}
          facilitySize={facilitySizes}
          handleClose={() => handleHospitalToView(null)}
        />
      )}

      {hospitalToView && user?.position === "customer_success" && (
        <HospitalScoreCard
          selectedProspect={hospitalToView}
          handleClose={() => handleHospitalToView(null)}
        />
      )}

      {hospitalInfoToEdit && (
        <EditProspectForm
          selectedProspect={hospitalInfoToEdit}
          handleClose={() => handleEditSelect(null)}
        />
      )}

      {hospitalToEdit && (
        <ExtraFeedback
          hospital={hospitalToEdit}
          handleClose={() => setHospitalToEdit(null)}
        />
      )}

      <div className="activity-feed fade-in">
        {loading ? (
          <LoadingButton
            visible={loading}
            message="Fetching Active Hospitals... Please wait"
          />
        ) : (
          <React.Fragment>
            <Table
              columns={getColumns()}
              data={paginated}
              sortColumn={sortColumn}
              onSort={setSortColumn}
            />

            <div className="sb-pagination-container mb-5">
              {/* <Pagination
              itemsCount={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            /> */}
              <CustomPagination
                goToPage={goToPage}
                currentPage={currentPage}
                totalPages={
                  Math.ceil(totalCount / pageSize) > 0
                    ? Math.ceil(totalCount / pageSize)
                    : 1
                }
              />
            </div>
          </React.Fragment>
        )}

        <NoItems
          isVisible={paginated.length === 0}
          label="No maintenance schedule to show"
        />
      </div>
    </React.Fragment>
  );
};

const columns: TableColumn[] = [
  {
    path: "hospitalid",
    label: "ID",
  },
  {
    path: "name",
    label: "Hospital Name",
    content: (h: Hospital) => (
      <Link to={`/maintenance/${h.hospitalid}`}>{h.name}</Link>
    ),
  },
  {
    path: "email",
    label: "Email",
  },
  {
    path: "phone",
    label: "Phone Number",
  },
  {
    path: "address",
    label: "Address",
  },

  {
    path: "orders",
    label: "No of Orders",
  },
  {
    path: "frequency",
    label: "Frequency",
  },
  {
    path: "amount",
    label: "Amount to spend",
  },
];

const initialSortColumn: SortColumn = { path: "id", order: "desc" };
export default MaintenanceTable;
