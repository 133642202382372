let token = "countryUrl";

export function saveCountryUrl(url: string | undefined) {
  if (url) {
    localStorage.setItem(token, url);
  }
}
export function saveSelectedCountry(country: any) {
  // console.log(country);
  const selectedCountry = JSON.stringify(country);
  return localStorage.setItem("selectedCountry", selectedCountry);
}

export function getSelectedCountry() {
  const selectedCountry = localStorage.getItem("selectedCountry");
  return selectedCountry ? JSON.parse(selectedCountry) : null;
}

export function getCountryUrl() {
  return localStorage.getItem(token);
}

export function deleteCountryUrl() {
  localStorage.removeItem(token);
  localStorage.removeItem("selectedCountry");
}

const countryService = {
  saveCountryUrl,
  saveSelectedCountry,
  getSelectedCountry,
  getCountryUrl,
  deleteCountryUrl,
};
export default countryService;
