import React, { useEffect, useState } from "react";

import _ from "lodash";

import LoadingButton from "../common/loadingButton";
import Pagination from "../common/pagination";
import Table from "../common/table";
import ProspectedInfo from "../modals/prospectedInfo";

import utilities from "../../utils/utilities";

import { getProspectedHospitals } from "../../services/prospectsService";
import toastService from "../../services/toastService";
import Conditional from "../blocks/conditional";
import { useGlobalContext } from "../../contexts/globalContext";

const ProspectsTable = ({ onCountChange, search, status, searchKey }) => {
  const [sortColumn, setSortColumn] = useState({ path: "id", order: "desc" });
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProspect, setSelectedProspect] = useState(null);
  const [prospects, setProspects] = useState([]);
  const [loading, setLoading] = useState(false);

  const { city, user } = useGlobalContext();

  const pageSize = 10;

  const columns = [
    {
      path: "id",
      label: "ID",
    },
    {
      path: "name",
      label: "Hospital Name",
    },
    {
      path: "email",
      label: "Email Address",
    },
    {
      path: "phone",
      label: "Phone Number",
    },
    {
      path: "address",
      label: "Address",
    },
    {
      path: "state",
      label: "State",
    },
    {
      path: "",
      label: "View",
      content: (c) => (
        <button className="status view" onClick={() => handleSelect(c)}>
          View
        </button>
      ),
    },
  ];

  const getProspects = async () => {
    try {
      setLoading(true);

      const response = await getProspectedHospitals(city, user.id);
      const data = utilities.mapKeyToNumber(response.data.hospitals, "id");
      console.log(user.id);

      setProspects(data);
      setLoading(false);
    } catch (error) {
      const errorMessage = error?.response?.data?.description ?? error.message;
      toastService.error(errorMessage);

      setLoading(false);
    }
  };

  const getFilteredSearchHospitals = () => {
    const searchBy = searchKey ? searchKey : "name";

    return prospects.filter((p) => {
      const item = p[searchBy];
      if (!item) return false;

      return item.toLowerCase().startsWith(search.toLowerCase());
    });
  };

  const getFilteredStatusHospitals = () => {
    return prospects.filter(
      (p) => p.status?.toLowerCase?.() === status.toLowerCase()
    );
  };

  const paginatedData = () => {
    let filtered = prospects;
    if (search) filtered = getFilteredSearchHospitals();
    if (status) filtered = getFilteredStatusHospitals();

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const paginated = utilities.paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, paginated };
  };

  const handleSelect = (prospect) => setSelectedProspect(prospect);

  const { totalCount, paginated } = paginatedData();

  useEffect(() => {
    getProspects();

    // eslint-disable-next-line
  }, [city]);

  useEffect(() => {
    onCountChange(totalCount);

    // eslint-disable-next-line
  }, [totalCount]);

  return (
    <React.Fragment>
      <Conditional isVisible={selectedProspect}>
        <ProspectedInfo
          selectedProspect={selectedProspect}
          handleClose={() => handleSelect(null)}
        />
      </Conditional>

      <div className="activity-feed fade-in">
        <LoadingButton
          visible={loading}
          message="Fetching Prospects, Please wait"
        />

        <Conditional isVisible={!loading}>
          <Table
            columns={columns}
            data={paginated}
            sortColumn={sortColumn}
            onSort={setSortColumn}
          />

          <div className="sb-pagination-container">
            <Pagination
              itemsCount={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </Conditional>

        {paginated.length === 0 && (
          <div className="no-items">
            <p>No prospects to show</p>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ProspectsTable;
