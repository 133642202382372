import _ from "lodash";
import React, { useEffect, useState } from "react";

import {
  PaginatedData,
  SortColumn,
  TableColumn,
} from "../../ts-utils/interfaces";

import NoItems from "../blocks/noItems";
import LoadingButton from "../common/loadingButton";
// import Pagination from "../common/pagination";
import Table from "../common/table";

import utilities from "../../utils/utilities";
import { useGlobalContext } from "../../contexts/globalContext";
import applyFilters from "../../helpers/urlState";
import CustomPagination from "../common/pagination/Pagination";
import { useNavigate } from "react-router-dom";
import { useGetFeed } from "../../hooks/useDashboard";

const ActivityFeed = ({ path }: { path?: string }) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState(initialSortColumn);

  const { user } = useGlobalContext();
  const { feed, isPending } = useGetFeed(user?.id as string);

  const pageSize: number = 3;

  const getPaginatedData = (): PaginatedData => {
    const filtered = feed;

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const paginated = utilities.paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, paginated };
  };

  const { totalCount, paginated }: PaginatedData = getPaginatedData();

  useEffect(() => {
    const pageFromQuery =
      Number(new URLSearchParams(window.location.search).get("page")) || 1;
    setCurrentPage(pageFromQuery);
  }, [currentPage]);

  
  const goToPage = (page: number) => {
    setCurrentPage(Math.min(Math.max(page, 1), totalCount));
    applyFilters(
      {
        page: Math.min(Math.max(page, 1), totalCount),
      },
      navigate,
      path ? path : "active-feed"
    );
  };

  return (
    <div className={isPending ? "loading-background" : "activity-feed fade-in"}>
      <LoadingButton visible={isPending} message="Fetching Activities" />

      {!isPending && (
        <React.Fragment>
          <div className="sb-page-heading">
            <h1
              className="
            text-3xl
            font-semibold
            text-left
            py-7
            "
            >
              History
            </h1>
            <Table
              columns={columns}
              data={paginated}
              sortColumn={sortColumn}
              onSort={setSortColumn}
            />
          </div>
          <div className="sb-pagination-container mb-5">
            {/* <Pagination
              itemsCount={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            /> */}
            <CustomPagination
              goToPage={goToPage}
              currentPage={currentPage}
              totalPages={
                Math.ceil(totalCount / pageSize) > 0
                  ? Math.ceil(totalCount / pageSize)
                  : 1
              }
            />
          </div>

          <NoItems
            isVisible={paginated.length === 0}
            label="No hospitals to show"
          />
        </React.Fragment>
      )}
    </div>
  );
};

const columns: TableColumn[] = [
  {
    path: "id",
    label: "",
    content: (a) => <i className="fa fa-star"></i>,
  },
  {
    path: "name",
    label: "Activity Feed",
    content: (a) =>
      `${a.name} has been ${a.activity} ${
        a.activity.toLowerCase() === "assigned" ? "to" : "by"
      } ${a.username}`,
  },
  {
    path: "status",
    label: "Status",
    content: (a) => (
      <button disabled className={"status " + a?.status?.toLowerCase?.()}>
        {a.status}
      </button>
    ),
  },
  {
    path: "date",
    label: "Date",
  },
  // {
  //   path: "hospital_id",
  //   label: "More",
  //   content: (a) => "...",
  // },
];

const initialSortColumn: SortColumn = { path: "id", order: "desc" };

export default ActivityFeed;
