import React, { useCallback, useEffect, useMemo, useState } from "react";

import AgentsLeadTable from "../../../../components/tables/agentsLeadTable";
import HibernatedHospitalsTable from "../../../../components/tables/hibernatedLeadTable";

// import Button from "../common/button";
import Header from "../../../../components/blocks/header";
import UnsuccessFulPitchTable from "../../../../components/tables/unsuccessfulLeadTable";

import { getPrivileges } from "../../../../services/authService";
import Table from "../../../../components/common/table";
import CustomPagination from "../../../../components/common/pagination/Pagination";
import DisableAgentButton from "../../../../components/blocks/disableAgentButton";
import {
  SelectOption,
  SortColumn,
  TableColumn,
} from "../../../../ts-utils/interfaces";
import { useGetAllAgents } from "../../../../hooks/agent/useAgent";
import { useLocation, useNavigate } from "react-router-dom";
import applyFilters from "../../../../helpers/urlState";
import { SyncLoader } from "react-spinners";
import SearchQuery from "../../../../components/forms/searchQuery";
// import Select from "../../../../components/common/select";
import { Button } from "../../../../@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../@/components/ui/select";
import { CirclePlus } from "lucide-react";
import * as Dialog from "@radix-ui/react-dialog";
import AddAgent from "./AddAgent";

import { useAddAgent } from "../../../../hooks/agent/useAgent";
import toastService from "../../../../services/toastService";

const positionsOptions: SelectOption[] = [
  { id: "sales_lead", name: "Key Account Agent" },
  { id: "sales_rep", name: "Customer Acquisition Agent" },
];
const Leads = () => {
  const { mutateAddAgent, isPending: isAdding } = useAddAgent();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const initialPageIndex = parseInt(searchParams.get("page") || "1", 10) - 1;
  const [currentPage, setCurrentPage] = useState(initialPageIndex + 1);
  const limit = 10;

  const [search, setSearch] = useState("");
  const [position, setPosition] = useState("sales_rep");
  const { agents, isPending, paginate } = useGetAllAgents(
    currentPage,
    limit,
    search,
    position
  );

  const initialSortColumn: SortColumn = { path: "id", order: "desc" };
  const [sortColumn, setSortColumn] = useState<SortColumn>(initialSortColumn);
  const [active, setActive] = useState("1");

  const privileges = getPrivileges();

  const checkActive = () => ({
    isFirstActive: active === "1",
    isSecondActive: active === "2",
    isThirdActive: active === "3",
  });

  const checkInitialPrivilege = () => {
    const initialPrivilege = privileges.viewAgents
      ? "1"
      : privileges.viewHibernated
      ? "2"
      : "3";

    setActive(initialPrivilege);
  };

  const { isFirstActive, isSecondActive, isThirdActive } = checkActive();

  useEffect(() => {
    checkInitialPrivilege();

    // eslint-disable-next-line
  }, []);
  const columns: TableColumn[] = [
    { path: "id", label: "ID" },
    {
      path: "username",
      label: "Agent Name",
    },
    {
      path: "position",
      label: "Position",
      // if position is not available, return "N/A"
      content: (a: any) =>
        a.position === "sales_rep" ? "Customer Acquisition" : "Key Account",
    },
    {
      path: "city",
      label: "City",
    },
    {
      path: "country",
      label: "Country",
    },
    {
      path: "total_assigned",
      label: "Total Assigned Hospitals",
    },
    {
      path: "total_active",
      label: "Total Active Hospitals",
    },
    {
      path: "conversion",
      label: "Conversion Rate (%)",
      content: (a: any) => `${a.conversion ?? 0}%`,
    },
    {
      path: "_",
      label: "",
      content: (a: any) => <DisableAgentButton agent={a} />,
    },
    // {
    //   path: "password",
    //   label: "",
    //   content: (a: any) => <ViewScoreCardButton agent={a} />,
    // },
  ];

  useEffect(() => {
    const pageFromUrl = parseInt(searchParams.get("page") || "1", 10);
    if (pageFromUrl !== currentPage) {
      setCurrentPage(pageFromUrl);
    }
  }, [location.search, searchParams, currentPage]);
  const pathname = location.pathname.replace("/", "");
  const goToPage = useCallback(
    (page: number) => {
      applyFilters(
        {
          page,
          search,
          position,
        },
        navigate,
        pathname
      );
      setCurrentPage(page);
    },
    [navigate, pathname, search, position]
  );

  const handlePositionChange = (value: string) => {
    setPosition(value);
  };

  const [open, setOpen] = React.useState(false);
  const handleAddAgent = (item: any) => {
    setOpen(true);
  };

  const onSubmit = (data: {
    username: string;
    email: string;
    password: string;
    confirmPassword: string;
    position: string;
    city: string;
    country: string;
  }): void => {
    mutateAddAgent(data, {
      onSuccess: (data) => {
        toastService.success(data.data.message || "Agent created successfully");
        setOpen(false);
      },
    });
  };
  return (
    <React.Fragment>
      {" "}
      {isPending && (
        <div className="fixed top-0 bottom-0 left-0 right-0 flex mx-auto items-center justify-center h-dvh z-30">
          <div className="bg-gray-700 absolute inset-0 opacity-50"></div>
          <SyncLoader
            color="#3B82F6"
            loading={isPending}
            className="relative"
            size={30}
          />
        </div>
      )}
      <Header title="Manage Agents" />
      {/* <div className="leads-arena-buttons"> */}
      {/* {privileges.viewAgents && (
          <Button
            label="Agents"
            className={isFirstActive ? "app-btn active" : "app-btn inactive"}
            onClick={() => setActive("1")}
          />
        )} */}
      {/* turned off temporarily */}
      {/* {privileges.viewHibernated && (
          <Button
            label="Hibernated Hospitals"
            className={isSecondActive ? "app-btn active" : "app-btn inactive"}
            onClick={() => setActive("2")}
          />
        )}
        {privileges.viewUnsuccessfulPitches && (
          <Button
            label="Re-pitch"
            className={isThirdActive ? "app-btn active" : "app-btn inactive"}
            onClick={() => setActive("3")}
          />
        )} */}
      {/* </div> */}
      <div className="lead-container mt-5 table-responsive">
        {privileges.viewAgents && isFirstActive && (
          <>
            <div className="agents-table-container-top ">
              <SearchQuery
                name="search"
                placeholder="Search"
                value={search}
                onChange={(e: any) => setSearch(e.currentTarget.value)}
              />

              <div className="right-options space-x-3">
                <Select
                  onValueChange={handlePositionChange}
                  defaultValue={position}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select Agent Role" />
                  </SelectTrigger>
                  <SelectContent position="item-aligned">
                    {positionsOptions?.map(
                      (position: { id: string; name: string | number }) => (
                        <SelectItem key={position.id} value={position.id}>
                          {position.name}
                        </SelectItem>
                      )
                    )}
                  </SelectContent>
                </Select>

                <Button
                  onClick={handleAddAgent}
                  variant="default"
                  className="px-5 !rounded-lg app-btn"
                >
                  Create Agent
                </Button>
              </div>
            </div>
            <div className="max-w-screen-2xl mx-auto px-4 md:px-8 mb-5">
              <>
                <Table
                  columns={columns}
                  data={agents}
                  sortColumn={sortColumn}
                  onSort={setSortColumn}
                />
                <CustomPagination
                  totalPages={paginate?.totalPages}
                  currentPage={currentPage}
                  goToPage={goToPage}
                />
              </>
            </div>
            {/* <AgentsLeadTable /> */}
          </>
        )}
        {privileges.viewHibernated && isSecondActive && (
          <HibernatedHospitalsTable search="" status="" />
        )}
        {privileges.viewUnsuccessfulPitches && isThirdActive && (
          <UnsuccessFulPitchTable />
        )}
      </div>
      <Dialog.Root open={open} onOpenChange={setOpen}>
        <Dialog.Portal>
          <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 w-full h-full bg-black opacity-40" />
          <Dialog.Content
            aria-describedby={undefined}
            className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-2xl mx-auto px-4"
          >
            <div className="bg-white rounded-md shadow-lg px-4 py-6 ">
              <div className="flex items-center justify-between mb-3">
                <Dialog.Title className="text-xl font-bold text-gray-800 text-center ">
                  Add Agent
                </Dialog.Title>
                <Dialog.Close className="p-2 text-gray-400 rounded-md hover:bg-gray-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 mx-auto"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Dialog.Close>
              </div>

              <div className="max-h-96 lg:max-h-full overflow-y-auto lg:overflow-y-hidden">
                <AddAgent onSubmit={onSubmit} isPending={isAdding} />
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </React.Fragment>
  );
};

export default Leads;
