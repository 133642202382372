export default function CustomField({ title, value, style, minWidth }: any) {
  return (
    <div
      className={`border-b-[2px] h-[80px] flex items-center justify-between w-full`}
      style={style}
    >
      {title && <span className="flex-1 w-full ">{title || ""}</span>}
      <span className={`${minWidth ?? "min-w-[50%]"} flex justify-end`}>
        {value || ""}
      </span>
    </div>
  );
}
