import React from "react";

const Checkbox = ({ checked, name, message, onChange, ...rest }) => {
  return (
    <label className="ohw-checkbox-container">
      <span className="checkbox-message">{message}</span>
      <input
        type="checkbox"
        name={name}
        onChange={onChange}
        checked={checked}
        {...rest}
      />
      <span className="checkbox-checkmark"></span>
    </label>
  );
};

export default Checkbox;
