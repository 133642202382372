/* eslint-disable eqeqeq */
import React, { useState } from "react";
import BackButton from "../blocks/backbutton";
import Button from "../common/button";
import CustomField from "../common/CustomField";
import LoadingButton from "../common/loadingButton";
import { useGetHospitalHistory } from "../../hooks/useHospital";
import utilities from "../../utils/utilities";
import CallHistory from "./CallHistory";

interface HospitalHistoryProps {
  handleClose: () => void;
  hospitalRef: any;
  hospitalStatus: any;
}
const HospitalHistory: React.FC<HospitalHistoryProps> = ({
  handleClose,
  hospitalRef,
  hospitalStatus
}) => {
  const { hospitalHistory, isPending } = useGetHospitalHistory(hospitalRef);
  const [hospitalId, setHospitalId] = useState("");
  console.log(hospitalHistory);

  return (
    <div className="bg-white w-[80%] h-[80%] overflow-hidden p-[4px] rounded-xl">
      <div className="overflow-y-auto w-full h-full custom-scrollbar p-10">
        <>
          {isPending ? (
            <LoadingButton
              visible={isPending}
              message="Fetching Data... Please wait"
            />
          ) : (
            <React.Fragment>
              {hospitalId ? (
                <CallHistory
                  setCallHistory={setHospitalId}
                  handleClose={handleClose}
                  hospitalId={hospitalId}
                  hospitalStatus={hospitalStatus}
                />
              ) : (
                <>
                  <BackButton goBack={handleClose} />
                  <CustomField
                    title={<h2 className="font-semibold text-2xl">HISTORY</h2>}
                  />
                  {hospitalHistory.map((history: any) => (
                    <div className="my-8" key={history.id}>
                      <CustomField
                        title="Date"
                        value={
                          utilities.formatDate(history?.created_at) ||
                          "N/A"
                        }
                      />

                      <CustomField
                        title="Current Status"
                        value={history?.current_status || "N/A"}
                      />
                      <CustomField
                        title="Previous Status"
                        value={history?.previous_status || "N/A"}
                      />
                      <CustomField
                        title="Health Score"
                        value={history?.health_score || "N/A"}
                      />
                    </div>
                  ))}
                  <div className="flex items-center gap-2">
                    <Button label={"Close"} onClick={() => handleClose()} />
                    <Button
                      label={"Call History"}
                      onClick={() => setHospitalId(hospitalRef)}
                    />
                  </div>
                </>
              )}
            </React.Fragment>
          )}
        </>
      </div>
    </div>
  );
};

export default HospitalHistory;
