import { ApiResponse } from "../../ts-utils/types";
import { SelectOption } from "../../ts-utils/interfaces";
// @ts-ignore
import Joi from "joi-browser";
import PropTypes from "prop-types";

import Form from "../common/form";
import Modal from "../common/modal";

import { addFeedback } from "../../services/onboardService";
import { promise as toastPromise } from "../../services/toastService";

class ExtraFeedback extends Form {
  state = {
    data: { pitch_method: "", feedback: "" },
    errors: {},
    history: {},
    progress: 0,
  };

  static propTypes = {
    hospital: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
  };

  schema = {
    pitch_method: Joi.string().required().label("Pitch Method"),
    feedback: Joi.string().trim().min(5).required().label("Extra Feedback"),
  };

  getApiData = (): object => {
    return { id: 12, ...this.state.data };
  };

  handleProgressChange = (progress: number): void => {
    this.setState({ progress });
  };

  handleApiFailure = (ex: any): string => {
    let errorMessage: string = ex?.response?.data?.description ?? ex.message;
    return errorMessage ?? "Could not add feedback";
  };

  postData = async (): Promise<void> => {
    let data: object = this.getApiData();
    let res: ApiResponse = await addFeedback(data, this.handleProgressChange);
    let result: any = res.data;
    if (result.status !== "success") throw new Error(result.message);

    this.props.handleClose();
  };

  doSubmit = (): void => {
    toastPromise(this.postData(), {
      loading: "Uploading data...",
      success: (): string => "Feedback Added Successfully!",
      error: (e: any): string => this.handleApiFailure(e),
    });
  };

  render(): JSX.Element {
    let { handleClose, hospital } = this.props;

    return (
      <Modal handleClose={handleClose}>
        <h3 className="modal-title">Add Comments for {hospital.name}</h3>

        <form onSubmit={this.handleSubmit}>
          {this.renderSelect({
            name: "pitch_method",
            label: "Pitch Method",
            placeholder: "Select Pitch Type",
            options: pitchTypes,
          })}

          {this.renderTextArea({
            name: "feedback",
            label: "Pitch Feedback",
            placeholder: "Enter pitch feedback here",
          })}

          {this.renderButton("Submit")}
        </form>
      </Modal>
    );
  }
}

const pitchTypes: SelectOption[] = [
  { id: "direct", name: "Direct" },
  { id: "telesales", name: "Telesale" },
];

export default ExtraFeedback;
