import React, { useState } from "react";
import { ComponentProps, Hospital } from "../../ts-utils/types";
import {
  PaginatedData,
  SortColumn,
  TableColumn,
} from "../../ts-utils/interfaces";

import _ from "lodash";

import PropTypes from "prop-types";
import LoadingButton from "../common/loadingButton";
import Pagination from "../common/pagination";
import Table from "../common/table";

import utilities from "../../utils/utilities";
import NoItems from "../blocks/noItems";
import AppointmentInfo from "../modals/appointmentInfo";
import withRequests from "../hoc/withRequest";
import { Link, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../contexts/globalContext";

const RequestTable = (props: ComponentProps) => {
  const [sortColumn, setSortColumn] = useState<SortColumn>(initialSortColumn);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hospitalToView, setHospitalToView] = useState<Hospital | null>(null);

  const history = useNavigate();

  const pageSize: number = 7;

  const { user } = useGlobalContext();
  const {
    category,
    requests,
    loading,
    search,
    searchKey,
    status,
  } = props;

  const handleHospitalToView = (hospital: Hospital): void => {
    setHospitalToView(hospital);
  };

  const getAgentMatch = (hospital: Hospital): boolean => {
    return hospital.rep === user?.id;
  };

  const getSearchMatch = (hospital: Hospital): boolean => {
    let searchBy: string = searchKey ? searchKey : "name";
    let searchValue: string | number = hospital[searchBy] ?? "";

    return searchValue
      .toString()
      .toLowerCase()
      .startsWith(search.toLowerCase());
  };

  const getStatusMatch = (hospital: Hospital): boolean => {
    return hospital.status?.toLowerCase?.() === status.toLowerCase();
  };

  const getPaginatedData = (): PaginatedData => {
    let filtered: Hospital[] = requests;
    let isAgentOrder: boolean = category === "1";

    if (search) filtered = requests.filter(getSearchMatch);
    if (status) filtered = filtered.filter(getStatusMatch);
    if (isAgentOrder) filtered = filtered.filter(getAgentMatch);

    let sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    let paginated = utilities.paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, paginated };
  };

  const getColumns = (): TableColumn[] => {
    const viewHospitalInformation: TableColumn = {
      path: "_",
      label: "",
      content: (h: Hospital): JSX.Element => (
        <button
          className="status primary"
          onClick={() => {
            window.sessionStorage.setItem("requestInfo", JSON.stringify(h));
            history("/request-info");
          }}
        >
          View
        </button>
      ),
    };

    return [...columns, viewHospitalInformation];
  };

  const { totalCount, paginated }: PaginatedData = getPaginatedData();

  // useEffect(() => {
  //   onCategoryChange(totalCount);

  //   // eslint-disable-next-line
  // }, [totalCount]);

  return (
    <React.Fragment>
      {hospitalToView && (
        <AppointmentInfo
          selectedProspect={hospitalToView}
          handleClose={() => handleHospitalToView(null)}
        />
      )}

      <div className="activity-feed fade-in">
        {loading ? (
          <LoadingButton
            visible={loading}
            message="Fetching Requests... Please wait"
          />
        ) : (
          <React.Fragment>
            <Table
              columns={getColumns()}
              data={paginated}
              sortColumn={sortColumn}
              onSort={setSortColumn}
            />

            {requests?.length > pageSize && (
              <div className="sb-pagination-container">
                <Pagination
                  itemsCount={totalCount}
                  pageSize={pageSize}
                  currentPage={currentPage}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            )}
          </React.Fragment>
        )}

        <NoItems
          isVisible={paginated?.length === 0}
          label="No requests to show"
        />
      </div>
    </React.Fragment>
  );
};

const columns: TableColumn[] = [
  {
    path: "hospital_id",
    label: "Hospital ID",
  },

  {
    path: "name",
    label: "Hospital Name",
    content: (h: Hospital) => (
      <Link to={`/hospitals/${h.hospital_id}`}>{h.name}</Link>
    ),
  },
  {
    path: "email",
    label: "Email Address",
  },
  {
    path: "phone",
    label: "Phone Number",
  },

  {
    path: "address",
    label: "Address",
  },
];

const initialSortColumn: SortColumn = { path: "id", order: "desc" };
export default withRequests(RequestTable);

RequestTable.propTypes = {
  canEditPitch: PropTypes.func.isRequired,
};
