import React from 'react'
import { GoDash } from 'react-icons/go'

const TitleDash = ({ text }:any) => {
    return (
        <div className='w-10/12 flex flex-row m-4 items-center'>
            <p className="text-8xl text-[#49209F]">
                <GoDash />
            </p>
            <p className="text-2xl">{text}</p>
        </div>
    )
}

export default TitleDash;