import React, { useState } from "react";
import { IoSearchOutline } from "react-icons/io5";

const Search = ({ placeholder, handleSearch }: any) => {
  const [search, setSearch] = useState("");

  const handleChange = (e: any) => {
    setSearch(e.target.value);
    handleSearch(e.target.value);
  };

  return (
    <div className="w-full flex mx-auto justify-center">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSearch(search);
        }}
        className="flex bg-[#FFFF] justify-center items-center max-w-md w-full rounded h-[68px] p-2 shadow-[#b6b6b6] shadow-lg"
      >
        <div className="relative w-full">
          <input
            onChange={handleChange}
            value={search}
            placeholder={placeholder}
            className=" text-black text-2xl w-full h-[40px] p-2 bg-white border-gray-500 focus:outline-none"
          />
          <button
            disabled={search === ""}
            type="submit"
            className="text-4xl text-white bg-[#49209F] flex p-2.5 lg:w-[150px] w-[120px] justify-between items-center rounded-lg hover:bg-[#6448a4] absolute inset-y-0 right-0"
          >
            <p className="lg:text-2xl text-1xl font-bold">
              <IoSearchOutline className="font-bold text-2xl" />
            </p>
            <p className="lg:text-1xl text-lg font-bold">Search</p>
          </button>
        </div>
      </form>
    </div>
  );
};

export default Search;

// import React, { useState } from 'react';

// const SearchBar = ({ handleSearch }: any) => {
//     const [search, setSearch] = useState('');

//     const handleChange = (e: any) => {
//         setSearch(e.target.value);
//         handleSearch(e.target.value);
//     };

//     return (
//         <input
//             value={search}
//             onChange={handleChange}
//             placeholder='Search for employee'
//         />
//     );
// };

// export default SearchBar;
