import React from "react";

const renderSelect = (params) => {
  const {
    name,
    label,
    options,
    data,
    errors,
    placeholder,
    compulsory,
    handleChange,
  } = params;

  return (
    <div className="form-field">
      <label htmlFor={name} className="form-field-label">
        {compulsory && <i className="fa fa-asterisk error"></i>}
        {label}
      </label>
      <select
        name={name}
        id={name}
        value={data[name]}
        onChange={handleChange}
        placeholder={placeholder}
        className="form-field-input"
      >
        <option>Select...</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.name}
          </option>
        ))}
      </select>
      {errors[name] && <div className="error-message">{errors[name]}</div>}
    </div>
  );
};

export { renderSelect };
