import { useState } from "react";

import Button from "../common/button";
import Modal from "../common/modal";
import ProgressLoader from "../common/progressLoader";



import prospectService from "../../services/prospectsService";
import toastService from "../../services/toastService";
import Select from "../common/select";
import { useGlobalContext } from "../../contexts/globalContext";

const ValidateProspect = ({
  selectedProspect,
  handleClose,
  handleExit,
  blood,
  oxygen,
}) => {
  const [progress, setProgress] = useState(0);
  const [page, setPage] = useState(1);
  const [bloodCadre, setBloodCadre] = useState(blood);
  const [oxygenCadre, setOxygenCadre] = useState(oxygen);
  const [requestChange, setRequestChange] = useState("");

  const { user } = useGlobalContext();

  const validate = async () => {
    const apiData = {
      user_id: user.id,
      hospital_id: selectedProspect,
      blood: bloodCadre,
      oxygen: oxygenCadre,
      reason: requestChange,
    };

    const res = await prospectService.validateHospital(apiData, handleProgress);
    if (!res.data.ok) throw new Error(res.data.description);

    handleClose();
    handleExit();
  };

  const handleProgress = (progress) => {
    setProgress(progress);
  };

  const handleValidation = () => {
    toastService.promise(validate(), {
      loading: "Validating...",
      success: () => "Validated Successfully",
      error: (e) => e.message,
    });
  };

  return (
    <Modal handleClose={handleClose}>
      <ProgressLoader progress={progress} />
      {page === 1 && (
        <>
          <div className="w-100 text-center">
            <i className="fa fa-star confirm-validate-icon"></i>
          </div>

          <p className="confirm-validate-text">This are your current cadres:</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p>
              <span style={{ fontWeight: "600" }}>Blood:</span>{" "}
              <span
                style={{
                  border: "2px solid #b80304",
                  borderRadius: "3px",
                  padding: "5px",
                }}
              >
                {blood}
              </span>
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>Oxygen:</span>{" "}
              <span
                style={{
                  border: "2px solid #0679f8",
                  borderRadius: "3px",
                  padding: "5px",
                }}
              >
                {oxygen}
              </span>
            </p>
          </div>
          <h3 className="modal-title confirm-validate-title">
            Are you fine with these cadres?
          </h3>

          <div className="modal-center-buttons">
            <Button label="Yes" onClick={() => setPage(2)} />

            <Button
              label="No"
              className="app-btn close-btn"
              // onClick={handleClose}
              onClick={() => setPage(3)}
            />
          </div>
        </>
      )}

      {page === 2 && (
        <>
          <div className="w-100 text-center">
            <i className="fa fa-exclamation-circle confirm-validate-icon"></i>
          </div>

          <h3 className="modal-title confirm-validate-title">Are You Sure?</h3>

          <p className="confirm-validate-text">
            You won't be able to reverse this
          </p>

          <div className="modal-center-buttons">
            <Button label="Yes" onClick={handleValidation} />

            <Button
              label="No"
              className="app-btn close-btn"
              onClick={() => setPage(3)}
            />
          </div>
        </>
      )}

      {page === 3 && (
        <div
          className="w-100 text-center"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            justifySelf: "center",
            marginTop: "2rem",
          }}
        >
          <div>
            <Select
              placeholder="Select Blood Cadre"
              options={cadreTypes}
              value={bloodCadre}
              onChange={(e) => setBloodCadre(e.currentTarget.value)}
            />
            <Select
              placeholder="Select Oxygen Cadre"
              options={cadreTypes}
              value={oxygenCadre}
              onChange={(e) => setOxygenCadre(e.currentTarget.value)}
            />
          </div>
          <textarea
            id="summary"
            name="requestCadre"
            onChange={(e) => setRequestChange(e.target.value)}
            placeholder="Reason for changing cadre"
            rows={5}
            cols={50}
            className="form-field-input-text"
          ></textarea>

          {/* <div
              className="status primary"
              onClick={() => setShowNewCadre(!showNewCadre)}
            >
              Submit
            </div> */}

          <Button label="Submit and Validate" onClick={() => setPage(2)} />
        </div>
      )}
    </Modal>
  );
};

export default ValidateProspect;

const cadreTypes = [
  { id: "Gold Plus", name: "Gold Plus" },
  { id: "Bronze", name: "Bronze" },
  { id: "Silver", name: "Silver" },
  { id: "Silver Plus", name: "Silver Plus" },
  { id: "Silver Lite", name: "Silver Lite" },
  { id: "Gold", name: "Gold" },
  { id: "Platinum", name: "Platinum" },
  { id: "Super Platimun", name: "Super Platimun" },
  { id: "Ruby", name: "Ruby" },
  { id: "Sapphire", name: "Sapphire" },
  { id: "Silver Flat", name: "Silver Flat" },
  { id: "Silver X", name: "Silver X" },
  { id: "Bronze plus", name: "Bronze plus" },
  { id: "Bronze lite", name: "Bronze lite" },
  { id: "Inter state Bronze", name: "Inter state Bronze" },
  { id: "Inter state Silver", name: "Inter state Silver" },
  { id: "Inter state Gold", name: "Inter state Gold" },
  { id: "Inter state Platinum", name: "Inter state Platinum" },
  { id: "Gold Lite", name: "Gold Lite" },
  { id: "Platinum Lite", name: "Platinum Lite" },
  { id: "Lab To Lab", name: "Lab To Lab" },
];
