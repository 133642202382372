import React, { useEffect, useState } from "react";

import Button from "../common/button";
import Modal from "../common/modal";


import withBreakdown from "../hoc/withBreakdown";
import { useGlobalContext } from "../../contexts/globalContext";

const TargetReminder = ({ targetForTheMonth, showTargetModal }) => {
  const [showTargetReminder, setShowTargetReminder] = useState(false);

  const { user } = useGlobalContext();

  const handleShowTargetReminder = () => {
    const showReminder = targetForTheMonth ? false : true;
    setShowTargetReminder(showReminder);
  };

  const handleShowTargetModal = () => {
    showTargetModal();
    setShowTargetReminder((prev) => !prev);
  };

  const handleCloseTargetReminder = () => {
    setShowTargetReminder(false);
  };

  useEffect(() => {
    handleShowTargetReminder();

    // eslint-disable-next-line
  }, [targetForTheMonth]);

  if (!showTargetReminder) return null;

  return (
    <Modal handleClose={handleCloseTargetReminder}>
      <h3 className="modal-title text-capitalize">
        <i className="fa fa-newspaper-o"></i> Welcome,{" "}
        <span>{user.username}</span>
      </h3>

      <p className="modal-body-text">
        Targets have not yet been set for this month.{" "}
        <span className="error">
          It's important to set targets at the start of every month.
        </span>{" "}
        Would you like to set them now?
      </p>

      <div className="modal-buttons">
        <Button label="Set Now" onClick={handleShowTargetModal} />
        <Button
          label="Remind me later"
          className="app-btn close-btn"
          onClick={handleCloseTargetReminder}
        />
      </div>
    </Modal>
  );
};

export default withBreakdown(TargetReminder);
