import { Navigate, useNavigate } from "react-router-dom";

import CustomSlider from "../../../components/blocks/customSlider";

import countries from "../../../utils/countries";
import navigationRoutes from "../../../routes/navigationRoutes";

import { useGlobalContext } from "../../../contexts/globalContext";
import AuthenticationLayout from "./Layout";
const CountryItem = ({ countryItem, onSelect }) => {
  let { imageUrl, country, color, label } = countryItem;

  return (
    <div className={"country-item"} style={{ backgroundColor: color }}>
      <div className={"country-item-top"}>
        <img src={imageUrl} alt={country + " Flag"} />
        <p className={"label"}>{label}</p>
      </div>

      <div className={"country-item-bottom"}>
        <div className={"country-item-bottom-inner"}>
          <div className={"country-item-bottom-inner-left"}>
            <h3>{country}</h3>
          </div>
          <div className={"country-item-bottom-inner-right"}>
            <button onClick={onSelect}>Select </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const SelectCountry = () => {
  const navigate = useNavigate();
  const { country, handleCountrySelect } = useGlobalContext();

  let userType = window.localStorage.getItem("userType");
  if (country && !userType) {
    return <Navigate to={navigationRoutes.userType} />;
  } else if (country && userType) {
    return <Navigate to={navigationRoutes.login} />;
  }

  return (
    <AuthenticationLayout>
      {" "}
      <div className="select-country-container">
        <div className="select-country-container-inner">
          <div className="left-pane">
            <h2>Which country are you visiting?</h2>
            <p>Please select a country from the available options</p>
          </div>

          <div className="right-pane">
            <CustomSlider {...sliderSettings}>
              {countries.map((country) => {
                const onSelect = (country) => {
                  handleCountrySelect(country);
                  navigate(navigationRoutes.userType);
                };

                return (
                  <CountryItem
                    key={country.country}
                    countryItem={country}
                    onSelect={() => onSelect(country)}
                  />
                );
              })}
            </CustomSlider>
          </div>
        </div>
      </div>
    </AuthenticationLayout>
  );
};

const sliderSettings = {
  autoplay: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export default SelectCountry;
