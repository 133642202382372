import React, { useState, useMemo, useCallback } from "react";

import Header from "../../../../components/blocks/header";

import EditForm from "./EditEvent";
import {
  useGetEvents,
  useArchiveEvent,
} from "../../../../hooks/marketing/useEvents";
import { useNavigate, Link } from "react-router-dom";

import { Button as Btn } from "../../../../@/components/ui/button";
import { ReloadIcon } from "@radix-ui/react-icons";
import DataTable from "../../../../components/tables/tableEvents/data-table";
import toastService from "../../../../services/toastService";
import { MoreVertical, Archive, SquarePen, ArrowUpDown } from "lucide-react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../../@/components/ui/dropdown-menu";
import { Checkbox } from "../../../../@/components/ui/checkbox";
import LoadingButton from "../../../../components/common/loadingButton";
import Modal from "../../../../components/modals/mod/Modal";

const handleCopyClick = (url: any) => {
  navigator.clipboard
    .writeText(url)
    .then(() => {
      toastService.success("URL copied to clipboard");
    })
    .catch((error) => {
      toastService.error("Failed to copy URL to clipboard");
    });
};
const Events = () => {
  const navigate = useNavigate();
  const { events, isPending: isEventLoading, baseUrl } = useGetEvents();
  const { mutateArchive, isPending } = useArchiveEvent();

  // transform event to add N/A to null datas
  const transformedEvents = events
    ? events
        ?.map(
          (event: {
            name: string;
            created: string;
            clicks: string;
            appointments: string;
            speaker: string;
            attendancepost: string;
            location: string;
            longitude: string;
            latitude: string;
            url: string;
            id: string;
            header: string;
            type: string;
            category: string;
            description: string;
            enddate: string;
            startime: string;
            eventdate: string;
            seats: string;
          }) => {
            const {
              name,
              created,
              clicks,
              appointments,
              speaker,
              attendancepost,
              location,
              longitude,
              latitude,
              url,
              id,
              header,
              type,
              category,
              description,
              startime,
              enddate,
              eventdate,
              seats,
            } = event;
            return {
              name: name || "N/A",
              created: created || "N/A",
              clicks: clicks || "N/A",
              appointments: appointments || "N/A",
              speaker: speaker || "N/A",
              attendancepost: attendancepost || "N/A",
              location: location || "N/A",
              longitude: longitude || "N/A",
              latitude: latitude || "N/A",
              url: url || "N/A",
              id: id || "N/A",
              header: header || "N/A",
              type: type || "N/A",
              category: category || "N/A",
              description: description || "N/A",
              startime: startime || "N/A",
              enddate: enddate || "N/A",
              eventdate: eventdate || "N/A",
              seats: seats || "N/A",
            };
          }
        )
        .reverse()
    : [];

  const [isOpen, setIsOpen] = useState(false);
  const [editData, setEditData] = useState<any>(null);

  const closeModal = () => setIsOpen(false);

  const openModal = useCallback((data: any) => {
    console.log(data);
    if (data) {
      setEditData(data);
      setIsOpen(true);
    }
  }, []);

  const [clickedRowId, setClickedRowId] = useState<string | null>(null);

  const handleArchive = useCallback(
    (id: string) => {
      setClickedRowId(id);
      mutateArchive(id);
    },
    [mutateArchive]
  );

  const columns = useMemo(
    () => [
      {
        id: "select",
        header: ({ table }: { table: any }) => (
          <Checkbox
            checked={
              table.getIsAllPageRowsSelected() ||
              (table.getIsSomePageRowsSelected() && "indeterminate")
            }
            onCheckedChange={(value) => {
              table.toggleAllPageRowsSelected(!!value);
              // console.log(table.options.data);
              // each data id
              // table.options.data.map((data: any) => {
              //   console.log(data.name);
              // });
            }}
            aria-label="Select all"
          />
        ),
        cell: ({ row }: { row: any }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => {
              row.toggleSelected(!!value);
              //   console.log(row.original);
            }}
            aria-label="Select row"
          />
        ),
        enableSorting: false,
        enableHiding: false,
      },
      {
        accessorKey: "name",
        // header: "Event Name",
        header: ({ column }: { column: any }) => {
          return (
            <button
              className="flex group"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
            >
              Event Name
              <ArrowUpDown className="ml-2 h-4 w-4 hidden group-hover:block" />
            </button>
          );
        },
        cell: ({ row }: { row: any }) => (
          <div className="flex items-center justify-between xl:justify-start gap-2 ">
            {/* <p className="xl:hidden font-semibold">Event Name</p> */}
            <div
              style={{ width: "fit-content" }}
              className="flex items-center  gap-2"
            >
              <img
                className="h-10 w-10 p-0 rounded-md"
                src={`https://${baseUrl}${row.original.header}`}
                alt={row.getValue("name")}
              />
              <Link
                to={`/events/create-event?id=${row.original.id}`}
                className="text-sm capitalize w-full"
              >
                {row.getValue("name")}
              </Link>
            </div>
          </div>
        ),
      },
      {
        accessorKey: "created",
        header: "Date",
      },
      {
        accessorKey: "clicks",
        header: "Number of hits ",
      },
      {
        accessorKey: "appointments",
        header: "Number of appointments",
      },
      {
        accessorKey: "speaker",
        header: "Speaker(s)",
      },
      {
        accessorKey: "seats",
        header: "Number of Reservation",
      },
      {
        accessorKey: "url",
        header: " ",
        cell: ({ row }: { row: any }) => {
          const link = row.getValue("url") as string;
          const addProtocolToUrl = (url: string) => {
            if (!url.includes("http")) {
              return `https://${url}`;
            }
            return url;
          };
          return (
            <Btn
              onClick={() => handleCopyClick(addProtocolToUrl(link))}
              variant="default"
              className="app-btn !rounded-lg !mt-0"
            >
              Share link
            </Btn>
          );
        },
      },
      {
        accessorKey: "id",
        header: " ",
        cell: ({ row }: { row: any }) => {
          const id = row.getValue("id") as string;
          return (
            <div>
              {id === clickedRowId && isPending ? (
                <ReloadIcon className="animate-spin mr-2 h-4 w-4" />
              ) : (
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Btn variant="ghost" className="h-8 w-8 p-0">
                      <span className="sr-only">Open menu</span>
                      <MoreVertical className="h-4 w-4" />
                    </Btn>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    <DropdownMenuItem
                      className="cursor-pointer"
                      onClick={() => {
                        //   navigator.clipboard.writeText(id);
                        console.log(row.original);
                        // setIsEdit(true);
                        openModal(row.original);
                        // setIsOpen(true);
                      }}
                    >
                      <SquarePen className="mr-2 h-4 w-4" /> Edit
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem
                      onClick={() => {
                        handleArchive(id);
                        // mutateArchive(id);
                      }}
                      className="cursor-pointer"
                    >
                      <Archive className="mr-2 h-4 w-4" /> Archive
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              )}
            </div>
          );
        },
      },
    ],
    [baseUrl, clickedRowId, handleArchive, isPending, openModal]
  );

  const createEvent = () => {
    navigate("/events/create-event");
  };
  return (
    <React.Fragment>
      <Header title={`Events`} />

      <div className="hospitals-container">
        <div className="">
          {isEventLoading ? (
            <LoadingButton
              visible={isEventLoading}
              message="Fetching Events... Please wait"
            />
          ) : (
            <DataTable
              columns={columns}
              data={transformedEvents}
              onClick={createEvent}
              btnTitle="ADD NEW EVENT"
            />
          )}
        </div>
      </div>

      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="relative space-y-5 flex flex-col items-center  text-center">
          <div className="flex justify-between w-full">
            <h1 className="text-2xl font-semibold capitalize">Edit Event</h1>
            <button
              onClick={closeModal}
              className="absolute -top-2 right-2  text-2xl text-red-500 bg-red-50 rounded-full py-2 px-3"
            >
              &times;
            </button>
          </div>
          <EditForm data={{ ...editData, baseUrl: baseUrl }} />
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default Events;
