import React, { useState } from "react";
import Header from "../../../components/blocks/header";
import RequestTable from "../../../components/tables/requestTable";
import SearchQuery from "../../../components/forms/searchQuery";

const Requests = () => {
  const [search, setSearch] = useState("");
  return (
    <div>
      <Header title={`Hospitals to close`} />
      {/* <div className="tabsContainer">
        {data.map((d) => (
          <div
            key={d}
            onClick={() => setActive(d)}
            className={active === d ? "activeTab" : "reqTab"}
          >
            {d}
          </div>
        ))}
      </div> */}

      <div className="hospitals-container">
        <div className="hospitals-container-top">
          <SearchQuery
            name="search"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
          />
        </div>
        <div className="hospitals-container-bottom table-responsive">
          {/* {active === "Pending" ? <RequestTable /> : <RequestClosedTable />} */}
          <RequestTable search={search} />
        </div>
      </div>
    </div>
  );
};

export default Requests;
