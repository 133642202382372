import { Card, CardContent } from "@mui/material";
import React from "react";
export default function DashboardCard({
  title,
  value,
  icon,
}: {
  title: string;
  value: number;
  icon: any;
}) {
  return (
    <>
      <Card
        sx={{
          minWidth: 200,
          boxShadow: "0px 2px 2px 1px rgba(0, 0, 0, 0.25)",
          width: "100%",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          mb: "10px",
          height: "100%",
        }}
      >
        <CardContent className="flex flex-col justify-between h-full w-full p-10 space-y-2">
          <div>
            <div className="flex w-full">
              <div className="bg-[#5D12D2] rounded-full p-3">{icon}</div>
              <div></div>
            </div>
            <div className="flex w-full">
              <h2 className="text-sm text-gray-500">{title}</h2>
              <div></div>
            </div>
          </div>

          <div className="flex flex-col justify-end w-full h-full">
            <div className="flex justify-end">
              <h3 className="text-2xl">{value}</h3>
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
}
