import React from "react";
import BackButton from "../backbutton";
import { Control, FieldErrors } from "react-hook-form";
import Button from "../../common/button";
import { DangerMasteryData, DangerMasteryKeys } from "./types";
import MissedMaintenanceLog from "./MissedMaintenanceLog";
import PlacedManualOrderLog from "./PlacedManualOrderLog";
import HotComplaintLog from "./HotComplaintLog";
import LateDeliveryLog from "./LateDeliveryLog";
import { dangerMasteryKeys } from "./constants";

type Props = {
  setCallInfo: React.Dispatch<
    React.SetStateAction<{ show: boolean; key: DangerMasteryKeys }>
  >;
  loading: boolean;
  control: {
    controlMissedMaintenance: Control<DangerMasteryData, any>;
    controlPlacedManualOrder: Control<DangerMasteryData, any>;
    controlHotComplaint: Control<DangerMasteryData, any>;
    controlLateDelivery: Control<DangerMasteryData, any>;
  };
  errors: FieldErrors<DangerMasteryData>;
  objKey: DangerMasteryKeys;
  submit: {
    submitMissedMaintenance: (data: any) => void;
    submitPlacedManualOrder: (data: any) => void;
    submitHotComplaint: (data: any) => void;
    submitLateDelivery: (data: any) => void;
  };
  setLogData: React.Dispatch<
    React.SetStateAction<{
      location: string;
    }>
  >;
  logData: any;
  position: any;
  setPosition: any;
};

export default function NewlyactiveMasteryCallLog({
  setCallInfo,
  loading,
  control,
  errors,
  objKey,
  submit,
  setLogData,
  logData,
  position,
  setPosition,
}: Props) {
  return (
    <React.Fragment>
      <BackButton
        goBack={() => {
          setCallInfo({ show: false, key: objKey });
        }}
      />
      <div className="my-8">
        {objKey === dangerMasteryKeys.missed_maintenance && (
          <MissedMaintenanceLog
            control={control.controlMissedMaintenance}
            errors={errors}
            setLogData={setLogData}
            position={position}
            setPosition={setPosition}
            logData={logData}
          />
        )}
        {objKey === dangerMasteryKeys.placed_manual_order && (
          <PlacedManualOrderLog
            control={control.controlPlacedManualOrder}
            errors={errors}
            setLogData={setLogData}
            position={position}
            setPosition={setPosition}
            logData={logData}
          />
        )}
        {objKey === dangerMasteryKeys.hot_complaint && (
          <HotComplaintLog
            control={control.controlHotComplaint}
            errors={errors}
            setLogData={setLogData}
            position={position}
            setPosition={setPosition}
            logData={logData}
          />
        )}
        {objKey === dangerMasteryKeys.late_delivery && (
          <LateDeliveryLog
            control={control.controlLateDelivery}
            errors={errors}
            setLogData={setLogData}
            position={position}
            setPosition={setPosition}
            logData={logData}
          />
        )}
      </div>
      <Button
        disabled={
          loading ||
          (objKey === dangerMasteryKeys.hot_complaint &&
            (logData.location && !position ? false : true)) 
        }
        type="button"
        label="Save call log"
        onClick={() => {
          if (objKey === dangerMasteryKeys.missed_maintenance) {
            submit.submitMissedMaintenance(control);
          }
          if (objKey === dangerMasteryKeys.placed_manual_order) {
            submit.submitPlacedManualOrder(control);
          }
          if (objKey === dangerMasteryKeys.hot_complaint) {
            submit.submitHotComplaint(control);
          }
          if (objKey === dangerMasteryKeys.late_delivery) {
            submit.submitLateDelivery(control);
          }
        }}
      />
    </React.Fragment>
  );
}
