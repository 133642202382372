import React from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

interface Props {
  pitch: any;
  close: any;
}

const Reminders = ({ pitch, close }: Props) => {
  const pipelineItems = [
    {
      title: "Pitch",
      tag: "pitches",
      color: "#441AB7",
      sortKey: "pitch_date",
    },
    {
      title: "Close",
      tag: "close",
      color: "#b71C1C",
      sortKey: "prospect_date",
    },
    {
      title: "Active",
      tag: "active",
      color: "#114B29",
      sortKey: "active_date",
    },
  ];
  const history = useNavigate();
  const navigate = (path: string) => {
    history(`/pipeline?content=${path}`);
  };
  return (
    <div className="bg-white border-2 border-purple-900 p-4 flex flex-col justify-start items-start shadow-md w-full rounded-lg">
      <div className="flex flex-col sm:flex-row justify-between items-center gap-6 w-full mb-2">
        <h1 className="text-3xl font-bold text-purple-900 uppercase">
          Reminders
        </h1>
        <div className="border-t-4 border-purple-900 w-32 "></div>
      </div>

      <div className="flex flex-col sm:flex-row justify-center items-center gap-2 mb-2  w-full sm:w-auto ">
        <AiFillCheckCircle className="text-2xl text-purple-900" />
        <p className="text-black text-base font-bold text-center sm:text-left">
          Pitch{" "}
          <button
            onClick={() => {
              window.sessionStorage.setItem(
                "pipeline",
                JSON.stringify(
                  pipelineItems.find((item) => item.title === "Pitch")
                )
              );
              navigate(
                pipelineItems.find((item) => item.title === "Pitch")?.tag || ""
              );
            }}
            // to="pitch"
            className="text-purple-900 font-bold italic underline underline-offset-2"
          >
            {pitch}
          </button>{" "}
          Hospitals
        </p>
      </div>

      <div className="flex flex-col sm:flex-row justify-center items-center gap-2 mb-2">
        <AiFillCheckCircle className="text-2xl text-purple-900" />
        <p className="text-black text-base font-bold text-center sm:text-left">
        {/* Message your lead to close{" "} */}
        You need to close{" "}
          <button
            onClick={() => {
              window.sessionStorage.setItem(
                "pipeline",
                JSON.stringify(
                  pipelineItems.find((item) => item.title === "Close")
                )
              );
              navigate(
                pipelineItems.find((item) => item.title === "Close")?.tag || ""
              );
            }}
            className="text-purple-900 font-bold italic underline underline-offset-2"
            // to="pipeline?content=close"
          >
            {close}
          </button>{" "}
          Hospitals
        </p>
      </div>

      <div className="flex flex-col sm:flex-row justify-center items-center gap-2 mb-2">
        <AiFillCheckCircle className="text-2xl text-purple-900" />
        <p className="text-black text-base font-bold text-center sm:text-left">
          ___________ has missed Active Target{" "}
          <button
            onClick={() => {
              window.sessionStorage.setItem(
                "pipeline",
                JSON.stringify(
                  pipelineItems.find((item) => item.title === "Active")
                )
              );
              navigate(
                pipelineItems.find((item) => item.title === "Active")?.tag || ""
              );
            }}
            className="text-purple-900 font-bold italic underline underline-offset-2"
          >
            Review&gt;
          </button>
        </p>
      </div>
    </div>
  );
};

export default Reminders;
