import React, { useState } from "react";
import { ImLocation } from "react-icons/im";
import Location from "./Location";

const LogVisit = ({ setIsOpen, address, name, id, contactType }) => {
  const [isOpenLocation, setIsOpenLocation] = useState(false);

  const handleClick = () => {
    console.log("Clicked on Log Visit div");
    setIsOpen(false);
  };

  const [coordinates, setCoordinates] = useState(null);
  const [pitchLocation, setPitchLocation] = useState(null);
  const [landmark, setLandmark] = useState(null);

  const handleFindLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCoordinates(`${latitude} N, ${longitude} E`);
          setPitchLocation(`${latitude},${longitude}`);
          fetchLocationDetails(latitude, longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const fetchLocationDetails = async (latitude, longitude) => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyAg7-YYR9clrIdYVCILJ0zZvL6qDquZLYw`
    );
    const data = await response.json();
    if (data.status === "OK") {
      const results = data.results;
      console.log(results[0].formatted_address);
      if (results.length > 0) {
        setLandmark(results[0].formatted_address);
      }
    }
  };
  const handleLocation = () => {
    console.log("Location component rendered");
    handleFindLocation();
    setIsOpenLocation(true);
  };

  return (
    <>
      <div className=" top-0 left-0 right-0 bottom-0 bg-black bg-opacity-25 flex items-center justify-center modal-backdrop ">
        <div className="bg-[#fff]  h-[400px] w-[400px] rounded-md flex flex-col justify-start relative">
          <div className="bg-[#D4C7F1] text-[#4D26A0] rounded-t-md text-center font-bold p-3 w-full">
            Log Your Visit
          </div>

          <div
            className="absolute flex items-center justify-center right-1 top-1 cursor-pointer  h-[40px] w-[40px] bg-[#4D26A0] text-center rounded-full"
            onClick={handleClick}
          >
            <p className="text-2xl text-white ">X</p>
          </div>

          <div className="flex flex-col items-center justify-evenly">
            <div className="p-4 mb-5">
              <h1>This is required before you can log your visit</h1>
            </div>
            <div className="text-[#4D26A0] text-8xl mb-5">
              <ImLocation />
            </div>
            <div
              className="bg-[#4D26A0] text-[#fff] text-center h-[40px] w-[300px] flex items-center justify-center rounded cursor-pointer"
              onClick={handleLocation}
            >
              Find My Location
            </div>
          </div>
        </div>
      </div>
      {isOpenLocation && (
        <Location
          setIsOpenLocation={setIsOpenLocation}
          address={address}
          name={name}
          cord={coordinates}
          id={id}
          contactType={contactType}
        />
      )}
    </>
  );
};

export default LogVisit;
