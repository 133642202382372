import React, { useEffect, useState } from "react";
import _ from "lodash";
import utilities from "../../utils/utilities";

import LoadingButton from "../common/loadingButton";
import Pagination from "../common/pagination";
import Table from "../common/table";

import { getHibernatedHospitals } from "../../services/leadService";
import toastService from "../../services/toastService";
import { useGlobalContext } from "../../contexts/globalContext";

const HibernatedHospitalsTable = ({ search, status }) => {
  const [sortColumn, setSortColumn] = useState({ path: "id", order: "desc" });
  const [currentPage, setCurrentPage] = useState(1);
  const [hibernatedHospitals, setHibernatedHospitals] = useState([]);
  const [loading, setLoading] = useState(false);

  const { city } = useGlobalContext();

  const pageSize = 8;

  const getData = async () => {
    try {
      setLoading(true);

      const response = await getHibernatedHospitals(city);
      const hospitals = utilities.mapKeyToNumber(response.data.hospitals, "id");
      setHibernatedHospitals(hospitals);

      setLoading(false);
    } catch (error) {
      const errorMessage = error?.response?.data?.description ?? error.message;
      toastService.error(errorMessage);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();

    // eslint-disable-next-line
  }, [city]);

  const paginatedData = () => {
    let filtered = hibernatedHospitals;

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const paginated = utilities.paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, paginated };
  };

  const { totalCount, paginated } = paginatedData();

  return (
    <div className="activity-feed fade-in">
      <LoadingButton
        visible={loading}
        message="Fetching Hibernated Hospitals"
      />
      {!loading && (
        <React.Fragment>
          <Table
            columns={columns}
            data={paginated}
            sortColumn={sortColumn}
            onSort={setSortColumn}
          />

          <div className="sb-pagination-container">
            <Pagination
              itemsCount={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>

          {paginated.length === 0 && (
            <div className="no-items">
              <p>No Hibernated Hospitals</p>
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

const columns = [
  {
    path: "id",
    label: "Hospital ID",
  },
  {
    path: "name",
    label: "Hospital Name",
  },
  {
    path: "email",
    label: "Email Address",
  },
  {
    path: "phone",
    label: "Phone Number",
  },
  {
    path: "address",
    label: "Hospital Address",
  },
];

export default HibernatedHospitalsTable;
