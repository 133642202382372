import React, { Dispatch, SetStateAction } from "react";
import { UploadIcon } from "@radix-ui/react-icons";
import { IoCloudUploadOutline } from "react-icons/io5";
import { Controller } from "react-hook-form";
import toastService from "../../../../services/toastService";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../@/components/ui/form";
import { Input } from "../../../../@/components/ui/input";
import { format } from "date-fns";

const typeOptions = [
  {
    value: "Quick Guide By LifeBank (PDF)",
    label: "Quick Guide By LifeBank (PDF)",
  },
  {
    value: "MasterClass By LifeBank (Video)",
    label: "MasterClass By LifeBank (Video)",
  },
];

export default function CreateTool({
  form,
  sample,
  setSample,
  asset,
  setAsset,
  selectedToolType,
}: {
  form: any;
  sample: File | null;
  setSample: Dispatch<SetStateAction<File | null>>;
  asset: File | null;
  setAsset: Dispatch<SetStateAction<File | null>>;
  selectedToolType: string;
}) {
  const toolType =
    selectedToolType === "Quick Guide By LifeBank (PDF)" ? "pdf" : "video";

  const getAcceptAttribute = () => {
    switch (toolType) {
      case "pdf":
        return ".pdf";
      case "video":
      default:
        return ".mp4, .avi, .mov";
    }
  };

  const uploadSample = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const maxSize = 1024 * 1024 * 10;
      if (file.size <= maxSize) {
        setSample(file);
      } else {
        console.log("error");
        toastService.error(
          "File size exceeds the limit, please choose an image less than 10MB"
        );
      }
    }
  };

  const uploadAsset = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const maxSize = 1024 * 1024 * 50;
      if (file.size <= maxSize) {
        setAsset(file);
      } else {
        console.log("error");
        toastService.error(
          "File size exceeds the limit, please choose a file less than 50MB"
        );
      }
    }
  };

  return (
    <Form {...form}>
      <form className="grid grid-cols-2 gap-3 w-full px-3">
        <FormField
          control={form.control}
          name="tooltype"
          render={({ field }) => (
            <FormItem className="flex flex-col items-start">
              <FormLabel className="font-semibold ">Type of Tool</FormLabel>
              <select
                {...field}
                id="tooltype"
                className="flex h-10 w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1"
              >
                {typeOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem className="flex items-start flex-col">
              <FormLabel htmlFor="name" className="font-semibold ">
                Guide Name
              </FormLabel>
              <FormControl>
                <Input id="name" placeholder="Enter Guide Name" {...field} />
              </FormControl>
              <div className="flex items-center justify-end">
                <FormMessage />
              </div>
            </FormItem>
          )}
        />{" "}
        <FormField
          control={form.control}
          name="duration"
          render={({ field }) => (
            <FormItem className="flex items-start flex-col">
              <FormLabel htmlFor="duration" className="font-semibold ">
                Duration to Read
              </FormLabel>
              <FormControl>
                <Input id="duration" placeholder="E.g 10 Min" {...field} />
              </FormControl>
              <div className="flex items-center justify-end">
                <FormMessage />
              </div>
            </FormItem>
          )}
        />
        <Controller
          control={form.control}
          name="startdate"
          render={({ field }) => (
            <FormItem className="flex items-start flex-col">
              <FormLabel className="font-semibold ">Go live date</FormLabel>
              <FormControl>
                <Input
                  type="date"
                  id="startdate"
                  value={field.value ? format(field.value, "yyyy-MM-dd") : ""}
                  onChange={(e) => field.onChange(new Date(e.target.value))}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex items-start flex-col space-y-5 col-span-2">
          <FormLabel className="font-semibold ">Upload Preview</FormLabel>
          <div className="upload">
            <label
              htmlFor="sampleImage"
              className="flex items-center justify-center h-80  max-h-72 bg-[#C4C4C426] border-dashed border-2 w-full "
            >
              {sample ? (
                <img
                  className="rounded-md object-fit max-h-full"
                  src={URL.createObjectURL(sample)}
                  alt="preview"
                />
              ) : (
                <div className="">
                  <UploadIcon className="w-20 h-20" />
                </div>
              )}{" "}
              <input
                name="sampleImage"
                type="file"
                id="sampleImage"
                accept=".jpeg, .png, .jpg"
                className="upload-input"
                onChange={uploadSample}
              />
            </label>
          </div>
          <FormDescription className="text-start">
            Upload a 32 x 32 pixel ICO, PNG, GIF, or JPG.
          </FormDescription>
        </div>
        <div className="space-y-5 col-span-2">
          <FormItem className="flex flex-col items-start">
            <FormLabel className="font-semibold ">Main Asset</FormLabel>
            <div className="flex gap-2 items-center justify-center">
              <FormLabel
                className="flex items-center justify-center font-semibold border-2 p-3 rounded-lg bg-[#7196EF] cursor-pointer text-white px-3"
                htmlFor="assetFile"
              >
                <IoCloudUploadOutline className="h-5 w-5 mr-2" /> Upload
                <FormControl>
                  <Input
                    className="hidden"
                    id="assetFile"
                    name="assetFile"
                    type="file"
                    accept={getAcceptAttribute()}
                    placeholder="Please upload an asset image"
                    onChange={uploadAsset}
                  />
                </FormControl>
              </FormLabel>
              {asset && (
                <div className="flex items-center justify-start">
                  <span className="text-sm border-2 border-gray-600 p-1 rounded-md">
                    {asset.name}
                  </span>
                </div>
              )}
            </div>
            <FormDescription>
              {`Upload a ${toolType === "pdf" ? "PDF" : "Video"} file`}
            </FormDescription>
          </FormItem>
        </div>
      </form>
    </Form>
  );
}
