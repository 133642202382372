import { useEffect, useState } from "react";

import utilities from "../utils/utilities";

interface BreakdownParams {
  data: any[];
  targets: any[];
  targetKey: string;
}

interface DatasetParams {
  name: string;
  data: any[];
}

const useBreakdown = (breakdownParams: BreakdownParams) => {
  const [isTargetVisible, setIsTargetVisible] = useState<boolean>(false);
  const [datasets, setDatasets] = useState<DatasetParams[]>([
    {
      name: "Actual",
      data: [],
    },
  ]);

  const breakdown: any = utilities.getBreakdown(breakdownParams);
  const setMonthTarget: string = breakdown.target?.[breakdownParams.targetKey];
  const labels: string[] = utilities.getMonths().labelledMonths;

  const chartData = datasets;
  
  const chartOptions = {
    chart: {
      height: 350,
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: isTargetVisible,
      position: "bottom" as "bottom",
      labels: {
        colors: undefined,
        useSeriesColors: false,
      },
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        strokeColor: "#fff",
        fillColors: undefined,
        radius: 12,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
      itemMargin: {
        horizontal: 5,
        vertical: 0,
      },
    },
    stroke: {
      width: 2,
    },
    xaxis: {
      categories: labels,
    },
    yaxis: {
      labels: {
        formatter: function (val: number) {
          return val?.toString();
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const handleDataset = (): void => {
    const newDataset = [];

    newDataset.push({
      name: "Actual",
      data: breakdown.actualData,
    });

    if (isTargetVisible) {
      newDataset.push({
        name: "Target",
        data: breakdown.targetData,
      });
    }

    setDatasets(newDataset);
  };

  const toggleTargetVisibility = (): void => {
    setIsTargetVisible((prev) => !prev);
  };

  useEffect(() => {
    handleDataset();

    // eslint-disable-next-line
  }, [isTargetVisible]);

  return {
    setMonthTarget,
    toggleTargetVisibility,
    chartData,
    chartOptions,
    isTargetVisible,
  };
};

export default useBreakdown;
