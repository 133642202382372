import ExodusHeader from "../../../../components/exodusComponent/ExodusHeader";
import useSortableTable from "../../../../components/exodusComponent/TableSort";
import { Complains } from "../../../../contant";
import LoadingButton from "../../../../components/common/loadingButton";

import { useSingleExodus } from "../../../../hooks/useExodus";
const AllExodusComplain = () => {
  const idString = localStorage.getItem("exodus_order_id");
  const id = idString ? JSON.parse(idString) : null;
  const { singleExodus, isPending } = useSingleExodus(id as string);

  const data = [{}];

  const { sortBy, sortDirection, handleSort } = useSortableTable(
    data,
    // @ts-ignore
    "complainID"
  );

  return (
    <div className="h-screen w-full">
      <div className="h-screen w-full">
        <div className="mb-8">
          <ExodusHeader
            Text={"All Complaints"}
            subText={`Exodus Arena > ${singleExodus?.hospital_summary[0].name} -> All Complaints`}
          />
        </div>

        {isPending ? (
          <div className="flex flex-col justify-between items-center w-full p-5 sm:p-10">
            <LoadingButton
              visible={isPending}
              message="Fetching Data... Please wait"
            />
          </div>
        ) : (
          <>
            {singleExodus?.complaints?.length === 0 ? (
              <div className="flex items-center h-[200px] justify-center">
                <p className="text-2xl">Hospital has no complaints!.</p>
              </div>
            ) : (
              <div className="w-11/12 mx-auto flex">
                <table>
                  <thead className="mt-4 ">
                    <tr className="mt-4 ">
                      <th
                        onClick={() => handleSort("complainID")}
                        className="cursor-pointer font-extrabold text-[18px]"
                      >
                        Complain ID
                        {sortBy === "complainID" && (
                          <span>{sortDirection === "asc" ? " ▲" : " ▼"}</span>
                        )}
                      </th>
                      <th className="font-extrabold text-[18px]">
                        Incident Date
                      </th>
                      <th className="font-extrabold text-[18px]">
                        Contact Name
                      </th>
                      <th className="font-extrabold text-[18px]">
                        Contact Phone No.
                      </th>
                      <th className="font-extrabold text-[18px]">Category</th>
                      <th className="font-extrabold text-[18px]">
                        Complaint summary
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-[#FFFFFF] h-[160px] shadow-white shadow-sm rounded">
                    {Complains.map((ele) => (
                      <tr>
                        <td className="ml-8">{ele.complainsID}</td>
                        <td>{ele.incidentDate}</td>
                        <td>{ele.contactName}</td>
                        <td>{ele.contactPhone}</td>
                        <td>{ele.category}</td>
                        <td>{ele.complaintIssue}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AllExodusComplain;
