import React from "react";
import { Outlet } from "react-router-dom";
 
import TopNavigation from "../../../../components/blocks/topNavigation";
import LeftNavigation from "../../../../components/blocks/leftNavigation";
import { Footer } from "../../../../components/blocks/footer";

const Dashboard: React.FC = () => {
  return (
    <>
      <TopNavigation />

      <div className="dashboard">
        <LeftNavigation />
        <div className="dashboard-right">
          <div className="dashboard-right-body">
            <Outlet />
          </div>

          <Footer />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
