import React from "react";

const TextArea = ({ type, name, label, error, compulsory, ...rest }) => {
  return (
    <div className="form-field">
      {label && (
        <label htmlFor={name} className="form-field-label">
          {compulsory && <i className="fa fa-asterisk error"></i>} {label}:
        </label>
      )}
      <textarea
        id={name}
        name={name}
        rows="4"
        cols="50"
        {...rest}
        className="form-field-input"
      ></textarea>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default TextArea;
