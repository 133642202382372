import React, { useState } from "react";

import Header from "../../../components/blocks/header";
import SearchQuery from "../../../components/forms/searchQuery";
import ClaimsTable from "../../../components/tables/claimsTable";

const NerveClaims = () => {
  const [search, setSearch] = useState("");
  const [status] = useState("");
  const [searchKey] = useState("name");
  const [active] = useState("2");
  const [count, setCount] = useState(0);

  const handleCount = (newCount) => {
    setCount(newCount);
  };

  return (
    <React.Fragment>
      <Header title={"Nerve Account Claims - " + count} />

      <div className="hospitals-container">
        <div className="hospitals-container-top">
          <SearchQuery
            name="search"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
          />
        </div>

        <div className="hospitals-container-bottom table-responsive">
          <ClaimsTable
            search={search}
            searchKey={searchKey}
            status={status}
            onCountChange={handleCount}
            activeCategory={active}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default NerveClaims;
