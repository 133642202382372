import React from "react";
import Joi from "joi-browser";

import Form from "../common/form";
import LoadingButton from "../common/loadingButton";
import Modal from "../common/modal";
import ProgressLoader from "../common/progressLoader";

import WithFacilityType from "../hoc/withFacilityType";

import { addProduct } from "../../services/productService";
import toastService from "../../services/toastService";

class AddProduct extends Form {
  state = {
    data: {
      name: "",
      color: "",
    },
    errors: {},
    progress: 0,
  };

  schema = {
    name: Joi.string()
      .trim()
      .regex(/^[a-zA-Z_ ]+$/, "product name")
      .required()
      .label("Product Name"),
    color: Joi.string()
      .default("#b6b6b6")
      .required()
      .label("Product Theme Color"),
  };

  getProductMatch = (value) => {
    try {
      const { productList } = this.props;

      return productList.find((s) =>
        s?.name?.toLowerCase?.()?.startsWith(value.toLowerCase())
      );
    } catch (error) {
      return null;
    }
  };

  handleProgressChange = (progress) => {
    this.setState({ progress });
  };

  handleChange = (e) => {
    const input = e.currentTarget;

    const data = { ...this.state.data };
    const errors = { ...this.state.errors };

    const productMatch = this.getProductMatch(input.value);
    const errorMessage = this.validateProperty(input);

    data[input.name] = input.value;
    errors[input.name] = productMatch
      ? `Match Found: ${productMatch.name}!`
      : errorMessage;

    this.setState({ data, errors });
  };

  postData = async () => {
    const { data } = this.state;

    const res = await addProduct(data, this.handleProgressChange);
    if (res.data.status !== "success") throw new Error(res.data.message);

    this.props.handleClose();
  };

  doSubmit = () => {
    toastService.promise(this.postData(), {
      loading: "Uploading data...",
      success: () => "Product Added Successfully!",
      error: (e) =>
        e?.response?.data?.message || e?.message || "Could not add product!",
    });
  };
  render() {
    const { progress } = this.state;
    const { loading } = this.props;

    return (
      <Modal {...this.props}>
        <ProgressLoader progress={progress} />
        <form onSubmit={this.handleSubmit}>
          <h2 className="form-header">
            Fields marked <i className="fa fa-asterisk error"></i> are
            compulsory
          </h2>

          {this.renderInput({
            type: "text",
            name: "name",
            placeholder: "e.g. Blood",
            label: "Product",
            compulsory: true,
          })}

          {this.renderInput({
            type: "color",
            name: "color",
            placeholder: "e.g. #000000",
            label: "Product Color",
            compulsory: true,
          })}

          <div className="d-flex justify-content-end ">
            <LoadingButton visible={loading} message="Please wait" />
            {!loading && this.renderButton("Add")}
          </div>
        </form>
      </Modal>
    );
  }
}

export default WithFacilityType(AddProduct);
