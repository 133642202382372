import React, { useEffect, useState } from "react";

import _ from "lodash";

import LoadingButton from "../common/loadingButton";
import Pagination from "../common/pagination";
import Table from "../common/table";

import utilities from "../../utils/utilities";

const FacilityTypeTable = ({
  facilityType,
  loading,
  search,
  onCountChange,
}) => {
  const [sortColumn, setSortColumn] = useState({ path: "id", order: "asc" });
  const [currentPage, setCurrentPage] = useState(1);

  const pageSize = 10;

  const paginatedData = () => {
    let filtered = facilityType;

    if (search)
      filtered = facilityType.filter((item) =>
        item.name.toLowerCase().startsWith(search.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const paginated = utilities.paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, paginated };
  };

  const columns = [
    {
      path: "id",
      label: "ID",
    },
    {
      path: "category",
      label: "Category",
    },
  ];

  const { totalCount, paginated } = paginatedData();

  useEffect(() => {
    onCountChange(totalCount);

    // eslint-disable-next-line
  }, [totalCount]);

  return (
    <React.Fragment>
      <div className="activity-feed fade-in">
        <LoadingButton
          visible={loading}
          message="Fetching Facility Types... Please wait"
        />
        {!loading && (
          <React.Fragment>
            <Table
              columns={columns}
              data={paginated}
              sortColumn={sortColumn}
              onSort={setSortColumn}
            />

            <div className="sb-pagination-container">
              <Pagination
                itemsCount={totalCount}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          </React.Fragment>
        )}

        {paginated.length === 0 && (
          <div className="no-items">
            <p>No types to show</p>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default FacilityTypeTable;
