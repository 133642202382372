import { ButtonProps } from "../../ts-utils/interfaces";

const Button = (props: ButtonProps): JSX.Element => {
  let { disabled, icon, label, style, type, ...rest } = props;

  return (
    <>
      <button
        className="app-btn px-4"
        style={{
          background:
            label === "Next"
              ? "#114b29"
              : label === "Prev"
              ? "#172b4d"
              : label === "Close" || label === "No"
              ? "#ff0000"
              : "",
          ...style,
        }}
        disabled={disabled}
        type={type}
        {...rest}
      >
        {icon && <i className={"fa fa-" + icon}></i>}
        {label}
      </button>
    </>
  );
};

export default Button;
