import React from "react";

interface NavigationItemProps {
  badge?: JSX.Element;
  className: string;
  onClick?: () => void;
  icon: string;
  label: string;
  isVisible: boolean;
}

const NavigationItem = (props: NavigationItemProps) => {
  if (!props.isVisible) return null;

  return (
    <li className={props.className} onClick={props.onClick}>
      <i className={"fa fa-" + props.icon}></i>
      <span>{props.label}</span>
      {props.badge}
    </li>
  );
};

export default NavigationItem;
