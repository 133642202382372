import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import utilitiesInstance from "../../../../utils/utilities";
const OrderTracker = ({ orderStatus }: any) => {
  const allNull = Object.values(orderStatus).every((value) => value === null);

  if (allNull) {
    return (
      <div className="text-center h-screen flex justify-center items-center w-full">
        <p className="text-lg text-gray-500 text-center">
          Tracking Details Not Available. <br /> Please Try Again Later.
        </p>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center ">
      {Object.keys(orderStatus).map((step, index) => (
        <div className="flex justify-center items-center " key={index}>
          <div className="flex justify-center items-center gap-20  p-8">
            <div className="flex items-center justify-center">
              <div
                className={`p-4 text-center shadow-md rounded-full ${
                  orderStatus[step] !== null ? "bg-white-500" : "bg-gray-300"
                }`}
              >
                <FaCheckCircle
                  className={`text-2xl ${
                    orderStatus[step] !== null
                      ? "text-green-500"
                      : "text-gray-500"
                  }`}
                  size={40}
                />
              </div>
            </div>
          </div>
          <div
            className={`text-md font-semibold ${
              orderStatus[step] !== null ? "text-darkGray" : ""
            }`}
          >
            <p
              className={`text-md font-semibold ${
                orderStatus[step] !== null ? "text-darkGray" : "text-gray-300"
              }`}
            >
              {step === "recieved"
                ? "Order Received"
                : step === "preparing"
                ? "Preparing Order"
                : step === "way"
                ? "Order In Transit"
                : step === "delivered"
                ? "Order Delivered"
                : step}
            </p>
            {/* Display formatted date and time */}
            {orderStatus[step] !== null ? (
              <>
                <p
                  className={`text-sm ${
                    orderStatus[step] !== null ? "text-darkGray" : ""
                  }`}
                >
                  {utilitiesInstance.formatTimestamp(orderStatus[step])?.day} |{" "}
                  {
                    utilitiesInstance.formatTimestamp(orderStatus[step])
                      ?.formattedTime
                  }
                </p>
              </>
            ) : (
              <p className="text-sm text-gray-300">date/time not available</p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default OrderTracker;
