import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../../components/blocks/header";
import RadioButton from "../../../components/common/radio";

import toastService from "../../../services/toastService";
import Modal from "../../../components/common/modal";
import LoadingButton from "../../../components/common/loadingButton";
import ProgressLoader from "../../../components/common/progressLoader";
import Select from "../../../components/common/select";
import { ReactComponent as UploadIcon } from "../../../assets/svg/upload.svg";
import LogVisit from "../../../components/modals/logVisit";
import ScheduleForm from "../../../components/forms/scheduleForm";
import {
  useCloseAppointment,
  useGetSinglePendingRequest,
} from "../../../hooks/sales/useAppointment";
const RequestInfo = () => {
  const history = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const request_id = searchParams.get("id");
  const { pitchInfo, isPending } = useGetSinglePendingRequest(request_id);

  const [meetingSummary, setMeetingSummary] = useState("");
  const [offerReason, setOfferReason] = useState("");
  const [summary, setSummary] = useState(null);
  const [progress, setProgress] = useState(0);
  const [page, setPage] = useState(1);
  const [statusType, setStatusType] = useState("");
  const [offerType, setOfferType] = useState("");
  const [offerList, setOfferList] = useState("");
  const [objectionType, setObjectionType] = useState("");

  const [logData, setLogData] = useState("");

  const [offerFile, setOfferFile] = useState(null);
  const [offerName, setOfferName] = useState("");

  const [slaFile, setSlaFile] = useState(null);
  const [slaName, setSlaName] = useState("");

  const [address, setAddress] = useState(null);

  const [onboard, setOnboard] = useState("");

  const navigate = (path) => {
    history(path);
  };

  localStorage.setItem("skippedLog", pitchInfo.pitch_method);

  const handleOnboard = (e) => {
    setOnboard(e.target.value);
  };

  const viewSummary = (value) => {
    setSummary(value);
  };

  const handleProgressChange = (progress) => {
    setProgress(progress);
    localStorage.setItem("lastname", pitchInfo);
    localStorage.setItem("name", JSON.stringify(pitchInfo.id));
  };

  const { mutate } = useCloseAppointment(handleProgressChange);

  const postData = async () => {
    const data = new FormData();
    if (statusType === "Yes") {
      data.append("summary", meetingSummary);
      data.append("grade", onboard);
      data.append("key", statusType);
      data.append("id", pitchInfo.id);
      data.append("sla", slaFile);
      data.append("location", logData.location);
    } else if (statusType === "Maybe" && offerType === "Yes") {
      data.append("summary", meetingSummary);
      data.append("grade", onboard);
      data.append("key", statusType);
      data.append("id", pitchInfo.id);
      data.append("sla", slaFile);
      data.append("location", logData.location);
      data.append("offer", offerFile);
      data.append("offer_type", offerList);
      data.append("offer_status", offerType);
    } else if (offerType === "No" && statusType === "Maybe") {
      data.append("summary", meetingSummary);
      data.append("grade", onboard);
      data.append("key", statusType);
      data.append("id", pitchInfo.id);
      data.append("location", logData.location);
      data.append("offer_status", offerType);
      data.append("reason", offerReason);
    } else if (objectionType === "No" && statusType === "No") {
      data.append("summary", meetingSummary);
      data.append("grade", onboard);
      data.append("key", statusType);
      data.append("id", pitchInfo.id);
      data.append("location", logData.location);
    } else if (
      objectionType === "Yes" &&
      statusType === "No" &&
      offerType === "Yes"
    ) {
      data.append("summary", meetingSummary);
      data.append("grade", onboard);
      data.append("key", statusType);
      data.append("id", pitchInfo.id);
      data.append("sla", slaFile);
      data.append("location", logData.location);
      data.append("offer", offerFile);
      data.append("offer_type", offerList);
      data.append("offer_status", offerType);
    } else if (
      objectionType === "Yes" &&
      statusType === "No" &&
      offerType === "No"
    ) {
      data.append("summary", meetingSummary);
      data.append("grade", onboard);
      data.append("key", statusType);
      data.append("id", pitchInfo.id);
      data.append("location", logData.location);
      data.append("offer_status", offerType);
      data.append("reason", offerReason);
    }

    mutate(data, {
      onSuccess: () => {
        if (offerType !== "No") {
          setPage(4);
        }
        if (offerType === "No" || objectionType === "No") {
          navigate("/pipeline?content=close");
        }
      },

      onError: (e) => {
        toastService.error(
          e?.response?.data?.message ||
            e?.message ||
            "Could not process this information!"
        );
      },
    });
    // const res = await appointmentService.closeAppointment(
    //   data,
    //   handleProgressChange
    // );
    // if (res.data.status !== "success") throw new Error(res.data.message);
    // if (res.data.status === "success" && offerType !== "No") {
    //   setPage(4);
    // }
    // if (
    //   res.data.status === "success" &&
    //   (offerType === "No" || objectionType === "No")
    // )
    //   navigate("/pipeline?content=close");
    // navigate("requests");
  };

  const isReserved = offerType === "No" || objectionType === "No";

  const handleClose = (e) => {
    e.preventDefault();
    toastService.promise(postData(), {
      loading: "Uploading data...",
      success: () =>
        isReserved
          ? `${pitchInfo.name} has been closed and moved to the reserved list`
          : `Data uploaded successfully, Please create active schedule for ${pitchInfo.name}`,
      error: (e) =>
        e?.response?.data?.message ||
        e?.message ||
        "Could not process this information!",
    });
  };

  useEffect(() => {
    const fetchLocationDetails = async (latitude, longitude) => {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyAg7-YYR9clrIdYVCILJ0zZvL6qDquZLYw`
      );
      const data = await response.json();
      if (data.status === "OK") {
        const results = data.results;
        if (results.length > 0) {
          setAddress(results[0].formatted_address);
        }
      }
    };
    const location = pitchInfo.location ?? ",";
    const [latitude, longitude] = location.split(",");

    fetchLocationDetails(latitude, longitude);
  }, [pitchInfo.location]);

  const handleBack = () => {
    if (page === 2) {
      setPage(1);
    } else {
      navigate("/pipeline?content=close");
    }
  };

  const handleStatusTypeChange = (e) => {
    setStatusType(e.currentTarget.value);
  };

  const handleOfferChange = (e) => {
    setOfferType(e.currentTarget.value);
  };

  const handleOfferList = (e) => {
    setOfferList(e.currentTarget.value);
  };

  const handleObjectionChange = (e) => {
    setObjectionType(e.currentTarget.value);
  };

  const handleOfferUpload = (event) => {
    const file = event.target.files[0];
    setOfferName(file.name);
    setOfferFile(file);
  };

  const handleSlaChange = (event) => {
    const file = event.target.files[0];
    setSlaName(file.name);
    setSlaFile(file);
  };

  return (
    <div>
      <ProgressLoader progress={progress} />
      <div onClick={handleBack} className="back">
        <i className={"fa fa-arrow-left"}></i> Back
      </div>
      <LoadingButton visible={isPending} message="Please wait" />

      {!isPending && (
        <>
          <Header
            title={`${page === 4 ? "Create Active Schedule for " : ""}${
              pitchInfo?.name
            }`}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              minHeight: "70vh",
            }}
          >
            {page === 1 && (
              <div className="pitch-main">
                <div className="pitch-data2">
                  <span className="label">Hospital Name:</span>
                  <span className="value">{pitchInfo.name}</span>
                </div>

                <div className="pitch-data2">
                  <span className="label">Agent:</span>
                  <span className="value">{pitchInfo.agent_name}</span>
                </div>
                <div className="pitch-data2">
                  <span className="label">Location:</span>
                  <span className="value">{pitchInfo.address}</span>
                </div>
                <div className="pitch-data2">
                  <span className="label">Pitch meeting summary</span>
                  <span
                    className="value link"
                    onClick={() =>
                      viewSummary({
                        feedback: pitchInfo.feedback,
                        pitch_note: pitchInfo.pitch_note,
                      })
                    }
                  >
                    view
                  </span>
                </div>
                <div className="pitch-data2">
                  <span className="label">Pitch location</span>
                  <span className="value">{address}</span>
                </div>

                <div className="pitch-data2">
                  <span className="label">Status</span>
                  <span className="value">{pitchInfo.onboard}</span>
                </div>

                <div className="pitch-data2">
                  <span className="label">Closing Date</span>
                  <span className="value">{`${pitchInfo.visit_date} | ${pitchInfo.visit_time} `}</span>
                </div>

                {/* <div className="custom-buttons"> */}
                <div className="flex items-center justify-center mt-3">
                  <button
                    className="custom-btn transition duration-500 ease-in-out bg-[#441AB7] text-white hover:bg-[#9575e7]  transform hover:-translate-y-1 hover:scale-110 px-4 rounded-md py-2"
                    onClick={() => setPage(2)}
                  >
                    Log your location
                  </button>
                </div>

                {/* </div> */}
              </div>
            )}

            {page === 2 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: "70%",
                  minHeight: "70vh",
                }}
              >
                <LogVisit
                  handleClose={() => {
                    setPage(1);
                  }}
                  // selectedProspect={log}
                  changePage={() => setPage(3)}
                  setLogData={setLogData}
                />
              </div>
            )}

            {page === 3 && (
              <form className="pitch-main">
                <div className="pitch-data2">
                  <span className="label">Grade Self</span>{" "}
                  {/* <span className="label">Grade your agent</span> */}
                  <div className="value">
                    <RadioButton
                      name="1"
                      value="1"
                      message="1"
                      onChange={handleOnboard}
                      checked={onboard === "1"}
                    />
                    <RadioButton
                      name="2"
                      value="2"
                      message="2"
                      onChange={handleOnboard}
                      checked={onboard === "2"}
                    />
                    <RadioButton
                      name="3"
                      value="3"
                      message="3"
                      onChange={handleOnboard}
                      checked={onboard === "3"}
                    />
                    <RadioButton
                      name="4"
                      value="4"
                      message="4"
                      onChange={handleOnboard}
                      checked={onboard === "4"}
                    />
                    <RadioButton
                      name="5"
                      value="5"
                      message="5"
                      onChange={handleOnboard}
                      checked={onboard === "5"}
                    />
                  </div>
                </div>

                <div className="pitch-data2">
                  <span className="label">Close Meeting Summary</span>
                  <textarea
                    id="summary"
                    name="summary"
                    onChange={(e) => setMeetingSummary(e.target.value)}
                    placeholder="Meeting Summary"
                    rows={3}
                    cols={50}
                    className="form-field-input-text outline p-1 rounded-md w-full outline-gray-300"
                  />
                </div>
                <div className="pitch-data2">
                  <span className="label">
                    Would they like to use LifeBank?
                  </span>
                  <div className="right-options">
                    <Select
                      placeholder="Select Status"
                      options={statusTypes}
                      value={statusType}
                      onChange={handleStatusTypeChange}
                    />
                  </div>
                </div>

                {statusType === "No" && (
                  <div className="pitch-data2">
                    <span className="label">Did the objection sheet help?</span>
                    <div className="value">
                      <Select
                        placeholder="Select "
                        options={objectionTypes}
                        value={objectionType}
                        onChange={handleObjectionChange}
                      />
                    </div>
                  </div>
                )}

                {statusType === "Yes" && (
                  <div className="pitch-data2">
                    <span className="label">Upload SLA</span>
                    <div className="value">
                      <div className="upload">
                        <label htmlFor="Image" className="upload-label">
                          {slaFile ? (
                            <img
                              src={URL.createObjectURL(slaFile)}
                              alt={slaName}
                            />
                          ) : (
                            <>
                              <UploadIcon className="upload-icon" />
                            </>
                          )}
                        </label>
                        <input
                          name="sla"
                          type="file"
                          id="SLA"
                          accept=".jpeg, .png, .jpg, .pdf"
                          className="upload-input"
                          onChange={handleSlaChange}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {(statusType === "Maybe" || objectionType === "Yes") && (
                  <div className="pitch-data2">
                    <span className="label">Did they accept your offer?</span>
                    <div className="value">
                      <Select
                        placeholder="Select Status"
                        options={offerTypes}
                        value={offerType}
                        onChange={handleOfferChange}
                      />
                    </div>
                  </div>
                )}

                {offerType === "No" && (
                  <div className="pitch-data2">
                    <span className="label">Why did they say "No"?</span>
                    <textarea
                      id="reason"
                      name="reason"
                      onChange={(e) => setOfferReason(e.target.value)}
                      placeholder="Reason for rejecting the offer"
                      rows={4}
                      cols={50}
                      className="form-field-input-text outline p-2 rounded-md w-full outline-gray-300"
                    />
                  </div>
                )}

                {offerType === "Yes" && statusType !== "Yes" && (
                  <div className="pitch-data2">
                    <span className="label">
                      Select offer and upload evidence
                    </span>
                    <div className="value">
                      <Select
                        placeholder="Select Offer"
                        options={offerLists}
                        value={offerList}
                        onChange={handleOfferList}
                      />

                      <div>
                        <div className="upload">
                          <label htmlFor="Image" className="upload-label">
                            {offerFile ? (
                              <img
                                src={URL.createObjectURL(offerFile)}
                                alt={offerName}
                              />
                            ) : (
                              <>
                                <UploadIcon className="upload-icon" />
                              </>
                            )}
                          </label>
                          <input
                            name="offer"
                            type="file"
                            id="Offer"
                            accept=".jpeg, .png, .jpg, .pdf"
                            className="upload-input"
                            onChange={handleOfferUpload}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {statusType !== "Yes" && offerType === "Yes" && (
                  <div className="pitch-data2">
                    <span className="label">Upload SLA</span>
                    <div className="value">
                      <div className="upload">
                        <label htmlFor="Image" className="upload-label">
                          {slaFile ? (
                            <img
                              src={URL.createObjectURL(slaFile)}
                              alt={slaName}
                            />
                          ) : (
                            <>
                              <UploadIcon className="upload-icon" />
                            </>
                          )}
                        </label>
                        <input
                          name="sla"
                          type="file"
                          id="SLA"
                          accept=".jpeg, .png, .jpg, .pdf"
                          className="upload-input"
                          onChange={handleSlaChange}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="custom-buttons mx-auto py-3">
                  <button
                    className="custom-btn transition duration-500 ease-in-out bg-[#441AB7] text-white hover:bg-[#9575e7] py-2 px-4 rounded-md active:translate-y-2 active:scale-95"
                    onClick={handleClose}
                    disabled={!meetingSummary || !onboard || !statusType}
                    style={{
                      background:
                        (!meetingSummary || !onboard || !statusType) && "gray",
                    }}
                  >
                    {isReserved
                      ? "Close and send to reserve list"
                      : "Proceed to create active schedule"}
                  </button>
                </div>
              </form>
            )}

            {page === 4 && (
              <ScheduleForm hospital_id={pitchInfo.hospital_id} width={true} />
            )}

            {summary && (
              <Modal handleClose={() => setSummary(null)}>
                <div className="row">
                  <div className="pitch-data2">
                    <span className="label">Feedback:</span>
                    <span className="value"> {summary.feedback}</span>
                  </div>

                  <div className="pitch-data2">
                    <span className="label">Pitch Note:</span>
                    <span className="value"> {summary.pitch_note}</span>
                  </div>
                </div>
              </Modal>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default RequestInfo;

const statusTypes = [
  { id: "Yes", name: "Yes" },
  { id: "Maybe", name: "Maybe" },
  { id: "No", name: "No" },
];

const offerTypes = [
  { id: "Yes", name: "Yes" },
  { id: "No", name: "No" },
];

const offerLists = [
  { id: "Data Reward", name: "Data Reward" },
  { id: "Registration Reward", name: "Registration Reward" },
];

const objectionTypes = [
  { id: "Yes", name: "Yes" },
  { id: "No", name: "No" },
];
