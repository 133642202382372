import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";
import "./css/index.css";
import { Toaster } from "react-hot-toast";

import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { store } from "./redux/store";
import { Provider } from "react-redux";

import OfflineNotice from "./components/blocks/offlineNotice";
import { GlobalContextProvider } from "./contexts/globalContext";
import { RouterProvider } from "react-router-dom";
import router from "./routes";
import { QueryProvider } from "./services/react-query/queryProvider";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement!);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryProvider>
        <ReactQueryDevtools initialIsOpen={false} />
        <GlobalContextProvider>
          <OfflineNotice />
          <Toaster />
          <RouterProvider router={router} />
        </GlobalContextProvider>
      </QueryProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
