import { PipelineItem } from "../../ts-utils/interfaces";

type Props = {
  activeCategory?: PipelineItem
  onActiveChange?: (item: PipelineItem) => void
  pipelineItems: Array<PipelineItem>
}

const GeneralArrowBox = ({ activeCategory, onActiveChange, pipelineItems }: Props): JSX.Element => {


  return (
    <ul id="breadcrumb">
      {pipelineItems.map((pipelineItem: PipelineItem) => {

        const isActive: boolean =
          pipelineItem.tag.toLowerCase() === activeCategory?.tag?.toLowerCase();
        return (
          <li
            key={pipelineItem.title}
            className={isActive ? "active" : "inactive"}
            style={{width: `calc(100%/${pipelineItems.length})`}}
            onClick={() => onActiveChange?.(pipelineItem)}
          >
            <span className="arrowLeft" style={{borderColor: pipelineItem.color, borderLeftColor: "transparent"}}></span>
            <p className="flex gap-2 font-medium" style={{backgroundColor: pipelineItem.color}}>
              <i className={"fa fa-" + pipelineItem.icon}></i>{" "}
              <span>{pipelineItem.title}</span>
            </p>
            <span className="arrowRight" style={{borderLeftColor: pipelineItem.color}}></span>
          </li>
        );
      })}
    </ul>
  );
};


export default GeneralArrowBox;
