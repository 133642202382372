import React from "react";
import DOMPurify from "dompurify";

const EmailContent = ({ htmlContent }: { htmlContent: string }) => {
  const sanitizedContent = DOMPurify.sanitize(htmlContent);

  return <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />;
};

export default EmailContent;
