import React, { useEffect, useState } from "react";
 
import Header from "../../../components/blocks/header";

import authService from "../../../services/authService"; 

import AddAppointmentForm from "../../../components/forms/addAppointmentForm";
import SalesAppointmentTable from "../../../components/tables/salesAppointmentTable";

const SalesAppointments = ({ appointments }) => {
  const [category, setCategory] = useState("0");
  const [search] = useState("");
  const [status] = useState("");
  const [searchKey] = useState("name");
  // const [hospitalsCount, setHospitalsCount] = useState(0);
  const [addHospital, setAddHospital] = useState(false);

  const privileges = authService.getPrivileges();
  const handleAddHospital = () => {
    setAddHospital((prev) => !prev);
  };

  const handleCategories = () => {
    const newCategories = [];

    const allHospitals = { id: "0", name: "All Hospitals" };
    const myHospitals = { id: "1", name: "My Hospitals" };

    const initialPrivilege = privileges.allHospitals
      ? allHospitals.id
      : myHospitals.id;

    if (privileges.allHospitals) newCategories.push(allHospitals);
    if (privileges.myHospitals) newCategories.push(myHospitals);

    setCategory(initialPrivilege);
  };

  useEffect(() => {
    handleCategories();

    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <Header title={`New Leads Appointments`} />

      {privileges.salesAppointments && addHospital && (
        <AddAppointmentForm handleClose={handleAddHospital} />
      )}

      <div className="hospitals-container">
        <div className="hospitals-container-bottom table-responsive">
          <SalesAppointmentTable
            appointments={appointments}
            category={category}
            search={search}
            status={status}
            searchKey={searchKey}
            // onCategoryChange={(count) => setHospitalsCount(count)}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default SalesAppointments;
