import React from "react";
import { DangerMasteryData } from "./types";
import { Control, Controller, FieldErrors } from "react-hook-form";
import InputField from "../../common/input";
import RadioButton from "../../common/radio";
import CustomField from "../../common/CustomField";

type Props = {
  control: Control<DangerMasteryData, any>;
  errors: FieldErrors<DangerMasteryData>;
  setLogData: React.Dispatch<
    React.SetStateAction<{
      location: string;
    }>
  >;
  logData: any;
  position: any;
  setPosition: any;
};

export default function MissedMaintenanceLog({
  control,
  errors,
  logData,
  setLogData,
  position,
  setPosition,
}: Props) {
  return (
    <>
      <CustomField
        title="Date"
        style={{ paddingTop: "10px" }}
        value={
          <Controller
            control={control}
            name={`missed_maintenance.date`}
            render={({ field: { onChange, value, name } }) => (
              <InputField
                style={{ width: "100%", border: "none", background: "#f1f1f1" }}
                autoFocus={false}
                name={name}
                onChange={onChange}
                placeholder="date"
                value={value}
                type="dateTime-local"
                error={errors?.missed_maintenance?.date?.message || null}
              />
            )}
          />
        }
      />
      <CustomField
        title="Name of Hospital Agent"
        style={{ paddingTop: "10px" }}
        value={
          <Controller
            control={control}
            name={`missed_maintenance.hospital_agent`}
            render={({ field: { onChange, value, name } }) => (
              <InputField
                style={{ width: "100%", border: "none", background: "#f1f1f1" }}
                autoFocus={false}
                name={name}
                onChange={onChange}
                placeholder="John Doe"
                value={value}
                type="text"
                error={
                  errors?.missed_maintenance?.hospital_agent?.message || null
                }
              />
            )}
          />
        }
      />

      <>
        <CustomField
          title={
            <span className="font-semibold">
              Questions to Ask Sales Agent :
            </span>
          }
          style={{ paddingTop: "10px" }}
        />
        <CustomField
          title="Have you placed an order?"
          value={
            <Controller
              control={control}
              name={`missed_maintenance.placed_order`}
              render={({ field: { onChange, value, name } }) => (
                <div className="flex gap-4">
                  <RadioButton
                    name={name}
                    onChange={onChange}
                    value="1"
                    checked={value === "1"}
                    message="Yes"
                  />
                  <RadioButton
                    name={name}
                    onChange={onChange}
                    value="0"
                    checked={value === "0"}
                    message="No"
                  />
                </div>
              )}
            />
          }
        />
        <CustomField
          title="Is there a reason why you have not placed an order"
          style={{ paddingTop: "10px" }}
          value={
            <Controller
              control={control}
              name={`missed_maintenance.reason_for_no_order`}
              render={({ field: { onChange, value, name } }) => (
                <InputField
                  style={{
                    width: "100%",
                    border: "none",
                    background: "#f1f1f1",
                  }}
                  autoFocus={false}
                  name={name}
                  onChange={onChange}
                  placeholder="type question here"
                  value={value}
                  type="text"
                  error={
                    errors?.missed_maintenance?.reason_for_no_order?.message ||
                    null
                  }
                />
              )}
            />
          }
        />
        <CustomField
          title="Do you have any issues with our Products or Service"
          style={{ paddingTop: "10px" }}
          value={
            <Controller
              control={control}
              name={`missed_maintenance.issue_with_product`}
              render={({ field: { onChange, value, name } }) => (
                <InputField
                  style={{
                    width: "100%",
                    border: "none",
                    background: "#f1f1f1",
                  }}
                  autoFocus={false}
                  name={name}
                  onChange={onChange}
                  placeholder="type question here"
                  value={value}
                  type="text"
                  error={
                    errors?.missed_maintenance?.issue_with_product?.message ||
                    null
                  }
                />
              )}
            />
          }
        />
        <CustomField
          title="Is there anything else I can help you with"
          style={{ paddingTop: "10px" }}
          value={
            <Controller
              control={control}
              name={`missed_maintenance.anything_to_help`}
              render={({ field: { onChange, value, name } }) => (
                <InputField
                  style={{
                    width: "100%",
                    border: "none",
                    background: "#f1f1f1",
                  }}
                  autoFocus={false}
                  name={name}
                  onChange={onChange}
                  placeholder="type question here"
                  value={value}
                  type="text"
                  error={
                    errors?.missed_maintenance?.anything_to_help?.message ||
                    null
                  }
                />
              )}
            />
          }
        />
      </>
    </>
  );
}
