import React from 'react';


interface button {
    text: string,
    onClick?: () => void,
    icon?: any;

}

const ExodusButton = ({ text, onClick, icon }: button) => {

    return (
        <div className="bg-[#4D26A0] hover:bg-[#9166ee] text-[#fff] text-center h-[50px] w-full flex items-center justify-center rounded cursor-pointer" onClick={onClick}>
            <p className="font-bold">{text}</p>
            <p className="text-[30px] flex pl-2 pr-2">{icon}</p>
        </div>
    )
}

export default ExodusButton;