import React, { useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../components/blocks/header";
import appointmentService from "../../../services/appointmentService";
// import toastService from "../../services/toastService";
import LoadingButton from "../../../components/common/loadingButton";

import Nerve from "./checklist/Nerve";
import Email from "./checklist/Email";
import Training from "./checklist/Training";
import SLA from "./checklist/SLA";
import Drip from "./checklist/Drip";

const OnboardChecklist = () => {
  const history = useNavigate();

  const params = useParams();
  console.log(params);

  // const [pitchInfo, setPitchInfo] = useState({});
  const [checklist, setChecklist] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [step, setStep] = useState(1);

  const info = window.sessionStorage.getItem("checklist");

  const selectedPitch = JSON.parse(info);

  const navigate = (path) => {
    history(path);
  };

  const getChecklist = async () => {
    try {
      setIsLoading(true);
      const response = await appointmentService.getOnboardChecklist(
        selectedPitch.hospital_id
      );
      const result = response.data?.data;

      setChecklist(result);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useLayoutEffect(() => {
    // getInfo();
    getChecklist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div
        onClick={() => navigate("/pipeline?content=onboard")}
        className="back"
      >
        <i className={"fa fa-arrow-left"}></i> Back
      </div>
      <LoadingButton visible={isLoading} message="Please wait" />

      {!isLoading && (
        <>
          <Header title={`${selectedPitch?.name} Checklist`} />
          <div className="navigation">
            {[1, 2, 3, 4, 5].map((n) => (
              <div key={n} className="navigation_container">
                {n > 1 && <span className="navigation_container_line"></span>}
                <p
                  className={
                    step >= n
                      ? "navigation_container_active"
                      : "navigation_container_step"
                  }
                >
                  {n}
                </p>
              </div>
            ))}
          </div>
          <form className="checklist-main">
            {step === 1 && (
              <Nerve
                selectedPitch={selectedPitch}
                selectedResponse={checklist}
                handleNext={() => setStep(2)}
              />
            )}

            {step === 2 && (
              <Training
                selectedPitch={selectedPitch}
                selectedResponse={checklist}
                handleNext={() => setStep(3)}
                handlePrev={() => setStep(1)}
              />
            )}
            {step === 3 && (
              <Email
                selectedPitch={selectedPitch}
                selectedResponse={checklist}
                handleNext={() => setStep(4)}
                handlePrev={() => setStep(2)}
              />
            )}

            {step === 4 && (
              <SLA
                selectedPitch={selectedPitch}
                selectedResponse={checklist}
                handleNext={() => setStep(5)}
                handlePrev={() => setStep(3)}
              />
            )}

            {step === 5 && (
              <Drip
                selectedPitch={selectedPitch}
                selectedResponse={checklist}
                handlePrev={() => setStep(4)}
              />
            )}
          </form>
        </>
      )}
    </div>
  );
};

export default OnboardChecklist;
