import React from "react";
import { FacilitySize, FacilityType } from "../../ts-utils/interfaces";

import HospitalFeedback from "../blocks/feedback";
import Modal from "../common/modal";

const ProspectedInfo = (props: {
  facilitySize: FacilitySize[];
  facilityType: FacilityType[];
  selectedProspect: any;
  handleClose: () => void;
}) => {
  const { facilitySize, facilityType, selectedProspect, handleClose } = props;

  const getFacilityType = (): FacilityType => {
    return facilityType?.find((f: FacilityType) => {
      return f.id === selectedProspect.facility;
    }) as FacilityType;
  };

  const getFacilitySize = (): FacilitySize => {
    return facilitySize?.find((f: FacilitySize) => {
      return f.id === selectedProspect.hospital_size;
    }) as FacilitySize;
  };

  return (
    <Modal handleClose={handleClose}>
      <h3 className="modal-title">Facility Information</h3>

      <ul className="complaint-modal-items no-list">
        <li className="complaint-modal-items-single">
          <i className="fa fa-th-large"></i> <span>Facility Type:</span>{" "}
          {getFacilityType()?.name || "Not Recorded"}
        </li>
        <li className="complaint-modal-items-single">
          <i className="fa fa-hospital-o"></i> <span>Facility Name:</span>{" "}
          {selectedProspect.name}
        </li>
        <li className="complaint-modal-items-single">
          <i className="fa fa-envelope-o"></i>{" "}
          <span>Current Email Address:</span> Changed to{" "}
          <span className="update">{selectedProspect.email || "N/A"}</span> from{" "}
          <span className="update">{selectedProspect.old_email || "N/A"}</span>
        </li>
        <li className="complaint-modal-items-single">
          <i className="fa fa-phone"></i> <span>Current Phone Number:</span>{" "}
          Changed to{" "}
          <span className="update">{selectedProspect.phone || "N/A"}</span> from{" "}
          <span className="update">{selectedProspect.old_phone || "N/A"}</span>
        </li>
        <li className="complaint-modal-items-single">
          <i className="fa fa-home"></i> <span>Current Facility Address:</span>{" "}
          Changed to{" "}
          <span className="update">{selectedProspect.address || "N/A"}</span>{" "}
          from{" "}
          <span className="update">
            {selectedProspect.old_address || "N/A"}
          </span>
        </li>
        <li className="complaint-modal-items-single">
          <i className="fa fa-sitemap"></i> <span>Facility Size:</span>{" "}
          {getFacilitySize()?.name || "Not Recorded"}
        </li>
        <li className="complaint-modal-items-single">
          <i className="fa fa-user-o"></i> <span>Contact Name:</span>{" "}
          {selectedProspect.contact_name}
        </li>
        <li className="complaint-modal-items-single">
          <i className="fa fa-bed"></i> <span>Number of Beds:</span>{" "}
          {selectedProspect.bed}
        </li>
        <li className="complaint-modal-items-single">
          <i className="fa fa-twitter"></i> <span>Social Media Profile:</span>{" "}
          {selectedProspect.socials}
        </li>

        <HospitalFeedback hospitalId={selectedProspect.id} />
      </ul>
    </Modal>
  );
};

export default ProspectedInfo;
