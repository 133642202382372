import React from "react";
import BackButton from "../backbutton";
import { Control, Controller, FieldErrors } from "react-hook-form";
import InputField from "../../common/input";
import { NewlyActiveMasteryData } from "./types";
import Button from "../../common/button";
import CustomField from "../../common/CustomField";
import RadioButton from "../../common/radio";

type Props = {
  setCallInfo: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      key:
        | "weekOne"
        | "weekTwo"
        | "weekThree"
        | "weekFour"
        | "weekFive"
        | "weekSix"
        | "weekSeven"
        | "weekEight"
        | "weekNine"
        | "weekTen"
        | "weekEleven"
        | "weekTwelve";
    }>
  >;
  control: Control<any>;
  errors: FieldErrors<NewlyActiveMasteryData>;
  objKey:
    | "weekOne"
    | "weekTwo"
    | "weekThree"
    | "weekFour"
    | "weekFive"
    | "weekSix"
    | "weekSeven"
    | "weekEight"
    | "weekNine"
    | "weekTen"
    | "weekEleven"
    | "weekTwelve";
  submit: {
    submitCallInfo: (data: any) => void;
  };
  isLoading: boolean;
};

export default function NewlyActiveMasteryCallLog({
  setCallInfo,
  control,
  errors,
  objKey,
  submit,
  isLoading,
}: Props) {
  return (
    <React.Fragment>
      <BackButton
        goBack={() => {
          setCallInfo({ show: false, key: objKey });
        }}
      />
      <div className="my-8">
        <CustomField
          title="Date"
          style={{ paddingTop: "10px" }}
          value={
            <Controller
              control={control}
              name={`date`}
              render={({ field: { onChange, value, name } }) => (
                <InputField
                  style={{
                    width: "100%",
                    border: "none",
                    background: "#f1f1f1",
                  }}
                  autoFocus={false}
                  name={name}
                  onChange={onChange}
                  placeholder="date"
                  value={value}
                  type="dateTime-local"
                  error={errors?.[objKey]?.date?.message || null}
                />
              )}
            />
          }
        />
        <CustomField
          title="Name of Hospital Agent"
          style={{ paddingTop: "10px" }}
          value={
            <Controller
              control={control}
              name={`hospital_agent`}
              render={({ field: { onChange, value, name } }) => (
                <InputField
                  style={{
                    width: "100%",
                    border: "none",
                    background: "#f1f1f1",
                  }}
                  autoFocus={false}
                  name={name}
                  onChange={onChange}
                  placeholder="John Doe"
                  value={value}
                  type="text"
                  error={errors?.[objKey]?.hospital_agent?.message || null}
                />
              )}
            />
          }
        />

        <CustomField
          title={
            <span className="font-semibold">
              Questions to Ask Sales Agent :
            </span>
          }
          style={{ paddingTop: "10px" }}
        />
        <CustomField
          title="Have you placed an order?"
          style={{ paddingTop: "10px" }}
          value={
            <Controller
              control={control}
              name="placed_order"
              render={({ field: { onChange, value, name } }) => (
                <div className="flex justify-end gap-4">
                  <RadioButton
                    name={name}
                    onChange={onChange}
                    value="1"
                    checked={value === "1"}
                    message="Yes"
                  />
                  <RadioButton
                    name={name}
                    onChange={onChange}
                    value="0"
                    checked={value === "0"}
                    message="No"
                  />
                </div>
              )}
            />
          }
        />
        <CustomField
          title="Are you satisfied Nerve?"
          style={{ paddingTop: "10px" }}
          value={
            <Controller
              control={control}
              name="satisfied_with_nerve"
              render={({ field: { onChange, value, name } }) => (
                <div className="flex justify-end gap-4">
                  <RadioButton
                    name={name}
                    onChange={onChange}
                    value="1"
                    checked={value === "1"}
                    message="Yes"
                  />
                  <RadioButton
                    name={name}
                    onChange={onChange}
                    value="0"
                    checked={value === "0"}
                    message="No"
                  />
                </div>
              )}
            />
          }
        />
        <CustomField
          title="Have you experienced late delivery with any of your orders"
          style={{ paddingTop: "10px" }}
          value={
            <Controller
              control={control}
              name="late_delivery"
              render={({ field: { onChange, value, name } }) => (
                <div className="flex justify-end gap-4">
                  <RadioButton
                    name={name}
                    onChange={onChange}
                    value="1"
                    checked={value === "1"}
                    message="Yes"
                  />
                  <RadioButton
                    name={name}
                    onChange={onChange}
                    value="0"
                    checked={value === "0"}
                    message="No"
                  />
                </div>
              )}
            />
            // <Controller
            //   control={control}
            //   name={`late_delivery`}
            //   render={({ field: { onChange, value, name } }) => (
            //     <InputField
            //       style={{
            //         width: "100%",
            //         border: "none",
            //         background: "#f1f1f1",
            //       }}
            //       autoFocus={false}
            //       name={name}
            //       onChange={onChange}
            //       placeholder="Very close"
            //       value={value}
            //       type="text"
            //       error={errors?.[objKey]?.late_delivery?.message || null}
            //     />
            //   )}
            // />
          }
        />
        <CustomField
          title="Do you have any complaints or concerns"
          style={{ paddingTop: "10px" }}
          value={
            <Controller
              control={control}
              name={`complaints`}
              render={({ field: { onChange, value, name } }) => (
                <InputField
                  style={{
                    width: "100%",
                    border: "none",
                    background: "#f1f1f1",
                  }}
                  autoFocus={false}
                  name={name}
                  onChange={onChange}
                  placeholder="Matron"
                  value={value}
                  type="text"
                  error={errors?.[objKey]?.complaints?.message || null}
                />
              )}
            />
          }
        />
      </div>
      <Button
        disabled={isLoading}
        type="button"
        label={isLoading ? "Saving..." : "Save call log"}
        onClick={() => {
          submit.submitCallInfo(control);
        }}
      />
    </React.Fragment>
  );
}
