import React from "react";
import { useNavigate } from "react-router-dom";

const MarketingTop = (props) => {
  const history = useNavigate();

  const navigate = (path) => {
    history(path);
  };

  return (
    <div className="marketing-top">
      {labels.map((l, index) => {
        return (
          <div
            key={index}
            className="labels"
            onClick={() => {
              l === "hospital stories"
                ? navigate(`/story`)
                : l === "tools"
                ? navigate(`/tool`)
                : navigate(`/${l.replaceAll(" ", "-")}`);
            }}
          >
            {l}
          </div>
        );
      })}
    </div>
  );
};

export default MarketingTop;

const labels = ["events", "tools", "hospital stories", "appointments"];
