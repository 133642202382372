import React, { useState } from "react";
// @ts-ignore
import Joi from "joi-browser";

import LoadingButton from "../common/loadingButton";
import Modal from "../common/modal";

import toastService from "../../services/toastService";
import { JSX } from "react/jsx-runtime";

import { useAddFacilityTypes } from "../../hooks/useHospital";

const AddFacilityType = (
  props: JSX.IntrinsicAttributes & { facilityType: any; handleClose: any }
) => {
  const { addFacilityTypes, isPending } = useAddFacilityTypes();
  const [data, setData] = useState<{ [key: string]: any }>({
    facility_type: "",
  });
  const [errors, setErrors] = useState<{ [key: string]: any }>({});

  const schema = {
    facility_type: Joi.string()
      .trim()
      .regex(/^[a-zA-Z_ ]+$/, "facility type")
      .required()
      .label("Facility Type"),
  };

  const getSizeMatch = (value: string) => {
    try {
      const { facilityType } = props;
      return facilityType.find((s: { category: string }) => {
        return s?.category?.toLowerCase?.()?.startsWith(value.toLowerCase());
      });
    } catch (error) {
      return null;
    }
  };

  const validateProperty = (input: { name: string; value: any }) => {
    if (input.name === "facility_type") {
      const { error } = Joi.validate(input.value, schema.facility_type);
      return error ? error.details[0].message : null;
    }
  };

  const handleChange = ({
    currentTarget: input,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const newData: { [key: string]: any } = { ...data };
    const newErrors: { [key: string]: any } = { ...errors };

    const sizeMatch = getSizeMatch(input.value);
    const errorMessage = validateProperty(input);

    newData[input.name] = input.value;
    newErrors[input.name] = sizeMatch
      ? `Match Found: ${sizeMatch.category}!`
      : errorMessage;

    setData(newData);
    setErrors(newErrors);
  };

  const postData = async () => {
    addFacilityTypes(data, {
      onSuccess: (data) => {
        props.handleClose();
      },
    });
  };

  const doSubmit = () => {
    toastService.promise(postData(), {
      loading: "Uploading data...",
      success: () => "Type Added Successfully!",
      error: (e: { response: { data: { message: any } }; message: any }) =>
        e?.response?.data?.message ||
        e?.message ||
        "Could not add facility type!",
    });
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors || {});
    if (errors) return;

    doSubmit();
  };
  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(data, schema, options);
    if (!error) return null;

    const errors: { [key: string]: any } = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };
  const renderInput = ({
    name,
    label,
    value,
    type = "text",
    readOnly = false,
    placeholder,
    compulsory = false,
    onChange = handleChange,
    error,
  }: {
    name: string;
    label: string;
    value: string;
    type?: string;
    readOnly?: boolean;
    placeholder: string;
    compulsory?: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    error: { [key: string]: any };
  }) => (
    <div className="form-group mb-3 space-y-3 px-2">
      <label htmlFor={name}>
        {compulsory && <i className="fa fa-asterisk error"></i>} {label}
      </label>
      <input
        value={data[name]}
        onChange={handleChange}
        id={name}
        type={type}
        name={name}
        className="form-control "
        readOnly={readOnly}
        placeholder={placeholder}
      />
      {errors[name] && (
        <div className="alert alert-danger mt-3">{errors[name]}</div>
      )}
    </div>
  );

  const renderButton = (label: string) => (
    <button disabled={!!validate()} className="btn btn-primary">
      {label}
    </button>
  );
  return (
    <Modal {...props}>
      <form onSubmit={handleSubmit} className="flex flex-col gap-3">
        <h2 className="form-header">
          Fields marked <i className="fa fa-asterisk error"></i> are compulsory
        </h2>

        {renderInput({
          type: "text",
          name: "facility_type",
          placeholder: "e.g. Hospital",
          label: "Facility Type",
          compulsory: true,
          value: data.facility_type,
          onChange: handleChange,
          error: errors.facility_type,
        })}

        <div className="flex justify-end">
          <LoadingButton visible={isPending} message="Please wait" />
          {!isPending && renderButton("Add")}
        </div>
      </form>
    </Modal>
  );
};

export default AddFacilityType;
