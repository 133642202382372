import React from "react";
import { useNavigate } from "react-router";
import { BiLeftArrowAlt } from "react-icons/bi";

interface ExodusHeaderProps {
  Text: string;
  subText: string;
}

const ExodusHeader = ({ Text, subText }: ExodusHeaderProps) => {
  const history = useNavigate();

  const handleback = () => {
    history(-1);
  };

  return (
    <div className="bg-[#49209F]  flex flex-row text-center items-center justify-between text-white p-4 rounded">
      <p className="text-6xl cursor cursor-pointer" onClick={handleback}>
        <BiLeftArrowAlt />
      </p>
      <div className="text-center flex-grow">
        <p className="text-4xl font-bold mb-2">{Text}</p>
        <p className="text-2xl capitalize">{subText}</p>
      </div>
    </div>
  );
};

export default ExodusHeader;
