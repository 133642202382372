import React from "react";

const ProgressLoader = ({ progress = 0 }) => {
  return (
    <div className="progress-loader">
      <div
        className="progress-loader-inner"
        style={{ width: progress + "%" }}
      ></div>
    </div>
  );
};

export default ProgressLoader;
