import { useState, useMemo } from "react";
// @ts-ignore
const useSortableTable = (data, defaultSortBy = null, defaultSortDirection = "asc"):any => {
    const [sortBy, setSortBy] = useState(defaultSortBy);
    const [sortDirection, setSortDirection] = useState(defaultSortDirection);
    const handleSort = (columnName: any) => {
        if (sortBy === columnName) {
            setSortDirection((prevDirection) =>
                prevDirection === "asc" ? "desc" : "asc"
            );
        } else {
            setSortBy(columnName);
            setSortDirection("asc");
        }
    };
    const sortedData = useMemo(() => {
        if (sortBy) {
            const sortedItems = [...data].sort((a, b) => {
                const aValue = a[sortBy];
                const bValue = b[sortBy];
                if (aValue < bValue) return sortDirection === "asc" ? -1 : 1;
                if (aValue > bValue) return sortDirection === "asc" ? 1 : -1;
                return 0;
            });
            return sortedItems;
        } else {
            return data;
        }
    }, [data, sortBy, sortDirection]);
    return {
        sortedData,
        sortBy,
        sortDirection,
        handleSort,
    };
};
export default useSortableTable;