import React from "react";
import Button from "../../../../components/common/button";

const Nerve = (props) => {
  const { selectedPitch, selectedResponse, handleNext } = props;
  return (
    <div>
      <div className="pitch-data">
        <span className="label">
          Does {selectedPitch?.name} have a Nerve account?
        </span>
        <span
          className={
            selectedResponse?.Nerve?.status === "Yes" ? "nerve" : "no-nerve"
          }
        >
          {selectedResponse?.Nerve?.status}{" "}
          <i
            className={
              selectedResponse?.Nerve?.status === "Yes"
                ? "fa fa-check yes"
                : "fa fa-times no"
            }
          ></i>
        </span>
      </div>
      <div className="checklist-button">
        {selectedResponse?.Nerve?.status === "Yes" && (
          <Button label="Next" onClick={handleNext} />
        )}
      </div>
    </div>
  );
};

export default Nerve;
