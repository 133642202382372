import React from "react";

interface VideoButtonProps {
  onClick: () => void;
}

const VideoButton = ({ onClick }: VideoButtonProps) => {
  return (
    <div className="download-link">
      <button onClick={onClick}>View Tutorial</button>
      <i className="fa fa-play-circle"></i>
    </div>
  );
};

export default VideoButton;
