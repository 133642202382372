import Appoint from "./appointment";

const Appointments = () => {
  return (
    <>
      <Appoint />
    </>
  );
};

export default Appointments;
