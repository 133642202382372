import React from "react";
import _ from "lodash";
const TableBody = ({ data, columns }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const modifiedData = data.map((item) => {
    if (item.created) {
      item.created = formatDate(item.created);
    }
    if (item.date) {
      item.date = formatDate(item.date);
    }
    if (item.releasedate) {
      item.date = formatDate(item.releasedate);
    }
    return item;
  });
  const renderCell = (item, column) => {
    if (column.content) return column.content(item);

    return _.get(item, column.path);
  };
  const createKey = (column) => {
    return _.uniqueId() + (column.path || column.key);
  };
  return (
    <tbody>
      {modifiedData.map((item, index) => (
        <tr key={index.toString()}>
          {columns.map((column) => (
            <td data-label={column.label} key={createKey(column)}>
              {(renderCell(item, column) ?? "N/A") ||
                (renderCell(item, column) === ""
                  ? "N/A"
                  : renderCell(item, column))}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};
export default TableBody;
