import React from "react";
import Joi from "joi-browser";

import Form from "../common/form";
import ProgressLoader from "../common/progressLoader";

import { deleteCountryUrl } from "../../services/countryService";
import { login } from "../../services/authService";
import { promise as toastPromise } from "../../services/toastService";

import navigationRoutes from "../../routes/navigationRoutes";

class LoginForm extends Form {
  state = {
    data: {
      username: "",
      password: "",
      rememberMe: false,
    },
    errors: {},
    progress: 0,
  };

  schema = {
    username: Joi.string().email().required().label("Email Address"),
    password: Joi.string().min(4).required().label("Password"),
    rememberMe: Joi.boolean()
      .default(false)
      .required()
      .label("Keep me logged in"),
  };

  changeCountry = () => {
    deleteCountryUrl();
    window.localStorage.removeItem("userType");
    window.location.href = navigationRoutes.selectCountry;
  };

  getErrorMessage = (ex) => {
    return ex?.response?.data?.message ?? ex.message ?? "Could not sign in";
  };

  handleProgressChange = (progress) => {
    this.setState({ progress });
  };

  postData = async () => {
    const { username, password, rememberMe } = this.state.data;
    await login(username, password, rememberMe, this.handleProgressChange);

    window.location = navigationRoutes.home;
  };

  doSubmit = () => {
    toastPromise(this.postData(), {
      loading: "Authenticating...",
      success: () => "Authenticated Successfully",
      error: this.getErrorMessage,
    });
  };

  render() {
    const { handlePush } = this.props;

    return (
      <React.Fragment>
        <ProgressLoader progress={this.state.progress} />

        <form onSubmit={this.handleSubmit}>
          {this.renderInput({
            type: "text",
            name: "username",
            placeholder: "e.g. johndoe@gmail.com",
            label: "Email Address",
          })}

          {this.renderInput({
            type: "password",
            name: "password",
            placeholder: "Enter password",
            label: "Password",
          })}

          <div className="mt-3">
            {this.renderCheckbox({
              name: "rememberMe",
              message: "Keep me signed in",
            })}
          </div>

          {this.renderButton("Sign in")}

          <div className="d-flex justify-content-between">
            <button
              type="button"
              className="change-country"
              onClick={this.changeCountry}
            >
              <i className="fa fa-flag"></i>
              Change Country
            </button>

            <button
              type="button"
              className="change-country text-decoration-underline"
              onClick={handlePush}
            >
              {/* <i className="fa fa-flag"></i> */}
              Forgot Password?
            </button>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default LoginForm;
