import httpService from "./httpService";

const getProductList = () => {
  return httpService.get("/products/list");
};

export const addProduct = (data, handleProgressChange) => {
  const options = {
    onUploadProgress: (progressEvent) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  return httpService.post("products/add", data, options);
};

const productService = {
  addProduct,
  getProductList,
};

export default productService;
