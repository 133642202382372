const config = {
  oneHospitalAPKUrl: process.env.REACT_APP_APK_DOWNLOAD,
  oneHospitalApiUrl: process.env.REACT_APP_OH_API_URL,
  liteApiUrl: process.env.REACT_APP_LITE_API_URL,
  countriesApiUrl: process.env.REACT_APP_COUNTRIES_API_URL,
  tokenKey: process.env.REACT_APP_OH_TOKEN,
  emailJsApiUrl: process.env.REACT_APP_EMAILJS_API_URL,
  emailJsServiceID: process.env.REACT_APP_EMAILJS_SERVICE_ID,
  emailJsTemplateID: process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
  emailJsUserID: process.env.REACT_APP_EMAILJS_USER_ID,
  gaTrackingId: process.env.REACT_APP_GA_TRACKING_ID,
  phonePattern(country) {
    return country === "nigeria" ? /^[0-9]{11}$/ : /^[0-9]{10}$/;
  },
};

export default Object.freeze(config);
