import React, { useState } from "react";
import utilities from "../../../utils/utilities";
import _ from "lodash";

import Header from "../../../components/blocks/header";
import Pagination from "../../../components/common/pagination";
import PDFButton from "../../../components/blocks/pdfButton";

const Support = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const pageSize = 9;

  const getPaginatedData = () => {
    const sorted = _.orderBy(documents, ["id"], ["asc"]);
    const paginated = utilities.paginate(sorted, currentPage, pageSize);

    return { totalCount: documents.length, paginated };
  };

  const { totalCount, paginated } = getPaginatedData();

  return (
    <React.Fragment>
      <Header title="Support Documents" />

      <div className="support-container">
        {paginated.map((document) => (
          <div className="support-container-item" key={document.id}>
            <div className="support-container-item-inner">
              <div className="inner-container">
                <h4>{document.title}</h4>
                <p>{document.subtitle}</p>

                <PDFButton title={document.title} href={document.href} />
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="sb-pagination-container">
        <Pagination
          itemsCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </React.Fragment>
  );
};

const documents = [
  {
    id: 1,
    href: "/documents/lifebank_product_mastery.pdf",
    subtitle:
      "Download the LifeBank product mastery document to learn more about LifeBank product(s)",
    title: "Lifebank Product Mastery",
  },
  {
    id: 2,
    href: "/documents/airbank_product_mastery.pdf",
    subtitle:
      "Download the Airbank product mastery document to learn more about Airbank by LifeBank",
    title: "Airbank Product Mastery",
  },
  {
    id: 3,
    href: "/documents/stockbank_product_mastery.pdf",
    subtitle:
      "Download the Stockbank product mastery document to learn more about Stockbank by LifeBank",
    title: "Stockbank Product Mastery",
  },
  {
    id: 4,
    href: "https://drive.google.com/file/d/146SfzfKTdJuzFpMiUs5vHwty8XHmSYYC/view?usp=sharing",
    subtitle: "Download the LifeBank Nigeria Flier",
    title: "LifeBank Flier Nigeria",
  },

  {
    id: 5,
    href: "https://drive.google.com/file/d/1HjnWPFNIHerVOCnp12HmSRZ6KpaItFgQ/view?usp=sharing",
    subtitle: "Download the front page of the StockBank Nigeria flier",
    title: "StockBank Flier Nigeria (Front)",
  },
  {
    id: 6,
    href: "https://drive.google.com/file/d/1ZhPwIfXPET8sYikeK7NjTzFiuSdlGlca/view?usp=sharing",
    subtitle: "Download the back page of the StockBank Nigeria flier",
    title: "StockBank Flier Nigeria (Back)",
  },
  {
    id: 7,
    href: "https://drive.google.com/file/d/1VOUNJaRHr56eijmrouGBOF7cQXH9H1Fk/view?usp=sharing",
    subtitle: "Download the AirBank Nigeria flier",
    title: "AirBank Flier Nigeria",
  },
  {
    id: 8,
    href: "https://drive.google.com/file/d/1tzyWF7a9o-nn2cPzEt-lE0gHrqZKuQXo/view?usp=sharing",
    subtitle: "Download the ColdBank Ethiopia flier",
    title: "ColdBank Flier Ethiopia",
  },
  {
    id: 9,
    href: "https://drive.google.com/file/d/1j1YlUVVeUG0czJ4ml2jDAkmXhUaKGcX_/view?usp=sharing",
    subtitle: "Download the LifeBank Kenya Flier",
    title: "LifeBank Flier Kenya",
  },
  {
    id: 10,
    href: "https://drive.google.com/file/d/1812rxtxJJY846WlcTjTPOKdMFX_lnf1f/view?usp=sharing",
    subtitle: "Download the LabBank Kenya Flier",
    title: "LabBank Flier Kenya",
  },
  {
    id: 11,
    href: "https://drive.google.com/file/d/1TnX6UmAvZqC4EOL2XoJ08-lOnxmqKMKh/view?usp=sharing",
    subtitle: "Download the front page of the StockBank Flier Kenya",
    title: "StockBank Flier Kenya (Back)",
  },
  {
    id: 12,
    href: "https://drive.google.com/file/d/1k1GB-6KcBgm-sKeUv66bOf915lT4VHgO/view?usp=sharing",
    subtitle: "Download the back page of the StockBank Flier Kenya",
    title: "StockBank Flier Kenya (Back)",
  },
];

export default Support;
