import { CustomInputElementProps } from "../../ts-utils/interfaces";

const InputField = (props: CustomInputElementProps): JSX.Element => {
  const {
    type,
    currencyFormat,
    name,
    label,
    error,
    compulsory,
    value,
    disabled,
    placeholder,
    readOnly = false,
    listOptions = [],
    style,
    autoFocus = true,
    ...rest
  } = props;

  return (
    <div className="form-field w-full">
      {label && (
        <label htmlFor={name} className="form-field-label">
          {compulsory && <i className="fa fa-asterisk error"></i>} {label}:
        </label>
      )}

      {/* {editing.value ? ( */}
      <input
        autoFocus={autoFocus}
        className="form-field-input"
        name={name}
        id={name}
        autoComplete="off"
        type={type}
        value={value}
        placeholder={placeholder}
        // onBlur={editing.toggleEditing}
        readOnly={readOnly}
        disabled={disabled}
        style={style}
        {...rest}
      />
      {/* ) : (
        <ProxyInput
          onClick={editing.toggleEditing}
          value={value}
          placeholder={placeholder}
          currencyFormat={currencyFormat}
        />
      )} */}

      {rest.list && (
        <datalist id={rest.list}>
          {listOptions.map((option: string, index: number) => (
            <option key={index} value={option} />
          ))}
        </datalist>
      )}
      <p className={`error-message ${error ? "opacity-100" : "opacity-0"}`}>
        {error || "error message"}
      </p>
    </div>
  );
};

export default InputField;
