import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import utils from "../../utils/utilities";
import { CartItem, CartState } from "../../ts-utils/interfaces";
const cartSlice = createSlice({
  name: "cart",
  initialState: {
    id: utils.makeid(),
    items: [],
    hospital_id: "",
  } satisfies CartState as CartState,
  reducers: {
    addToCart: (state, action: PayloadAction<CartItem>) => {
      state.items.push({
        ...action.payload,
        qty: 1,
      });
    },
    removeFromCart: (state, action: PayloadAction<string>) => {
      return {
        id: state.id,
        items: state.items.filter((item) => item.id !== action.payload),
        hospital_id: state.hospital_id,
      };
    },
    clearCart: () => {
      return { id: utils.makeid(), items: [], hospital_id: "" };
    },
    incrementItemQuantity: (state, action: PayloadAction<string>) => {
      const itemId = action.payload;
      const itemToUpdate = state.items.find((item) => item.id === itemId);
      if (itemToUpdate) {
        (itemToUpdate.qty as number) += 1;
      }
    },
    decrementItemQuantity: (state, action: PayloadAction<string>) => {
      const itemId = action.payload;
      const itemToUpdate = state.items.find((item) => item.id === itemId);
      if (itemToUpdate && (itemToUpdate.qty as number) > 1) {
        (itemToUpdate.qty as number) -= 1;
      }
    },
    addToCartMultiple: (state, action: PayloadAction<CartItem[]>) => {
      const itemsToAdd = action.payload;
      itemsToAdd.forEach((item) => {
        state.items.push({ ...item });
      });
    },
    updateItemQuantity: (
      state,
      action: PayloadAction<{ id: string; qty: number }>
    ) => {
      const { id, qty } = action.payload;
      const itemToUpdate = state.items.find((item) => item.id === id);
      if (itemToUpdate) {
        itemToUpdate.qty = qty;
      }
    },
    updateItemBrand: (
      state,
      action: PayloadAction<{ id: string; brand: string }>
    ) => {
      const { id, brand } = action.payload;
      const itemToUpdate = state.items.find((item) => item.id === id);
      if (itemToUpdate) {
        itemToUpdate.brand = brand;
      }
    },
    updateItemPacks: (
      state,
      action: PayloadAction<{ id: string; quantityType: string }>
    ) => {
      const { id, quantityType } = action.payload;
      const itemToUpdate = state.items.find((item) => item.id === id);
      if (itemToUpdate) {
        itemToUpdate.quantityType = quantityType;
      }
    },
    addHospitalId: (state, action: PayloadAction<string>) => {
      state.hospital_id = action.payload;
    },
  },
});

export const {
  addToCart,
  removeFromCart,
  clearCart,
  decrementItemQuantity,
  incrementItemQuantity,
  addToCartMultiple,
  updateItemQuantity,
  updateItemBrand,
  updateItemPacks,
  addHospitalId,
} = cartSlice.actions;

export default cartSlice.reducer;
