import React from "react";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

interface CustomDatePickerProps {
  selectedDate: Date | null;
  onDateChange: (date: Date | null) => void;
  minDate?: Date;
  maxDate?: Date;
  label: string;
  compulsory?: boolean;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = (props) => {
  return (
    <div className="form-field">
      <p className="date-picker-label">
        {props.compulsory && <i className="fa fa-asterisk error"></i>}
        {props.label}
      </p>

      <DatePicker
        selected={props.selectedDate}
        minDate={props.minDate}
        maxDate={props.maxDate}
        onChange={props.onDateChange}
      />
    </div>
  );
};

export default CustomDatePicker;
