import httpService from "./httpService";

export const getProspectedHospitals = (city, id) => {
  return httpService.get("/manage/verified/hospitals/" + city + "/" + id);
};

export const getUnprospectedHospitals = (city, id) => {
  return httpService.get("/manage/unverified/hospitals/" + city + "/" + id);
};

export const getNerveClaims = (city) => {
  return httpService.get("/manage/hospitals/claim/account/" + city);
};

export const getUnassignedHospitals = (city) => {
  return httpService.get("/manage/unassigned/hos/" + city);
};

export const updateHospital = (data, handleProgressChange) => {
  const options = {
    onUploadProgress: (progressEvent) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  return httpService.put("/manage/hospitals/update", data, options);
};

export const validateHospital = (data, handleProgressChange) => {
  const options = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    onUploadProgress: (progressEvent) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  return httpService.put("/manage/hospitals/verification", data, options);
};

export const confirmClaim = (data, handleProgressChange) => {
  const options = {
    onUploadProgress: (progressEvent) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  return httpService.post(
    "/manage/hospitals/claim/account/now/",
    data,
    options
  );
};

const prospectService = {
  confirmClaim,
  getNerveClaims,
  getUnassignedHospitals,
  getProspectedHospitals,
  getUnprospectedHospitals,
  updateHospital,
  validateHospital,
};

export default prospectService;
