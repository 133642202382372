import { useEffect, useState, useCallback } from "react";
import { ApiResponse as Response, Appointment } from "../../ts-utils/types";

import utilities from "../../utils/utilities";

import appointmentService from "../../services/appointmentService";
import toastService from "../../services/toastService";

import { useGlobalContext } from "../../contexts/globalContext";

function withRequests(Component: any): (props: any) => JSX.Element {
  return function WithRequests(props): JSX.Element {
    const [requests, setRequests] = useState<Appointment>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { user } = useGlobalContext();

    const getAppointments = useCallback(async (): Promise<void> => {
      try {
        setIsLoading(true);

        const response: Response = await appointmentService.getPendingRequests(
          user?.city
        );
        const result: any = response.data;
        const data: any[] = utilities.mapKeyToNumber(result.data, "id");

        setRequests(data);
        setIsLoading(false);
      } catch (ex: any) {
        const message: string = ex?.response?.data?.description ?? ex.message;
        toastService.error(message);

        setIsLoading(false);
      }
    }, [user?.city]);

    useEffect(() => {
      getAppointments();
      // clean up
      return () => {
        setRequests([]);
      };
    }, [getAppointments]);

    return <Component requests={requests} loading={isLoading} {...props} />;
  };
}

export default withRequests;
