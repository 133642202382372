const applyFilters = (
  filters: { [s: string | number]: unknown } | ArrayLike<unknown>,
  history: any,
  path: any
) => {
  const queryParams = new URLSearchParams();
  Object.entries(filters).forEach(([key, value]) => {
    if (value !== null && value !== undefined && value !== "") {
      queryParams.append(key, String(value));
    }
  });

  history(`/${path}?${queryParams.toString()}`);
};

export default applyFilters;
