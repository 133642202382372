import React, { useState } from "react";
import config from "../../../utils/config.js";
import navigationRoutes from "../../../routes/navigationRoutes";

import { useNavigate } from "react-router-dom";
import AuthenticationLayout from "./Layout";

const lists = ["Marketing", "Sales", "Customer Success"];
const salesType = [
  "Key Account Agent",
  "Customer Acquisition Agent",
  "Sales Admin",
];
const UserType = () => {
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState(null);

  const handleUser = (data) => {
    setSelectedUser(data);
    if (data !== "Sales") {
      window.localStorage.setItem("userType", data);
      navigate(navigationRoutes.login);
    }
  };

  const handleSalesType = (type) => {
    window.localStorage.setItem("userType", type);
    navigate(navigationRoutes.login);
  };
  return (
    <AuthenticationLayout>
      <div className="main-usertype">
        <p className="header">Login as</p>
        {selectedUser === "Sales" ? (
          <div className="usertype">
            {salesType.map((type) => (
              <div
                className="usertype-sales"
                key={type}
                onClick={() => handleSalesType(type)}
              >
                {type}
              </div>
            ))}
          </div>
        ) : (
          <div className="usertype">
            {lists.map((l) => (
              <div
                className="usertype-sales"
                key={l}
                onClick={() => handleUser(l)}
              >
                {l}
              </div>
            ))}
          </div>
        )}
        <div className="download-apk">
          <p>
            Download APK
            <a
              href={config.oneHospitalAPKUrl}
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              Here
            </a>
          </p>
        </div>
      </div>
    </AuthenticationLayout>
  );
};

export default UserType;
