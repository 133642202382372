import React, { useState, useMemo } from "react";

import ChangePassword from "../forms/changePassword";
import SendFeedback from "../forms/sendFeedback";
import ArrowBoxes from "./arrowBoxes";

import { useGetDashboard } from "../../hooks/useDashboard";
import { useGetPipeline } from "../../hooks/usePipeline";

import { useGlobalContext } from "../../contexts/globalContext";

export const Footer = () => {
  const { user } = useGlobalContext();
  const { dashboard } = useGetDashboard(user?.id ?? "");

  const { pipeline, isPending } = useGetPipeline("", user?.id);

  const AssignedCount = useMemo(() => {
    if (!isPending) {
      return pipeline?.pitches?.filter(
        (item: { sub_status: string }) => item?.sub_status === "To be contacted"
      )?.length;
    }
    return 0;
  }, [isPending, pipeline?.pitches]);

  const PitchedCount = useMemo(() => {
    if (!isPending) {
      return pipeline?.pitches?.filter(
        (item: { sub_status: string }) => item?.sub_status !== "To be contacted"
      )?.length;
    }
    return 0;
  }, [isPending, pipeline?.pitches]);

  const uniquePipeline = (pipeline: any[]) => {
    return pipeline?.filter(
      (v: { id: any; hospital_id: any }, i: any, a: any[]) => {
        const hasSameId = a.findIndex((t: { id: any }) => t.id === v.id) === i;
        const hasSameHospitalId = v.hospital_id
          ? a.findIndex(
              (t: { hospital_id: any }) => t.hospital_id === v.hospital_id
            ) === i
          : true;
        return hasSameId && hasSameHospitalId;
      }
    );
  };

  const uniqueCloseCount = useMemo(() => {
    if (!isPending) {
      return uniquePipeline(pipeline?.close)?.length;
    }
    return 0;
  }, [isPending, pipeline?.close]);

  const uniqueExodusCount = useMemo(() => {
    if (!isPending) {
      return uniquePipeline(pipeline?.exodus)?.length;
    }
    return 0;
  }, [isPending, pipeline?.exodus]);

  const uniqueActiveCount = useMemo(() => {
    if (!isPending) {
      return uniquePipeline(pipeline?.active)?.length;
    }
    return 0;
  }, [isPending, pipeline?.active]);

  const uniqueOnboardCount = useMemo(() => {
    if (!isPending) {
      return uniquePipeline(pipeline?.onboard)?.length;
    }
    return 0;
  }, [isPending, pipeline?.onboard]);

  // const uniqueImportedCount = useMemo(() => {
  //   if (!isPending) {
  //     return uniquePipeline(pipeline?.preexodus).length;
  //   }
  //   return 0;
  // }, [isPending, pipeline?.preexodus]);

  const [changePassword, setChangePassword] = useState<boolean>(false);
  const [sendFeedback, setSendFeeback] = useState<boolean>(false);
  const [activeCategory, setActiveCategory] = useState(initialCategory);

  const handleFeedback = (): void => {
    setSendFeeback(!sendFeedback);
  };

  const handlePassword = (): void => {
    setChangePassword(!changePassword);
  };

  const currentYear: number = new Date().getFullYear();

  return (
    <React.Fragment>
      {changePassword && <ChangePassword handleClose={handlePassword} />}

      {sendFeedback && <SendFeedback handleClose={handleFeedback} />}

      {user?.position === "sales_rep" && (
        <div>
          <ArrowBoxes
            activeCategory={activeCategory}
            onActiveChange={(category: any) => setActiveCategory(category)}
            data={{
              assign: AssignedCount,
              // assign: dashboard?.assign,
              // pitch: dashboard?.pitch,
              // pitch: PitchedCount,
              pitch: dashboard?.pitch,
              // close: uniqueCloseCount,
              close: dashboard?.close,
              // exodus: uniqueExodusCount,
              exodus: dashboard?.exodus,
              // active: uniqueActiveCount,
              active: dashboard?.active,
              // onboard: uniqueOnboardCount,
              onboard: dashboard?.onboard,
              imported: dashboard?.preexodus,
            }}
          />
        </div>
      )}

      <div className="dashboard-footer">
        <div className="dashboard-footer-left">
          {currentYear} &copy; OneHospital Dashboard
        </div>

        <div className="dashboard-footer-right">
          <button onClick={handlePassword} className="change-password">
            Change Password
          </button>

          <button onClick={handleFeedback} className="send-feedback">
            Send Feedback
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

const initialCategory = {
  title: "New",

  tag: "new_hospitals",

  color: "#424200",

  sortKey: "created_at",
};

// console.log(initialCategory);
