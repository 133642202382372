import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

interface CustomTextEditorProps {
  onChange: (value: string) => void;
}

const CustomTextEditor = forwardRef(
  ({ onChange }: CustomTextEditorProps, ref) => {
    const [editorState, setEditorState] = useState(() =>
      EditorState.createEmpty()
    );
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
      setIsMounted(true);
      return () => {
        setIsMounted(false);
      };
    }, []);

    const handleChange = (e: EditorState) => {
      if (!isMounted) return;
      setEditorState(e);
      const inputValue = draftToHtml(convertToRaw(e.getCurrentContent()));
      onChange(inputValue);
    };

    useImperativeHandle(ref, () => ({
      getEditorState: () => editorState,
    }));

    return (
      <Editor
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={handleChange}
        placeholder="Enter Email message"
      />
    );
  }
);

export default CustomTextEditor;
