import React, { useEffect, useState } from "react";
import Header from "../../../components/blocks/header";
import pipelineService from "../../../services/pipelineService";
import toastService from "../../../services/toastService";
import { useGlobalContext } from "../../../contexts/globalContext";

const MessageLeadPage = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useGlobalContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await pipelineService.cancelledorders(user.id);
        setMessages(response.data.actions);
        setLoading(false);
      } catch (error) {
        toastService.error(
          "You currently don't have any pending cancelled orders"
        );
        setLoading(false);
      }
    };

    fetchData();
  }, [user.id, setMessages, setLoading]);

  //   const handleButtonClick = async (messageId) => {
  //     try {
  //       // Uncomment the following lines when you have the corresponding service method
  //       // const response = await pipelineService.readMessages(messageId);
  //       // toastService.success("This has been read");
  //       // window.location.reload();
  //     } catch (error) {
  //       console.error("Error fetching message:", error);
  //     }
  //   };

  return (
    <React.Fragment>
      <Header title="Your Hospital that Cancelled This Month" />
      <div className="score-card">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <table>
            <thead>
              <tr>
                <th>Hospital</th>
                <th>Date</th>
                <th>Reason</th>
                <th>Product</th>
                <th>Quantity</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {messages.map((message) => (
                <tr key={message.id}>
                  <td>{message.order_by}</td>
                  <td>{message.time}</td>
                  <td>{message.reason}</td>
                  <td>{message.product_type}</td>
                  <td>{message.qty}</td>
                  <td>
                    {/* <button onClick={() => handleButtonClick(message.id)}>
                      Read
                    </button> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </React.Fragment>
  );
};

export default MessageLeadPage;
