const navigationRoutes = {
  home: "/home",
  hospitals: "/hospitals",
  userType: "/user-type",
  login: "/login",
  forgotPassword: "/forgot-password",
  logout: "/logout",
  clear_sub: "/clear-sub",
  prospectOrders: "/prospects/:hospitalId/orders",
  myScore: "/my-score/:agentId/:agentUsername",
  selectCountry: "/select-country",
  homeRoutes: [
    "/active-hospitals",
    "/active-schedules",
    // "/active-feed",
    "/maintenance",
    "/home",
    "/hospitals",
    "/events",
    "/create-event",
    "/appointments",
    "/tool",
    "/story",
    "/dazzles",
    "/drip",
    "/prospects",
    "/add-hospital",
    "/hospitals/:id",
    "/hospitals/:id/log",
    "/hospitals/log/call",
    "/support",
    "/requests",
    "/complaints",
    "/add-complaint",
    "/leads",
    "/pitch",
    "/info",
    "/nerve-claims",
    "/request-info",
    "/onboard-info",
    "/health-score",
    "/pipeline",
    "/onboard",
    "/checklist/:id",
    "/facility",
    "/products",
    "/documents",
    "/tutorials",
    "/mymessages",
    "/message-lead/:hospitalId/:hospitalname",
    "/prospects/:hospitalId/orders",
    "/my-score/:agentId/:agentUsername",
    "/nerve-usage",
    "/cancelled-orders",
    "/exodus",
    "/exodus-list",
    "/single-exodus/:id",
    "/hospitals/single-exodus/order",
    "/exodus-activites",
    "/exodus-complains",
    "/exodus-contact",
    "/exodus-call",
    "/hospitals/exodus-send-drip",
    "/hospitals/exodus-send-a-drip",
    "/hospitals/exodus-log-a-contact",
    "/hospitals/exodus-send-note",
    "/hospital-review-active-schedule",
    "/",
  ],
};

export default Object.freeze(navigationRoutes);
