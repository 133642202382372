import React, { useCallback, useMemo, useState } from "react";

import Header from "../../../../components/blocks/header";

import ProgressLoader from "../../../../components/common/progressLoader";

import {
  useGetStory,
  useCreateStory,
} from "../../../../hooks/marketing/useStory";
import DataTable from "../../../../components/tables/tableEvents/data-table";

import toastService from "../../../../services/toastService";
import { MoreVertical, Eye } from "lucide-react";
import { Button } from "../../../../@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../@/components/ui/form";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../@/components/ui/dropdown-menu";
import { Checkbox } from "../../../../@/components/ui/checkbox";
import LoadingButton from "../../../../components/common/loadingButton";
import Modal from "../../../../components/modals/mod/Modal";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { CalendarIcon, ReloadIcon } from "@radix-ui/react-icons";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../@/components/ui/select";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../@/components/ui/popover";
import { cn } from "../../../../@/lib/utils";
import { Calendar } from "../../../../@/components/ui/calendar";
import { addDays, format } from "date-fns";
import { Input } from "../../../../@/components/ui/input";
import dayjs from "dayjs";
import TransitionsModal from "../../../../components/modals/mod/CustomModal";

const handleCopyClick = (url: any) => {
  navigator.clipboard
    .writeText(url)
    .then(() => {
      toastService.success("URL copied to clipboard");
    })
    .catch((error) => {
      toastService.error("Failed to copy URL to clipboard");
    });
};
const requestModalStyle = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "90%", // 90% width on extra-small screens
    sm: "80%", // 80% width on small screens
    md: "60%", // 60% width on medium screens
    lg: "50%", // 50% width on large screens
    xl: "40%", // 40% width on extra-large screens
  },
  maxWidth: "800px", // Maximum width regardless of screen size
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  p: 4,
};
const Story = () => {
  const { story, isPending } = useGetStory();

  const [progress, setProgress] = useState(0);

  const [isOpen, setIsOpen] = useState(false);
  const [isViewData, setIsViewData] = useState(false);
  const [viewData, setViewData] = useState<any>(null);

  const handleCloseDataModal = () => {
    setIsViewData(false);
  };
  const openDataModal = useCallback((data: any) => {
    if (data) {
      setViewData(data);
      setIsViewData(true);
    }
  }, []);
  const handleProgressChange = (progress: any) => {
    setProgress(progress);
  };
  const columns = useMemo(
    () => [
      {
        id: "select",
        header: ({ table }: { table: any }) => (
          <Checkbox
            checked={
              table.getIsAllPageRowsSelected() ||
              (table.getIsSomePageRowsSelected() && "indeterminate")
            }
            onCheckedChange={(value) => {
              table.toggleAllPageRowsSelected(!!value);
              //   console.log(table.options.data)
            }}
            aria-label="Select all"
          />
        ),
        cell: ({ row }: { row: any }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => {
              row.toggleSelected(!!value);
              //   console.log(row.original);
            }}
            aria-label="Select row"
          />
        ),
        enableSorting: false,
        enableHiding: false,
      },
      {
        accessorKey: "name",
        header: "Name of story",
      },

      {
        accessorKey: "hero",
        header: "Story hero",
      },
      {
        accessorKey: "releasedate",
        header: "Release date",
        cell: ({ row }: { row: any }) => (
          <div
          >
            {row.getValue("releasedate") ?? "N/A"}
          </div>
        ),
      },
      {
        accessorKey: "clicks",
        header: "Clicks",
      },
      {
        accessorKey: "appointments",
        header: "Number of appointments",
      },

      {
        accessorKey: "url",
        header: " ",
        cell: ({ row }: { row: any }) => {
          const link = row.getValue("url") as string;
          const addProtocolToUrl = (url: string) => {
            if (!url.includes("http")) {
              return `https://${url}`;
            }
            return url;
          };
          return (
            <Button
              onClick={() => handleCopyClick(addProtocolToUrl(link))}
              variant="default"
              className="app-btn !rounded-lg !mt-0"
            >
              Share link
            </Button>
          );
        },
      },
      {
        accessorKey: "id",
        header: " ",
        cell: ({ row }: { row: any }) => {
          return (
            <div>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" className="h-8 w-8 p-0">
                    <span className="sr-only">Open menu</span>
                    <MoreVertical className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem
                    className="cursor-pointer"
                    onClick={() => {
                      openDataModal(row.original);
                    }}
                  >
                    <Eye className="mr-2 h-4 w-4" /> View
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          );
        },
      },
    ],
    [openDataModal]
  );

  const createStory = () => {
    setIsOpen(true);
  };

  const FormSchema = z.object({
    title: z.string().min(2, {
      message: "Enter the name of your story",
    }),
    hero: z.string().min(2, {
      message: "Enter the name of your Hero",
    }),
    releasedate: z.date({
      required_error: "A story date is required.",
    }),
  });
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    mode: "onChange",
    defaultValues: {
      title: "",
      hero: "",
      releasedate: new Date(),
    },
  });

  const { mutate, isPending: isSubmitting } =
    useCreateStory(handleProgressChange);

  const onSubmit = (data: z.infer<typeof FormSchema>) => {
    const date = dayjs(new Date(data.releasedate)).format("YYYY/MM/DD");

    const payload = {
      title: data.title,
      hero: data.hero,
      releasedate: date,
    };

    mutate(payload, {
      onSuccess: () => {
        form.reset();
        setIsOpen(false);
        // window.location.reload();
      },
    });
  };
  return (
    <React.Fragment>
      <Header title={`Hospital Stories`} />
      <>
        {isPending ? (
          <LoadingButton
            visible={isPending}
            message="Fetching Stories... Please wait"
          />
        ) : (
          <DataTable
            columns={columns}
            onClick={createStory}
            btnTitle="CREATE NEW STORY"
            data={story}
          />
        )}
      </>{" "}
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="relative space-y-14 flex flex-col items-center text-center">
          <div className="flex justify-between w-full">
            <h1 className="text-2xl font-semibold capitalize">
              Create new story
            </h1>
            <button
              onClick={() => setIsOpen(false)}
              className="absolute -top-2 right-2  text-2xl text-red-500 bg-red-50 rounded-full py-2 px-3"
            >
              &times;
            </button>
          </div>
          <div className="w-full">
            <Form {...form}>
              <ProgressLoader progress={progress} />
              <form onSubmit={form.handleSubmit(onSubmit)}>
                <div className="grid grid-cols-2 w-full gap-3 mb-5">
                  <FormField
                    control={form.control}
                    name="title"
                    render={({ field }) => (
                      <FormItem>
                        <div className="flex flex-col gap-3 w-full">
                          <FormLabel className="text-start font-semibold">
                            Name of story
                          </FormLabel>
                          <FormControl>
                            <Input
                              placeholder="Enter the name of story"
                              {...field}
                            />
                          </FormControl>
                        </div>
                        <div className="flex items-center justify-start">
                          <FormMessage />
                        </div>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="hero"
                    render={({ field }) => (
                      <FormItem>
                        <div className="flex flex-col gap-3 w-full">
                          <FormLabel className="text-start font-semibold">
                            Story hero
                          </FormLabel>
                          <FormControl>
                            <Input
                              className=""
                              placeholder="Enter the story hero"
                              {...field}
                            />
                          </FormControl>
                        </div>
                        <div className="flex items-center justify-start">
                          <FormMessage />
                        </div>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="releasedate"
                    render={({ field }) => (
                      <FormItem className="flex flex-col w-full">
                        <FormLabel className="text-start font-semibold">
                          Release date
                        </FormLabel>
                        <Popover>
                          <PopoverTrigger asChild>
                            <FormControl>
                              <Button
                                variant={"outline"}
                                className={cn(
                                  "w-[150px] justify-start text-left font-normal",
                                  !field.value && "text-muted-foreground"
                                )}
                              >
                                <CalendarIcon className="mr-2 h-4 w-4" />
                                {field.value ? (
                                  format(field.value, "PPP")
                                ) : (
                                  <span>Pick a date</span>
                                )}
                              </Button>
                            </FormControl>
                          </PopoverTrigger>

                          <PopoverContent
                            align="start"
                            className="flex w-auto flex-col space-y-2 p-2"
                          >
                            <Select
                              onValueChange={(value) =>
                                field.onChange(
                                  addDays(new Date(), parseInt(value))
                                )
                              }
                            >
                              <SelectTrigger>
                                <SelectValue placeholder="Select" />
                              </SelectTrigger>
                              <SelectContent position="popper">
                                <SelectItem value="0">Today</SelectItem>
                                <SelectItem value="1">Tomorrow</SelectItem>
                                <SelectItem value="3">In 3 days</SelectItem>
                                <SelectItem value="7">In a week</SelectItem>
                              </SelectContent>
                            </Select>
                            <div className="rounded-md border">
                              <Calendar
                                mode="single"
                                selected={field.value}
                                onSelect={field.onChange}
                              />
                            </div>
                          </PopoverContent>
                        </Popover>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <Button
                  type="submit"
                  disabled={!form.formState.isValid || isSubmitting}
                  className="w-1/2"
                >
                  {isSubmitting && (
                    <ReloadIcon className="animate-spin mr-2 h-4 w-4" />
                  )}
                  {isSubmitting ? "Please wait..." : "New Story"}
                </Button>
              </form>
            </Form>
          </div>
        </div>
      </Modal>
      {isViewData && (
        <TransitionsModal
          handleCloseModal={handleCloseDataModal}
          open={isViewData}
          setOpen={setIsViewData}
          style={requestModalStyle}
          title={"Story Information"}
        >
          <>
            <>
              <div className="w-full border rounded-md">
                <div className="flex items-center justify-between w-full border-b">
                  <p className="px-4 py-3 bg-[#A0A0A0A0] font-bold text-[0.8rem] md:text-base w-1/3 text-start rounded-tl-md">
                    Name of story:
                  </p>
                  <p className="px-4 py-3 flex-1 w-full text-start capitalize">
                    {viewData?.name}
                  </p>
                </div>
                <div className="flex items-center justify-between w-full border-b">
                  <p className="px-4 py-3 bg-[#A0A0A0A0] font-bold text-[0.8rem] md:text-base w-1/3 text-start">
                    Hospital Name:
                  </p>
                  <p className="px-4 py-3 flex-1 w-full text-start capitalize">
                    {viewData?.hospital_name ?? "N/A"}
                  </p>
                </div>
                <div className="flex items-center justify-between  w-full border-b ">
                  <p className="px-4 py-3 bg-[#A0A0A0A0] font-bold text-[0.8rem] md:text-base w-1/3 text-start">
                    Story hero:
                  </p>
                  <p className="px-4 py-3 flex-1 w-full text-start capitalize">
                    {viewData?.hero}
                  </p>
                </div>
                <div className="flex items-center justify-between  w-full border-b ">
                  <p className="px-4 py-3 bg-[#A0A0A0A0] font-bold text-[0.8rem] md:text-base w-1/3 text-start">
                    Release date:
                  </p>
                  <p className="px-4 py-3 flex-1 w-full text-start capitalize">
                    {viewData?.releasedate ?? "N/A"}
                  </p>
                </div>
                <div className="flex items-center justify-between  w-full border-b ">
                  <p className="px-4 py-3 bg-[#A0A0A0A0] font-bold text-[0.8rem] md:text-base w-1/3 text-start">
                    Clicks:
                  </p>
                  <p className="px-4 py-3 flex-1 w-full text-start capitalize">
                    {viewData?.clicks}
                  </p>
                </div>
                <div className="flex items-center justify-between  w-full border-b ">
                  <p className="px-4 py-3 bg-[#A0A0A0A0] font-bold text-[0.8rem] md:text-base w-1/3 text-start">
                    Number of appointment:
                  </p>
                  <p className="px-4 py-3 flex-1 w-full text-start capitalize">
                    {viewData?.appointments}
                  </p>
                </div>
              </div>
            </>
          </>
        </TransitionsModal>
      )}
    </React.Fragment>
  );
};

export default Story;
