import { useEffect, useState } from "react";
import { ApiResponse } from "../../ts-utils/types";

import utilities from "../../utils/utilities";

import productService from "../../services/productService";
import toastService from "../../services/toastService";

function withProductList(Component: any): (props: any) => JSX.Element {
  return function WithProductList(props: any) {
    const [isLoading, setIsLoading] = useState(false);
    const [productList, setProductList] = useState<any[]>([]);

    const getProductList = async (): Promise<void> => {
      try {
        setIsLoading(true);

        const response: ApiResponse = await productService.getProductList();
        const result = response.data as any;
        const products: any[] = utilities.mapItemsFromObjects(result.data);

        setProductList(products);
        setIsLoading(false);
      } catch (ex: any) {
        const message: string = ex?.response?.data?.description ?? ex.message;

        toastService.error(message);
        setIsLoading(false);
      }
    };

    const sortProducts = (): void => {
      productList.sort((a, b): number => {
        const nameA: string = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB: string = b.name.toUpperCase(); // ignore upper and lowercase

        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
      });
    };

    useEffect(() => {
      getProductList();
    }, []);

    useEffect(() => {
      sortProducts();

      // eslint-disable-next-line
    }, [productList]);

    return (
      <Component productList={productList} loading={isLoading} {...props} />
    );
  };
}

export default withProductList;
