import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import Modal from "../common/modal";
import ProgressLoader from "../common/progressLoader";
import { changePassword } from "../../services/agent.service";
import toastService from "../../services/toastService";
import { useGlobalContext } from "../../contexts/globalContext";

const ChangePassword = (props) => {
  const [data, setData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [progress, setProgress] = useState(0);

  const { user } = useGlobalContext();

  const schema = {
    email: Joi.string().required().label("Email"),
    password: Joi.string().min(6).required().label("Password"),
    confirmPassword: Joi.string().min(6).required().label("Confirm Password"),
  };

  useEffect(() => {
    const mapUserEmail = () => {
      setData((prevData) => ({ ...prevData, email: user.email }));
    };
    mapUserEmail();
  }, [user]);

  const handleProgressChange = (progress) => setProgress(progress);

  const postData = async () => {
    const apiData = { ...data };
    delete apiData.confirmPassword;

    const response = await changePassword(apiData, handleProgressChange);
    if (response.data.status !== "success")
      throw new Error(response.data.description);

    props.handleClose();
  };

  const doSubmit = () => {
    toastService.promise(postData(), {
      loading: "Updating Password...",
      success: () => "Password Updated Successfully",
      error: (e) =>
        e?.response?.data?.message || e.message || "Could not update password",
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validate();
    setErrors(errors || {});
    if (errors) return;

    doSubmit();
  };

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(data, schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const handleChange = ({ currentTarget: input }) => {
    const newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  };

  const renderInput = ({
    name,
    label,
    type = "text",
    readOnly = false,
    placeholder,
  }) => (
    <div className="form-group mb-3 px-2">
      <label htmlFor={name}>{label}</label>
      <input
        value={data[name]}
        onChange={handleChange}
        id={name}
        type={type}
        name={name}
        className="form-control"
        readOnly={readOnly}
        placeholder={placeholder}
      />
      {errors[name] && <div className="alert alert-danger">{errors[name]}</div>}
    </div>
  );

  const renderButton = (label) => (
    <button disabled={validate()} className="btn btn-primary">
      {label}
    </button>
  );

  return (
    <Modal {...props}>
      <ProgressLoader progress={progress} />
      <form onSubmit={handleSubmit}>
        <h3 className="modal-title text-center primary">
          Change <span className="error">Password</span>
        </h3>

        {renderInput({
          type: "email",
          name: "email",
          placeholder: "your email address",
          label: "Your Email Address",
          readOnly: true,
        })}

        {renderInput({
          type: "password",
          name: "password",
          placeholder: "Choose a password",
          label: "Password",
        })}

        {renderInput({
          type: "password",
          name: "confirmPassword",
          placeholder: "Confirm your password",
          label: "Confirm Password",
        })}

        <div className="modal-buttons">{renderButton("Update")}</div>
      </form>
    </Modal>
  );
};

export default ChangePassword;
