// Import necessary libraries
import React, { useEffect, useRef } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

function MapComponent(mapData) { 
  // Reference to the map container
  const mapContainer = useRef(null);

  // Effect hook to initialize the map
  useEffect(() => {
    const initializeMap = async () => {
      try {
        // Check if map container exists and the map has not been initialized
        if (!mapContainer.current || mapContainer.current._leaflet_id) {
          return;
        }

        // Create a Leaflet map instance
        const map = L.map(mapContainer.current).setView([7.3775, 3.947], 13);

        // Add Mapbox tile layer with street names
        L.tileLayer(
          "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
          {
            attribution:
              'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
              '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
              'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
            maxZoom: 18,
            id: "mapbox/streets-v11", // Change the map style ID as per your requirement
            tileSize: 512,
            zoomOffset: -1,
            accessToken:
              "pk.eyJ1IjoiYWRlZ2JveWVnYSIsImEiOiJjbHc5a201YWMwNTJsMm1vZWdjOHZ1bzlnIn0.VEfuKecOrNmcpQ87S72DAw", // Replace with your Mapbox access token
          }
        ).addTo(map);

        // Create a layer group for markers
        const markers = L.featureGroup();

        // Add markers to the feature group
        mapData.mapData.mapData.forEach((data) => {
          const marker = L.marker([data.latitude, data.longitude]).bindPopup(
            data.address
          );
          markers.addLayer(marker);
        });

        // Add the feature group to the map
        markers.addTo(map);

        // Fit the map to the bounds of all markers
        map.fitBounds(markers.getBounds());
      } catch (error) {
        console.error("Error initializing map:", error);
        // Handle the error gracefully, e.g., show a message to the user
      }
    };

    initializeMap();
  }, [mapData.mapData.mapData]);

  // Render the map container
  return <div ref={mapContainer} style={{ height: "100%", width: "100%" }} />;
}

// Main component to render the map component
function NigeriaMap(mapData) {
  return <MapComponent mapData={mapData} />;
}

// Export the main component
export default NigeriaMap;
