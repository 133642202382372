import { useEffect, useState } from "react";

const OfflineNotice = (): JSX.Element => {
  const [online, setOnline] = useState<boolean>(window.navigator.onLine);

  const getClassName = (): string => {
    let className: string = "offline-notice fade-in ";
    className += online ? "online" : "";

    return className;
  };

  const handleInternetCheck = (): void => {
    setOnline(navigator.onLine);
  };

  useEffect(() => {
    handleInternetCheck();
  }, []);

  useEffect(() => {
    window.addEventListener("online", handleInternetCheck);
    window.addEventListener("offline", handleInternetCheck);

    return () => {
      window.removeEventListener("online", handleInternetCheck);
      window.removeEventListener("offline", handleInternetCheck);
    };
  }, []);

  return (
    <div className={getClassName()}>
      <i className="fa fa-globe"></i>

      {online ? "You're online again!" : "No internet connection"}
    </div>
  );
};

export default OfflineNotice;
