import { ReactNode, useEffect } from "react";
import { Navigate } from "react-router-dom";

import navigationRoutes from "./navigationRoutes";
import { useGlobalContext } from "../contexts/globalContext";
import authService from "../services/authService";

const PrivilegeProtectedRoute = ({
  element,
  privilege,
}: {
  element: ReactNode;
  privilege: boolean | undefined;
}) => {
  const { country } = useGlobalContext();

  useEffect(() => {
    if (!privilege && country) {
      <Navigate to={navigationRoutes.home} replace />;
    } else if (!privilege || !country) authService.logout();
  }, [country, privilege]);
  return (
    <>
      {privilege && country ? (
        <>{element}</>
      ) : (
        <Navigate to={navigationRoutes.selectCountry} replace />
      )}
    </>
  );
};

export default PrivilegeProtectedRoute;
