import React from "react";
import { MasterLoyalData } from "./types";
import { Control, Controller, FieldErrors } from "react-hook-form";
import BackButton from "../backbutton";
import Button from "../../common/button";
import InputField from "../../common/input";
import CustomField from "../../common/CustomField";
import RadioButton from "../../common/radio";
import LogVisit from "../../modals/logVisit";

type Props = {
  isLoading: boolean;
  setCallInfo: React.Dispatch<
    React.SetStateAction<{ show: boolean; key: number }>
  >;
  control: Control<MasterLoyalData, any>;
  errors: FieldErrors<MasterLoyalData>;
  objKey: number;
  btnTitle: string;
  submit: {
    submitWeekOne: (data: any) => void;
    submitWeekTwo: (data: any) => void;
    submitWeekThree: (data: any) => void;
    submitWeekFour: (data: any) => void;
  };
  setLogData: React.Dispatch<
    React.SetStateAction<{
      location: string;
    }>
  >;
  logData: any;
  position: any;
  setPosition: any;
};

export default function LoyalMasteryCallLog({
  isLoading,
  setCallInfo,
  control,
  errors,
  objKey,
  btnTitle,
  submit,
  logData,
  setLogData,
  position,
  setPosition,
}: Props) {
  return (
    <React.Fragment>
      <BackButton
        goBack={() => {
          setCallInfo({ show: false, key: objKey });
        }}
      />
      <div className="my-8">
        <CustomField
          title="Date"
          style={{ paddingTop: "10px" }}
          value={
            <Controller
              control={control}
              name={`date`}
              render={({ field: { onChange, value, name } }) => (
                <InputField
                  style={{
                    width: "100%",
                    border: "none",
                    background: "#f1f1f1",
                  }}
                  autoFocus={false}
                  name={name}
                  onChange={onChange}
                  placeholder=""
                  value={value}
                  type="dateTime-local"
                  error={errors?.date?.message || null}
                />
              )}
            />
          }
        />
        <CustomField
          title="Name of Hospital Agent"
          style={{ paddingTop: "10px" }}
          value={
            <Controller
              control={control}
              name={`hospital_agent`}
              render={({ field: { onChange, value, name } }) => (
                <InputField
                  style={{
                    width: "100%",
                    border: "none",
                    background: "#f1f1f1",
                  }}
                  autoFocus={false}
                  name={name}
                  onChange={onChange}
                  placeholder="John Doe"
                  value={value}
                  type="text"
                  error={errors?.hospital_agent?.message || null}
                />
              )}
            />
          }
        />
        {btnTitle === "Log Visit" && (
          <>
            <CustomField
              title="Confirm Hospital Visit (GPS Log Location)"
              value={
                <Button
                  onClick={() => {
                    setPosition(true);
                  }}
                  label="Log Your Location"
                  type="button"
                  style={{ marginTop: "0px", width: "40%" }}
                />
              }
            />
            {position && (
              <>
                <LogVisit
                  handleClose={() => {
                    setPosition(false);
                  }}
                  changePage={() => setPosition(false)}
                  setLogData={setLogData}
                />
              </>
            )}
          </>
        )}
        {btnTitle === "Log Visit" ? (
          <>
            {logData.location && position === false && (
              <>
                <CustomField
                  title={
                    <span className="font-semibold">
                      Questions to Ask Sales Agent :
                    </span>
                  }
                  style={{ paddingTop: "10px" }}
                />
                <CustomField
                  title="Have you had any issues with your orders?"
                  style={{ paddingTop: "10px" }}
                  value={
                    <Controller
                      control={control}
                      name={`issue_with_order`}
                      render={({ field: { onChange, value, name } }) => (
                        <InputField
                          style={{
                            width: "100%",
                            border: "none",
                            background: "#f1f1f1",
                          }}
                          autoFocus={false}
                          name={name}
                          onChange={onChange}
                          placeholder={`type issue here. type 'n/a' if there's no issue`}
                          value={value}
                          type="text"
                          error={errors?.issue_with_order?.message || null}
                        />
                      )}
                    />
                  }
                />
                <CustomField
                  title="Are you having any issues with Nerve"
                  style={{ paddingTop: "10px" }}
                  value={
                    <Controller
                      control={control}
                      name={`issue_with_nerve`}
                      render={({ field: { onChange, value, name } }) => (
                        <InputField
                          style={{
                            width: "100%",
                            border: "none",
                            background: "#f1f1f1",
                          }}
                          autoFocus={false}
                          name={name}
                          onChange={onChange}
                          placeholder={`type issue here. type 'n/a' if there's no issue`}
                          value={value}
                          type="text"
                          error={errors?.issue_with_nerve?.message || null}
                        />
                      )}
                    />
                  }
                />
                <CustomField
                  title="Are you satisfied with our Products and Services"
                  style={{ paddingTop: "10px" }}
                  value={
                    <Controller
                      control={control}
                      name={`satisfied_with_product`}
                      render={({ field: { onChange, value, name } }) => (
                        <div className="flex gap-4">
                          <RadioButton
                            name={name}
                            onChange={onChange}
                            value="1"
                            checked={value === "1"}
                            message="Yes"
                          />
                          <RadioButton
                            name={name}
                            onChange={onChange}
                            value="0"
                            checked={value === "0"}
                            message="No"
                          />
                        </div>
                        // <InputField
                        //   style={{
                        //     width: "100%",
                        //     border: "none",
                        //     background: "#f1f1f1",
                        //   }}
                        //   autoFocus={false}
                        //   name={name}
                        //   onChange={onChange}
                        //   placeholder={`write complaint here. type 'n/a' if there's no complaint`}
                        //   value={value}
                        //   type="text"
                        //   error={errors?.satisfied_with_product?.message || null}
                        // />
                      )}
                    />
                  }
                />
                <CustomField
                  title="Is there anything you will like us to help you with"
                  style={{ paddingTop: "10px" }}
                  value={
                    <Controller
                      control={control}
                      name={`anything_to_help`}
                      render={({ field: { onChange, value, name } }) => (
                        <InputField
                          style={{
                            width: "100%",
                            border: "none",
                            background: "#f1f1f1",
                          }}
                          autoFocus={false}
                          name={name}
                          onChange={onChange}
                          placeholder={`write anything you would like us to help with. type 'n/a' if there's nothing`}
                          value={value}
                          type="text"
                          error={errors?.anything_to_help?.message || null}
                        />
                      )}
                    />
                  }
                />
              </>
            )}
          </>
        ) : (
          <>
            <CustomField
              title={
                <span className="font-semibold">
                  Questions to Ask Sales Agent :
                </span>
              }
              style={{ paddingTop: "10px" }}
            />
            <CustomField
              title="Have you had any issues with your orders?"
              style={{ paddingTop: "10px" }}
              value={
                <Controller
                  control={control}
                  name={`issue_with_order`}
                  render={({ field: { onChange, value, name } }) => (
                    <InputField
                      style={{
                        width: "100%",
                        border: "none",
                        background: "#f1f1f1",
                      }}
                      autoFocus={false}
                      name={name}
                      onChange={onChange}
                      placeholder={`type issue here. type 'n/a' if there's no issue`}
                      value={value}
                      type="text"
                      error={errors?.issue_with_order?.message || null}
                    />
                  )}
                />
              }
            />
            <CustomField
              title="Are you having any issues with Nerve"
              style={{ paddingTop: "10px" }}
              value={
                <Controller
                  control={control}
                  name={`issue_with_nerve`}
                  render={({ field: { onChange, value, name } }) => (
                    <InputField
                      style={{
                        width: "100%",
                        border: "none",
                        background: "#f1f1f1",
                      }}
                      autoFocus={false}
                      name={name}
                      onChange={onChange}
                      placeholder={`type issue here. type 'n/a' if there's no issue`}
                      value={value}
                      type="text"
                      error={errors?.issue_with_nerve?.message || null}
                    />
                  )}
                />
              }
            />
            <CustomField
              title="Are you satisfied with our Products and Services"
              style={{ paddingTop: "10px" }}
              value={
                <Controller
                  control={control}
                  name={`satisfied_with_product`}
                  render={({ field: { onChange, value, name } }) => (
                    <div className="flex gap-4">
                      <RadioButton
                        name={name}
                        onChange={onChange}
                        value="1"
                        checked={value === "1"}
                        message="Yes"
                      />
                      <RadioButton
                        name={name}
                        onChange={onChange}
                        value="0"
                        checked={value === "0"}
                        message="No"
                      />
                    </div>
                    // <InputField
                    //   style={{
                    //     width: "100%",
                    //     border: "none",
                    //     background: "#f1f1f1",
                    //   }}
                    //   autoFocus={false}
                    //   name={name}
                    //   onChange={onChange}
                    //   placeholder={`write complaint here. type 'n/a' if there's no complaint`}
                    //   value={value}
                    //   type="text"
                    //   error={errors?.satisfied_with_product?.message || null}
                    // />
                  )}
                />
              }
            />
            <CustomField
              title="Is there anything you will like us to help you with"
              style={{ paddingTop: "10px" }}
              value={
                <Controller
                  control={control}
                  name={`anything_to_help`}
                  render={({ field: { onChange, value, name } }) => (
                    <InputField
                      style={{
                        width: "100%",
                        border: "none",
                        background: "#f1f1f1",
                      }}
                      autoFocus={false}
                      name={name}
                      onChange={onChange}
                      placeholder={`write anything you would like us to help with. type 'n/a' if there's nothing`}
                      value={value}
                      type="text"
                      error={errors?.anything_to_help?.message || null}
                    />
                  )}
                />
              }
            />
          </>
        )}
      </div>
      <Button
        disabled={
          isLoading ||
          (btnTitle === "Log Visit" &&
            (logData.location && !position ? false : true))
        }
        type="button"
        label={isLoading ? "Saving..." : "Save Log"}
        onClick={() => {
          if (objKey === 1) {
            submit.submitWeekOne(control);
          } else if (objKey === 2) {
            submit.submitWeekTwo(control);
          } else if (objKey === 3) {
            submit.submitWeekThree(control);
          } else {
            submit.submitWeekFour(control);
          }
        }}
      />
    </React.Fragment>
  );
}
