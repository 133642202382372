import { useState, RefObject, useEffect, useCallback } from "react";
import { Button } from "../../../../@/components/ui/button";
import { CalendarIcon, ReloadIcon, UploadIcon } from "@radix-ui/react-icons";

import { cn } from "../../../../@/lib/utils";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../@/components/ui/form";
import { Input } from "../../../../@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../@/components/ui/select";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../@/components/ui/popover";
import { Calendar } from "../../../../@/components/ui/calendar";
import { addDays, format } from "date-fns";
import dayjs from "dayjs";
import {
  RadioGroup,
  RadioGroupItem,
} from "../../../../@/components/ui/radio-group";
import { Textarea } from "../../../../@/components/ui/textarea";
import { useUpdateEvent } from "../../../../hooks/marketing/useEvents";
import ProgressLoader from "../../../../components/common/progressLoader";
import { usePlacesWidget } from "react-google-autocomplete";
import { useNavigate } from "react-router-dom";
import toastService from "../../../../services/toastService";

const FormSchema = z.object({
  title: z.string().min(2, {
    message: "Enter the name of your event",
  }),
  category: z.string({
    required_error: "Please select one",
  }),
  type: z.enum(["virtual", "physical"], {
    required_error: "You need to select an event type.",
  }),
  date: z.date({
    required_error: "A event date is required.",
  }),
  start_time: z.string({
    required_error: "A event start time is required.",
  }),
  end_time: z.string({
    required_error: "A event end time is required.",
  }),
  location: z.string({
    required_error: "A event location is required.",
  }),

  longitude: z.string(),
  latitude: z.string(),

  description: z.string().min(10, {
    message: "Description must be at least 10 characters.",
  }),

  speaker: z.string().min(2, {
    message: "Please enter a speaker name",
  }),
});
const EditForm = ({ data }: { data: any }) => {
  const [imageURL] = useState(`https://${data.baseUrl}${data.header}`);

  const handleEditImage = useCallback(async () => {
    try {
      const response = await fetch(imageURL);
      const blob = await response.blob();

      const file = new File([blob], "image.jpg", { type: blob.type });
      console.log(file);
      setHeader(file);
      const formData = new FormData();
      formData.append("file", file);
    } catch (error) {
      console.error("Error fetching and uploading image:", error);
    }
  }, [imageURL]);

  useEffect(() => {
    const handleEditImage = async () => {
      try {
        const response = await fetch(imageURL);
        const blob = await response.blob();

        const file = new File([blob], "image.jpg", { type: blob.type });
        console.log(file);
        setHeader(file);
        const formData = new FormData();
        formData.append("file", file);
      } catch (error) {
        console.error("Error fetching and uploading image:", error);
      }
    };

    handleEditImage();
  }, [handleEditImage, imageURL]);

  const [header, setHeader] = useState<File | null>(null);

  const uploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      console.log(file);
      const maxSize = 1024 * 1024 * 10;
      if (file.size <= maxSize) {
        setHeader(file);
      } else {
        console.log("error");
        toastService.error(
          "File size exceeds the limit, please choose image less than 10MB"
        );
      }
    }
  };
  const router = useNavigate();
  const [country] = useState("ng");
  const [progress, setProgress] = useState(0);

  const handleProgressChange = (progress: any) => {
    setProgress(progress);
  };
  const { mutateUpdate, isPending } = useUpdateEvent(
    data.id,
    handleProgressChange
  );

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    mode: "onChange",
    defaultValues: {
      title: "",
      category: data?.category,
      type: data?.type,
      date: new Date(),
      start_time: "",
      end_time: "",
      description: "",
      location: "",
      speaker: "",
      longitude: "",
      latitude: "",
    },
  });

  const { setValue } = form;

  useEffect(() => {
    if (data) {
      console.log(data);
      const formattedAddress = data.location
        .split(",")
        .map((part: string) => part.split(/(?=[A-Z])/).join(" "))
        .join(", ");

      setValue("title", data.name);
      setValue("category", data.category);
      setValue("type", data.type);
      setValue("date", new Date(data.eventdate));
      setValue("start_time", data.startime);
      setValue("end_time", data.enddate);
      setValue("location", formattedAddress);
      setValue("latitude", data.latitude);
      setValue("longitude", data.longitude);
      setValue("description", data.description);
      setValue("speaker", data.speaker);
    }
    handleEditImage();
  }, [data, handleEditImage, setValue]);

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyDQjd9fzSdMQzaCXmjXMlfJILG53ZVJs5M",
    onPlaceSelected: (place: any) => {
      console.log(place);
      const latitudePlace = place.geometry.location.lat().toString();
      const longitudePlace = place.geometry.location.lng().toString();
      const address = place.formatted_address;
      setValue("location", address);
      setValue("latitude", latitudePlace);
      setValue("longitude", longitudePlace);
    },
    inputAutocompleteValue: "country",
    options: {
      types: ["geocode", "establishment"],
      componentRestrictions: { country },
    },
  });

  function onSubmit(data: z.infer<typeof FormSchema>) {
    console.log(data);

    const date = dayjs(new Date(data.date)).format("YYYY/MM/DD");

    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("category", "remove category");
    formData.append("type", data.type);
    formData.append("date", date);
    formData.append("start_time", data.start_time);
    formData.append("end_time", data.end_time);
    formData.append("location", data.location);
    formData.append("lat", data.latitude);
    formData.append("long", data.longitude);
    formData.append("description", data.description);
    formData.append("speaker", data.speaker);
    if (header) {
      formData.append("header", header);
    }

    mutateUpdate(formData, {
      onSuccess: (data: any) => {
        console.log(data.data.id);
        const eventDetails = data.data.id;

        // update the url with the event id as a search param
        router(`/create-event?id=${eventDetails.id}`);
        // window.location.reload();

        localStorage.setItem("event", JSON.stringify(eventDetails));
        form.reset();
      },
      onError: (error: { message: string }) => {
        console.log("error", error);
        setProgress(0);
      },
    });
  }

  return (
    <Form {...form}>
      <ProgressLoader progress={progress} />
      <form onSubmit={form.handleSubmit(onSubmit)} className="  space-y-6">
        <div className="space-y-5">
          <FormField
            control={form.control}
            name="title"
            render={({ field }) => (
              <FormItem>
                <div className="flex flex-col md:flex-row md:items-center gap-3 w-full">
                  <FormLabel className="md:w-1/6 text-start">
                    Event Title
                  </FormLabel>
                  <FormControl>
                    <Input
                      className=""
                      placeholder="Enter the name of your event"
                      {...field}
                    />
                  </FormControl>
                </div>
                <div className="flex items-center justify-end">
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />
          {/* <FormField
            control={form.control}
            name="category"
            render={({ field }) => (
              <FormItem>
                <div className="flex flex-col md:flex-row md:items-center gap-3 w-full">
                  <FormLabel className="md:w-1/6 text-start">
                    Event Category
                  </FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a category" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="blood">Blood bank</SelectItem>
                      <SelectItem value="oxygen">Oxygen</SelectItem>
                      <SelectItem value="support">Medical Support</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div className="flex items-center justify-end">
                  <FormMessage />
                </div>
              </FormItem>
            )}
          /> */}
        </div>
        <div className="space-y-5">
          <FormField
            control={form.control}
            name="type"
            render={({ field }) => (
              <FormItem className="space-y-3">
                <div className="flex  flex-col md:flex-row md:items-center gap-3 w-full">
                  <FormLabel className="md:w-1/6 text-start">
                    Event Type
                  </FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      className="flex item-center"
                    >
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="virtual" />
                        </FormControl>
                        <FormLabel className="font-normal cursor-pointer">
                          Virtual
                        </FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="physical" />
                        </FormControl>
                        <FormLabel className="font-normal cursor-pointer">
                          Physical
                        </FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="flex items-center justify-end">
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />
          <div
            className="grid grid-cols-6
            items-center"
          >
            <div className="hidden md:block text-start">Session(s)</div>
            <div className="flex items-center col-span-5">
              <div className="flex gap-2 flex-wrap">
                <FormField
                  control={form.control}
                  name="date"
                  render={({ field }) => (
                    <FormItem className="flex flex-col">
                      <FormLabel className=" text-start">Start Date</FormLabel>
                      <Popover>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant={"outline"}
                              className={cn(
                                "w-[150px] justify-start text-left font-normal",
                                !field.value && "text-muted-foreground"
                              )}
                            >
                              <CalendarIcon className="mr-2 h-4 w-4" />
                              {field.value ? (
                                format(field.value, "PPP")
                              ) : (
                                <span>Pick a date</span>
                              )}
                            </Button>
                          </FormControl>
                        </PopoverTrigger>

                        <PopoverContent
                          align="start"
                          className="flex w-auto flex-col space-y-2 p-2"
                        >
                          <Select
                            onValueChange={(value) =>
                              field.onChange(
                                addDays(new Date(), parseInt(value))
                              )
                            }
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="Select" />
                            </SelectTrigger>
                            <SelectContent position="popper">
                              <SelectItem value="0">Today</SelectItem>
                              <SelectItem value="1">Tomorrow</SelectItem>
                              <SelectItem value="3">In 3 days</SelectItem>
                              <SelectItem value="7">In a week</SelectItem>
                            </SelectContent>
                          </Select>
                          <div className="rounded-md border">
                            <Calendar
                              mode="single"
                              selected={field.value}
                              onSelect={field.onChange}
                            />
                          </div>
                        </PopoverContent>
                      </Popover>
                      <FormMessage />
                    </FormItem>
                  )}
                />{" "}
                <FormField
                  control={form.control}
                  name="start_time"
                  render={({ field }) => (
                    <FormItem className="flex flex-col">
                      <FormLabel className=" text-start">Start Time</FormLabel>
                      <FormControl>
                        <Input type="time" {...field} />
                      </FormControl>
                      <div className="flex items-center justify-end">
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="end_time"
                  render={({ field }) => (
                    <FormItem className="flex flex-col">
                      <FormLabel className=" text-start">End Time</FormLabel>
                      <FormControl>
                        <Input type="time" {...field} />
                      </FormControl>
                      <div className="flex items-center justify-end">
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="space-y-5">
          <FormField
            control={form.control}
            name="location"
            render={({ field }) => (
              <FormItem>
                <div className="flex  flex-col md:flex-row md:items-center gap-3">
                  <FormLabel className="md:w-1/6 text-start">
                    Where will your event take place?
                  </FormLabel>

                  <Input
                    {...field}
                    placeholder="Please enter a location"
                    ref={ref as unknown as RefObject<HTMLInputElement>}
                  />
                </div>
                <div className="flex items-center justify-end">
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />
        </div>
        <div className="space-y-5">
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <div className="flex  flex-col md:flex-row md:items-center  gap-3 w-full">
                  <FormLabel className="md:w-1/6 text-start">
                    Event Description
                  </FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="Describe what's special about your event & other important details."
                      className="resize-none"
                      {...field}
                    />
                  </FormControl>
                </div>
                <div className="flex items-center justify-end">
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />{" "}
          <FormField
            control={form.control}
            name="speaker"
            render={({ field }) => (
              <FormItem>
                <div className="flex  flex-col md:flex-row md:items-center gap-3">
                  <FormLabel className="md:w-1/6">Speaker Name </FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Please enter a speaker name"
                      {...field}
                    />
                  </FormControl>
                </div>
                <div className="flex items-center justify-end">
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />
        </div>
        <div className="space-y-5">
          <FormItem>
            <div className="flex  flex-col md:flex-row md:items-center  gap-3">
              <FormLabel className="md:w-1/6">Upload Header</FormLabel>
              <FormControl>
                <Input
                  type="file"
                  accept=".jpeg, .png, .jpg, .gif"
                  placeholder="Please upload a header image"
                  onChange={uploadImage}
                />
              </FormControl>
            </div>
            <div className="flex justify-center">
              {header ? (
                <div className="h-80 max-h-32 flex justify-center w-32">
                  <img
                    className="rounded-md object-fit max-h-full"
                    src={URL.createObjectURL(header)}
                    alt="preview"
                  />
                </div>
              ) : (
                <div className="">
                  <UploadIcon className="w-20 h-20" />
                </div>
              )}
            </div>
            <div className="grid md:grid-cols-6">
              <div></div>
              <div className="col-span-5">
                <FormDescription>
                  Feature Image must be at least 1170 pixels wide by 504 pixels
                  high. Valid file formats: JPG, GIF, PNG.
                </FormDescription>
              </div>
            </div>
          </FormItem>
        </div>
        <div className="flex justify-end">
          <Button disabled={isPending} type="submit">
            {isPending && <ReloadIcon className="animate-spin mr-2 h-4 w-4" />}
            {isPending ? "Please wait" : " Save & Continue"}
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default EditForm;
