import React, { useEffect, useState } from "react";

import _ from "lodash";

import utilities from "../../utils/utilities";

import LoadingButton from "../common/loadingButton";
import Pagination from "../common/pagination";
import ReassignAgentForm from "../forms/reassignAgentForm";
import Table from "../common/table";

import { useGlobalContext } from "../../contexts/globalContext";

import { getUnsuccessfulPitches } from "../../services/leadService";
import toastService from "../../services/toastService";

const UnsuccessFulPitchTable = () => {
  const [sortColumn, setSortColumn] = useState(initialSortColumn);
  const [currentPage, setCurrentPage] = useState(1);
  const [pitches, setPitches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [prospectToAssign, setProspectToAssign] = useState(null);

  const { city } = useGlobalContext();

  const pageSize = 3;

  const mergeData = () => {
    const allUnsuccessfulPitches = [];

    for (const key in pitches) allUnsuccessfulPitches.push(...pitches[key]);

    return utilities.mapKeyToNumber(allUnsuccessfulPitches, "pitch_id");
  };

  const getData = async () => {
    try {
      setLoading(true);

      const response = await getUnsuccessfulPitches(city);
      const data = response.data.ok
        ? _.pick(response.data, ["direct", "telesales"])
        : {};

      setPitches(data);
      setLoading(false);
    } catch (ex) {
      const errorMessage = ex?.response?.data?.description ?? ex.message;

      toastService.error(errorMessage);
      setLoading(false);
    }
  };

  const handleReassignSelect = (prospect) => {
    setProspectToAssign(prospect);
  };

  const paginatedData = () => {
    const filtered = mergeData();

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const paginated = utilities.paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, paginated };
  };

  const columns = [
    {
      path: "pitch_id",
      label: "Pitch ID",
    },
    {
      path: "name",
      label: "Hospital Name",
    },
    {
      path: "feedback",
      label: "Pitch Feedback",
    },
    {
      path: "pitch_method",
      label: "Pitch Method",
    },
    {
      path: "agent",
      label: "Previous Agent",
    },
    {
      path: "state",
      label: "State",
    },
    {
      path: "_",
      label: "",
      content: (hospital) => (
        <button
          className="status view"
          onClick={() => handleReassignSelect(hospital)}
        >
          Reassign
        </button>
      ),
    },
  ];

  const { totalCount, paginated } = paginatedData();

  useEffect(() => {
    getData();

    // eslint-disable-next-line
  }, [city]);

  return (
    <div className="activity-feed fade-in">
      {prospectToAssign && (
        <ReassignAgentForm
          selectedProspect={prospectToAssign}
          handleClose={() => handleReassignSelect(null)}
        />
      )}

      <LoadingButton
        visible={loading}
        message="Fetching Unsuccessful Pitches"
      />

      {!loading && (
        <React.Fragment>
          <Table
            columns={columns}
            data={paginated}
            sortColumn={sortColumn}
            onSort={setSortColumn}
          />

          <div className="sb-pagination-container">
            <Pagination
              itemsCount={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>

          {paginated.length === 0 && (
            <div className="no-items">
              <p>No unsuccessful pitches</p>
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

const initialSortColumn = {
  path: "pitch_id",
  order: "desc",
};

export default UnsuccessFulPitchTable;
