import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "../../../../@/components/ui/form";
import { Button } from "../../../../@/components/ui/button";
import { Input } from "../../../../@/components/ui/input";
import { Search, X } from "lucide-react";

// Define the schema for form validation
const FormSchema = z.object({
  search: z.string().min(3, {
    message: "Search query must be at least 3 characters long",
  }),
});

// Define the form data type based on the schema
type FormData = z.infer<typeof FormSchema>;

function SearchItems({
  onSubmit,
  FormSchema,
}: {
  onSubmit: SubmitHandler<FormData>;
  FormSchema: z.ZodObject<any, any>;
}) {
  const [isVisible, setIsVisible] = useState(false);
  const form = useForm<FormData>({
    resolver: zodResolver(FormSchema),
    mode: "onChange",
    defaultValues: {
      search: "",
    },
  });
  const handleClearSearch = () => {
    form.reset(); // Reset the form values
    setIsVisible(false); // Hide the search input
  };
  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="relative">
          {isVisible && (
            <FormField
              control={form.control}
              name="search"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormControl>
                    <Input
                      {...field}
                      autoComplete="off"
                      aria-autocomplete="none"
                      placeholder="Search Item"
                      type="text"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
          <div className="absolute inset-y-0 right-0 z-40">
            {isVisible && form.formState.isDirty && (
              <Button
                onClick={handleClearSearch}
                variant="outline"
                size="icon"
                type="submit"
                // disabled={!form.formState.isValid && isVisible}
              >
                <X />
              </Button>
            )}
            <Button
              onClick={() =>
                setIsVisible(form.formState.isValid ? isVisible : !isVisible)
              }
              variant="outline"
              size="icon"
              type="submit"
              disabled={!form.formState.isValid && isVisible}
            >
              <Search />
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
}

export default SearchItems;
