import React from "react";
import OrderCard from "./Card";
import { useGetEquipment } from "../../../../hooks/purchasingOrder/usePurchaseOrder";
import { useNavigate } from "react-router-dom";
import { SyncLoader } from "react-spinners";

export default function BuyEquipments() {
  const navigate = useNavigate();
  const { equipment, isPending } = useGetEquipment();

  function handleSubCategoryClick(slug: string, idx: string) {
    navigate(
      `/purchasing-orders/category/buy-equipment/${slug.toLowerCase()}`,
      {
        state: { item: idx, name: slug },
      }
    );
  }

  return (
    <>
      {isPending ? (
        <div className="fixed top-0 bottom-0 left-0 right-0 flex mx-auto items-center justify-center h-dvh">
          <div className="bg-gray-700 absolute inset-0 opacity-50"></div>
          <SyncLoader size={30} color="blue" className="relative" />
        </div>
      ) : (
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4">
          {equipment.map(
            (category: {
              id: string;
              name: string;
              icon_url: string | undefined;
            }) => (
              <OrderCard
                key={category.id}
                hoverClass={"white"}
                width="w-full"
                title={category.name}
                image={category.icon_url}
                textColor="text-bloodRed"
                iconOnTop={true}
                borderColor="border-green-400"
                iconPosition="mx-auto"
                textPosition="mx-auto"
                height="220px"
                onClick={() =>
                  handleSubCategoryClick(category.name, category.id)
                }
              />
            )
          )}
        </div>
      )}{" "}
    </>
  );
}
