import React from "react";

const LoadingButton = ({ visible = true, message }) => {
  if (!visible) return null;

  return (
    <div className="loading-button">
      <i className="fa fa-spinner fa-spin"></i>
      <span>{message}...</span>
    </div>
  );
};

export default LoadingButton;
