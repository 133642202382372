import { Key, useEffect, useState } from "react";
import { Button } from "../../../../@/components/ui/button";
import CustomPagination from "../../../../components/common/pagination/Pagination";
import applyFilters from "../../../../helpers/urlState";
import { Link, useNavigate } from "react-router-dom";
import { SortColumn } from "../../../../ts-utils/interfaces";
import _ from "lodash";

const Tabs = ({
  tab,
  handleViewOrder,
}: {
  tab: any[];
  handleViewOrder: (item: any) => void;
}) => {
  const [selectedItem, setSelectedItem] = useState(0);
  const labelColors: { [key: string]: { color: string } } = {
    completed: {
      color: "text-green-600 bg-green-50",
    },
    "awaiting pick up": {
      color: "text-yellow-600 bg-yellow-50",
    },
    Normal: {
      color: "text-blue-600 bg-blue-50",
    },
    Great: {
      color: "text-pink-600 bg-pink-50",
    },
    Bad: {
      color: "text-red-600 bg-red-50",
    },
  };

  const navigate = useNavigate();
  const initialSortColumn: SortColumn = { path: "id", order: "desc" };
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState(initialSortColumn);
  const pageSize: number = 5;
  function paginate(items: any[], pageNumber: number, pageSize: number) {
    const startIndex = (pageNumber - 1) * pageSize;
    return items.slice(startIndex, startIndex + pageSize);
  }

  const sortedItems = _.orderBy(
    tab[selectedItem]?.items,
    [sortColumn.path],
    [sortColumn.order]
  );

  const paginatedItems = paginate(sortedItems, currentPage, pageSize);

  const totalPages =
    Math.ceil(tab[selectedItem]?.items?.length / pageSize) > 0
      ? Math.ceil(tab[selectedItem]?.items?.length / pageSize)
      : 1;

  useEffect(() => {
    const pageFromQuery =
      Number(new URLSearchParams(window.location.search).get("page")) || 1;

    if (paginatedItems.length === 0) {
      if (totalPages > 0) {
        applyFilters(
          {
            page: pageFromQuery > totalPages ? 1 : totalPages,
          },
          navigate,
          "purchasing-orders"
        );
      }
      setCurrentPage(1);
    }
  }, [navigate, paginatedItems.length, totalPages]);

  const goToPage = (page: number) => {
    const newPage = Math.min(Math.max(page, 1), totalPages);
    setCurrentPage(newPage);
    applyFilters(
      {
        page: newPage,
      },
      navigate,
      "purchasing-orders"
    );
  };

  return (
    <div>
      <div className="max-w-7xl flex justify-end items-center">
        {/* Other controls can be added here */}
      </div>
      <div className="text-sm mt-12 overflow-x-auto">
        <ul
          role="tablist"
          className="w-full border-b flex items-center gap-x-3 overflow-x-auto"
        >
          {tab?.map((item, idx) => (
            <li
              key={idx}
              className={`py-2 border-b-2 ${
                selectedItem === idx
                  ? "border-indigo-600 text-indigo-600"
                  : "border-white text-gray-500"
              }`}
            >
              <Button
                role="tab"
                aria-selected={selectedItem === idx ? true : false}
                aria-controls={`tabpanel-${idx + 1}`}
                className="py-2.5 px-4 rounded-lg duration-150 hover:text-gray-300 hover:bg-gray-50 active:bg-gray-100 font-medium"
                onClick={() => setSelectedItem(idx)}
              >
                {item?.label} ({item?.items?.length ?? 0})
              </Button>
            </li>
          ))}
        </ul>
        <table className="w-full table-auto text-left">
          <thead className="text-gray-600 font-medium border-b">
            <tr>
              <th className="w-9/12 py-4 pr-6">{tab[selectedItem]?.title}</th>
              <th className="py-4 pr-6">Hospital</th>
              <th className="py-4 pr-6">Status</th>
              <th className="py-4 pr-6">Time</th>
              <th className="py-4 pr-6">Action</th>
            </tr>
          </thead>
          {paginatedItems?.length > 0 ? (
            <tbody className="text-gray-600 divide-y">
              {paginatedItems?.map(
                (
                  item: {
                    id: string | number;
                    urgency: string | number;
                    type: string | number;
                    order_state: string | number;
                    created_at: string | number;
                    hospital_name: string;
                  },
                  idx: Key | null | undefined
                ) => (
                  <tr key={idx}>
                    <td
                      className="pr-6 py-4 whitespace-nowrap"
                      data-label={tab[selectedItem]?.title}
                    >
                      <div className="flex flex-col">
                        <p>#{item.id}</p>
                        <p>
                          <span className="capitalize">{item.urgency}</span>{" "}
                          {item.type}
                        </p>
                      </div>
                    </td>
                    <td
                      className="pr-6 py-4 whitespace-nowrap text-indigo-600"
                      data-label="Hospital Name"
                    >
                      <Link
                        to={`/hospitals/${item.id}`}
                        className="underline text-blue-600"
                      >
                        {item.hospital_name}
                      </Link>
                    </td>
                    <td
                      className="pr-6 py-4 whitespace-nowrap text-indigo-600"
                      data-label="Status"
                    >
                      <span
                        className={`py-2 px-3 rounded-full font-semibold text-xs capitalize ${
                          labelColors[
                            (item?.order_state as string)?.toLowerCase()
                          ]?.color || ""
                        }`}
                      >
                        {item?.order_state}
                      </span>
                    </td>
                    <td
                      className="pr-6 py-4 whitespace-nowrap"
                      data-label="Time"
                    >
                      <span
                        className={`py-2 px-3 rounded-full font-semibold text-xs ${
                          labelColors[item?.created_at]?.color || ""
                        }`}
                      >
                        {item?.created_at}
                      </span>
                    </td>
                    <td
                      className="pr-6 py-4 whitespace-nowrap"
                      data-label="Action"
                    >
                      <Button
                        onClick={() => handleViewOrder(item)}
                        className="text-white font-medium hover:text-gray-500 hover:opacity-85"
                      >
                        View Order
                      </Button>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={5} className="py-4 text-center">
                  No orders
                </td>
              </tr>
            </tbody>
          )}
        </table>

        <div className="sb-pagination-container mb-5">
          <CustomPagination
            goToPage={goToPage}
            currentPage={currentPage}
            totalPages={totalPages}
          />
        </div>
      </div>
    </div>
  );
};

export default Tabs;
