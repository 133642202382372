import { useQuery } from "@tanstack/react-query";
import appointmentService  from "../../services/appointment.service";
import onboardService from "../../services/onboardService";
import { AxiosError, AxiosResponse } from "axios"; 
import toastService from "../../services/toastService";
import { useGlobalContext } from "../../contexts/globalContext";
import { AssignedHospital } from "../../ts-utils/interfaces";

interface ResponseData {
  tele: AssignedHospital[];
  description: string;
  data: any;
  meta: any;
}

export const useGetPitches = () => {
  const { user } = useGlobalContext();
  const { isError, isSuccess, data, isPending, error } = useQuery({
    queryKey: ["pitches", user?.id],
    enabled: !!user?.id,
    queryFn: async () => {
      const response = (await onboardService.getPitches(
        user?.id
      )) as AxiosResponse<ResponseData>;
      return response;
    },
  });

  const assignedHospitals = (data?.data?.tele as AssignedHospital[]) || [];

  if (isError) {
    toastService.error((error as AxiosError)?.message);
  }

  return { isError, isSuccess, assignedHospitals, isPending, error };
};

export const useGetPitchInformation = (hospitalId: string) => {
  const { isError, isSuccess, data, isPending, error } = useQuery({
    queryKey: ["pitch", hospitalId],
    enabled: !!hospitalId,
    queryFn: async () => {
      const response = await appointmentService.getPitchInformation(hospitalId);
      return response;
    },
  });

  const pitchInformation: any =
    (data as AxiosResponse<ResponseData>)?.data?.data || [];

  if (isError) {
    toastService.error((error as AxiosError)?.message);
  }

  return { isError, isSuccess, pitchInformation, isPending, error };
};
