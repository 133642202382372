import React, { useCallback, useEffect, useMemo, useState } from "react";

import AddHospitalForm from "../../../../components/forms/addHospitalForm";
import Header from "../../../../components/blocks/header";

import SearchQuery from "../../../../components/forms/searchQuery";
import Select from "../../../../components/common/select";

import authService, { getPrivileges } from "../../../../services/authService";

import { useGlobalContext } from "../../../../contexts/globalContext";

import { Hospital } from "../../../../ts-utils/types";

import * as Dialog from "@radix-ui/react-dialog";

import {
  useAssignAgent,
  useGetAgentAdminHospitals,
  useGetAllAgent,
  usePostCadre,
  useGetCadreList,
} from "../../../../hooks/agent/useAgent";
import { Link, useSearchParams } from "react-router-dom";
import { Eye, MoreVertical, Pencil, Equal } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../@/components/ui/dropdown-menu";
import { Button } from "../../../../@/components/ui/button";
import Table from "../../../../components/common/table";
import { SortColumn, TableColumn } from "../../../../ts-utils/interfaces";
import CustomPagination from "../../../../components/common/pagination/Pagination";
import EditProspectForm from "../../../../components/forms/editProspectForm";
import ProspectedInfo from "../../../../components/modals/prospectedInfo";
import { SyncLoader } from "react-spinners";

import {
  useGetFacilityTypes,
  useGetFacilitySizes,
} from "../../../../hooks/useHospital";
import toastService from "../../../../services/toastService";
import ReactSelect from "../../../../components/common/ReactSelect";
import { PiGitPullRequestThin } from "react-icons/pi";

import { Renderable, ValueFunction, Toast } from "react-hot-toast";
import ProductTable, { ProductTableColumn } from "../exodus/ProductTable";

const Hospitals = () => {
  const permission = getPrivileges();
  const { user } = useGlobalContext();
  const { facilityTypes } = useGetFacilityTypes();
  const { facilitySizes } = useGetFacilitySizes();

  const { cadreList } = useGetCadreList();

  const { mutatePostCadre, isPending: isSubmitting } = usePostCadre();
  const { agent } = useGetAllAgent();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const status = searchParams.get("status") || "";
  const search = searchParams.get("search") || "";
  const limit = searchParams.get("limit") || "20";

  const [hospitalToView, setHospitalToView] = useState<Hospital | null>(null);
  const [hospitalInfoToEdit, setHospitalInfoToEdit] = useState<Hospital | null>(
    null
  );
  const [open, setOpen] = useState(false);
  const [category, setCategory] = useState("0");
  const [searchKey, setSearchKey] = useState("name");
  const [addHospital, setAddHospital] = useState(false);
  const [categories, setCategories] = useState<{ id: string; name: string }[]>(
    []
  );
  const [selectedAgent, setSelectedAgent] = useState<string>("");
  const [assign, setAssign] = useState<{
    hospital_id: string;
    name: string;
    username: string;
  } | null>(null);

  const [cadre, setCadre] = useState({
    blood: "",
    oxygen: "",
    ref: "",
  });
  // filter agent with position sales_rep or sales_lead
  const filterAgents = useMemo(
    () =>
      agent
        ?.filter((agent: { position: string }) =>
          ["sales_rep", "sales_lead"].includes(agent.position)
        )
        .map((agent: { id: string; username: string }) => {
          return {
            id: agent.id,
            name: agent.username,
          };
        })
        .filter((v: { id: string; name: string }, i: number, a: any[]) => {
          return a.findIndex((t: { id: string }) => t.id === v.id) === i;
        })
        .filter((v: { name: string }) => v.name !== null)
        .sort((a: { name: string }, b: { name: string }) =>
          a.name.localeCompare(b.name)
        ) || [],
    [agent]
  );

  const handleEditSelect = (hospital: Hospital): void => {
    setHospitalInfoToEdit(hospital);
  };
  const handleHospitalToView = (hospital: Hospital): void => {
    setHospitalToView(hospital);
  };

  const getUserId = useCallback(
    (user: any) => {
      if (!permission.createAgent) {
        return user;
      }
      return "";
    },
    [permission.createAgent]
  );
  const pageLimit = Number(limit) as number;
  const { hospitals, paginate, isPending } = useGetAgentAdminHospitals(
    page,
    pageLimit,
    status,
    search,
    getUserId(user?.id) as string
  );

  const filters = useMemo(() => {
    return {
      user_id: getUserId(user?.id) as string,
      page,
      search,
      status,
    };
  }, [getUserId, user?.id, page, search, status]);

  const filteredHospitals = useMemo(() => {
    return hospitals && !isPending
      ? hospitals
          .filter((agent: { id: null }) => agent.id !== null)
          .filter((agent: { hospital_id: null }) => agent.hospital_id !== null)
      : [];
  }, [hospitals, isPending]);

  const { mutateAssignAgent, isPending: isAssignPending } = useAssignAgent();
  const privileges = authService.getPrivileges();

  const handleAddHospital = () => {
    setAddHospital((prev) => !prev);
  };

  const handleCategories = () => {
    const newCategories = [];

    const allHospitals = { id: "0", name: "All Hospitals" };
    const myHospitals = { id: "1", name: "My Hospitals" };

    const initialPrivilege = privileges.allHospitals
      ? allHospitals.id
      : myHospitals.id;

    if (privileges.allHospitals) newCategories.push(allHospitals);
    if (privileges.myHospitals) newCategories.push(myHospitals);

    setCategory(initialPrivilege);
    setCategories(newCategories);
  };

  const getAgents = useMemo(
    () =>
      filteredHospitals
        .map((hospital: Hospital) => {
          return {
            id: hospital.user_id,
            name: hospital.username,
          };
        })
        .filter((v: { id: string; name: string }, i: number, a: any[]) => {
          return a.findIndex((t: { id: string }) => t.id === v.id) === i;
        })
        .filter((v: { name: string }) => v.name !== null)
        .sort((a: { name: string }, b: { name: string }) =>
          a.name.localeCompare(b.name)
        ) || [],
    [filteredHospitals]
  );

  useEffect(() => {
    if (getAgents?.length > 0) {
      setSelectedAgent(getAgents[0]?.id);
    }
  }, [getAgents]);

  const handleSelectAgent = (item: any) => {
    setSelectedAgent(item);
  };
  const handleAssignAgent = (item: any) => {
    setAssign(item);
    setOpen(true);
  };

  const submitAgent = () => {
    const payload = {
      agent_id: selectedAgent,
      hospital_id: assign?.hospital_id,
    };
    mutateAssignAgent(payload, {
      onSuccess: (data: {
        data: { message: Renderable | ValueFunction<Renderable, Toast> };
      }) => {
        console.log(data);
        toastService.success(data?.data?.message);
        setOpen(false);
      },
      onError: () => {
        setOpen(false);
      },
    });
  };

  useEffect(() => {
    handleCategories();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (cadreList.length > 0) {
      setCadre({
        ...cadre,
        blood: cadreList?.[0]?.name,
        oxygen: cadreList?.[0]?.name,
      });
    }
  }, [cadreList]);

  const [openCadre, setOpenCadre] = React.useState(false);

  const handleCadreRequest = (hospital: Hospital) => {
    setCadre({
      ...cadre,
      ref: hospital.ref_id,
    });

    setOpenCadre(true);
  };

  const onSubmitCadre = () => {
    mutatePostCadre(cadre, {
      onSuccess: (data: {
        data: { message: Renderable | ValueFunction<Renderable, Toast> };
      }) => {
        console.log(data);
        toastService.success(data?.data?.message);
        setOpenCadre(false);
      },
      onError: () => {
        setOpenCadre(false);
      },
    });
  };

  const getSalesAdminColumns = (): TableColumn[] => {
    const viewHospitalInformation: TableColumn = {
      path: "_",
      label: "Action",
      content: (h: Hospital): JSX.Element => (
        <div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <MoreVertical className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem
                className="cursor-pointer"
                onClick={() => {
                  handleHospitalToView(h);
                }}
              >
                <Eye className="mr-2 h-4 w-4" /> View
              </DropdownMenuItem>
              <DropdownMenuItem
                className="cursor-pointer"
                onClick={() => {
                  handleCadreRequest(h);
                }}
              >
                <PiGitPullRequestThin className="mr-2 h-4 w-4" /> Cadre Request
              </DropdownMenuItem>
              {permission.createAgent && (
                <>
                  <DropdownMenuItem
                    className="cursor-pointer"
                    onClick={() => {
                      handleEditSelect(h);
                    }}
                  >
                    <Pencil className="mr-2 h-4 w-4" /> Edit
                  </DropdownMenuItem>

                  <DropdownMenuItem
                    className="cursor-pointer"
                    onClick={() => {
                      handleAssignAgent(h);
                    }}
                  >
                    <Equal className="mr-2 h-4 w-4" /> Assign
                  </DropdownMenuItem>
                </>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      ),
    };

    return [...salesAdminColumns, viewHospitalInformation];
  };

  const [sortColumn, setSortColumn] = useState<SortColumn>(initialSortColumn);

  const updateSearchParams = useCallback(
    (filters: { [x: string]: string | undefined }) => {
      for (const key in filters) {
        if (Object.prototype.hasOwnProperty.call(filters, key)) {
          if (
            filters[key] === "" ||
            filters[key] === null ||
            filters[key] === undefined
          ) {
            searchParams.delete(key);
          } else {
            if (typeof filters[key] === "string") {
              searchParams.set(key, filters[key]);
            }
          }
        }
      }

      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const goToPage = useCallback(
    (page: number) => {
      updateSearchParams({ ...filters, page: page.toString() });
    },
    [filters, updateSearchParams]
  );

  function renderCell(hospital: Hospital, column: ProductTableColumn) {
    switch (column.accessor) {
      case "id":
        return <p className="text-[#49209F] font-bold">{hospital?.id}</p>;
      case "name":
        return (
          <Link
            className="text-[#49209F]  underline font-bold"
            to={`/exodus/list/${hospital?.id}`}
          >
            {hospital?.name}
          </Link>
        );
      default:
        // For boolean fields, show Yes/No
        return hospital[column.accessor as keyof Hospital];
      //  === "Yes" ? (
      //   <>
      //     <span className="text-green-600 font-semibold flex items-end md:items-center justify-end md:justify-center text-center">
      //       <IoCheckmarkDoneSharp />
      //     </span>
      //   </>
      // ) : (
      //   <>
      //     {" "}
      //     <span className="text-red-500 flex items-end md:items-center justify-end md:justify-center text-center">
      //       <RiCloseLargeFill />
      //     </span>
      //   </>
      // );
    }
  }

  const columns: ProductTableColumn[] = [
    { header: "ID", accessor: "hospital_id", className: "mx-5 w-fit" },
    { header: "Hospital Name", accessor: "name", className: "mx-10 w-fit" },
    { header: "Email Address", accessor: "email", className: "mx-10 w-fit" },
    { header: "Phone Number", accessor: "phone", className: "mx-10 w-fit" },
    { header: "Status", accessor: "status", className: "mx-10 w-fit" },
    { header: "Address", accessor: "address", className: "mx-10 w-fit" },
    {
      header: "LifeBank Agent",
      accessor: "username",
      className: "mx-10 w-fit",
    },
    { header: "Contact", accessor: "contact_name", className: "mx-10 w-fit" },
    {
      header: "Action",
      accessor: "_",
    },
  ];
  return (
    <React.Fragment>
      <>
        <div className="max-w-screen-2xl mx-auto px-4 md:px-8 mb-5"></div>
      </>
      {(isAssignPending || isPending) && (
        <div className="fixed top-0 bottom-0 left-0 right-0 flex mx-auto items-center justify-center h-dvh z-30">
          <div className="bg-gray-700 absolute inset-0 opacity-50"></div>
          <SyncLoader
            color="#3B82F6"
            loading={isAssignPending || isPending}
            className="relative"
            size={30}
          />
        </div>
      )}
      <Header title={`Hospitals - ${paginate?.totalItems ?? 0}`} />
      {privileges.createHospital && addHospital && (
        <AddHospitalForm handleClose={handleAddHospital} />
      )}
      <div className="hospitals-container">
        <div className="hospitals-container-top">
          <SearchQuery
            name="search"
            placeholder="Search"
            value={search}
            onChange={(e: { currentTarget: { value: string } }) => {
              updateSearchParams({
                ...filters,
                search: e.currentTarget.value,
                page: "1",
              });
            }}
          />

          <div className="right-options">
            {/* <Select
              name="category"
              placeholder="Select Category"
              options={categories}
              value={category}
              onChange={(e: {
                currentTarget: { value: React.SetStateAction<string> };
              }) => setCategory(e.currentTarget.value)}
            /> */}

            <Select
              name="status"
              placeholder="Hospital Status"
              value={status}
              options={statusCategories}
              onChange={(e: { currentTarget: { value: string } }) => {
                updateSearchParams({
                  ...filters,
                  status: e.currentTarget.value,
                  page: "1",
                });
              }}
            />

            <Select
              name="search_key"
              placeholder="Search By"
              value={searchKey}
              options={searchKeys}
              onChange={(e: {
                currentTarget: { value: React.SetStateAction<string> };
              }) => setSearchKey(e.currentTarget.value)}
            />

            {/* {privileges.createHospital && (
              <Button
                icon="plus"
                label="Add New Hospital"
                onClick={handleAddHospital}
              />
            )} */}
          </div>
        </div>
        <div className="hospitals-container-bottom table-responsive">
          <Dialog.Root open={open} onOpenChange={setOpen}>
            <>
              <div className="max-w-screen-2xl mx-auto px-4 md:px-8 mb-5">
                {/* <>
                  <ProductTable
                    data={filteredHospitals}
                    columns={columns}
                    renderCell={renderCell}
                    totalPages={paginate?.totalPages}
                    currentPage={+page}
                    goToPage={goToPage}
                  />
                </> */}
                <>
                  <Table
                    columns={getSalesAdminColumns()}
                    data={filteredHospitals}
                    sortColumn={sortColumn}
                    onSort={setSortColumn}
                  />
                  <CustomPagination
                    totalPages={paginate?.totalPages}
                    currentPage={+page}
                    goToPage={goToPage}
                  />
                </>
              </div>
            </>

            <Dialog.Portal>
              <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 w-full h-full bg-black opacity-40" />
              <Dialog.Content
                aria-describedby={undefined}
                className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-lg mx-auto px-4"
              >
                <div className="bg-white rounded-md shadow-lg px-4 py-6">
                  <div className="flex items-center justify-end">
                    <Dialog.Close
                      className="p-2 text-gray-400 rounded-md hover:bg-gray-100"
                      // onClick={() => {
                      //   setIsUpload(false);
                      //   setPoUpload(null);
                      // }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5 mx-auto"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </Dialog.Close>
                  </div>
                  <div className="max-w-sm mx-auto space-y-3 ">
                    <Dialog.Title className="text-2xl font-bold text-gray-800 text-center ">
                      Assign Agent
                    </Dialog.Title>

                    <div className="space-y-5">
                      <p className="flex justify-between">
                        <span className="font-semibold inline-block">
                          Hospital Name:
                        </span>
                        <span className="inline-block">{assign?.name}</span>
                      </p>

                      <p className="flex justify-between">
                        <span className="font-semibold inline-block">
                          Current Agent:
                        </span>
                        <span className="inline-block">
                          {assign?.username ?? "Agent not assigned"}
                        </span>
                      </p>
                    </div>
                    <div className="flex justify-between items-center">
                      <span className="font-semibold inline-block">
                        Select New Agent:
                      </span>
                      <div>
                        <ReactSelect
                          options={filterAgents?.map(
                            (hospital: { id: string; name: string }) => ({
                              value: hospital.id,
                              label: hospital.name,
                            })
                          )}
                          onChange={handleSelectAgent}
                        />
                      </div>
                    </div>
                    <button
                      onClick={submitAgent}
                      className=" w-full mt-3 py-3 px-4 font-medium text-sm text-center text-white bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 rounded-lg ring-offset-2 ring-indigo-600 focus:ring-2"
                    >
                      Assign
                    </button>
                    {/* <Dialog.Close asChild>
                    
                    </Dialog.Close> */}
                  </div>
                </div>
              </Dialog.Content>
            </Dialog.Portal>
          </Dialog.Root>

          {/* Change Cadre */}

          <Dialog.Root open={openCadre} onOpenChange={setOpenCadre}>
            <Dialog.Portal>
              <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 w-full h-full bg-black opacity-40" />
              <Dialog.Content
                aria-describedby={undefined}
                className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-lg mx-auto px-4"
              >
                <div className="bg-white rounded-md shadow-lg px-4 py-6 ">
                  <div className="flex items-center justify-between mb-3">
                    <Dialog.Title className="text-xl font-bold text-gray-800 text-center ">
                      Change Cadre
                    </Dialog.Title>
                    <Dialog.Close className="p-2 text-gray-400 rounded-md hover:bg-gray-100">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5 mx-auto"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </Dialog.Close>
                  </div>

                  <div className="space-y-3">
                    <div className="flex justify-between items-center">
                      <span className="font-semibold inline-block">
                        Select Blood Cadre:
                      </span>
                      <div>
                        <ReactSelect
                          options={
                            Array.isArray(cadreList)
                              ? cadreList?.map(
                                  (cadre: { id: string; name: string }) => ({
                                    value: cadre.name,
                                    label: cadre.name,
                                  })
                                )
                              : []
                          }
                          onChange={(value) =>
                            setCadre({
                              ...cadre,
                              blood: value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="flex justify-between items-center">
                      <span className="font-semibold inline-block">
                        Select Oxygen Cadre:
                      </span>
                      <div>
                        <ReactSelect
                          options={
                            Array.isArray(cadreList)
                              ? cadreList?.map(
                                  (cadre: { id: string; name: string }) => ({
                                    value: cadre.name,
                                    label: cadre.name,
                                  })
                                )
                              : []
                          }
                          onChange={(value) =>
                            setCadre({
                              ...cadre,
                              oxygen: value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <button
                      disabled={isSubmitting}
                      onClick={onSubmitCadre}
                      className=" w-full mt-3 py-3 px-4 font-medium text-sm text-center text-white bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 rounded-lg ring-offset-2 ring-indigo-600 focus:ring-2"
                    >
                      {isSubmitting ? "Sumitting" : "Submit Request"}
                    </button>
                  </div>
                </div>
              </Dialog.Content>
            </Dialog.Portal>
          </Dialog.Root>
        </div>
      </div>{" "}
      {hospitalInfoToEdit && (
        <EditProspectForm
          selectedProspect={hospitalInfoToEdit}
          handleClose={() => handleEditSelect(null)}
        />
      )}{" "}
      {hospitalToView && user?.position !== "customer_success" && (
        <ProspectedInfo
          selectedProspect={hospitalToView}
          facilityType={facilityTypes}
          facilitySize={facilitySizes}
          handleClose={() => handleHospitalToView(null)}
        />
      )}
    </React.Fragment>
  );
};

const statusCategories = [
  { id: "new", name: "New" },
  { id: "prospect", name: "Prospect" },
  { id: "pitch", name: "Pitch" },
  { id: "onboard", name: "Onboard" },
  { id: "exodus", name: "Exodus" },
  { id: "active", name: "Active" },
  { id: "inactive", name: "Inactive" },
  { id: "hibernate", name: "Hibernate" },
];

const searchKeys = [
  { id: "name", name: "Name" },
  { id: "email", name: "Email" },
  { id: "phone", name: "Phone" },
  { id: "address", name: "Address" },
];

const salesAdminColumns: TableColumn[] = [
  {
    path: "hospital_id",
    label: "ID",
  },
  {
    path: "name",
    label: "Hospital Name",
    content: (h: Hospital) => (
      <Link to={`/hospitals/${h.hospital_id}`} state={{ status: h.status }}>
        {h.name}
      </Link>
    ),
  },
  {
    path: "email",
    label: "Email Address",
  },
  {
    path: "phone",
    label: "Phone Number",
  },
  {
    path: "status",
    label: "Status",
    content: (h: Hospital) => (
      <button disabled className={"status " + h.status?.toLowerCase?.()}>
        {h.status}
      </button>
    ),
  },
  {
    path: "address",
    label: "Address",
  },
  {
    path: "username",
    label: "LifeBank Agent",
  },
  {
    path: "contact_name",
    label: "Contact",
    content: (h: Hospital) => (
      <span className="text-capitalize">{h.contact_name}</span>
    ),
  },
];
const initialSortColumn: SortColumn = { path: "id", order: "desc" };
export default Hospitals;
