import React, { useEffect, useState } from "react";

import Chart from "react-apexcharts";
import ActiveHospitals from "../charts/activeHospitals";
import CustomSlider from "./customSlider";
import NewlyOnboarded from "../charts/newlyOnboarded";
import TotalHospitals from "../charts/totalHospitals";
import UnfulfilledOrders from "../charts/unfulfilledOrders";

import dashboardService from "../../services/dashboardService";
import toastService from "../../services/toastService";
import { useGlobalContext } from "../../contexts/globalContext";

import { FaHospital } from "react-icons/fa";
import { GiTakeMyMoney } from "react-icons/gi";
import { GiMoneyStack } from "react-icons/gi";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import {
  useGetAgentRevenue,
  useGetTargetRevenue,
  useGetTotalHospitals,
} from "../../hooks/useDashboard";
import { getPrivileges } from "../../services/authService";
import utilities from "../../utils/utilities";
import { ApexOptions } from "apexcharts";
import { Button } from "../../@/components/ui/button";

import * as Dialog from "@radix-ui/react-dialog";
import { Link } from "react-router-dom";
type TransformedRevenue = {
  [key: string]: any; // Consider using a more specific type instead of `any` if possible
};
const transformRevenueObject = (revenue: {
  [x: string]: any;
  hasOwnProperty: (arg0: string) => any;
}) => {
  const transformed: TransformedRevenue = {};
  for (const key in revenue) {
    if (revenue.hasOwnProperty(key)) {
      const newKey = key.replace("_revenue", "");
      transformed[newKey] = revenue[key];
    }
  }
  return transformed;
};
const DashboardTopRow = ({
  breakdown,
  monthData,
  targetData,
}: {
  breakdown: any;
  monthData: any;
  targetData: any;
}) => {
  const permission = getPrivileges();
  const { country, user } = useGlobalContext();

  const getUserId = (user: any) => {
    if (!permission.createAgent) {
      return user;
    }
    return "";
  };
  const { revenue } = useGetAgentRevenue(user?.id as string);

  const transformedRevenue = transformRevenueObject(revenue);
  const totalRevenue = Object.values(transformedRevenue).reduce(
    (sum: number, value) => {
      const numberValue = Number(value);
      return sum + numberValue;
    },
    0
  );

  const { target } = useGetTargetRevenue(user?.id as string);

  const { totalHospitals } = useGetTotalHospitals(
    getUserId(user?.id) as string
  );

  const [showTotal, setShowTotal] = useState(false);
  const [showActive, setShowActive] = useState(false);
  const [showOnboarded, setShowOnboarded] = useState(false);
  const [showUnfulfilled, setShowUnfulfilled] = useState(false);
  const [unfulfilledOrders, setUnfulfilledOrders] = useState<
    Record<string, any>
  >({});

  const handleActiveHospitals = () => setShowActive(false);
  const handleOnboardedHospitals = () => setShowOnboarded(false);
  const handleTotalHospitals = () => setShowTotal(false);
  const handleUnfulfilledOrders = () => setShowUnfulfilled(false);

  const currentMonth = new Date().getMonth() + 1;
  const apiUrl = country?.liteApiUrl;

  const getUnfulfilledOrders = async () => {
    try {
      const response = await dashboardService.getUnfulfilled(apiUrl);

      setUnfulfilledOrders(response.data);
    } catch (error) {
      toastService.error("Couldn't fetch unfulfilled orders");
    }
  };

  const getMonthUnfulfilledByCategory = (month: number) => {
    const totalUnfulfilled: { category: string; monthCount: number }[] = [];

    for (const category in unfulfilledOrders) {
      if (typeof unfulfilledOrders[category] !== "object") continue;
      else {
        const monthData = unfulfilledOrders[category].find(
          (c: { month: string | number }) => +c.month === month
        );

        const monthCount = monthData ? monthData["COUNT(*)"] : 0;

        totalUnfulfilled.push({ category, monthCount });
      }
    }

    return totalUnfulfilled;
  };

  const getRateClassName = (exceededTarget: boolean) => {
    let classes = "rate ";
    classes += exceededTarget ? "increase" : "decrease";

    return classes;
  };

  const getIconClassName = (exceededTarget: boolean) => {
    let classes = "fa fa-long-arrow-";
    classes += exceededTarget ? "up" : "down";

    return classes;
  };

  const getTotalMonthUnfulfilled = () => {
    const currentMonthTotal = currentMonthUnfulfilled.reduce((a, c) => {
      return a + parseInt(String(c.monthCount));
    }, 0);
    const previousMonthTotal = previousMonthUnfulfilled.reduce((a, c) => {
      return a + parseInt(String(c.monthCount));
    }, 0);

    return { currentMonthTotal, previousMonthTotal };
  };

  const currentMonthUnfulfilled = getMonthUnfulfilledByCategory(currentMonth);
  const previousMonthUnfulfilled = getMonthUnfulfilledByCategory(
    currentMonth - 1
  );

  const { currentMonthTotal, previousMonthTotal } = getTotalMonthUnfulfilled();

  const unfulfilledTargetNotMet = currentMonthTotal > previousMonthTotal;
  const divisor = unfulfilledTargetNotMet
    ? currentMonthTotal / previousMonthTotal
    : previousMonthTotal / currentMonthTotal;
  const unfulfilledMargin = Math.floor(divisor * 100);

  useEffect(() => {
    getUnfulfilledOrders();

    // eslint-disable-next-line
  }, []);

  const [open, setOpen] = React.useState(false);
  const handleViewCash = (item: any) => {
    setOpen(true);
  };
  return (
    <React.Fragment>
      {showTotal && (
        <TotalHospitals
          handleClose={handleTotalHospitals}
          data={breakdown?.created_hospitals}
          targets={breakdown?.targets}
          targetExceeded={targetData.totalExceeded}
        />
      )}
      {showActive && (
        <ActiveHospitals
          data={breakdown?.active}
          handleClose={handleActiveHospitals}
          targets={breakdown?.targets}
          targetExceeded={targetData.activeExceeded}
        />
      )}
      {showOnboarded && (
        <NewlyOnboarded
          handleClose={handleOnboardedHospitals}
          data={breakdown?.onboarded}
          targets={breakdown?.targets}
          targetExceeded={targetData.onboardedExceeded}
        />
      )}
      {showUnfulfilled && (
        <UnfulfilledOrders
          unfulfilled={currentMonthUnfulfilled}
          handleClose={handleUnfulfilledOrders}
        />
      )}

      <div className="flex flex-col lg:flex-row  w-full gap-3">
        <Card
          className="p-1 !bg-blue-100 rounded-lg shadow-md"
          sx={{
            minWidth: 275,
            // maxWidth: { sm: 20 },
            boxShadow: "0px 2px 2px 1px rgba(0, 0, 0, 0.25)",
            width: "100%",
            borderRadius: "8px",
            // padding: "20px",
            display: "flex",
            flexDirection: "column",
            mb: "10px",
          }}
        >
          <CardContent className="h-full w-full space-y-4">
            <div className="flex w-full items-center justify-between">
              <div className="bg-blue-300 rounded-full p-3">
                <FaHospital className="text-white w-6 h-6" />
              </div>
              <div>
                <Button asChild className="bg-blue-400 hover:bg-blue-600">
                  <Link to={`/hospitals`}> View</Link>
                </Button>
              </div>
            </div>
            <div className="flex w-full">
              <h2 className="text-sm text-gray-500">Number of Hospitals</h2>
              <div></div>
            </div>
            <div className="flex justify-between w-full">
              <div></div>
              <div className=" ">
                <h3 className="text-3xl lg:text-4xl">
                  {typeof totalHospitals === "object" ? 0 : totalHospitals}
                </h3>
              </div>
            </div>
          </CardContent>
        </Card>
        <Card
          className="p-1 !bg-green-100 rounded-lg shadow-md"
          sx={{
            minWidth: 275,
            // maxWidth: { sm: 20 },
            boxShadow: "0px 2px 2px 1px rgba(0, 0, 0, 0.25)",
            width: "100%",
            borderRadius: "8px",
            // padding: "20px",
            display: "flex",
            flexDirection: "column",
            mb: "10px",
          }}
        >
          <CardContent className="h-full w-full space-y-4">
            <div className="flex w-full">
              <div className="bg-green-300 rounded-full p-3">
                <GiMoneyStack className="text-white w-6 h-6" />
              </div>
              <div></div>
            </div>
            <div className="flex w-full">
              <h2 className="text-sm text-gray-500">Monthly Target</h2>
              <div></div>
            </div>
            <div className="flex justify-between w-full">
              <div></div>
              <div className="flex w-full">
                <h3 className="text-3xl lg:text-4xl">
                  {utilities.currencyFormatter(
                    target?.revenue ?? 0,
                    country?.currency
                  )}
                </h3>
              </div>
            </div>
          </CardContent>
        </Card>
        <Card
          className="p-1 !bg-orange-100 rounded-lg shadow-md"
          sx={{
            minWidth: 275,
            // maxWidth: { sm: 20 },
            boxShadow: "0px 2px 2px 1px rgba(0, 0, 0, 0.25)",
            width: "100%",
            borderRadius: "8px",
            // padding: "20px",
            display: "flex",
            flexDirection: "column",
            mb: "10px",
          }}
        >
          <CardContent className="h-full w-full space-y-4">
            <div className="flex w-full items-center justify-between">
              <div className="bg-orange-300 rounded-full p-3">
                <GiTakeMyMoney className="text-white w-6 h-6" />
              </div>
              {totalRevenue > 0 && (
                <Button
                  onClick={handleViewCash}
                  className="bg-orange-400 hover:bg-orange-600"
                >
                  View
                </Button>
              )}
            </div>
            <div className="flex w-full">
              <h2 className="text-sm text-gray-500">Monthly Actual</h2>
              <div></div>
            </div>
            <div className="flex justify-between w-full">
              <div></div>
              <div className="w-full flex flex-col">
                <h3 className="text-3xl lg:text-4xl">
                  {utilities.currencyFormatter(
                    totalRevenue ?? 0,
                    country?.currency
                  )}
                </h3>
                <span className="opacity-55 text-sm ml-auto">
                  No of Hospital: {revenue?.hospitals_count}
                </span>
              </div>
            </div>
          </CardContent>
        </Card>

        {/* */}

        <Dialog.Root open={open} onOpenChange={setOpen}>
          <Dialog.Portal>
            <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 w-full h-full bg-black opacity-40" />
            <Dialog.Content
              aria-describedby={undefined}
              className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-xl mx-auto px-4"
            >
              <div className="bg-white rounded-md shadow-lg px-4 py-6">
                <div className="flex items-center justify-end">
                  <Dialog.Close className="p-2 text-gray-400 rounded-md hover:bg-gray-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 mx-auto"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Dialog.Close>
                </div>
                <div className="max-w-sm mx-auto space-y-3 ">
                  <Dialog.Title className="text-2xl font-bold text-gray-800 text-center ">
                    Cash breakdown
                  </Dialog.Title>
                </div>{" "}
                <div>
                  <RevenuePieChart data={transformedRevenue} />
                </div>
              </div>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>

        {/* <div>
          <div className="monthly-onboarded-hospitals breakdown-item">
            <h2 className="text-truncate">Monthly Onboarded</h2>

            <div className="breakdown-item-inner">
              <div className="breakdown-item-inner-left">
                <i className="fa fa-envelope-o"></i>
              </div>
              <div className="breakdown-item-inner-right">
                <h3 className="title">{monthData.onboardedCurrent}</h3>
                <div className="bottom">
                  <div
                    className={getRateClassName(targetData.onboardedExceeded)}
                  >
                    <i
                      className={getIconClassName(targetData.onboardedExceeded)}
                    ></i>
                    <span>{targetData.onboardedPercentage}%</span>
                  </div>
                  <div className="description">Monthly target</div>
                </div>
              </div>
            </div>

            <div className="mom-button-container">
              <button className="mom" onClick={() => setShowOnboarded(true)}>
                View Breakdown
              </button>
            </div>
          </div>
        </div>

        <div>
          <div className="monthly-active-hospitals breakdown-item">
            <h2>Monthly Active Hospitals</h2>

            <div className="breakdown-item-inner">
              <div className="breakdown-item-inner-left">
                <i className="fa fa-file-o"></i>
              </div>
              <div className="breakdown-item-inner-right">
                <h3 className="title">{monthData.activeCurrent}</h3>
                <div className="bottom">
                  <div className={getRateClassName(targetData.activeExceeded)}>
                    <i
                      className={getIconClassName(targetData.activeExceeded)}
                    ></i>
                    <span>{targetData.activePercentage}%</span>
                  </div>
                  <div className="description">Monthly target</div>
                </div>
              </div>
            </div>

            <div className="mom-button-container">
              <button className="mom" onClick={() => setShowActive(true)}>
                View Breakdown
              </button>
            </div>
          </div>
        </div>

        <div>
          <div className="monthly-unfulfilled breakdown-item">
            <h2>Monthly Unfulfilled</h2>

            <div className="breakdown-item-inner">
              <div className="breakdown-item-inner-left">
                <i className="fa fa-clock-o"></i>
              </div>
              <div className="breakdown-item-inner-right">
                <h3 className="title">{currentMonthTotal}</h3>
                <div className="bottom">
                  <div className={getRateClassName(unfulfilledTargetNotMet)}>
                    <i
                      className={getIconClassName(unfulfilledTargetNotMet)}
                    ></i>
                    <span>{unfulfilledMargin}%</span>
                  </div>
                  <div className="description">Monthly target</div>
                </div>
              </div>
            </div>

            <div className="mom-button-container">
              <button className="mom" onClick={() => setShowUnfulfilled(true)}>
                View Breakdown
              </button>
            </div>
          </div>
        </div> */}
      </div>
      {/* 
      <CustomSlider className="top-row-breakdown max-w-7xl">
        <div>
          <div className="monthly-total-hospitals breakdown-item">
            <h2>Monthly New Hospitals</h2>
            <div className="breakdown-item-inner">
              <div className="breakdown-item-inner-left">
                <i className="fa fa-bar-chart"></i>
              </div>
              <div className="breakdown-item-inner-right">
                <h3 className="title">{monthData.totalCurrent}</h3>
                <div className="bottom">
                  <div className={getRateClassName(targetData.totalExceeded)}>
                    <i
                      className={getIconClassName(targetData.totalExceeded)}
                    ></i>
                    <span>{targetData.totalPercentage}%</span>
                  </div>
                  <div className="description">Monthly target</div>
                </div>
              </div>
            </div>
            <div className="mom-button-container">
              <button className="mom" onClick={() => setShowTotal(true)}>
                View Breakdown
              </button>
            </div>
          </div>
        </div>

        <div>
          <div className="monthly-onboarded-hospitals breakdown-item">
            <h2 className="text-truncate">Monthly Onboarded</h2>

            <div className="breakdown-item-inner">
              <div className="breakdown-item-inner-left">
                <i className="fa fa-envelope-o"></i>
              </div>
              <div className="breakdown-item-inner-right">
                <h3 className="title">{monthData.onboardedCurrent}</h3>
                <div className="bottom">
                  <div
                    className={getRateClassName(targetData.onboardedExceeded)}
                  >
                    <i
                      className={getIconClassName(targetData.onboardedExceeded)}
                    ></i>
                    <span>{targetData.onboardedPercentage}%</span>
                  </div>
                  <div className="description">Monthly target</div>
                </div>
              </div>
            </div>

            <div className="mom-button-container">
              <button className="mom" onClick={() => setShowOnboarded(true)}>
                View Breakdown
              </button>
            </div>
          </div>
        </div>

        <div>
          <div className="monthly-active-hospitals breakdown-item">
            <h2>Monthly Active Hospitals</h2>

            <div className="breakdown-item-inner">
              <div className="breakdown-item-inner-left">
                <i className="fa fa-file-o"></i>
              </div>
              <div className="breakdown-item-inner-right">
                <h3 className="title">{monthData.activeCurrent}</h3>
                <div className="bottom">
                  <div className={getRateClassName(targetData.activeExceeded)}>
                    <i
                      className={getIconClassName(targetData.activeExceeded)}
                    ></i>
                    <span>{targetData.activePercentage}%</span>
                  </div>
                  <div className="description">Monthly target</div>
                </div>
              </div>
            </div>

            <div className="mom-button-container">
              <button className="mom" onClick={() => setShowActive(true)}>
                View Breakdown
              </button>
            </div>
          </div>
        </div>

        <div>
          <div className="monthly-unfulfilled breakdown-item">
            <h2>Monthly Unfulfilled</h2>

            <div className="breakdown-item-inner">
              <div className="breakdown-item-inner-left">
                <i className="fa fa-clock-o"></i>
              </div>
              <div className="breakdown-item-inner-right">
                <h3 className="title">{currentMonthTotal}</h3>
                <div className="bottom">
                  <div className={getRateClassName(unfulfilledTargetNotMet)}>
                    <i
                      className={getIconClassName(unfulfilledTargetNotMet)}
                    ></i>
                    <span>{unfulfilledMargin}%</span>
                  </div>
                  <div className="description">Monthly target</div>
                </div>
              </div>
            </div>

            <div className="mom-button-container">
              <button className="mom" onClick={() => setShowUnfulfilled(true)}>
                View Breakdown
              </button>
            </div>
          </div>
        </div>
      </CustomSlider> */}
    </React.Fragment>
  );
};
export default DashboardTopRow;

const RevenuePieChart = ({ data }: { data: Record<string, any> }) => {
  const labels = Object.keys(data);
  const values = Object.values(data).map((value) => Number(value));

  const options: ApexOptions = {
    chart: {
      type: "pie",
    },
    labels: labels,
    title: {
      // text: "Cash Breakdown",
      align: "center",
    },
    tooltip: {
      y: {
        formatter: (val) => `${val}`,
      },
    },

    // dataLabels: {
    //   enabled: false, // Disable labels on the chart
    // },
    legend: {
      position: "bottom", // Change to 'bottom' if you prefer the legend at the bottom
    },
    dataLabels: {
      formatter: (val, opts) => {
        const name = opts.w.globals.labels[opts.seriesIndex];
        const value = opts.w.globals.series[opts.seriesIndex];
        return `${name}: ${value}`;
      },
    },
    colors: labels.map((label) => {
      if (label === "blood") return "#b70303"; // Red for blood
      if (label === "oxygen") return "#5286c3"; // Blue for oxygen
      if (label === "quip") return "#F16C03"; // Orange for quip
      if (label === "stockbank") return "#215200"; // Green for stockbank
      return "#999999"; // Default color for others
    }),
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 320,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const series = values;

  return <Chart options={options} series={series} type="pie" width="500" />;
};
