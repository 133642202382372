import React from "react";

import Form from "../common/form";
import Modal from "../common/modal";
import ProgressLoader from "../common/progressLoader";



import ps from "../../services/prospectsService";
import toastService from "../../services/toastService";

class ConfirmClaim extends Form {
  constructor(props) {
    super(props);
    const { selectedProspect, userId } = this.props;

    this.state = {
      data: {
        id: selectedProspect.id,
        user_id: userId,
      },
      errors: {},
      progress: 0,
    };

    // this.schema = {
    //   attendance: Joi.number().required().label("Attendance"),
    //   hit: Joi.number().required().label("Click"),
    //   appointment: Joi.number().required().label("Appointment"),
    //   event_id: Joi.number().required(),
    // };
  }

  

  handleProgressChange = (progress) => {
    this.setState({ progress });
  };

  handleEvent = () => {
    const data = { ...this.state.data };

    this.setState({ data });
  };

  handleStartDate = (startdate) => {
    this.setState({ startdate });
  };

  getApiData = () => {
    const { data } = this.state;

    const apiData = {
      ...data,
    };

    return apiData;
  };

  postData = async () => {
    const apiData = this.getApiData();

    const res = await ps.confirmClaim(apiData, this.handleProgressChange);
    if (res.status !== 200) throw new Error(res.data);

    if (res.status === 200) this.props.handleClose();
  };

  doSubmit = () => {
    toastService.promise(this.postData(), {
      loading: "Processing...",
      success: () => "Account has been updated!",
      error: (e) =>
        e?.response?.data || e?.message || "Could not update this information!",
    });
  };

  render() {
    const { progress } = this.state;

    const { selectedProspect } = this.props;

    return (
      <Modal {...this.props}>
        <ProgressLoader progress={progress} />
        <form onSubmit={this.handleSubmit}>
          <h2 className="form-header text-center mb-3">
            Click below to update{" "}
            <span style={{ color: "#2f49d1" }}>{selectedProspect?.name}</span>{" "}
            information.{" "}
          </h2>

          <div className="d-flex justify-content-center ">
            {/* <LoadingButton visible={loading} message="Please wait" /> */}
            {this.renderButton("Confirm")}
          </div>
        </form>
      </Modal>
    );
  }
}

// const customComponent = WithHospitals(WithSize(WithType(AddPostEventForm)));

export default ConfirmClaim;
