import React, { useEffect, useState } from "react";
import Header from "../../../../components/blocks/header";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { FaCartPlus } from "react-icons/fa";
import SideDrawer from "../../../../components/SideDrawer";
import ItemsCard from "./ItemsCard";
import {
  clearCart,
  decrementItemQuantity,
  incrementItemQuantity,
  removeFromCart,
  updateItemQuantity,
} from "../../../../redux/slices/Cart";
import Badge, { BadgeProps } from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";

import { useSendQuotations } from "../../../../hooks/purchasingOrder/usePurchaseOrder";
import TransitionsModal from "../../../../components/modals/mod/CustomModal";
import { Button } from "../../../../@/components/ui/button";
import { FaCheckCircle } from "react-icons/fa";
import PaymentMethods from "./PaymentMethod";
import toastService from "../../../../services/toastService";
import BackButton from "../../../../components/blocks/backbutton";

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const PurchasingOrder = () => {
  const location = useLocation();
  const pathname = location.pathname.replace("/", "");

  const lastPathname = pathname.split("/").pop();

  const navigate = useNavigate();
  const { mutateQuotation, isPending } = useSendQuotations();
  const [state, setState] = React.useState(false);
  const [paymentType, setPaymentType] = useState("");
  const [isContinue, setIsContinue] = useState(false);

  const cart =
    useSelector((state: { cart: any }) => state?.cart) ?? ([] as any[]);

  const hospitalId = useSelector(
    (state: { cart: any }) => state.cart.hospital_id
  );

  const [quantities, setQuantities] = useState<{ [key: string]: number }>({});

  useEffect(() => {
    const updatedQuantities = cart.items.reduce(
      (acc: { [key: string]: number }, item: { id: string; qty: number }) => ({
        ...acc,
        [item.id]: item.qty || 1,
      }),
      {}
    );
    setQuantities(updatedQuantities);
  }, [cart.items]);

  const handleViewCart = () => {
    setState(true);
  };

  const dispatch = useDispatch();

  const isItemInCart = (item: any) => {
    return cart.items.some((cartItem: { id: any }) => cartItem.id === item.id);
  };

  const handleRemoveFromCart = (prod: any) => {
    const isItemInCart = cart?.items?.some(
      (cartItem: { id: any }) => cartItem.id === prod.id
    );

    if (isItemInCart) {
      dispatch(removeFromCart(prod.id));
    }
  };

  const handleQuantityChange = (
    e: { target: { value: string } },
    id: string
  ) => {
    const newQuantity = e.target.value;

    // Allow the input to be empty
    if (newQuantity === "") {
      setQuantities((prev) => ({ ...prev, [id]: 0 }));
      return;
    }

    const parsedQuantity = Number(newQuantity);

    // Ensure the parsed quantity is a valid number and greater than 0
    if (!isNaN(parsedQuantity) && parsedQuantity >= 1) {
      setQuantities((prev) => ({ ...prev, [id]: parsedQuantity }));
      dispatch(updateItemQuantity({ id, qty: parsedQuantity }));
    }
  };

  const handleIncrement = (id: string) => {
    setQuantities((prev) => {
      const currentQuantity = prev[id] || 0;
      const newQuantity = currentQuantity + 1;

      // Ensure newQuantity is a valid number
      if (!isNaN(newQuantity)) {
        dispatch(updateItemQuantity({ id, qty: newQuantity }));
        return { ...prev, [id]: newQuantity };
      }

      return prev;
    });
  };

  const handleDecrement = (id: string) => {
    setQuantities((prev) => {
      const currentQuantity = prev[id] || 0;
      const newQuantity = currentQuantity > 1 ? currentQuantity - 1 : 1;

      // Ensure newQuantity is a valid number
      if (!isNaN(newQuantity)) {
        dispatch(updateItemQuantity({ id, qty: newQuantity }));
        return { ...prev, [id]: newQuantity };
      }

      return prev;
    });
  };

  const [isOpen, setIsOpen] = useState(false);
  const handleCloseModal = () => {
    setIsOpen(false);
    setIsContinue(false);
  };

  const [period, setPeriod] = useState("");
  const [openAlert, setOpenAlert] = useState(false);

  const handleSubmitQuotation = (period: string) => {
    setPeriod(period);
    const payload = {
      order_time: period,
      hospital_id: hospitalId,
      items: cart.items.map(
        (item: { id: any; name: any; qty: any; quantity: any }) => ({
          item_id: item.id,
          name: item.name,
          qty: item.qty || item.quantity,
        })
      ),
      channel_type: "web",
      payment_method: paymentType,
    };

    mutateQuotation(payload, {
      onSuccess: () => {
        handleCloseModal();
        dispatch(clearCart());
        setOpenAlert(true);
        setState(false);
        setIsContinue(false);
      },
    });
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
    navigate("/purchasing-orders");
  };

  function handlePaymentType(paymentType: any): void {
    setPaymentType(paymentType);
  }

  function handleNavigateBack(): void {
    navigate(-1);
  }

  return (
    <React.Fragment>
      <div className="flex w-full items-center justify-between px-4">
        <div className="w-full">
          {lastPathname !== "purchasing-orders" && (
            <BackButton goBack={handleNavigateBack} />
          )}
          <Header title={"Purchasing Orders"} />
        </div>
        {lastPathname !== "cart" && (
          <IconButton aria-label="cart" onClick={handleViewCart}>
            <StyledBadge badgeContent={cart?.items?.length} color="secondary">
              <FaCartPlus />
            </StyledBadge>
          </IconButton>
        )}
      </div>
      <div className="min-h-screen">
        <Outlet />
      </div>
      {state && (
        <SideDrawer setState={setState} state={state}>
          <>
            <div className="flex justify-between w-full items-center">
              <h1 className="font-semibold">Cart</h1>
              <button
                className="p-2 text-gray-400 rounded-md hover:bg-gray-100"
                onClick={() => {
                  setState(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 mx-auto"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            <>
              {cart?.items?.length > 0 ? (
                <div className="flex flex-col flex-grow h-full">
                  <div>
                    <ItemsCard
                      items={cart.items}
                      selectItem={handleRemoveFromCart}
                      isItemInCart={isItemInCart}
                      handleQuantityChange={handleQuantityChange}
                      handleIncrement={handleIncrement}
                      handleDecrement={handleDecrement}
                      quantities={quantities}
                    />
                  </div>

                  <div className="mt-12 items-start justify-between sm:flex w-full">
                    <button
                      onClick={() => setIsOpen(true)}
                      className="inline-flex items-center justify-center gap-1 py-2 px-3 mt-2 font-medium text-sm text-center text-white bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 rounded-lg sm:mt-0 w-full"
                    >
                      Send for Quotation
                    </button>
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-center w-full h-full">
                  <h1 className="text-gray-400">No items in cart</h1>
                </div>
              )}
            </>
          </>
        </SideDrawer>
      )}

      {/* Success alert */}
      <TransitionsModal
        open={openAlert}
        setOpen={setOpenAlert}
        handleCloseModal={handleCloseAlert}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          // width: "100%",
          bgcolor: "white",
          boxShadow: 24,
          borderRadius: "10px",
        }}
        footer={
          <div className="flex items-center justify-center w-1/2 gap-3">
            <Button
              className="transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-210 w-full active:translate-y-2 active:scale-95"
              type="button"
              onClick={handleCloseAlert}
            >
              Close
            </Button>
          </div>
        }
      >
        <div className="flex flex-col justify-center items-center space-y-8">
          <h2 className="font-semibold lg:text-3xl mb-8">
            Quotation Request Received!
          </h2>
          <div className="">
            <FaCheckCircle className="w-20 h-20 text-green-400 bg-white" />
          </div>
          <p className="text-base text-gray-600">
            Dear customer, Your Quotation request has been received, We will
            revert with your Quotation {period}
          </p>
        </div>
      </TransitionsModal>

      {/* Quotation period */}
      <TransitionsModal
        open={isOpen}
        setOpen={setIsOpen}
        handleCloseModal={handleCloseModal}
        title={"Get Quotation"}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          // width: "100%",
          bgcolor: "white",
          boxShadow: 24,
          borderRadius: "10px",
        }}
      >
        {!isContinue && (
          <>
            <PaymentMethods
              handlePaymentType={handlePaymentType}
              paymentType={paymentType}
            />

            <button
              onClick={() => {
                if (!paymentType) {
                  toastService.error("Please select a payment method");
                  return;
                }
                setIsContinue(true);
              }}
              className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-blue-400 ease-in-out duration-300 delay-150"
            >
              Continue
            </button>
          </>
        )}

        {isContinue && (
          <div className="lg:h-96">
            <h2 className="font-semibold lg:text-3xl mb-8">
              When do you need your quotation?
            </h2>

            <div className="grid lg:grid-cols-3 w-full gap-4">
              <button
                disabled={isPending}
                onClick={() => handleSubmitQuotation("In 24 hours")}
                className="lg:h-32 bg-smokeWhite border    flex flex-col items-center justify-center w-full group px-4 py-2 font-semibold text-sm bg-blue-500 text-white rounded-md shadow-sm  hover:scale-105 ease-in-out duration-300 delay-150"
              >
                <span className="font-semibold text-lg">24 </span> In 24 hours
              </button>
              <button
                disabled={isPending}
                onClick={() => handleSubmitQuotation("In 3 days")}
                className="lg:h-32 bg-smokeWhite border    flex flex-col items-center justify-center w-full group px-4 py-2 font-semibold text-sm bg-blue-500 text-white rounded-md shadow-sm  hover:scale-105 ease-in-out duration-300 delay-150"
              >
                <span className="font-semibold text-lg">3</span> In Three days
              </button>
              <button
                disabled={isPending}
                onClick={() => handleSubmitQuotation("In 7 days")}
                className="lg:h-32 bg-smokeWhite border    flex flex-col items-center justify-center w-full group px-4 py-2 font-semibold text-sm bg-blue-500 text-white rounded-md shadow-sm  hover:scale-105 ease-in-out duration-300 delay-150"
              >
                <span className="font-semibold text-lg">7</span> In Seven days
              </button>
              <div className="hidden lg:block"></div>
              <button
                disabled={isPending}
                onClick={() => handleSubmitQuotation("In 10 days")}
                className="lg:h-32 bg-smokeWhite border flex flex-col items-center justify-center w-full group px-4 py-2 font-semibold text-sm bg-blue-500 text-white rounded-md shadow-sm  hover:scale-105 ease-in-out duration-300 delay-150"
              >
                <span className="font-semibold text-lg  ">10</span>{" "}
                <span className="flex group-hover:text-white">
                  {" "}
                  In Ten days
                </span>
              </button>
              <div></div>
            </div>
          </div>
        )}
      </TransitionsModal>
    </React.Fragment>
  );
};

export default PurchasingOrder;
