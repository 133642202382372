import React, { useState } from "react";
import Button from "../../common/button";
import { success, error } from "../../../services/toastService";
import { useForm } from "react-hook-form";
import BackButton from "../backbutton";
import DangerMasteryCallLog from "./DangerMasteryCallLog";
import { dangerMasteryKeys } from "./constants";
import { DangerMasteryData, DangerMasteryKeys } from "./types";
import CustomField from "../../common/CustomField";
import {
  useCreateCallLog,
  useUpdateDangerHospitals,
} from "../../../hooks/useHospital";


import utilities from "../../../utils/utilities";
import { useGlobalContext } from "../../../contexts/globalContext";

type Props = {
  setShowMastery: React.Dispatch<React.SetStateAction<boolean>>;
  refId: string;
  handleClose: () => void;
  score: any;
};

const defaultValues: DangerMasteryData = {
  [dangerMasteryKeys.missed_maintenance]: {
    date: "",
    hospital_agent: "",
    questions: "",
    placed_order: "",
    reason_for_no_order: "",
    issue_with_product: "",
    anything_to_help: "",
  },
  [dangerMasteryKeys.placed_manual_order]: {
    date: "",
    hospital_agent: "",
    questions: "",
    placed_order: "",
    reason_for_no_order: "",
    issue_with_product: "",
    anything_to_help: "",
  },
  [dangerMasteryKeys.hot_complaint]: {
    date: "",
    hospital_agent: "",
    questions: "",
    complaint: "",
    complaint_detail: "",
    issue_with_product: "",
    issue_with_nerve: "",
    assist_with_complaint: "",
    anything_to_help: "",
  },
  [dangerMasteryKeys.late_delivery]: {
    date: "",
    hospital_agent: "",
    questions: "",
    late_delivery: "",
    late_delivery_effect: "",
    manage_late_delivery: "",
    anything_to_help: "",
  },
};

export default function MasterDanger({
  setShowMastery,
  refId,
  handleClose,
  score,
}: Props) {
  const { user } = useGlobalContext();
  const { mutateCallLog, isPending } = useCreateCallLog(refId);
  const { updateDangerHospitals } = useUpdateDangerHospitals();
  const [logData, setLogData] = useState({
    location: "",
  });
  const [position, setPosition] = useState<any>(false);
  // const data: any = {};

  const location: any = [];
  if (logData.location) {
    location.push(parseFloat(logData.location.split(",")[0]));
    location.push(parseFloat(logData.location.split(",")[1]));
  }
  const [formCompletion, setFormCompletion] = useState({
    maintenance_issue_resolved: 0,
    manual_issue_resolved: 0,
    complaint_issue_resolved: 0,
    latedelivery_issue_resolved: 0,
  });
  const [callInfo, setCallInfo] = useState<{
    show: boolean;
    key: DangerMasteryKeys;
  }>({
    show: false,
    key: dangerMasteryKeys.missed_maintenance,
  });

  const {
    handleSubmit: submitMissedMaintenance,
    control: controlMissedMaintenance,
    formState: { errors: errorsMissedMaintenance },
    reset: resetMissedMaintenance,
  } = useForm<DangerMasteryData>({
    defaultValues,
  });

  const {
    handleSubmit: submitPlacedManualOrder,
    control: controlPlacedManualOrder,
    formState: { errors: errorsPlacedManualOrder },
    reset: resetPlacedManualOrder,
  } = useForm<any>({
    defaultValues,
  });

  const {
    handleSubmit: submitHotComplaint,
    control: controlHotComplaint,
    formState: { errors: errorsHotComplaint },
    reset: resetHotComplaint,
  } = useForm<any>({
    defaultValues,
  });

  const {
    handleSubmit: submitLateDelivery,
    control: controlLateDelivery,
    formState: { errors: errorsLateDelivery },
    reset: resetLateDelivery,
  } = useForm<any>({
    defaultValues,
  });

  const onMaintenanceSubmit = (data: any) => {
    const issues = [];
    const others = [];
    if (data.missed_maintenance.issue_with_product) {
      issues.push(data.missed_maintenance.issue_with_product);
    }

    if (data.missed_maintenance.anything_to_help) {
      others.push(data.missed_maintenance.anything_to_help);
    }

    const updatedData = {
      hospital_ref: refId,
      customer_success_rep_ref: user?.id,
      call_date: utilities.formatDate(
        data.missed_maintenance.date,
        "YYYY-MM-DD HH:mm:ss"
      ),
      hospital_agent: data.missed_maintenance.hospital_agent,
      placedorder: data.missed_maintenance.placed_order,
      actiontype: "danger",
      log_type: "missed-maintenance",
      log_data: {
        reason: data.missed_maintenance.reason_for_no_order,
        issueslifebank: issues,
        others: others,
      },
    };
    mutateCallLog(updatedData, {
      onSuccess: (data) => {
        setCallInfo({ show: false, key: dangerMasteryKeys.missed_maintenance });
        success(
          "Missed maintenance call logged successful",
          "danger-mastery-success"
        );
        setFormCompletion({ ...formCompletion, maintenance_issue_resolved: 1 });
        resetMissedMaintenance();
      },
      onError: (err) => {
        console.log(err);
        error(
          "An error occurred while logging missed maintenance call",
          "danger-mastery-error"
        );
      },
    });
  };
  const onPlacedManualOrderSubmit = (data: any) => {
    const issues = [];
    const others = [];
    if (data.placed_manual_order.issue_with_product) {
      issues.push(data.placed_manual_order.issue_with_product);
    }

    if (data.placed_manual_order.anything_to_help) {
      others.push(data.placed_manual_order.anything_to_help);
    }

    const updatedData = {
      hospital_ref: refId,
      customer_success_rep_ref: user?.id,
      hospital_agent: data.placed_manual_order.hospital_agent,
      call_date: utilities.formatDate(
        data.placed_manual_order.date,
        "YYYY-MM-DD HH:mm:ss"
      ),
      actiontype: "danger",
      log_type: "off-nerve-order",
      log_data: {
        placedmanualorder: data.placed_manual_order.placed_order,
        reason: data.placed_manual_order.reason_for_no_order,
        issueslifebank: issues,
        others: others,
      },
    };
    mutateCallLog(updatedData, {
      onSuccess: (data) => {
        setCallInfo({
          show: false,
          key: dangerMasteryKeys.placed_manual_order,
        });
        success(
          "Placed manual order call logged successful",
          "danger-mastery-success"
        );
        setFormCompletion({ ...formCompletion, manual_issue_resolved: 1 });
        resetPlacedManualOrder();
      },
      onError: (err) => {
        console.log(err);
        error(
          "An error occurred while logging Placed manual order call",
          "danger-mastery-error"
        );
      },
    });
  };
  const onHotComplaintSubmit = (data: any) => {
    const issues = [];
    const others = [];
    const nerveIssue = [];
    const resolution = [];
    const reason = data.hot_complaint.complaint_details;
    if (data.hot_complaint.issue_with_product) {
      issues.push(data.hot_complaint.issue_with_product);
    }

    if (data.hot_complaint.anything_to_help) {
      others.push(data.hot_complaint.anything_to_help);
    }
    if (data.hot_complaint.assist_with_complaint) {
      others.push(data.hot_complaint.assist_with_complaint);
    }

    if (data.hot_complaint.issue_with_nerve) {
      nerveIssue.push(data.hot_complaint.issue_with_nerve);
    }

    if (data.hot_complaint.assist_with_complaint) {
      resolution.push(data.hot_complaint.assist_with_complaint);
    }

    const updatedData = {
      geo_location: location,
      hospital_ref: refId,
      customer_success_rep_ref: user?.id,
      call_date: utilities.formatDate(
        data.hot_complaint.date,
        "YYYY-MM-DD HH:mm:ss"
      ),
      hospital_agent: data.hot_complaint.hospital_agent,
      actiontype: "danger",
      log_type: "hot-complaint",
      complaint: data.hot_complaint.complaint,
      log_data: {
        placedmanualorder: data.hot_complaint.placed_order,
        reason: reason,
        issueslifebank: issues,
        issuesnerve: nerveIssue,
        others: others,
        resolutions: resolution,
      },
    };
    mutateCallLog(updatedData, {
      onSuccess: (data) => {
        setCallInfo({ show: false, key: dangerMasteryKeys.hot_complaint });
        success(
          "Hot complaint call logged successful",
          "danger-mastery-success"
        );
        setFormCompletion({ ...formCompletion, complaint_issue_resolved: 1 });
        resetHotComplaint();
        setLogData({
          location: "",
        });
        setPosition(false);
      },
      onError: (err) => {
        console.log(err);
        error(
          "An error occurred while logging Hot complaint call",
          "danger-mastery-error"
        );
      },
    });
  };
  const onLateDeliverySubmit = (data: any) => {
    const lateDeliveryEffect = [];
    const others = [];
    const manageLateDelivery = [];
    if (data.late_delivery.late_delivery_effect) {
      lateDeliveryEffect.push(data.late_delivery.late_delivery_effect);
    }

    if (data.late_delivery.anything_to_help) {
      others.push(data.late_delivery.anything_to_help);
    }

    if (data.late_delivery.manage_late_delivery) {
      manageLateDelivery.push(data.late_delivery.manage_late_delivery);
    }

    const updatedData = {
      hospital_ref: refId,
      customer_success_rep_ref: user?.id,
      call_date: utilities.formatDate(
        data.late_delivery.date,
        "YYYY-MM-DD HH:mm:ss"
      ),
      hospital_agent: data.late_delivery.hospital_agent,
      placedorder: data.late_delivery.placed_order,
      actiontype: "danger",
      log_type: "late-delivery",
      log_data: {
        latedeliveyeffect: lateDeliveryEffect,
        others: others,
        resolutions: manageLateDelivery,
      },
    };
    mutateCallLog(updatedData, {
      onSuccess: (data) => {
        setCallInfo({ show: false, key: dangerMasteryKeys.late_delivery });
        success(
          "Late delivery call logged successful",
          "danger-mastery-success"
        );
        setFormCompletion({
          ...formCompletion,
          latedelivery_issue_resolved: 1,
        });
        resetLateDelivery();
      },
      onError: (err) => {
        console.log(err);
        error(
          "An error occurred while logging Late delivery call",
          "danger-mastery-error"
        );
      },
    });
  };

  const handleFinishDangerMastery = () => {
    const payload = {
      ...formCompletion,
      refId,
    };
    updateDangerHospitals(payload, {
      onSuccess: (data) => {
        success("Danger Mastery successful", "success");
        handleClose();
      },
      onError: (err) => {
        console.log(err);
        error("An error occurred while Mastering Danger", "error");
      },
    });
  };

  return (
    <>
      <div>
        {callInfo.show ? (
          <DangerMasteryCallLog
            setCallInfo={setCallInfo}
            loading={isPending}
            control={{
              controlMissedMaintenance: controlMissedMaintenance,
              controlPlacedManualOrder: controlPlacedManualOrder,
              controlHotComplaint: controlHotComplaint,
              controlLateDelivery: controlLateDelivery,
            }}
            errors={
              callInfo.key === "missed_maintenance"
                ? errorsMissedMaintenance
                : callInfo.key === "placed_manual_order"
                ? errorsPlacedManualOrder
                : callInfo.key === "hot_complaint"
                ? errorsHotComplaint
                : errorsLateDelivery
            }
            objKey={callInfo.key}
            submit={{
              submitMissedMaintenance:
                submitMissedMaintenance(onMaintenanceSubmit),
              submitPlacedManualOrder: submitPlacedManualOrder(
                onPlacedManualOrderSubmit
              ),
              submitHotComplaint: submitHotComplaint(onHotComplaintSubmit),
              submitLateDelivery: submitLateDelivery(onLateDeliverySubmit),
            }}
            setLogData={setLogData}
            position={position}
            setPosition={setPosition}
            logData={logData}
          />
        ) : (
          <React.Fragment>
            <BackButton
              goBack={() => {
                setShowMastery(false);
              }}
            />
            <div className="my-8">
              <CustomField
                title={
                  <>
                    <span>Missed Maintenance Schedule - </span>
                    <span
                      className={`px-1 rounded ${
                        (score?.placedorder ? 2.5 : 0) < 2.5
                          ? "text-red-500 bg-red-100"
                          : "text-green-500 bg-green-100"
                      }`}
                    >
                      {score?.placedorder ? 2.5 : 0}
                    </span>
                  </>
                }
                value={
                  <Button
                    disabled={score?.placedorder}
                    onClick={() =>
                      setCallInfo({
                        show: true,
                        key: dangerMasteryKeys.missed_maintenance,
                      })
                    }
                    label="Resolve Issue"
                    type="button"
                    style={{ marginTop: "0px" }}
                  />
                }
              />
              <CustomField
                title={
                  <>
                    <span>Hospital has placed a Manual Order - </span>
                    <span
                      className={`px-1 rounded ${
                        (score?.usenerve ? 2.5 : 0) < 2.5
                          ? "text-red-500 bg-red-100"
                          : "text-green-500 bg-green-100"
                      }`}
                    >
                      {score?.usenerve ? 2.5 : 0}
                    </span>
                  </>
                }
                value={
                  <Button
                    disabled={score?.usenerve}
                    onClick={() =>
                      setCallInfo({ show: true, key: "placed_manual_order" })
                    }
                    label="Resolve Issue"
                    type="button"
                    style={{ marginTop: "0px" }}
                  />
                }
              />
              <CustomField
                title={
                  <>
                    <span>Hot Complaint - </span>
                    <span
                      className={`px-1 rounded ${
                        (score?.nocomplaint ? 2.5 : 0) < 2.5
                          ? "text-red-500 bg-red-100"
                          : "text-green-500 bg-green-100"
                      }`}
                    >
                      {score?.nocomplaint ? 2.5 : 0}
                    </span>
                  </>
                }
                value={
                  <Button
                    disabled={score?.nocomplaint}
                    onClick={() =>
                      setCallInfo({ show: true, key: "hot_complaint" })
                    }
                    label="Resolve Issue"
                    type="button"
                    style={{ marginTop: "0px" }}
                  />
                }
              />
              <CustomField
                title={
                  <>
                    <span>Late Delivery - </span>
                    <span
                      className={`px-1 rounded ${
                        (score?.earlydelivery ? 2.5 : 0) < 2.5
                          ? "text-red-500 bg-red-100"
                          : "text-green-500 bg-green-100"
                      }`}
                    >
                      {score?.earlydelivery ? 2.5 : 0}
                    </span>
                  </>
                }
                value={
                  <Button
                    disabled={score?.earlydelivery}
                    onClick={() =>
                      setCallInfo({ show: true, key: "late_delivery" })
                    }
                    label="Resolve Issue"
                    type="button"
                    style={{ marginTop: "0px" }}
                  />
                }
              />
            </div>
            <Button
              label="Finish Danger"
              type="button"
              onClick={handleFinishDangerMastery}
            />
          </React.Fragment>
        )}
      </div>
    </>
  );
}
