import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import marketingService from "../../services/marketingService";
import { AxiosResponse } from "axios";
import toastService from "../../services/toastService";

interface ResponseData {
  [x: string]: any;
  description: string;
  data: any;
  meta: any;
}

export const useGetDrips = () => {
  const { isError, isSuccess, data, isPending, error } = useQuery({
    queryKey: ["drips"],
    queryFn: async () => {
      const response =
        (await marketingService.getDrips()) as AxiosResponse<ResponseData>;
      return response;
    },
  });

  const drips: any = (data as AxiosResponse<ResponseData>)?.data?.data || [];

  const baseUrl = (data as AxiosResponse<ResponseData>)?.data?.baseurl;
  if (isError) {
    // toastService.error((error as AxiosError)?.message);
    // toastService.error("No drips found");
  }

  return { isError, isSuccess, drips, baseUrl, isPending, error };
};

export const useCreateDrip = (
  handleProgressChange: (progress: number) => void
) => {
  const queryClient = useQueryClient();
  const { mutate, isPending, error } = useMutation({
    mutationFn: async (data: any) => {
      const response = await marketingService.createDrip(
        data,
        handleProgressChange
      );
      return response;
    },
    onSuccess: (data: any) => {
      toastService.success("Drips created successfully!");
      queryClient.invalidateQueries({
        queryKey: ["drips"],
      });
    },
    onError: (error: { message: string }) => {
      toastService.error(error.message || "Failed to create drip");
    },
  });

  return { mutate, isPending, error };
};

export const useGetHospitalValues = () => {
  const { isError, isSuccess, data, isPending, error } = useQuery({
    queryKey: ["hospital-value"],
    queryFn: async () => {
      const response =
        (await marketingService.getAllDrips()) as AxiosResponse<ResponseData>;
      return response;
    },
  });

  const hospitalValue: any =
    (data as AxiosResponse<ResponseData>)?.data?.data || [];

  if (isError) {
    toastService.error("No hospitals value found");
  }

  return { isError, isSuccess, hospitalValue, isPending, error };
};
