import http from "./httpService";

export const getAllAppointments = (userId) => {
  return http.get("/marketing/appointment/" + userId);
};

export const getAllAcquisition = (city) => {
  return http.get("acquisition/appointment/" + city);
};

export const getPitchInformation = (hospitalId) => {
  return http.get("acquisition/pitch/information/" + hospitalId);
};

export const getPendingRequests = (city) => {
  return http.get("acquisition/close/pending/" + city);
};

export const getSinglePendingRequests = (id) => {
  return http.get("acquisition/single/closed/" + id);
};

export const getClosedRequests = () => {
  return http.get("acquisition/closed");
};

export const getActiveHospitals = () => {
  return http.get("acquisition/active");
};

export const getOnboardChecklist = (id) => {
  return http.get("/acquisition/checklist/" + id);
};

export const getSuccessActive = () => {
  return http.get("/acquisition/success/active");
};

export const getMaintenanceSchedule = () => {
  return http.get("/acquisition/schedule/active");
};

export const getHospitalScoreCard = (id) => {
  return http.get("/acquisition/hospitals/score/" + id);
};

export const sendToClose = (data, handleProgressChange) => {
  const options = {
    onUploadProgress: (progressEvent) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  return http.post("/acquisition/sendtoclosed", data, options);
};

export const closeAppointment = (data, handleProgressChange) => {
  const options = {
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress: (progressEvent) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  return http.post("/acquisition/closed", data, options);
};

export const scheduleAppointment = (data, handleProgressChange) => {
  const options = {
    onUploadProgress: (progressEvent) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  return http.post("/acquisition/schedule/onboard", data, options);
};

export const scheduleActive = (data, handleProgressChange) => {
  const options = {
    onUploadProgress: (progressEvent) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  return http.post("/acquisition/schedule/active", data, options);
};

export const sendEmail = (id, handleProgressChange) => {
  const options = {
    onUploadProgress: (progressEvent) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  return http.post("/acquisition/checklist/email/" + id, options);
};

export const completeChecklist = (id, handleProgressChange) => {
  const options = {
    onUploadProgress: (progressEvent) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  return http.post("/acquisition/checklist/completed/" + id, options);
};

export const confirmTraining = (id, handleProgressChange) => {
  const options = {
    onUploadProgress: (progressEvent) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  return http.post("/acquisition/checklist/training/" + id, options);
};

export const uploadSla = (id, data, handleProgressChange) => {
  const options = {
    onUploadProgress: (progressEvent) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  return http.post("/acquisition/checklist/sla/" + id, data, options);
};

export const sendToSuccess = (id, handleProgressChange) => {
  const options = {
    onUploadProgress: (progressEvent) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  return http.put("/acquisition/sucess/handover/" + id, options);
};

export const alertEveryone = (data, handleProgressChange) => {
  const options = {
    onUploadProgress: (progressEvent) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  return http.post("/acquisition/alert/everyone", data, options);
};

export const sendGift = (data, handleProgressChange) => {
  const options = {
    onUploadProgress: (progressEvent) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  return http.post("/acquisition/send/active/gift", data, options);
};

export const updateAppointment = (data, handleProgressChange) => {
  const options = {
    onUploadProgress: (progressEvent) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  return http.post("/acquisition/change/appointment/date", data, options);
};

const appointmentService = {
  alertEveryone,
  confirmTraining,
  completeChecklist,
  closeAppointment,
  getActiveHospitals,
  getAllAppointments,
  getAllAcquisition,
  getClosedRequests,
  getHospitalScoreCard,
  getPendingRequests,
  getPitchInformation,
  getOnboardChecklist,
  getSinglePendingRequests,
  getMaintenanceSchedule,
  getSuccessActive,
  scheduleAppointment,
  sendEmail,
  sendGift,
  sendToClose,
  sendToSuccess,
  uploadSla,
  updateAppointment,
};

export default appointmentService;
