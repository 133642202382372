import React from "react";

const SearchQuery = (props) => {
  return (
    <div className="search-query">
      <input type="text" className="search-query-input" {...props} />
      <div className="search-query-icon">
        <i className="fa fa-search"></i>
      </div>
    </div>
  );
};

export default SearchQuery;
