import pipelineService from "../services/pipeline.service";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { AxiosResponse } from "axios";
import toastService from "../services/toastService";
import { getPrivileges } from "../services/authService";

const permission = getPrivileges();

export const useGetPipeline = (
  id: string | undefined,
  city?: string | undefined
) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["pipeline", city, id],

    queryFn: async () => {
      const response = (await pipelineService.getPipelineData(
        city,
        id
      )) as AxiosResponse<any[]>;
      return response;
    },
    enabled: permission.exodus,
  });

  const pipeline: any = data?.data || [];

  return { isError, isSuccess, pipeline, isPending };
};

export const useReadyToPitch = () => {
  const queryClient = useQueryClient();
  const { mutate, isPending, error } = useMutation({
    mutationFn: async (data: {
      hospital_id: string | number | null;
      user_id: string;
    }) => {
      const response = await pipelineService.readyToPitch(data);
      return response;
    },
    onSuccess: (data: any) => {
      toastService.success("Pipeline updated successfully!");
      queryClient.invalidateQueries({
        queryKey: ["pipeline", "dashboard"],
      });
    },
    onError: (error: { message: string }) => {
      toastService.error(error.message || "Failed to update pipeline");
    },
  });

  return { mutate, isPending, error };
};

export const useNerveMessage = (id: string) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["nerve-message", id],
    queryFn: async () => {
      const response = await pipelineService.nerveUsageMessages(id);
      return response.data;
    },
  });

  const messages: any = data?.actions || [];

  return { isError, isSuccess, messages, isPending };
};
