import React from "react";

import Conditional from "../blocks/conditional";
import LoadingButton from "../common/loadingButton";
import Modal from "../common/modal";
import NoItems from "../blocks/noItems";

import useHospitalHistory from "../../hooks/useHospitalHistory";

const PitchHistory = ({ selectedPitch, handleClose }) => {
  const { history, loading } = useHospitalHistory(selectedPitch.hospital_id);

  return (
    <Modal handleClose={handleClose}>
      <h3 className="modal-title">Pitch History For {history?.data?.name}</h3>

      <LoadingButton visible={loading} message="Fetching History" />

      <Conditional isVisible={!loading}>
        <ul className="complaint-modal-items">
          {history.direct &&
            history.direct.map((h) => (
              <li key={h.date} className="complaint-modal-items-single">
                <strong>{h.event} pitch</strong> by <strong>{h.agent}</strong>{" "}
                on <strong>{h.date}</strong>
              </li>
            ))}
          {history.telesales &&
            history.telesales.map((h) => (
              <li key={h.date} className="complaint-modal-items-single">
                <strong>{h.event} </strong> pitch by <strong>{h.agent}</strong>{" "}
                to <strong>{history?.data?.name}</strong> on{" "}
                <strong>{h.date}</strong>
              </li>
            ))}
        </ul>
      </Conditional>

      <NoItems
        visible={
          history.direct?.length === 0 && history.telesales?.length === 0
        }
        label="No pitches to show"
      />
    </Modal>
  );
};

export default PitchHistory;
