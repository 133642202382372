import React from "react";

interface PDFButtonProps {
  title: string;
  href: string;
}

const PDFButton = ({ title, href }: PDFButtonProps) => {
  return (
    <div className="download-link">
      <a
        href={href}
        rel="noopener noreferrer nofollow"
        target="_blank"
        download={title}
      >
        Download PDF
      </a>
      <i className="fa fa-file-pdf-o"></i>
    </div>
  );
};

export default PDFButton;
