import httpService from "./httpService";

export const getActiveHospital = async (
  user_id: string,
  page?: number,
  limit?: number
) => {
  return await httpService.get(`/acquisition/success/active/${user_id}`);
};

const activeHospitalService = {
  getActiveHospital,
};

export default activeHospitalService;
