import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

import Button from "../common/button";
import LoadingButton from "../common/loadingButton";
import NoItems from "./noItems";

import comma from "../../utils/comma";
import utilities from "../../utils/utilities";

import authService from "../../services/authService";
import dashboardService from "../../services/dashboardService";
import saveFile from "../../services/pdfService";

import withProductList from "../hoc/withProductList";
import { useGlobalContext } from "../../contexts/globalContext";

const LeadershipBoard = ({ productList, loading: productsLoading }) => {
  const [activeCategory, setActiveCategory] = useState("blood");
  const [leadershipBoard, setLeadershipBoard] = useState({});
  const [loading, setLoading] = useState(false);

  const { city, country } = useGlobalContext();
  const { liteApiUrl } = country;

  const getLeadershipBoard = async () => {
    try {
      setLoading(true);

      const res = await dashboardService.getLeadershipBoard(liteApiUrl, city);
      const board = utilities.parseKeysToLowercase(res.data);
      setLeadershipBoard(board);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getActiveData = () => {
    const allItems = utilities.mapArrayFromObjects(leadershipBoard);
    const data = leadershipBoard[activeCategory] ?? allItems;

    data.sort((a, b) => parseInt(b.cost) - parseInt(a.cost));
    return data;
  };

  const privileges = authService.getPrivileges();
  const activeData = getActiveData();

  useEffect(() => {
    getLeadershipBoard();

    // eslint-disable-next-line
  }, [city]);

  return (
    <React.Fragment>
      <LoadingButton
        visible={loading || productsLoading}
        message="Fetching leadership board"
      />

      {!loading && !productsLoading && (
        <React.Fragment>
          <div className="top-header">
            <h2>Leadership Board</h2>
            <button
              className="full-report"
              onClick={() => saveFile(activeData)}
            >
              Download
            </button>
          </div>

          <p className="sales-paragraph">Top sales of this month</p>

          <div className="flex items-center w-full gap-1 mt-2">
            {productList.map((product) => {
              const productName = product.name.toLowerCase();

              return (
                <Button
                  key={product.id}
                  label={productName}
                  onClick={() => setActiveCategory(productName)}
                  className={
                    productName === activeCategory
                      ? "flex items-center justify-center w-full p-1.5 rounded-sm text-white active"
                      : "flex items-center justify-center w-full p-1.5 rounded-sm text-white  disabled"
                  }
                  style={{ backgroundColor: product.color }}
                />
              );
            })}
          </div>

          <div className="leadership-board">
            {activeData.map((l) => {
              return (
                <div className="leadership-board-item" key={l.ref + l.time}>
                  <div className="left-item">
                    <p className="title">{l.name.substring(0, 15)}...</p>
                    <p className="subtitle">
                      Last Order: {dayjs(l.time).format("YYYY-MM-DD hh:mm:ss")}
                    </p>
                  </div>
                  <div className="right-item">
                    <p className="price">
                      {country.currency}
                      {privileges.viewLeaderboardPrice
                        ? comma.addComma(l.cost)
                        : "******"}
                    </p>
                    <p className="inner error">{l.qty} Units</p>
                  </div>
                </div>
              );
            })}
          </div>
        </React.Fragment>
      )}

      <div className="flex items-center justify-center mt-5">
        <NoItems isVisible={activeData.length === 0} label="No data to show" />
      </div>
    </React.Fragment>
  );
};

export default withProductList(React.memo(LeadershipBoard));
