interface NoItemsModel {
  label: string;
  isVisible: boolean;
}

const NoItems = (props: NoItemsModel): JSX.Element | null => {
  if (!props.isVisible) return null;

  return (
    <div className="no-items">
      <p>{props.label}</p>
    </div>
  );
};

export default NoItems;
