import http from "./httpService";

export const getAllEvents = () => {
  return http.get("/marketing/event");
};

// get single event
export const getEvent = (eventid) => {
  return http.get(`/marketing/event/${eventid}`);
};

export const createEvent = (data, handleProgressChange) => {
  const options = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    onUploadProgress: (progressEvent) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  return http.post("/marketing/create/event", data, options);
};

export const archiveEvent = (eventid, handleProgressChange) => {
  const options = {
    onUploadProgress: (progressEvent) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  return http.patch(`/marketing/archive/event/${eventid}`, options);
};

export const updateEvent = (eventid, data, handleProgressChange) => {
  const options = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    onUploadProgress: (progressEvent) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  return http.post(`/marketing/update/event/${eventid}`, data, options);
};

export const createPostEvent = (data, handleProgressChange) => {
  //
  const options = {
    onUploadProgress: (progressEvent) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  return http.post("/marketing/post/event", data, options);
};

export const getAllTools = () => {
  return http.get("/marketing/tool");
};

export const createTool = (data, handleProgressChange) => {
  const options = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    onUploadProgress: (progressEvent) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  return http.post("/marketing/create/tool", data, options);
};

export const editTool = (data, id) => {
  const options = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  };
  return http.post(`/marketing/update/tool/${id}`, data, options);
};

export const getAllStories = () => {
  return http.get("/marketing/stories");
};

export const createStory = (data, handleProgressChange) => {
  const options = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    onUploadProgress: (progressEvent) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  return http.post("/marketing/create/story", data, options);
};
export const editStory = (data, handleProgressChange, id) => {
  const options = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    onUploadProgress: (progressEvent) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };
  return http.post(`/marketing/update/story/${id}`, data, options);
};

export const getDrips = () => {
  return http.get("/marketing/drip/all");
};

export const getAllDrips = () => {
  return http.get("/marketing/drip/type");
};

export const createDrip = (data, handleProgressChange) => {
  const options = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    onUploadProgress: (progressEvent) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  return http.post("/marketing/create/drip", data, options);
};

export const sendAcquisition = (data) => {
  // const options = {
  //   onUploadProgress: (progressEvent) =>
  //     handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  // };

  return http.post("/marketing/send/acquisition", data);
};

export const createDazzle = (data, handleProgressChange) => {
  const options = {
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress: (progressEvent) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  return http.post("/marketing/dazz", data, options);
};

export const getAllDazz = () => {
  return http.get("/marketing/dazz");
};

const marketingService = {
  getAllEvents,
  getEvent,
  createEvent,
  createPostEvent,
  getAllTools,
  editStory,
  createTool,
  editTool,
  getAllStories,
  createStory,
  sendAcquisition,
  createDazzle,
  createDrip,
  getAllDazz,
  getAllDrips,
  getDrips,
  archiveEvent,
  updateEvent,
};

export default marketingService;
