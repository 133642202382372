import { AxiosProgressEvent, AxiosRequestConfig } from "axios";
import httpService from "./httpService";
import queryString from "query-string";

export const getAgents = (
  page?: number,
  limit?: number,
  search?: string,
  position?: string
) => {
  const queryParams = new URLSearchParams();

  if (position) {
    queryParams.append("position", position);
  }

  if (page) {
    queryParams.append("page", page.toString());
  }

  if (limit) {
    queryParams.append("limit", limit.toString());
  }

  if (search) {
    queryParams.append("username", search);
  }

  return httpService.get(`/authentication/users?${queryParams.toString()}`);
};

export const getCityAgents = (city: string): Promise<any> => {
  return httpService.get("/leader/" + city);
};

export const getSaleAgents = (city: string) => {
  return httpService.get("/leader/position/sale/" + city);
};

export const assignAgent = (
  data: any,
  handleProgressChange?: (progress: number) => void
): Promise<any> => {
  const options: AxiosRequestConfig = {
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      if (progressEvent.total !== undefined) {
        handleProgressChange &&
          handleProgressChange(
            (progressEvent.loaded * 100) / progressEvent.total
          );
      }
    },
  };

  return httpService.post("/manage/assign/hos", data, options);
};

export const reassignAgent = (
  data: Record<string, any>,
  handleProgressChange: { (progress: any): void; (arg0: number): any }
) => {
  const options: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      if (progressEvent.total !== undefined) {
        handleProgressChange(
          (progressEvent.loaded * 100) / progressEvent.total
        );
      }
    },
  };

  return httpService.post(
    "/manage/reassign/pitch",
    queryString.stringify(data),
    options
  );
};

export const createAgent = (
  data: {
    username: string;
    email: string;
    password: string;
    confirmPassword: string;
    position: string;
    city: string;
    country: string;
  },
  handleProgressChange?: { (progress: number): void; (arg0: number): any }
) => {
  const options: AxiosRequestConfig = {
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      if (progressEvent.total !== undefined) {
        handleProgressChange &&
          handleProgressChange(
            (progressEvent.loaded * 100) / progressEvent.total
          );
      }
    },
  };

  return httpService.post("/leader/agent/add", data, options);
};

export const changePassword = (
  data: { email: string; password: string; confirmPassword: string },
  handleProgressChange: { (progress: any): void; (arg0: number): any }
) => {
  const options: AxiosRequestConfig = {
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      if (progressEvent.total !== undefined) {
        handleProgressChange(
          (progressEvent.loaded * 100) / progressEvent.total
        );
      }
    },
  };

  return httpService.post("/authentication/password/change", data, options);
};

export const disableAgent = (user_id: number | string) => {
  return httpService.post("/leader/agent/disable", { user_id });
};

export const getAssignedHospitals = (agentId: string) => {
  return httpService.get("/manage/agent/assign/hos/" + agentId);
};

export const getScoreCard = (agentId: string) => {
  return httpService.get("/admin/target/indie/" + agentId);
};

export const getAgentAdminHospitals = (
  page?: number | string,
  limit?: number,
  status?: string,
  name?: string,
  user_id?: string
) => {
  const queryParams = new URLSearchParams();

  if (user_id !== null && user_id !== undefined && user_id !== "") {
    queryParams.append("userid", user_id.toString());
  }

  if (page !== null && page !== undefined && page !== 0 && page !== "") {
    queryParams.append("page", page.toString());
  }
  if (limit !== null && limit !== undefined && limit !== 0) {
    queryParams.append("limit", limit.toString());
  }
  if (status !== null && status !== undefined && status !== "") {
    queryParams.append("status", status);
  }
  if (name !== null && name !== undefined && name !== "") {
    queryParams.append("name", name);
  }

  return httpService.get(`/admin/hospitals/all?${queryParams.toString()}`);
};

export const reassign = (data: any) => {
  return httpService.post("/admin/hospital/assign/agent", data);
};

export const getRewardList = (
  type: string,
  page: number,
  limit: number,
  status: string,
  user_id?: string
) => {
  const queryParams = new URLSearchParams();

  if (user_id !== null && user_id !== undefined && user_id !== "") {
    queryParams.append("userid", user_id.toString());
  }

  if (page !== null && page !== undefined && page !== 0) {
    queryParams.append("page", page.toString());
  }
  if (limit !== null && limit !== undefined && limit !== 0) {
    queryParams.append("limit", limit.toString());
  }
  if (status !== null && status !== undefined && status !== "") {
    queryParams.append("status", status);
  }
  if (type !== null && type !== undefined && type !== "") {
    queryParams.append("type", type);
  }

  return httpService.get(`/admin/users/rewards/list?${queryParams.toString()}`);
};

export const getRewardBalance = (user_id: string) => {
  return httpService.get("/admin/users/rewards/balance/" + user_id);
};

export const getRewardValue = (
  type: string,
  status: string,
  userid?: string
) => {
  const queryParams = new URLSearchParams();

  if (userid !== null && userid !== undefined && userid !== "") {
    queryParams.append("userid", userid.toString());
  }

  if (status !== null && status !== undefined && status !== "") {
    queryParams.append("status", status);
  }

  if (type !== null && type !== undefined && type !== "") {
    queryParams.append("type", type);
  }

  return httpService.get(`admin/users/rewards/value?${queryParams.toString()}`);
};
export const getHospitalOrderHistory = (data: {
  refId: string;
  hospitalId: string;
}) => {
  return httpService.post("/manage/details/pending", data);
};

export const postCadre = (data: any) => {
  const options = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  };

  return httpService.post("/manage/request/carde/change", data, options);
};

export const getCadreRequest = () => {
  return httpService.get("/manage/get/carde/change/request");
};

export const getCadreList = () => {
  return httpService.get("/manage/get/carde/list/lagos");
};

export const approveCadreRequest = (data: any) => {
  return httpService.post(`/manage/approve/carde/request/${data.id}`, data);
};

const agentService = {
  assignAgent,
  changePassword,
  createAgent,
  disableAgent,
  getAgents,
  getAssignedHospitals,
  getSaleAgents,
  getScoreCard,
  reassignAgent,
  getCityAgents,
  getAgentAdminHospitals,
  reassign,
  getRewardList,
  getRewardBalance,
  getRewardValue,
  getHospitalOrderHistory,
  postCadre,
  getCadreRequest,
  getCadreList,
  approveCadreRequest,
};

export default agentService;
