import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";

import Header from "../../../components/blocks/header";
import LoadingButton from "../../../components/common/loadingButton";
import Select from "../../../components/common/select";
import SearchQuery from "../../../components/forms/searchQuery";
// import Pagination from "../../../components/common/pagination";

import utils from "../../../utils/utilities";

import { useGetPipeline, useReadyToPitch } from "../../../hooks/usePipeline";

import { ReloadIcon } from "@radix-ui/react-icons";
import { useGlobalContext } from "../../../contexts/globalContext";
import { Dayjs } from "dayjs";
import CustomPagination from "../../../components/common/pagination/Pagination";
// import applyFilters from "../../../helpers/urlState";

function createCategoryValue(
  key: string,
  date: string | number | Date | Dayjs | null | undefined,
  action: string
) {
  let duration = date ? utils.getDurationFromDate(date) : "N/A";
  let value = date ? date : "Not Available";

  return { key, value, duration, action };
}

const searchKeys = [
  { id: "name", name: "Name" },
  { id: "email", name: "Email" },
  { id: "phone", name: "Phone Number" },
  { id: "address", name: "Address" },
];

const statusCategories = [
  { id: "0", name: "Unassigned" },
  { id: "1", name: "Assigned" },
];

const timeFilters = [
  { id: "desc", name: "oldest to newest" },
  { id: "asc", name: "newest to oldest" },
];

function removePrefix(inputString: string) {
  // Split the input string at the first occurrence of '/'
  const parts = inputString?.split("/");
  // Return the part after the '/'
  return parts.length > 1 ? parts[1] : inputString;
}
const Pipeline = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const contentId = searchParams.get("content");

  const clickedCategory = JSON.parse(
    window.sessionStorage.getItem("pipeline") ?? ""
  );
  const newCategory = clickedCategory;
  const { user } = useGlobalContext();

  const [activeCategory, setActiveCategory] = useState(newCategory);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState({
    path: activeCategory?.sortKey,
    order: "desc",
  });
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [searchKey, setSearchKey] = useState("name");
  const [timeFilter, setTimeFilter] = useState("");

  const { pipeline, isPending } = useGetPipeline(user?.city, user?.id);

  // Hospitals to be pitched
  const filteredAssignedPitch = pipeline?.pitches
    ?.filter((pitch: { sub_status: string }) => {
      return pitch?.sub_status !== "To be contacted";
    })
    .map((pitch: { hospital_id: string }, index: string) => {
      return {
        ...pitch,
        hospital_id: index + "/" + pitch?.hospital_id,
      };
    });

  // Assigned Hospitals to be contacted
  const filteredPitches = pipeline?.pitches?.filter(
    (pitch: { sub_status: string }) => {
      return pitch?.sub_status === "To be contacted";
    }
  );

  // filter pipeline with same id and return just one
  // const uniquePipeline = (pipeline: any[]) => {
  //   return pipeline?.filter(
  //     (v: { id: any; hospital_id: any }, i: any, a: any[]) => {
  //       const hasSameId = a.findIndex((t: { id: any }) => t.id === v.id) === i;
  //       const hasSameHospitalId = v.hospital_id
  //         ? a.findIndex(
  //             (t: { hospital_id: any }) => t.hospital_id === v.hospital_id
  //           ) === i
  //         : true;
  //       return hasSameId && hasSameHospitalId;
  //     }
  //   );
  // };
  // console.log(uniquePipeline(pipeline?.exodus));
  useEffect(() => {
    if (newCategory?.tag === contentId) {
      setActiveCategory(newCategory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentId]);

  const pageSize = 20;

  const getAllHospitals = () => {
    const allHospitals: any[] = [];

    for (const key in pipeline) {
      const hospitalsInKey = pipeline[key];

      for (const hospital of hospitalsInKey) {
        const match = allHospitals.find((h) => h.id === hospital.id);
        if (!match) allHospitals.push(hospital);
      }
    }

    return utils.mapKeyToNumber(allHospitals, "id");
  };

  const getActiveCategoryHospitals = () => {
    // console.log(activeCategory?.tag)
    let activeCategoryHospitals = pipeline[activeCategory?.tag];
    // console.log(activeCategoryHospitals);
    if (activeCategory?.tag === "pitches") {
      activeCategoryHospitals = filteredAssignedPitch;
    }
    if (activeCategory?.tag === "assign") {
      activeCategoryHospitals = filteredPitches;
    }

    return activeCategoryHospitals ?? [];
    // return uniquePipeline(activeCategoryHospitals) ?? [];
  };

  const getMatchingHospitals = (sample: any[]) => {
    let searchCriteria = searchKey ? searchKey : "name";

    return sample.filter((h: { [x: string]: string }) => {
      let searchValue = h[searchCriteria] ?? "";

      return searchValue.toLowerCase().startsWith(search.toLowerCase());
    });
  };

  const getPaginatedData = () => {
    let allHospitals = getAllHospitals();

    if (activeCategory?.tag) allHospitals = getActiveCategoryHospitals();
    if (search) allHospitals = getMatchingHospitals(allHospitals);

    if (status)
      allHospitals = allHospitals.filter((h) => h.assigned === status);

    const sorted = _.orderBy(
      allHospitals,
      [sortColumn.path],
      [sortColumn.order === "desc" ? "desc" : "asc"]
    );
    const paginated = utils.paginate(sorted, currentPage, pageSize);

    return { totalCount: allHospitals.length, paginated };
  };

  const getValueByCategory = (hospital: {
    created_at: any;
    date: any;
    onboard_date: any;
    actualonboard: any;
    active_date: any;
    inactive_date: any;
    hibernate_date: any;
  }) => {
    let newHospitalValue = createCategoryValue(
      "Created on",
      hospital.created_at,
      "creation"
    );

    switch (activeCategory?.tag) {
      case "new_hospitals":
        return newHospitalValue;
      case "prospected":
        return createCategoryValue(
          "Prospected on",
          hospital.date,
          "prospected"
        );
      case "pitches":
        return createCategoryValue("Pitched on", hospital.date, "pitch");
      case "onboard":
        return createCategoryValue("Due on", hospital.onboard_date, "due date");
      case "exodus":
        return createCategoryValue(
          "Exodus began on",
          hospital.actualonboard,
          "exodus"
        );
      case "close":
        return createCategoryValue(
          "Created on",
          hospital.created_at,
          "Created"
        );
      case "active":
        return createCategoryValue(
          "Active since",
          hospital.active_date,
          "activation"
        );
      case "inactive":
        return createCategoryValue(
          "Inactive since",
          hospital.inactive_date,
          "inactivity"
        );
      case "hibernated":
        return createCategoryValue(
          "Hibernated since",
          hospital.hibernate_date,
          "hibernated"
        );
      default:
        return newHospitalValue;
    }
  };

  const handleTimeFilterChange = (e: { currentTarget: { value: any } }) => {
    let newSortColumn = { ...sortColumn };
    let order = e.currentTarget.value;

    if (newSortColumn.path === activeCategory?.sortKey)
      newSortColumn.order = order === "asc" ? "desc" : "asc";
    else {
      newSortColumn.path = activeCategory?.sortKey;
      newSortColumn.order = order;
    }

    setSortColumn(newSortColumn);
    setTimeFilter(order);
  };

  const { totalCount, paginated } = getPaginatedData();

  const { mutate: readyToPitch, isPending: isPitching } = useReadyToPitch();

  const [clickedRowId, setClickedRowId] = useState<number | string | null>(
    null
  );

  const handleReadyToPitch = useCallback(
    (hospital_id: string | number | null) => {
      const payload = {
        hospital_id,
        user_id: user?.id as string,
      };
      setClickedRowId(hospital_id);
      readyToPitch(payload, {
        onSuccess: () => {
          // setTimeout(() => {
          //   window.location.reload();
          // }, 2000);
        },
      });
    },
    [readyToPitch, user?.id]
  );
  const goToPage = (page: number) => {
    setCurrentPage(Math.min(Math.max(page, 1), totalCount));
    // applyFilters(
    //   {
    //     page: Math.min(Math.max(page, 1), totalCount),
    //   },
    //   navigate,
    //   `pipeline?content=${activeCategory?.tag}`
    // );
  };

  useEffect(() => {
    if (paginated.length === 0) {
      setCurrentPage(1);
    }
  }, [paginated.length, setCurrentPage]);

  return (
    <React.Fragment>
      <Header title={`Pipeline - ${activeCategory?.title} (${totalCount})`} />

      <div className="pipeline-container">
        <div className="pipeline-container-top">
          <SearchQuery
            name="search"
            placeholder="Search"
            value={search}
            onChange={(e: {
              currentTarget: { value: React.SetStateAction<string> };
            }) => setSearch(e.currentTarget.value)}
          />

          <div className="right-options">
            <Select
              name="filter_by"
              placeholder="Filter By"
              value={timeFilter}
              options={timeFilters}
              onChange={handleTimeFilterChange}
            />

            <Select
              name="search_key"
              placeholder="Search By"
              value={searchKey}
              options={searchKeys}
              onChange={(e: {
                currentTarget: { value: React.SetStateAction<string> };
              }) => setSearchKey(e.currentTarget.value)}
            />

            <Select
              name="status"
              placeholder="Assigned Status"
              value={status}
              options={statusCategories}
              onChange={(e: {
                currentTarget: { value: React.SetStateAction<string> };
              }) => setStatus(e.currentTarget.value)}
            />

            {/* <Button
              icon="refresh"
              label="Reset"
              onClick={() =>
                setActiveCategory({
                  title: "All",
                  tag: "",
                  color: "#172b4d",
                  sortKey: "created_at",
                })
              }
            /> */}
          </div>
        </div>
        <div className="pipeline-container-bottom">
          <LoadingButton visible={isPending} message="Fetching Pipelines" />

          {!isPending && (
            <div className="pipeline-container-bottom-inner">
              {paginated.map((hospital, index) => {
                const categoryValue = getValueByCategory(hospital);

                return (
                  <div
                    className="single-pipeline-item"
                    key={hospital.hospital_id ?? index + 1}
                  >
                    <h4
                      className="single-pipeline-item-title text-truncate"
                      style={{ color: activeCategory?.color }}
                    >
                      <Link
                        to={
                          "/hospitals/" +
                          removePrefix(hospital.hospital_id ?? hospital.id)
                        }
                      >
                        {/* <Link to={"/message-lead"}> */}
                        {hospital.name ?? hospital.hospital_name}
                      </Link>
                      {/* {hospital.name ?? hospital.hospital_name} */}
                    </h4>
                    <p className="single-pipeline-item-subtitle">
                      {categoryValue.key}:{" "}
                      {/* {hospital.hospital_id ? (
                        <Link to={"/hospitals/" + hospital.hospital_id}>
                          {categoryValue?.value as string}
                        </Link>
                      ) : ( */}
                      <span>{categoryValue?.value as string}</span>
                      {/* )} */}
                    </p>
                    <p className="single-pipeline-item-subtitle">
                      Days since {categoryValue.action}:
                      <span
                        className={
                          Number(categoryValue?.duration) > 0
                            ? "error"
                            : "neutral"
                        }
                      >
                        {" "}
                        {categoryValue.duration}
                      </span>
                    </p>

                    <div className="single-pipeline-item-subtitle w-full">
                      {
                        // user?.position === "sales_rep" &&
                        // (contentId === "close" || contentId === "onboard") ? (
                        //   <Link
                        //     to={
                        //       "/message-lead/" +
                        //       hospital.hospital_id +
                        //       "/" +
                        //       hospital.name
                        //     }
                        //   >
                        //     Message Lead
                        //   </Link>
                        // ) :
                        user?.position === "sales_rep" &&
                        contentId === "onboard" ? (
                          <button
                            className="app-btn text-white status primary"
                            onClick={() => {
                              window.sessionStorage.setItem(
                                "checklist",
                                JSON.stringify(hospital)
                              );
                              navigate(`/checklist/${hospital.hospital_id}`);
                            }}
                          >
                            Checklist
                          </button>
                        ) : user?.position === "sales_rep" &&
                          contentId === "close" ? (
                          <Link
                            className="app-btn text-white px-4 rounded-full"
                            to={"/request-info?id=" + hospital.id}
                          >
                            View
                          </Link>
                        ) : (user?.position === "sales_rep" ||
                            user?.position === "sales_lead") &&
                          contentId === "assign" ? (
                          <>
                            {hospital.hospital_id === clickedRowId &&
                            isPitching ? (
                              <p className="flex items-center">
                                <ReloadIcon className="animate-spin mr-2 h-4 w-4" />
                                <span>Processing...</span>
                              </p>
                            ) : (
                              <span
                                className="app-btn text-white px-4 rounded-full"
                                onClick={() =>
                                  handleReadyToPitch(hospital.hospital_id)
                                }
                              >
                                Ready to Pitch
                              </span>
                            )}
                          </>
                        ) : (user?.position === "sales_rep" ||
                            user?.position === "sales_lead") &&
                          contentId === "pitches" ? (
                          <Link
                            className="app-btn text-white px-4 rounded-full"
                            to={
                              "/info?id=" +
                              removePrefix(hospital?.hospital_id ?? "")
                            }
                          >
                            Pitch
                          </Link>
                        ) : (user?.position === "sales_rep" ||
                            user?.position === "sales_lead") &&
                          contentId === "exodus" ? (
                          <Link
                            className="app-btn text-white px-4 rounded-full"
                            to={`/exodus/log-a-contact/contact?id=${hospital.id}`}
                            state={
                              hospital.hospital_id
                                ? { id: hospital.hospital_id }
                                : { id: hospital.id }
                            }
                          >
                            Contact
                          </Link>
                        ) : user?.position === "sales_rep" &&
                          contentId === "active" ? (
                          <Link
                            className="app-btn text-white px-4 rounded-full"
                            to={`/active-schedules?id=${hospital.id}`}
                          >
                            Process
                          </Link>
                        ) : null
                      }
                    </div>
                  </div>
                );
              })}

              {paginated.length === 0 && (
                <div className="no-items">
                  <p>No facilities to show</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="sb-pagination-container mb-5">
        <CustomPagination
          goToPage={goToPage}
          currentPage={currentPage}
          totalPages={
            Math.ceil(totalCount / pageSize) > 0
              ? Math.ceil(totalCount / pageSize)
              : 1
          }
        />
      </div>
    </React.Fragment>
  );
};

export default Pipeline;
