import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Button from "../../../components/common/button";
import Header from "../../../components/blocks/header";

import pipelineService from "../../../services/pipelineService";
import toastService from "../../../services/toastService";

import { useGlobalContext } from "../../../contexts/globalContext";

const MessageLeadPage = () => {
  const { hospitalId, hospitalname } = useParams();
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();
  const { user } = useGlobalContext();

  const handleSubmit = async () => {
    try {
      // Send a POST request to your endpoint with the message content
      const response = await pipelineService.sendMessage(
        hospitalId,
        message,
        user.id
      );

      // Show success message and redirect to the home page
      setSuccessMessage("Message sent successfully!");

      if (response?.data?.data?.status === "success") {
        toastService.success(response?.data?.data?.description);
      }

      setTimeout(() => {
        setSuccessMessage("");
        navigate("/pipeline?content=close");
      }, 2000);
    } catch (error) {
      const errorMessage = error?.response?.data?.description || error.message;
      toastService.error(errorMessage);
    }
  };

  return (
    <React.Fragment>
      <Header title="Message Your Lead" />
      <div className="score-card">
        <h2 className="score-card-title">
          You are about to Message your lead about <span>{hospitalname}</span>
        </h2>
        <div className="logContent">
          <textarea
            rows="4"
            cols="50"
            name="comment"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Enter text here..."
            className=" bg-white border border-gray-300 rounded-md py-2 px-4 block w-full appearance-none"
          />
        </div>

        <div className="logContent">
          <Button
            onClick={handleSubmit}
            className="bg-blue-800 text-white px-8 py-2 rounded-md w-full sm:w-auto"
            label="Send Message"
          />
        </div>

        {successMessage && (
          <div className="logContent">
            <p className="text-green-500 font-semibold text-3xl">
              {successMessage}
            </p>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default MessageLeadPage;
