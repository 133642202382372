import React, { useState } from "react";

import DashboardTopRow from "../../../../components/blocks/dashboardTopRow";
import Header from "../../../../components/blocks/header";

import AssignedHospital from "../../../../components/blocks/assignedHospital";
import CurrentRevenue from "../../../../components/charts/currentRevenue";
import CustomSlider from "../../../../components/blocks/customSlider";
import LeadershipBoard from "../../../../components/blocks/leadershipBoard";
import LoadingButton from "../../../../components/common/loadingButton";
import RevenueMTD from "../../../../components/charts/revenueMTD";
import SalesStatistics from "../../../../components/charts/salesStatistics";

import withBreakdown from "../../../../components/hoc/withBreakdown";

import MarketingTop from "../../../../components/blocks/marketingTop";
import CustomerDashboard from "../customerDashboard";
import HeaderContainer from "../../../../components/blocks/HeaderContainer";
import { Focus } from "./components/focus";
import authService from "../../../../services/authService";

import { useGlobalContext } from "../../../../contexts/globalContext";
import { AssignedHospital as Hospital } from "../../../../ts-utils/interfaces";

import { useGetPitches } from "../../../../hooks/sales/useOnboard";
import { useGetDashboardData } from "../../../../hooks/useDashboard";
import HospitalVisits from "../../../../components/charts/hospitalVisitst";
import ActivityFeed from "../../../../components/tables/activityFeed";

const Home = (props: {
  isLoading: boolean;
  breakdown: any;
  monthData: any;
  targetData: any;
}) => {
  const { user } = useGlobalContext();
  const [showHospitalVisits, setShowHospitalVisits] = useState(false);
  const { pitch, viewRevenueMTD } = authService.getPrivileges();
  const { breakdown, monthData, targetData } = props;

  const handleHospitalVisits = () => setShowHospitalVisits(false);

  const { assignedHospitals, isPending } = useGetPitches();
  // const {
  //   dashboard,
  //   // isPending: isDashboardPending,
  //   // targetData,
  //   // monthData,
  //   // targetForTheMonth,
  // } = useGetDashboardData(user?.id || "");

  return user?.position === "marketing" ? (
    <React.Fragment>
      <Header title={`Hello, ${user.username}`} />
      <MarketingTop {...props} />
    </React.Fragment>
  ) : user?.position === "customer_success" ? (
    <React.Fragment>
      <Header title={`Hello, ${user.username}`} />
      <CustomerDashboard />
    </React.Fragment>
  ) : user?.position === "sales_rep" ? (
    <React.Fragment>
      <Header title={`Hello, ${user?.username}`} />
      <HeaderContainer />
      <div className="flex flex-col justify-center items-center">
        <Focus />
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      {showHospitalVisits && (
        <HospitalVisits
          data={[]}
          targets={breakdown?.targets}
          targetExceeded={targetData.visitedExceeded}
          handleClose={handleHospitalVisits}
        />
      )}
      <Header title={`Hello, ${user?.username}`} />
      <div className="px-3">
        <DashboardTopRow {...props} />
      </div>
      <div className="flex flex-col-reverse lg:flex-row gap-3">
        <div className="w-full">
          <ActivityFeed path={"home"} />
        </div>
        <div className="bottom-row-breakdown">
          <div className="bottom-row-breakdown-right">
            <div className="bottom-row-breakdown-right-inner">
              <LeadershipBoard />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="chart-breakdown">
        <div className="chart-breakdown-left">
          {pitch ? (
            isPending ? (
              <LoadingButton visible message="fetching assigned hospitals..." />
            ) : assignedHospitals.length ? (
              <CustomSlider
                className="assigned-hospital-slider max-w-7xl"
                slidesToShow={assignedHospitals.length < 2 ? 1 : 2}
              >
                {assignedHospitals.map((hospital: Hospital) => (
                  <AssignedHospital
                    hospital={hospital}
                    key={hospital.hospital_id}
                  />
                ))}
              </CustomSlider>
            ) : (
              <div className="sales-chart">
                <h2>Sales Statistics</h2>
                <SalesStatistics {...props} />
              </div>
            )
          ) : viewRevenueMTD ? (
            <>
              <CurrentRevenue />{" "}
            </>
          ) : (
            <div className="sales-chart">
              <h2>Sales Statistics</h2>
              <SalesStatistics {...props} />
            </div>
          )}
        </div>
        <div className="chart-breakdown-right">
          <div className="total-hospital-visits breakdown-item">
            <h2>Total Hospital Visits</h2>
            <div className="breakdown-item-inner">
              <div className="breakdown-item-inner-left">
                <i className="fa fa-hospital-o"></i>
              </div>
              <div className="breakdown-item-inner-right">
                <h3 className="title">{monthData.visitedCurrent}</h3>
                <div className="bottom">
                  <div
                    className={
                      "rate " +
                      (targetData.visitedExceeded ? "increase" : "decrease")
                    }
                  >
                    <i
                      className={
                        "fa fa-long-arrow-" +
                        (targetData.visitedExceeded ? "up" : "down")
                      }
                    ></i>
                    <span>{targetData.visitedPercentage}%</span>
                  </div>
                  <div className="description">Monthly target</div>
                </div>
              </div>
            </div>
            <div className="mom-button-container">
              <button
                className="mom"
                onClick={() => setShowHospitalVisits(true)}
              >
                View Breakdown
              </button>
            </div>
          </div>
          <div className="bottom-chart">
            {" "}
            <RevenueMTD {...props} />
          </div>
        </div>
      </div> */}
      {/* <div className="bottom-row-breakdown">
        <div className="bottom-row-breakdown-left"></div>
        <div className="bottom-row-breakdown-right">
          <div className="bottom-row-breakdown-right-inner">
            <LeadershipBoard />
          </div>
        </div>
      </div> */}
    </React.Fragment>
  );
};

export default withBreakdown(Home);
