import toast, {
  Renderable,
  Toast,
  ValueFunction,
  ValueOrFunction,
} from "react-hot-toast";

const success = (
  message: Renderable | ValueFunction<Renderable, Toast>,
  id?: string | undefined
) => toast.success(message, { id });

const error = (message: string, id?: string | undefined) => {
  toast.error(message, { id });
};

const remove = (id?: string | undefined) => toast.remove(id);

const dismiss = (id?: string | undefined) => toast.dismiss(id);

const loading = (message: Renderable | ValueFunction<Renderable, Toast>) =>
  toast.loading(message);

const promise = (
  callback: Promise<unknown>,
  params: {
    loading: Renderable;
    success:
      | (() => string)
      | (() => string)
      | (() => string)
      | (() => string)
      | (() => string)
      | (() => string)
      | (() => string)
      | (() => string)
      | (() => string)
      | ValueOrFunction<Renderable, unknown>;
    error:
      | ((ex: any) => any)
      | ((ex: any) => any)
      | ((ex: any) => string)
      | ((e: any) => string)
      | ((e: { response: { data: { message: any } }; message: any }) => any)
      | ((e: { response: { data: { message: any } }; message: any }) => any)
      | ((e: any) => any)
      | ((ex: any) => string)
      | ((e: any) => any)
      | ValueOrFunction<Renderable, any>;
  }
) => toast.promise(callback, params);

const toastService = { success, error, remove, dismiss, loading, promise };

export { success, error, remove, dismiss, loading, promise };
export default toastService;
