import React, { Component } from "react";
import { OneHospitalUser } from "../../ts-utils/interfaces";

// @ts-ignore
import Joi from "joi-browser";

import Button from "./button";
import Checkbox from "./checkbox";
import Input from "./input";
import Select from "./select";
import TextArea from "./textArea";

import authService from "../../services/authService";

import config from "../../utils/config.js";

export class Form extends Component<any> {
  state = { data: {}, errors: {} };

  schema: any;

  doSubmit = (): void => {};

  validate = (): any => {
    const options = { abortEarly: false };
    const { data } = this.state;
    const { error } = Joi.object(this.schema).validate(data, options);

    if (!error) return null;

    const errors: any = {};
    for (const item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }: HTMLInputElement): string | null => {
    const obj: object = { [name]: value };
    const schema: object = { [name]: this.schema[name] };

    const { error } = Joi.object(schema).validate(obj);
    return error ? error.details[0].message : null;
  };

  getPhonePattern = (): RegExp => {
    const user: OneHospitalUser | null = authService.getCurrentUser();
    const country = user?.country ?? "Nigeria";

    return config.phonePattern(country.toLowerCase());
  };

  handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });

    if (errors) return;

    this.doSubmit();
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { currentTarget: input } = e;

    const errors: any = { ...this.state.errors };
    const errorMessage: string | null = this.validateProperty(input);

    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data: any = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });
  };

  handleConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { currentTarget: input } = e;
    const { name, value } = input;

    const data: any = { ...this.state.data };
    const errors: any = { ...this.state.errors };

    if (value !== data.password) errors[name] = "Password fields must match";
    else delete errors[name];

    data[name] = value;

    this.setState({ data, errors });
  };

  handleCheckboxChange = (inputName: string): void => {
    const data: any = { ...this.state.data };
    data[inputName] = !data[inputName];

    this.setState({ data });
  };

  renderButton(label: string): JSX.Element {
    return <Button disabled={this.validate()} label={label} />;
  }

  renderSelect(params: any): JSX.Element {
    const { name, label, options, ...rest } = params;
    const { data, errors } = this.state;

    return (
      <Select
        name={name}
        value={(data as any)[name]}
        label={label}
        options={options}
        onChange={this.handleChange}
        error={(errors as any)[name]}
        {...rest}
      />
    );
  }

  renderCheckbox(params: any): JSX.Element {
    const { name, message } = params;
    const { data } = this.state;

    return (
      <Checkbox
        name={params.name}
        checked={(data as any)[name]}
        onChange={() => this.handleCheckboxChange(name)}
        message={message}
      />
    );
  }

  renderInput(params: any): JSX.Element {
    const { name, label, type, ...rest } = params;
    const { data, errors } = this.state;

    return (
      <Input
        type={type}
        id={name}
        name={name}
        value={(data as any)[name]}
        label={label}
        onChange={this.handleChange}
        error={(errors as any)[name]}
        {...rest}
      />
    );
  }

  renderTextArea(params: any): JSX.Element {
    const { name, label, ...rest } = params;
    const { data, errors } = this.state;

    return (
      <TextArea
        id={name}
        name={name}
        value={(data as any)[name]}
        label={label}
        onChange={this.handleChange}
        error={(errors as any)[name]}
        {...rest}
      />
    );
  }
}

export default Form;
