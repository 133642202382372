import React, { 
  useEffect, 
  Dispatch,
  SetStateAction,
} from "react";
import { AnimatePresence, motion } from "framer-motion";
type ModalProps = {
  children: React.ReactNode;
  isOpen: boolean;
  // closeModal: () => void;
  setIsOpen: Dispatch<SetStateAction<boolean>>;

  // other props...
};
const Modal = React.forwardRef<HTMLDivElement, ModalProps>((props, ref) => {
  // const [isOpen, setIsOpen] = useState(false);

  const { isOpen, setIsOpen } = props;

  // const openModal = () => setIsOpen(true);
  // const closeModal = () => setIsOpen(false);

  useEffect(() => {
    function onKeyup(e: { key: string }) {
      if (e.key === "Escape") setIsOpen(false);
    }

    function onClickOutside(e: MouseEvent) {
      const target = e.target as HTMLElement; // Cast the target to an HTMLElement
      if (target && target.classList.contains("modal-backdrop")) {
        setIsOpen(false);
      }
    }

    document.addEventListener("keyup", onKeyup);
    document.addEventListener("click", onClickOutside);

    return () => {
      document.removeEventListener("keyup", onKeyup);
      document.removeEventListener("click", onClickOutside);
    };
  }, [isOpen, setIsOpen]);

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <div className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-25 z-20 flex items-center justify-center modal-backdrop p-4 ">
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              className="bg-[#f5f5f5] rounded-lg z-60 min-w-[380px] md:w-7/12 max-h-[85%] overflow-y-auto  p-4 mt-20 scrollbar"
            >
              {props.children}
            </motion.div>
          </div>
        </>
      )}
    </AnimatePresence>
  );
});

export default Modal;
