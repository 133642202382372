import countries from "./countries";
import dayjs from "dayjs";

class Utilities {
  calculateCummulativeBreakdown = (arrayItems: any) => {
    const cummulativeBreakdown: { [key: string]: number } = {}; // Provide a type for cummulativeBreakdown object

    for (const dataItem of arrayItems) {
      let currentItem = cummulativeBreakdown[dataItem.state.toLowerCase()];
      let currentCost = parseInt(dataItem.cost);

      if (currentItem)
        cummulativeBreakdown[dataItem.state.toLowerCase()] += currentCost;
      else cummulativeBreakdown[dataItem.state.toLowerCase()] = currentCost;
    }

    return cummulativeBreakdown;
  };

  checkItemExpired(timestamp: any, format: string | undefined, expiry: number) {
    let duration = this.getDurationFromDate(
      timestamp,
      format as
        | "millisecond"
        | "second"
        | "minute"
        | "hour"
        | "day"
        | "month"
        | "year"
        | "date"
        | "milliseconds"
        | "seconds"
        | "minutes"
        | "hours"
        | "days"
        | "months"
        | "years"
        | "dates"
        | "d"
        | undefined
    );
    return duration > expiry;
  }

  formatDate(
    date: string | number | Date | dayjs.Dayjs | null | undefined,
    format = "MMM DD, YYYY HH:mm:ss"
  ) {
    return dayjs(date).format(format);
  }

  getAllPositions() {
    return [
      {
        id: "gceo",
        name: "Global CEO",
      },
      {
        id: "cceo",
        name: "Country CEO",
      },
      {
        id: "sales_lead",
        name: "Sales Lead",
      },
      {
        id: "sales_rep",
        name: "Sales Rep",
      },
      {
        id: "customer_success",
        name: "Customer Success",
      },
      {
        id: "fulfilment_officer",
        name: "Fulfilment Officer",
      },
      {
        id: "marketing",
        name: "Marketing",
      },
    ];
  }

  getBreakdown({
    targetKey,
    data = [],
    targets = [],
  }: {
    targetKey: string;
    data: any[];
    targets: any[];
  }) {
    const today = new Date();

    const getYearData = (item: { yr: string }) =>
      item.yr === today.getFullYear().toString();

    const getMappedMonthData = (dataset: any[], key: string) => {
      return this.getMonths().months.map((m) => {
        let foundMonth = dataset.find(
          (d: { mon: string | number }) => +d.mon === m.index + 1
        );

        return foundMonth
          ? foundMonth[key]
          : m.index <= today.getMonth()
          ? 0
          : null;
      });
    };

    const actualYearData = data.filter(getYearData);
    const targetYearData = targets.filter(getYearData);

    const actualData = getMappedMonthData(actualYearData, "num");
    const targetData = getMappedMonthData(targetYearData, targetKey);

    const target = targets.find(
      (t) =>
        parseInt(t.mon) === today.getMonth() + 1 &&
        t.yr === today.getFullYear().toString()
    );

    return { yearData: actualYearData, actualData, targetData, target, today };
  }

  getCountries() {
    return countries.map(({ id, country }) => ({ id, name: country }));
  }

  getCurrencyRegFormat() {
    // eslint-disable-next-line
    return /^\$?([1-9][0-9]{3,}(\.\d{2})?|(\d{1,3}\,\d{3}|\d{1,3}\,\d{3}(\.\d{2})?)|(\d{1,3}\,\d{3}|\d{1,3}\,\d{3}\,\d{3}(\.\d{2})?)*)?$/;
  }

  getDurationFromDate(
    date: string | number | Date | dayjs.Dayjs | null | undefined,
    format:
      | "millisecond"
      | "second"
      | "minute"
      | "hour"
      | "day"
      | "month"
      | "year"
      | "date"
      | "milliseconds"
      | "seconds"
      | "minutes"
      | "hours"
      | "days"
      | "months"
      | "years"
      | "dates"
      | "d"
      | undefined = "day"
  ) {
    let now = dayjs();
    let storedTime = date ? dayjs(date) : now;

    return now.diff(storedTime, format);
  }

  getErrorMessageFromException(ex: unknown) {
    return (
      (ex as { response?: { data?: { message?: string } } })?.response?.data
        ?.message ||
      (ex as Error)?.message ||
      "Could not complete the request"
    );
  }

  getMonths() {
    const months = [
      { label: "Jan", index: 0 },
      { label: "Feb", index: 1 },
      { label: "Mar", index: 2 },
      { label: "Apr", index: 3 },
      { label: "May", index: 4 },
      { label: "Jun", index: 5 },
      { label: "Jul", index: 6 },
      { label: "Aug", index: 7 },
      { label: "Sep", index: 8 },
      { label: "Oct", index: 9 },
      { label: "Nov", index: 10 },
      { label: "Dec", index: 11 },
    ];

    const labelledMonths = months.map((m) => m.label);
    const indexedMonths = months.map((m) => m.index);

    return { months, labelledMonths, indexedMonths };
  }

  getMonthLabel = () => {
    const today = new Date();
    const { months } = this.getMonths();

    const currentMonth = months.find((m) => m.index === today.getMonth());

    if (currentMonth) {
      return currentMonth.label + ", " + today.getFullYear();
    } else {
      return "";
    }
  };

  getUserPrivilegeIndex = (position: string | number) => {
    const allPositions = this.getAllPositions();
    const privilegeIndex = allPositions.findIndex(
      (p) => p.id.toString().toLowerCase() === position.toString().toLowerCase()
    );

    return privilegeIndex;
  };

  getTopCities = (countryRevenue: { [x: string]: { [x: string]: number } }) => {
    const cummulative = [];

    for (const city in countryRevenue) {
      let total = 0;

      for (const product in countryRevenue[city])
        total += countryRevenue[city][product];

      cummulative.push({ city, total });
    }

    return cummulative.sort((a, b) => b.total - a.total).slice(0, 3);
  };

  mapItemsFromObjects = (items: { [x: string]: any }) => {
    const mappedFacilities = [];

    for (const key in items) mappedFacilities.push(items[key]);

    return mappedFacilities;
  };

  mapArrayFromObjects = (dataObject: { [x: string]: any }) => {
    const allData = [];

    for (const key in dataObject)
      if (typeof dataObject[key] === "object") allData.push(...dataObject[key]);

    return allData;
  };

  mapCountryRevenueByState = (countryRevenue: { [x: string]: any }) => {
    const newRevenueBreakdown: { [key: string]: { [key: string]: number } } =
      {};

    for (const product in countryRevenue) {
      if (typeof countryRevenue[product] === "object") {
        for (const cityRevenue of countryRevenue[product]) {
          const parsedCost = parseInt(cityRevenue.cost);
          const productToLowerCase = product.toLowerCase();
          const state = cityRevenue.state.toLowerCase();

          if (!newRevenueBreakdown[state])
            newRevenueBreakdown[state] = { [productToLowerCase]: parsedCost };
          else newRevenueBreakdown[state][productToLowerCase] = parsedCost;
        }
      }
    }

    return newRevenueBreakdown;
  };

  mapKeyToNumber = (items: any[], key: string) => {
    const newData = [];

    for (const item in items) {
      const newItem = { ...items[item] };

      const parsedItem = +newItem[key];
      newItem[key] = !isNaN(parsedItem) ? parsedItem : "";

      newData.push(newItem);
    }

    return newData;
  };

  paginate(items: any[], currentPageNumber: number, totalPages: number) {
    const startIndex = (currentPageNumber - 1) * totalPages;
    return items
      .slice(startIndex)
      .filter((_: any, index: number) => index < totalPages);
  }

  parseKeysToLowercase = (items: { [x: string]: any }) => {
    const parsedItems: { [key: string]: any } = {}; // Add index signature to parsedItems

    for (const key in items) parsedItems[key.toLowerCase()] = items[key];

    return parsedItems;
  };

  summarize(text: string) {
    if (!text) return null;

    const textLimit = 20;
    const affix = text.length < textLimit ? "" : "...";

    return text.substring(0, textLimit) + affix;
  }

  transformOrderHistory(orderHistory: { carde: any[] }) {
    const newOrderHistory = { ...orderHistory };
    newOrderHistory.carde = orderHistory.carde[0];

    return newOrderHistory;
  }

  makeid = (length: number = 10) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };

  currencyFormatter = (val: string | number, symbol: string | undefined) => {
    return new Intl.NumberFormat(undefined, {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 2,
      currencyDisplay: "code",
    })
      .format(+val.toString().replace(/,/g, ""))
      .replace("USD", symbol ? symbol : "")
      .trim();
  };

  formatTimestamp = (timestamp: number) => {
    const date = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds

    // Extract day, month, and year components
    const day = date.getDate();
    const month = date.getMonth() + 1; // Month is zero-based, so we add 1
    const year = date.getFullYear();

    // Format with leading zeros if necessary
    const formattedDate = `${day}/${month < 10 ? "0" : ""}${month}/${year}`;

    // Format time in 12-hour format with AM/PM
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const amPm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12 AM
    const formattedTime = `${formattedHours < 10 ? "0" : ""}${formattedHours}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${amPm}`;

    return { day: formattedDate, formattedTime };
  };
}

const utilitiesInstance = new Utilities();

export default utilitiesInstance;
