import ReactPlayer from "react-player";
import Modal from "../common/modal";

const CustomVideoPlayer = ({ url, ...rest }) => {
  if (!url) return null;

  return (
    <Modal {...rest}>
      <ReactPlayer
        url={url}
        width="100%"
        height="100%"
        muted={false}
        playing
        controls
      />
    </Modal>
  );
};

export default CustomVideoPlayer;
