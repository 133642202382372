import httpService from "./httpService";

export const exodus = (id: string) => {
  return httpService.get(`/manage/exodus/dashboard/${id}`);
};

export const singleExodus = (id: number | string) => {
  return httpService.get(`/manage/hos/details/${id}`);
};

export const sendMessage = (data: FormData) => {
  const options = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  };
  return httpService.post("/manage/logs/exodus", data, options);
};

export const sendNote = (data: any) => {
  return httpService.post("/manage/send-note/exodus", data);
};

export const getDrip = () => {
  return httpService.get("/manage/drip/all/exodus");
};

export const postDrip = (data: any) => {
  return httpService.post("/manage/send-drip/exodus", data);
};

export const getAllExodus = (filters: {
  page?: number | string;
  limit?: number;
  status?: string;
  name?: string;
  user_id?: string;
  channel?: string;
  type?: string;
  order_by?: string;
  list?: string;
}) => {
  const queryParams = new URLSearchParams();

  if (
    filters.page !== null &&
    filters.page !== undefined &&
    filters.page !== 0 &&
    filters.page !== ""
  ) {
    queryParams.append("page", filters.page.toString());
  }
  if (
    filters.limit !== null &&
    filters.limit !== undefined &&
    filters.limit !== 0
  ) {
    queryParams.append("limit", filters.limit.toString());
  }
  if (
    filters.status !== null &&
    filters.status !== undefined &&
    filters.status !== ""
  ) {
    queryParams.append("status", filters.status.toLowerCase());
  }
  if (
    filters.name !== null &&
    filters.name !== undefined &&
    filters.name !== ""
  ) {
    queryParams.append("name", filters.name.toLowerCase());
  }
  if (
    filters.user_id !== null &&
    filters.user_id !== undefined &&
    filters.user_id !== ""
  ) {
    queryParams.append("user_id", filters.user_id);
  }
  if (
    filters.channel !== null &&
    filters.channel !== undefined &&
    filters.channel !== ""
  ) {
    queryParams.append("channel", filters.channel.toLowerCase());
  }
  if (
    filters.type !== null &&
    filters.type !== undefined &&
    filters.type !== ""
  ) {
    queryParams.append("type", filters.type.toLowerCase());
  }

  if (
    filters.order_by !== null &&
    filters.order_by !== undefined &&
    filters.order_by !== ""
  ) {
    queryParams.append("order_by", filters.order_by.toLowerCase());
  }

  if (
    filters.list !== null &&
    filters.list !== undefined &&
    filters.list !== ""
  ) {
    queryParams.append("list", filters.list.toLowerCase());
  }

  return httpService.get(`/manage/hospital/orders?${queryParams.toString()}`);
};

export const exodusPipeline = (filters: {
  page?: number | string;
  limit?: number;
  status?: string;
  name?: string;
  user_id?: string;
  list?: string;
}) => {
  const queryParams = new URLSearchParams();

  if (
    filters.page !== null &&
    filters.page !== undefined &&
    filters.page !== 0 &&
    filters.page !== ""
  ) {
    queryParams.append("page", filters.page.toString());
  }
  if (
    filters.limit !== null &&
    filters.limit !== undefined &&
    filters.limit !== 0
  ) {
    queryParams.append("limit", filters.limit.toString());
  }
  if (
    filters.status !== null &&
    filters.status !== undefined &&
    filters.status !== ""
  ) {
    queryParams.append("status", filters.status.toLowerCase());
  }
  if (
    filters.name !== null &&
    filters.name !== undefined &&
    filters.name !== ""
  ) {
    queryParams.append("name", filters.name.toLowerCase());
  }
  if (
    filters.user_id !== null &&
    filters.user_id !== undefined &&
    filters.user_id !== ""
  ) {
    queryParams.append("user_id", filters.user_id);
  }
  if (
    filters.list !== null &&
    filters.list !== undefined &&
    filters.list !== ""
  ) {
    queryParams.append("list", filters.list.toLowerCase());
  }

  return httpService.get(
    `/manage/hospitals/current/month?${queryParams.toString()}`
  );
};

const exodusServices = {
  exodus,
  singleExodus,
  sendMessage,
  getDrip,
  sendNote,
  postDrip,
  getAllExodus,
  exodusPipeline,
};

export default exodusServices;
