import React, { useState } from "react";
import Button from "../../../../components/common/button";
import { useNavigate } from "react-router-dom";
import MaintenanceForm from "../../../../components/forms/maintenanceForm";
import toastService from "../../../../services/toastService";
import ProgressLoader from "../../../../components/common/progressLoader";
import appointmentService from "../../../../services/appointmentService";

import { useLocation } from "react-router-dom";

const Active = (props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const hospitalId = searchParams.get("id");
  // const hospitalId = JSON.parse(
  //   window.sessionStorage.getItem("activeHospitals")
  // );

  const [step, setStep] = useState(1);
  const [addMaintenance, setAddMaintenance] = useState(false);

  const handleAddMaintenance = () => {
    setAddMaintenance(!addMaintenance);
  };
  const history = useNavigate();

  const [progress, setProgress] = useState(0);

  const handleProgressChange = (progress) => {
    setProgress(progress);
  };

  const navigate = (path) => {
    history(path);
  };

  const postData = async () => {
    if (step === 1) {
      const res = await appointmentService.alertEveryone(
        { hos_id: hospitalId },
        handleProgressChange
      );
      if (res.data.status !== "success") throw new Error(res.data.message);
      if (res.data.status === "success") setStep(2);
    } else if (step === 2) {
      const res = await appointmentService.sendGift(
        { hos_id: hospitalId },
        handleProgressChange
      );
      if (res.data.status !== "success") throw new Error(res.data.message);
      if (res.data.status === "success") setStep(3);
    } else if (step === 4) {
      const res = await appointmentService.sendToSuccess(
        hospitalId,
        handleProgressChange
      );
      if (res.data.status !== "success") throw new Error(res.data.message);
      if (res.data.status === "success") navigate("/pipeline?content=active");
    }
  };

  const sendToSuccess = (e) => {
    e.preventDefault();
    toastService.promise(postData(), {
      loading: "sending data...",
      success: () =>
        step === 1
          ? "everyone Would be alerted."
          : step === 2
          ? "Marketing team has been notified."
          : step === 4
          ? "Sent to Success"
          : "Maintenance Schedule",
      error: (e) =>
        e?.response?.data?.message || e?.message || "Could not proccess!",
    });
  };

  return (
    <div>
      <div onClick={() => navigate("/pipeline?content=active")} className="back">
        <i className={"fa fa-arrow-left"}></i> Back
      </div>
      <ProgressLoader progress={progress} />

      {addMaintenance && (
        <MaintenanceForm
          handleClose={() => setAddMaintenance(false)}
          handleState={() => {
            setAddMaintenance(false);
            setStep(4);
          }}
          hospitalid={hospitalId}
        />
        // <ScheduleForm
        //   hospital_id={hospitalId}
        //   handlePush={() => setStep(4)}
        // />
      )}

      <div className="navigation">
        {list.map(({ id, label }) => (
          <div key={id} className="navigation_container">
            {id > 1 && <span className="navigation_container_line"></span>}
            <p
              className={
                step >= id
                  ? "navigation_container_active2"
                  : "navigation_container_step2"
              }
            >
              {label}
            </p>
          </div>
        ))}
      </div>

      <div className="active-actions">
        {step === 1 && (
          <div className="single-action">
            <h3>Click the button below to send alert to everyone</h3>
            <Button label="Tell Everyone" onClick={sendToSuccess} />
          </div>
        )}

        {step === 2 && (
          <div className="single-action">
            <h3>Click the button below to notify marketing for confirmation</h3>
            <Button label="Send Active Gift" onClick={sendToSuccess} />
          </div>
        )}

        {step === 3 && (
          <div className="single-action">
            <h3>Click the button below to fill maintenance schedule form</h3>
            <Button
              label="Fill Maintenance Schedule"
              onClick={handleAddMaintenance}
            />
          </div>
        )}

        {step === 4 && (
          <div className="single-action">
            <Button label="Send To Success" onClick={sendToSuccess} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Active;

const list = [
  {
    id: 1,
    label: "Tell Everyone",
  },
  {
    id: 2,
    label: "Send Active Gift",
  },
  {
    id: 3,
    label: "Fill Maintenance Schedule",
  },
  {
    id: 4,
    label: "Send To Success",
  },
];
