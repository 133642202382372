import React, { useState } from "react";

import Select from "react-select";

// const Checkbox = ({ children, ...props }: JSX.IntrinsicElements["input"]) => (
//   <label style={{ marginRight: "1em" }}>
//     <input type="checkbox" {...props} />
//     {children}
//   </label>
// );

const ReactSelect = ({
  options,
  isDisabled,
  onChange,
  className,
  placeholder,
}: {
  options: { value: string; label: string }[];
  isDisabled?: boolean;
  onChange?: (value: string) => void;
  className?: string;
  placeholder?: string;
}) => {
  const [isClearable] = useState(false);

  const [isLoading] = useState(false);

  return (
    <>
      <Select
        className={className}
        classNamePrefix="select"
        defaultValue={options[0]}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isSearchable
        name="options"
        options={options}
        placeholder={placeholder}
        onChange={(e) => onChange && onChange(e?.value || "")}
      />
    </>
  );
};

export default ReactSelect;
