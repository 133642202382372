import { disableAgent } from "../../services/agent.service";
import { getPrivileges } from "../../services/authService";
import { promise as toastPromise } from "../../services/toastService";

const DisableAgentButton = ({ agent }: any): JSX.Element | null => {
  const getErrorMessage = (e: any): string => {
    return e?.response?.data?.message || e.message || "Could not disable agent";
  };

  const handleDisable = async (user_id: number): Promise<void> => {
    toastPromise(disableAgent(user_id), {
      loading: "Disabling Agent...",
      success: () => "Agent disabled Successfully",
      error: (ex: any) => getErrorMessage(ex),
    });
  };

  if (!getPrivileges().deleteAgent) return null;

  return (
    <button className="status error" onClick={() => handleDisable(agent.id)}>
      disable
    </button>
  );
};

export default DisableAgentButton;
