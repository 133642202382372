import { useEffect, useState } from "react";

import hospitalService from "../../services/hospitalService";
import toastService from "../../services/toastService";

import utilities from "../../utils/utilities";

function withFacilitySize(Component) {
  return function WithFacilitySize(props) {
    const [facilitySize, setFacilitySize] = useState([]);
    const [loading, setLoading] = useState(false);

    const getFacilitySizesData = async () => {
      try {
        setLoading(true);

        const response = await hospitalService.getFacilitySizes();
        const responseData = response.data?.data || {};
        const facilitySizes = utilities.mapItemsFromObjects(responseData);

        setFacilitySize(facilitySizes);
        setLoading(false);
      } catch (error) {
        const message = error?.response?.data?.description ?? error.message;

        toastService.error(message);
        setLoading(false);
      }
    };

    useEffect(() => {
      getFacilitySizesData();
    }, []);

    return (
      <Component facilitySize={facilitySize} loading={loading} {...props} />
    );
  };
}

export default withFacilitySize;
