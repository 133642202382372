import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { LoyalMasteryKeys, MasterLoyalData } from "./types";
import BackButton from "../backbutton";
import { error, success } from "../../../services/toastService";
import Button from "../../common/button";
import LoyalMasteryCallLog from "./LoyalMasteryCallLog";
import CustomField from "../../common/CustomField";
import { useCreateCallLog, useCallCount } from "../../../hooks/useHospital";
import utilities from "../../../utils/utilities";

import { useGlobalContext } from "../../../contexts/globalContext";

type Props = {
  setShowMastery: React.Dispatch<React.SetStateAction<boolean>>;
  refId: string;
  status: string;
  handleClose: () => void;
};
const defaultValues = {
  date: "",
  hospital_agent: "",
  questions: "",
  issue_with_order: "",
  issue_with_nerve: "",
  satisfied_with_product: "",
  anything_to_help: "",
};

function generateWeeksInfo(weeksCount: number, callCount: number) {
  let startWeek = callCount >= 4 ? callCount + 1 : 1;
  return Array.from({ length: weeksCount }, (_, index) => ({
    title: `Week ${startWeek + index} ${
      (startWeek + index) % 2 === 1 ? "- Call Hospital" : "- Visit Hospital"
    }`,
    btn: `Log ${(startWeek + index) % 2 === 1 ? "Call" : "Visit"}`,
    key: index + 1,
  }));
}

export default function LoyalMastery({
  setShowMastery,
  refId,
  status,
  handleClose,
}: Props) {
  const { callCount } = useCallCount(refId, status);
  const numberOfWeeks = 4;
  const callWeeksInfo = generateWeeksInfo(numberOfWeeks, callCount);

  const [callInfo, setCallInfo] = useState<{
    show: boolean;
    key: LoyalMasteryKeys<number>;
  }>({ show: false, key: 1 });

  const [btnTitle, setBtnTitle] = useState("");

  const { mutateCallLog, isPending } = useCreateCallLog(refId);
  const [logData, setLogData] = useState({
    location: "",
  });
  const [position, setPosition] = useState<any>(false);
  const { user } = useGlobalContext();

  const location: any = [];
  if (logData.location) {
    location.push(parseFloat(logData.location.split(",")[0]));
    location.push(parseFloat(logData.location.split(",")[1]));
  }

  const {
    handleSubmit: submitWeekOne,
    control: controlWeekOne,
    formState: { errors: errorsWeekOne },
  } = useForm<MasterLoyalData>({
    defaultValues: defaultValues,
  });

  const {
    handleSubmit: submitWeekTwo,
    control: controlWeekTwo,
    formState: { errors: errorsWeekTwo },
  } = useForm<MasterLoyalData>({
    defaultValues: defaultValues,
  });

  const {
    handleSubmit: submitWeekThree,
    control: controlWeekThree,
    formState: { errors: errorsWeekThree },
  } = useForm<MasterLoyalData>({
    defaultValues: defaultValues,
  });

  const {
    handleSubmit: submitWeekFour,
    control: controlWeekFour,
    formState: { errors: errorsWeekFour },
  } = useForm<MasterLoyalData>({
    defaultValues: defaultValues,
  });

  const onSubmitWeekOne = (data: MasterLoyalData) => {
    const resolution = [];
    const nerveIssue = [];
    if (data.anything_to_help) {
      resolution.push(data.anything_to_help);
    }
    if (data.issue_with_nerve) {
      nerveIssue.push(data.issue_with_nerve);
    }
    const date = utilities.formatDate(data.date, "YYYY-MM-DD HH:mm:ss");
    const payload = {
      geo_location: location,
      hospital_ref: refId,
      customer_success_rep_ref: user?.id,
      hospital_agent: data.hospital_agent,
      call_date: date,
      actiontype: "loyal",
      satisfied: data.satisfied_with_product,
      complaint: data.anything_to_help,
      log_type: "loyalty-check",
      log_data: {
        issuesnerve: nerveIssue,
        resolutions: resolution,
      },
    };

    mutateCallLog(payload, {
      onSuccess: (data) => {
        success(data?.data?.message, "call-info");
        setCallInfo(
          (prev) =>
            (prev = {
              show: false,
              key: 1,
            })
        );
        setLogData({
          location: "",
        });
        setPosition(false);
      },
      onError: (err) => {
        console.log(err);
        error("An error occurred", "call-info");
      },
    });
  };

  const onSubmitWeekTwo = (data: MasterLoyalData) => {
    const resolution = [];
    const nerveIssue = [];
    if (data.anything_to_help) {
      resolution.push(data.anything_to_help);
    }
    if (data.issue_with_nerve) {
      nerveIssue.push(data.issue_with_nerve);
    }
    const date = utilities.formatDate(data.date, "YYYY-MM-DD HH:mm:ss");
    const payload = {
      geo_location: location,
      hospital_ref: refId,
      customer_success_rep_ref: user?.id,
      hospital_agent: data.hospital_agent,
      call_date: date,
      actiontype: "loyal",
      satisfied: data.satisfied_with_product,
      complaint: data.anything_to_help,
      log_type: "loyalty-check",
      log_data: {
        issuesnerve: nerveIssue,
        resolutions: resolution,
      },
    };

    mutateCallLog(payload, {
      onSuccess: (data) => {
        success(data?.data?.message, "call-info");
        setCallInfo(
          (prev) =>
            (prev = {
              show: false,
              key: 2,
            })
        );
        setLogData({
          location: "",
        });
        setPosition(false);
      },
      onError: (err) => {
        console.log(err);
        error("An error occurred", "call-info");
      },
    });
  };

  const onSubmitWeekThree = (data: MasterLoyalData) => {
    const resolution = [];
    const nerveIssue = [];
    if (data.anything_to_help) {
      resolution.push(data.anything_to_help);
    }
    if (data.issue_with_nerve) {
      nerveIssue.push(data.issue_with_nerve);
    }
    const date = utilities.formatDate(data.date, "YYYY-MM-DD HH:mm:ss");
    const payload = {
      geo_location: location,
      hospital_ref: refId,
      customer_success_rep_ref: user?.id,
      hospital_agent: data.hospital_agent,
      call_date: date,
      actiontype: "loyal",
      satisfied: data.satisfied_with_product,
      complaint: data.anything_to_help,
      log_type: "loyalty-check",
      log_data: {
        issuesnerve: nerveIssue,
        resolutions: resolution,
      },
    };

    mutateCallLog(payload, {
      onSuccess: (data) => {
        success(data?.data?.message, "call-info");
        setCallInfo(
          (prev) =>
            (prev = {
              show: false,
              key: 3,
            })
        );
        setLogData({
          location: "",
        });
        setPosition(false);
      },
      onError: (err) => {
        console.log(err);
        error("An error occurred", "call-info");
      },
    });
  };

  const onSubmitWeekFour = (data: MasterLoyalData) => {
    const resolution = [];
    const nerveIssue = [];
    if (data.anything_to_help) {
      resolution.push(data.anything_to_help);
    }
    if (data.issue_with_nerve) {
      nerveIssue.push(data.issue_with_nerve);
    }
    const date = utilities.formatDate(data.date, "YYYY-MM-DD HH:mm:ss");
    const payload = {
      geo_location: location,
      hospital_ref: refId,
      customer_success_rep_ref: user?.id,
      hospital_agent: data.hospital_agent,
      call_date: date,
      actiontype: "loyal",
      satisfied: data.satisfied_with_product,
      complaint: data.anything_to_help,
      log_type: "loyalty-check",
      log_data: {
        issuesnerve: nerveIssue,
        resolutions: resolution,
      },
    };

    mutateCallLog(payload, {
      onSuccess: (data) => {
        success(data?.data?.message, "call-info");
        setCallInfo(
          (prev) =>
            (prev = {
              show: false,
              key: 4,
            })
        );
        setLogData({
          location: "",
        });
        setPosition(false);
      },
      onError: (err) => {
        console.log(err);
        error("An error occurred", "call-info");
      },
    });
  };

  return (
    <div>
      {callInfo.show ? (
        <LoyalMasteryCallLog
          isLoading={isPending}
          setCallInfo={setCallInfo}
          control={
            callInfo.key === 1
              ? controlWeekOne
              : callInfo.key === 2
              ? controlWeekTwo
              : callInfo.key === 3
              ? controlWeekThree
              : controlWeekFour
          }
          errors={
            callInfo.key === 1
              ? errorsWeekOne
              : callInfo.key === 2
              ? errorsWeekTwo
              : callInfo.key === 3
              ? errorsWeekThree
              : errorsWeekFour
          }
          objKey={callInfo.key}
          btnTitle={btnTitle}
          submit={{
            submitWeekOne: submitWeekOne(onSubmitWeekOne),
            submitWeekTwo: submitWeekTwo(onSubmitWeekTwo),
            submitWeekThree: submitWeekThree(onSubmitWeekThree),
            submitWeekFour: submitWeekFour(onSubmitWeekFour),
          }}
          setLogData={setLogData}
          position={position}
          setPosition={setPosition}
          logData={logData}
        />
      ) : (
        <React.Fragment>
          <BackButton
            goBack={() => {
              setShowMastery(false);
            }}
          />
          <div className="my-8">
            {callWeeksInfo.map((call) => {
              return (
                <CustomField
                  key={call.key}
                  title={call.title}
                  value={
                    <Button
                      onClick={() => {
                        setCallInfo({
                          show: true,
                          key: call.key,
                        });
                        setBtnTitle(call.btn);
                      }}
                      label={call.btn}
                      type="button"
                      style={{ marginTop: "0px" }}
                    />
                  }
                />
              );
            })}
          </div>
          <Button onClick={handleClose} label="Finish Actions" />
        </React.Fragment>
      )}
    </div>
  );
}
