import { useNavigate, useParams } from "react-router";
import ExodusHeader from "../../../../components/exodusComponent/ExodusHeader";
import {
  AverageCard,
  ContactInformationCard,
  HospitalActivityStream,
  ProductCard,
  // RewardStation,
  SmallCard,
  SuccessRelation,
} from "../../../../components/exodusComponent/Card";
// import frequency from "../../../../assets/Frequency.png";
import moneySpent from "../../../../assets/EstimatedGrowth.png";
import potential from "../../../../assets/PaymentHistory.png";

import {
  scubatank,
  equipment,
  box,
  blood,
  // transistion,
  // dollar,
  // phoneShaking,
  // hero,
  // herogo,
  // pdf,
} from "../../../../components/exodusComponent/Image";

import { FcCheckmark } from "react-icons/fc";
import { RiCheckboxBlankCircleLine } from "react-icons/ri";
import { useSingleExodus, useGetAllExodus } from "../../../../hooks/useExodus";

import { useGlobalContext } from "../../../../contexts/globalContext";
import { useState } from "react";
import { SyncLoader } from "react-spinners";
import dayjs from "dayjs";

const SingleExodusHospital = () => {
  const navigate = useNavigate();

  const { user } = useGlobalContext();

  // const getCurrencySign = () => {
  //   switch (user?.country) {
  //     case "Nigeria":
  //       return "₦";
  //     case "Kenya":
  //       return "KSh";
  //     case "Ethiopia":
  //       return "ETB";
  //     default:
  //       return "";
  //   }
  // };

  const { id } = useParams();
  const { singleExodus, isPending } = useSingleExodus(id as string);
  localStorage.setItem("exodus_order_id", id as string);

  // const items = [
  //   {
  //     text: "Data Reward",
  //     image: transistion,
  //     textValue: "10GB",
  //     subText: "Available",
  //   },
  //   {
  //     text: "Cash Reward",
  //     image: dollar,
  //     textValue: "N20,000",
  //     subText: "Available",
  //   },
  //   {
  //     text: "Airtime Reward",
  //     image: phoneShaking,
  //     textValue: "N5,000",
  //     subText: "Available",
  //   },
  // ];

  // const dripStation = [
  //   { text: "Data Reward", image: hero, subText: "Available" },
  //   { text: "Cash Reward", image: herogo, subText: "Available" },
  //   { text: "Airtim Reward", image: pdf, subText: "Available" },
  // ];

  const liteSum = singleExodus?.ordercounts?.reduce(
    (sum: number, item: { channel: string; total_sum: any }) => {
      if (item.channel === "" || item.channel === "Call-in") {
        return sum + Number(item.total_sum);
      }
      return sum;
    },
    0
  );

  // Object with channel "nerve"
  const nerveItem = singleExodus?.ordercounts?.find(
    (item: { channel: string }) => item.channel === "Nerve"
  );

  // Result with lite and nerve values
  const result = {
    lite: liteSum,
    nerve: nerveItem ? Number(nerveItem.total_sum) : 0,
  };
  const handleOnclick = () => {
    navigate("/exodus/log-a-contact/contact", {
      state: {
        id: id,
        name: singleExodus?.hospital_summary?.[0]?.name,
        address: singleExodus?.hospital_summary?.[0]?.address,
        averageMonthlySpent: singleExodus?.average_monthly_spend,
        nerveCount: result.nerve,
        lastOrder: singleExodus?.hospital_summary?.last_order_date,
        scheduleAmount: singleExodus?.schedule_amount,
      },
    });
  };
  const handleSendDrip = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    navigate(`/hospitals/exodus-send-drip`, {
      state: {
        ref_id: singleExodus?.hospital_summary?.[0]?.ref_id,
        id: id,
        name: singleExodus?.hospital_summary?.[0]?.name,
        address: singleExodus?.hospital_summary?.[0]?.address,
        averageMonthlySpent: singleExodus?.average_monthly_spend,
        lastOrder: singleExodus?.hospital_summary?.last_order_date,
        scheduleAmount: singleExodus?.schedule_amount,
      },
    });
  };

  const formatDate = (item: string) => {
    if (!item) return "N/A";
    const dateObj = new Date(item);

    // Array of month names
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Extract day, month, and year
    var day = dateObj.getDate();
    var monthIndex = dateObj.getMonth();
    var year = dateObj.getFullYear();

    // Format the date
    var formattedDate = day + " " + monthNames[monthIndex] + " " + year;

    return formattedDate;
  };
  const [type, setType] = useState("oxygen");

  const filters = {
    user_id: user?.id as string,
    status: "exodus",
    order_by: singleExodus?.hospital_summary?.[0]?.ref_id,
    type: type,
    limit: 5,
  };
  const { allExodus } = useGetAllExodus({ ...filters }, "single-exodus");

  const productFilter = {
    user_id: user?.id as string,
    status: "exodus",
    order_by: singleExodus?.hospital_summary?.[0]?.ref_id,
    channel: "nerve",
  };
  const { allExodus: productsList } = useGetAllExodus(
    { ...productFilter },
    "single-exodus"
  );

  const products = [
    { text: "Blood", order: "has_blood_order", image: blood },
    { text: "Cylinder", order: "has_oxygen_order", image: scubatank },
    { text: "Equipement", order: "has_quip_order", image: equipment },
    { text: "Consumable", order: "has_stockbank_order", image: box },
  ];

  const productsWithIcons = products.map((product) => {
    const orderKey = product.order;

    const isAvailable =
      productsList?.some((item: { [x: string]: string }) => {
        return item[orderKey] === "Yes";
      }) ?? false;

    return {
      ...product,
      icon: isAvailable ? <FcCheckmark /> : <RiCheckboxBlankCircleLine />,
    };
  });

  const recentActivities = [
    {
      label: "Oxygen",
      value: "oxygen",
      title: "Oxygen Activities",
      color: "#5286c3",
      items: allExodus
        ? allExodus
            .map(
              (item: {
                id: string;
                order_by: string;
                payment: string;
                qty: string;
                tym: string;
                urgency: string;
                product: string;
                unitprice: string;
                paymentdetails: string;
                schedule_date: string;
                schedule_time: string;
                order_state: string;
                complete_tym: string;
                created_at: string;
                hospital_name: string;
                cylinder: string;
              }) => ({
                id: item.id,
                order_by: item.order_by,
                payment: item.payment,
                qty: item.qty,
                tym: item.tym,
                urgency: item.urgency,
                product: item.product,
                unitprice: item.unitprice,
                paymentdetails: item.paymentdetails,
                schedule_date: item.schedule_date,
                schedule_time: item.schedule_time,
                order_state: item.order_state,
                complete_tym: item.complete_tym,
                created_at: item.created_at,
                hospital_name: item.hospital_name,
                cylinder: item.cylinder,
              })
            )
            .filter((item: { id: null }) => item.id !== null)
        : [],
    },
    {
      label: "Blood",
      value: "blood",
      title: "Blood Activities",
      color: "#b70303",
      items: allExodus
        ? allExodus
            .map((item: { [x: string | number]: string | number }) => {
              const formattedTime = dayjs
                .unix(item.tym as number)
                .format("YYYY-MM-DD HH:mm:ss");
              return {
                id: item.order_id,
                cylinder: item.b_type,
                urgency: item.Request_Type,
                payment: item.payment_type,
                order_by: item.order_by,
                qty: item.qty,
                tym: item.tym,
                product: item.product,
                unitprice: item.unitprice,
                paymentdetails: item.paymentdetails,
                schedule_date: item.schedule_date,
                schedule_time: item.schedule_time,
                order_state: item.order_state,
                complete_tym: item.complete_tym,
                created_at: formattedTime,
                hospital_name: item.hospital_name,
              };
            })
            .filter((item: { id: null }) => item.id !== null)
        : [],
    },
    {
      label: "Quip",
      value: "quip",
      title: "Quip Activities",
      color: "#F16C03",
      items: allExodus
        ? allExodus
            .map(
              (item: {
                id: string;
                order_by: string;
                payment: string;
                qty: string;
                tym: string;
                urgency: string;
                product: string;
                unitprice: string;
                paymentdetails: string;
                schedule_date: string;
                schedule_time: string;
                order_state: string;
                complete_tym: string;
                created_at: string;
                hospital_name: string;
                b_type: string;
              }) => ({
                id: item.id,
                order_by: item.order_by,
                payment: item.payment,
                qty: item.qty,
                tym: item.tym,
                urgency: item.urgency,
                product: item.product,
                unitprice: item.unitprice,
                paymentdetails: item.paymentdetails,
                schedule_date: item.schedule_date,
                schedule_time: item.schedule_time,
                order_state: item.order_state,
                complete_tym: item.complete_tym,
                created_at: item.created_at,
                hospital_name: item.hospital_name,
                cylinder: item.b_type,
              })
            )
            .filter((item: { id: null }) => item.id !== null)
        : [],
    },
    {
      label: "StockBank",
      value: "stockbank",
      title: "StockBank Activities",
      color: "#215200",
      items: allExodus
        ? allExodus
            .map(
              (item: {
                id: string;
                order_by: string;
                payment: string;
                qty: string;
                tym: string;
                urgency: string;
                product: string;
                unitprice: string;
                paymentdetails: string;
                schedule_date: string;
                schedule_time: string;
                order_state: string;
                complete_tym: string;
                created_at: string;
                hospital_name: string;
                b_type: string;
              }) => ({
                id: item.id,
                order_by: item.order_by,
                payment: item.payment,
                qty: item.qty,
                tym: item.tym,
                urgency: item.urgency,
                product: item.product,
                unitprice: item.unitprice,
                paymentdetails: item.paymentdetails,
                schedule_date: item.schedule_date,
                schedule_time: item.schedule_time,
                order_state: item.order_state,
                complete_tym: item.complete_tym,
                created_at: item.created_at,
                hospital_name: item.hospital_name,
                cylinder: item.b_type,
              })
            )
            .filter((item: { id: null }) => item.id !== null)
        : [],
    },
  ];
  return (
    <div className="w-full relative flex flex-col flex-grow">
      {isPending ? (
        <div className="fixed top-0 bottom-0 left-0 right-0 flex mx-auto items-center justify-center h-dvh z-30">
          <div className="bg-gray-700 absolute inset-0 opacity-50"></div>
          <SyncLoader
            color="#3B82F6"
            loading={isPending}
            className="relative"
            size={30}
          />
        </div>
      ) : (
        <>
          <ExodusHeader
            Text={
              singleExodus?.hospital_summary?.[0]?.name ??
              "Hospital Details Not Found"
            }
            subText={""}
          />
          {!singleExodus?.hospital_summary?.[0]?.ref_id ? (
            <>
              <div className="h-screen flex items-center justify-center">
                Hospital Details Not Found
              </div>
            </>
          ) : (
            <>
              <div className="mb-2 mt-3 flex gap-6 w-full overflow-x-auto">
                {[
                  {
                    Text: "Hospital Name",
                    subText: singleExodus?.hospital_summary?.[0]?.name || "",
                  },
                  {
                    Text: "Length of Relationship",
                    subText:
                      `${
                        singleExodus?.hospital_summary
                          ?.length_of_relationship ?? 0
                      } month` || "",
                  },
                  {
                    Text: "Nerve Status",
                    subText:
                      singleExodus?.hospital_summary?.nerve_status === "true"
                        ? "Yes"
                        : "No",
                  },
                  { Text: "Hospital Owner", subText: "N/A" },
                  {
                    Text: "Health Score",
                    subText: "Coming Soon!",
                    // subText: singleExodus?.hospital_summary?.health_score,
                  },
                ].map((item, index) => (
                  <SmallCard
                    key={index}
                    Text={item.Text}
                    subText={item.subText}
                  />
                ))}
              </div>
              <div className="border-t border-gray-400 mt-3 mb-3"></div>

              <div className="w-full flex flex-col lg:flex-row gap-3 mb-3">
                <div className="w-full lg:w-3/12">
                  <ContactInformationCard
                    text={"Contact Information"}
                    icon={<i className="fas fa-phone-alt"></i>}
                    subText={""}
                    mailtext={
                      singleExodus?.hospital_summary?.[0]?.email || "N/A"
                    }
                    phoneText={
                      singleExodus?.hospital_summary?.[0]?.phone || "N/A"
                    }
                    locationText={
                      singleExodus?.hospital_summary?.[0]?.address || "N/A"
                    }
                  />
                </div>
                <div className="w-full lg:w-9/12">
                  <div className="flex gap-2">
                    <div className="w-full">
                      <AverageCard
                        text={"Nerve Order"}
                        subText={result?.nerve?.toString()}
                        icon={potential}
                      />
                    </div>
                    <div className="w-full">
                      <AverageCard
                        text={`Lite Order`}
                        subText={result?.lite?.toString()}
                        icon={moneySpent}
                      />
                    </div>
                    <div className="w-full">
                      <div className="bg-[white] flex flex-col shadow-white shadow-sm rounded-lg border border-black p-2 h-[200px] items-start justify-around">
                        <p>
                          <span className="font-semibold">
                            Date of Last Order :
                          </span>{" "}
                          <span className="text-[#49209F] font-semibold">
                            {formatDate(
                              singleExodus?.hospital_summary?.last_order
                                ?.order_date
                            )}
                          </span>
                        </p>
                        <p>
                          <span className="font-semibold"> Channel :</span>{" "}
                          <span className="text-[#49209F] font-semibold">
                            {singleExodus?.hospital_summary?.last_order
                              ?.channel ?? "N/A"}
                          </span>
                        </p>
                        <p>
                          <span className="font-semibold">Product : </span>{" "}
                          <span className="text-[#49209F] font-semibold">
                            {singleExodus?.hospital_summary?.last_order
                              ?.product ?? "N/A"}
                          </span>
                        </p>
                      </div>

                      {/* <AverageCard
                text={"Ordering Frequency"}
                subText={singleExodus?.ordering_frequency}
                icon={frequency}
              /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full lg:flex lg:flex-row gap-4 md:flex md:flex-col sm:flex-col sm:flex">
                <div className="w-full gap-3 mb-3">
                  <div className="w-full flex gap-3 flex-col lg:flex-row mb-4 mt-1">
                    <div className="w-full lg:w-4/12">
                      <ProductCard
                        headerText={"Products"}
                        items={productsWithIcons}
                      />
                    </div>
                    <div className=" flex flex-col lg:flex-row w-full lg:w-10/12 justify-between gap-2">
                      <div className="w-full lg:w-6/12 h-[240px] bg-[#F7F3FE]  shadow-white shadow-sm flex flex-col items-center justify-center border border-black font-semibold text-2xl rounded-lg">
                        <div className="p-1">
                          <p className="text-[#49209F] text-[20px] font-bold p-1">
                            Reward Station
                          </p>
                        </div>
                        Coming Soon!
                      </div>
                      {/* <div className="w-full lg:w-6/12">
                <RewardStation headerText={"Reward Station"} items={items} />
              </div> */}
                      <button
                        onClick={handleSendDrip}
                        className="w-full lg:w-6/12 h-[240px] bg-[#F7F3FE] hover:bg-purple-200 shadow-white shadow-sm flex flex-col items-center justify-center border border-black font-semibold text-2xl rounded-lg"
                      >
                        Send Drip!
                        {/* <RewardStation headerText={"Drip Station"} items={dripStation} /> */}
                      </button>
                      {/* <div className="w-full lg:w-6/12">
                <RewardStation
                  headerText={"Drip Station"}
                  items={dripStation}
                />
              </div> */}
                    </div>
                  </div>
                  <div className="flex flex-col lg:flex-row gap-3 w-full ">
                    <div className="w--full lg:w-6/12">
                      <SuccessRelation
                        Text={"Sales Relationship"}
                        subText={"Active"}
                        btnText={"Log/schedule a visit"}
                        onClick={() => handleOnclick()}
                      />
                    </div>
                    <div className="w-full lg:w-6/12">
                      <SuccessRelation
                        Text={"Success Relationship"}
                        subText={"Add value"}
                        btnText={"Log/schedule a visit"}
                        onClick={() => handleOnclick()}
                      />
                    </div>
                  </div>
                </div>
                <div className="lg:w-4/12 md:w-full sm:w-full h-full sm:mb-4">
                  <HospitalActivityStream
                    items={recentActivities}
                    setType={setType}
                    type={type}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
export default SingleExodusHospital;
