import httpService from "./httpService";

export const getPipelineData = (
  id: string | undefined,
  city?: string | undefined
) => {
  // return httpService.get(`/admin/pipeline/data/${city}/${id}`);
  return httpService.get(`/admin/pipeline/data/${id}`);
};

export const nerveUsageMessages = (id: string) => {
  return httpService.get("/manage/hospitals/why/nerve/" + id);
};

export const readyToPitch = (data: {
  hospital_id: string | number | null;
  user_id: string;
}) => {
  return httpService.post(`/manage/ready/pitch`, data);
};

const pipelineService = {
  getPipelineData,
  nerveUsageMessages,
  readyToPitch,
};

export default pipelineService;
