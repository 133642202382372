import React from "react";
import BackButton from "../backbutton";
import { Control, Controller, FieldErrors } from "react-hook-form";
import InputField from "../../common/input";
import { PreactiveMasteryData } from "./types";
import Button from "../../common/button";
import CustomField from "../../common/CustomField";
import { SelectOption } from "../../../ts-utils/interfaces";
import Select from "../../common/select";

type Props = {
  setShowCallInfo: React.Dispatch<React.SetStateAction<boolean>>;
  control: Control<PreactiveMasteryData, any>;
  errors: FieldErrors<PreactiveMasteryData>;
  submit: (data: any) => void;
  isLoading?: boolean;
};
const procurementCycle: SelectOption[] = [
  { id: "weekly", name: "Weekly" },
  { id: "monthly", name: "Monthly" },
  { id: "annually", name: "Annually" },
  { id: "quarterly", name: "Quarterly" },
  { id: "bi-annual", name: "Bi-annual" },
  { id: "bi-monthly", name: "Bi-Monthly" },
];
export default function PreactiveMasteryCallLog({
  setShowCallInfo,
  control,
  errors,
  submit,
  isLoading,
}: Props) {
  return (
    <React.Fragment>
      <BackButton
        goBack={() => {
          setShowCallInfo(false);
        }}
      />
      <div className="my-8">
        <CustomField
          title="Date"
          style={{ paddingTop: "10px" }}
          value={
            <Controller
              control={control}
              name="call.date"
              render={({ field: { onChange, value, name } }) => (
                <InputField
                  style={{
                    width: "100%",
                    border: "none",
                    background: "#f1f1f1",
                  }}
                  autoFocus={false}
                  name={name}
                  onChange={onChange}
                  placeholder="date"
                  value={value}
                  type="dateTime-local"
                  error={errors?.call?.date?.message || null}
                />
              )}
            />
          }
        />
        <CustomField
          title="Name of Sales Agent"
          style={{ paddingTop: "10px" }}
          value={
            <Controller
              control={control}
              name="call.sales_agent"
              render={({ field: { onChange, value, name } }) => (
                <InputField
                  style={{
                    width: "100%",
                    border: "none",
                    background: "#f1f1f1",
                  }}
                  autoFocus={false}
                  name={name}
                  onChange={onChange}
                  placeholder="John Doe"
                  value={value}
                  type="text"
                  error={errors?.call?.sales_agent?.message || null}
                />
              )}
            />
          }
        />
        <CustomField
          title="City"
          style={{ paddingTop: "10px" }}
          value={
            <Controller
              control={control}
              name="call.city"
              render={({ field: { onChange, value, name } }) => (
                <InputField
                  style={{
                    width: "100%",
                    border: "none",
                    background: "#f1f1f1",
                  }}
                  autoFocus={false}
                  name={name}
                  onChange={onChange}
                  placeholder="Ikeja"
                  value={value}
                  type="text"
                  error={errors?.call?.city?.message || null}
                />
              )}
            />
          }
        />

        <>
          {" "}
          <CustomField
            title={
              <span className="font-semibold">
                Questions to Ask Sales Agent :
              </span>
            }
            style={{ paddingTop: "10px" }}
          />
          <CustomField
            title="What is the Hospital’s Procurement Cycle"
            style={{ paddingTop: "10px" }}
            value={
              <Controller
                control={control}
                name="call.procurement_cycle"
                render={({ field: { onChange, value, name } }) => (
                  <>
                    {/* <p className="capitalize">{value}</p> */}
                    <Select
                      // disabled
                      placeholder="Weekly"
                      options={procurementCycle}
                      value={value}
                      onChange={onChange}
                      error={errors?.call?.procurement_cycle?.message || null}
                    />
                  </>

                  // <InputField
                  //   style={{
                  //     width: "100%",
                  //     border: "none",
                  //     background: "#f1f1f1",
                  //   }}
                  //   autoFocus={false}
                  //   name={name}
                  //   onChange={onChange}
                  //   placeholder="Weekly"
                  //   value={value}
                  //   type="text"
                  //   error={errors?.call?.procurement_cycle?.message || null}
                  // />
                )}
              />
            }
          />
          <CustomField
            title="What is the Hospital Product Selection"
            style={{ paddingTop: "10px" }}
            value={
              <Controller
                control={control}
                name="call.product_selection"
                render={({ field: { onChange, value, name } }) => (
                  <InputField
                    style={{
                      width: "100%",
                      border: "none",
                      background: "#f1f1f1",
                    }}
                    autoFocus={false}
                    name={name}
                    onChange={onChange}
                    placeholder="LifeBank, Stockbank"
                    value={value}
                    type="text"
                    error={errors?.call?.product_selection?.message || null}
                  />
                )}
              />
            }
          />
          <CustomField
            title="What is the Sale’s Agent Relationship with the Hospital"
            style={{ paddingTop: "10px" }}
            value={
              <Controller
                control={control}
                name="call.sales_agent_relationship"
                render={({ field: { onChange, value, name } }) => (
                  <InputField
                    style={{
                      width: "100%",
                      border: "none",
                      background: "#f1f1f1",
                    }}
                    autoFocus={false}
                    name={name}
                    onChange={onChange}
                    placeholder="Very close"
                    value={value}
                    type="text"
                    error={
                      errors?.call?.sales_agent_relationship?.message || null
                    }
                  />
                )}
              />
            }
          />
          <CustomField
            title="Who are the major Stakeholders in the Hospital to ensure Hospital becomes loyal"
            style={{ paddingTop: "10px" }}
            value={
              <Controller
                control={control}
                name="call.major_stakeholders"
                render={({ field: { onChange, value, name } }) => (
                  <InputField
                    style={{
                      width: "100%",
                      border: "none",
                      background: "#f1f1f1",
                    }}
                    autoFocus={false}
                    name={name}
                    onChange={onChange}
                    placeholder="Matron"
                    value={value}
                    type="text"
                    error={errors?.call?.major_stakeholders?.message || null}
                  />
                )}
              />
            }
          />
          <CustomField
            title="Are there any current agreement between the Sales Agent and the hospital"
            style={{ paddingTop: "10px" }}
            value={
              <Controller
                control={control}
                name="call.current_agreement"
                render={({ field: { onChange, value, name } }) => (
                  <InputField
                    style={{
                      width: "100%",
                      border: "none",
                      background: "#f1f1f1",
                    }}
                    autoFocus={false}
                    name={name}
                    onChange={onChange}
                    placeholder="None"
                    value={value}
                    type="text"
                    error={errors?.call?.current_agreement?.message || null}
                  />
                )}
              />
            }
          />
        </>
      </div>
      <Button
        disabled={isLoading}
        type="button"
        label={isLoading ? "Saving..." : "Save call log"}
        onClick={() => {
          submit(control);
        }}
      />
    </React.Fragment>
  );
}
