import React from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { FaCheck } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import toastService from "../../../../services/toastService";

export default function ItemsCard({
  items,
  quantities,
  handleSelectedBrand,
  selectedPackage,
  handlePackageChange,
  selectItem,
  isItemInCart,
  handleQuantityChange,
  handleIncrement,
  handleDecrement,
  error,
}: {
  items: any;
  quantities: { [key: string]: number };
  handleSelectedBrand?: (newBrand: string, id: string) => void;
  selectedPackage?: string;
  handlePackageChange?: (pack: string, id: string) => void;
  selectItem: (item: any) => void;
  isItemInCart: (item: any) => boolean;
  handleQuantityChange: (e: any, id: string) => void;
  handleIncrement: (id: string) => void;
  handleDecrement: (id: string) => void;
  error?: { [key: string]: string };
}) {
  // const [error, setError] = React.useState<{ [key: string]: string }>({});

  const handleAddToCart = (item: any) => {
    const quantity = quantities[item.id];
    if (!quantity || quantity === 0) {
      // setError({ ...error, [item.id]: "Quantity cannot be 0" });
      toastService.error("Quantity cannot be 0");
      return;
    }
    selectItem({ ...item, qty: quantity });
  };

  return (
    <div>
      <ul className="divide-y">
        {items.map(
          (
            item: {
              icon_url: string | undefined;
              name: string;
              description: string;
              id: string;
              itemBrand: string;
              brand: string;
              cartonsize: string;
              packsize: string;
              quantityType: string;
            },
            idx: React.Key | null | undefined
          ) => (
            <li key={idx} className="py-5 flex items-start justify-between">
              <div className="flex gap-3">
                <img
                  src={item.icon_url}
                  className="flex-none w-20 h-20 rounded-md"
                  alt={item.name}
                />
                <div>
                  <span className="block text-sm text-gray-700 font-semibold">
                    {item.name}
                  </span>
                  <span className="block text-sm text-gray-600">
                    {item.description}
                  </span>

                  {/* Other UI elements */}
                  {handlePackageChange && (
                    <div className="flex gap-4 my-2">
                      <button
                        onClick={() =>
                          handlePackageChange &&
                          handlePackageChange("packs", item.id)
                        }
                        className={
                          item.quantityType === "packs"
                            ? "bg-blue-400 hover:bg-blue-500 text-white rounded-md px-2 flex items-center gap-1"
                            : "bg-white border  hover:bg-gray-400 ease-in-out duration-200 delay-100 rounded-md px-2 "
                        }
                      >
                        {item.quantityType === "packs" && (
                          <FaCheck color={"white"} />
                        )}
                        Packs
                      </button>

                      <button
                        onClick={() =>
                          handlePackageChange &&
                          handlePackageChange("carton", item.id)
                        }
                        className={
                          item.quantityType === "carton"
                            ? "bg-blue-400 hover:bg-blue-500 text-white rounded-md px-2 flex items-center gap-1"
                            : "bg-white hover:bg-gray-400 border rounded-md px-2  ease-in-out duration-200 delay-100"
                        }
                      >
                        {item.quantityType === "carton" && (
                          <FaCheck color={"white"} />
                        )}
                        Carton
                      </button>
                    </div>
                  )}

                  <div className="flex capitalize">
                    {item.quantityType === "packs" ? (
                      <div className="flex justify-between w-full">
                        <p className="text-sm font-bold p-1 rounded-sm">
                          {item.packsize}
                        </p>
                      </div>
                    ) : (
                      <div className="flex justify-between w-full">
                        <p className="text-sm font-bold p-1 rounded-sm">
                          {item.cartonsize}
                        </p>
                      </div>
                    )}
                  </div>
                  {handleSelectedBrand &&
                    item.itemBrand &&
                    item.itemBrand.split(",").length > 1 && (
                      <div className="flex gap-2">
                        <div className="flex flex-row mt-2">
                          <label
                            htmlFor="brand"
                            className="text-black font-bold"
                          >
                            Brand:
                          </label>
                          <select
                            name="brand"
                            id="brand"
                            value={item.brand}
                            className="
                            border border-green-400 rounded-md ml-2
                            w-32
                          "
                            onChange={(e) => {
                              handleSelectedBrand &&
                                handleSelectedBrand(e.target.value, item.id);
                            }}
                          >
                            {item.itemBrand
                              .split(",")
                              .map((brand, index) => ({
                                value: brand,
                                label: brand,
                                key: (index + 1).toString(),
                                backgroundColor: "",
                                icon: "",
                              }))
                              .map((brand) => (
                                <option key={brand.value} value={brand.value}>
                                  {brand.label}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    )}

                  <div className="flex flex-row items-center mt-4 gap-1">
                    <span
                      role="button"
                      className="w-8 h-8 text-black text-center font-bold rounded-full p-1 border border-black"
                      onClick={() => handleDecrement(item.id)}
                    >
                      -
                    </span>
                    <input
                      id="quantity"
                      name="quantity"
                      type="text"
                      value={quantities[item.id] ?? 0}
                      min={1}
                      className="w-10 h-8 text-black text-center font-bold rounded-full p-1 border border-gray-300"
                      onChange={(e) => handleQuantityChange(e, item.id)}
                    />
                    <span
                      role="button"
                      className="w-8 h-8 text-black text-center font-bold rounded-full p-1 border border-black"
                      onClick={() => handleIncrement(item.id)}
                    >
                      +
                    </span>

                    <span className="font-semibold capitalize text-gray-500">
                      {item.quantityType}
                    </span>
                  </div>
                  {error && error[item.id] && (
                    <p className="text-red-500 text-sm mt-1">
                      {error[item.id]}
                    </p>
                  )}
                </div>
              </div>
              <button
                onClick={() => handleAddToCart(item)}
                className="flex items-center justify-center gap-1 bg-white text-gray-700 text-sm border rounded-lg px-3 py-2 duration-150 hover:bg-gray-100"
              >
                {isItemInCart(item) ? (
                  <MdDeleteForever className="w-5 h-6 text-red-400" />
                ) : (
                  <AiOutlineShoppingCart />
                )}
                {isItemInCart(item) ? "" : "Add"}
              </button>
            </li>
          )
        )}
      </ul>
    </div>
  );
}
