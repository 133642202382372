import { useState, useEffect } from "react";

let idCounter = 0;

function useId() {
  const [id, setId] = useState<string>();

  useEffect(() => {
    setId(`unique-id-${idCounter}`);
    idCounter += 1;
  }, []);

  return id;
}

export { useId };
