import { useNavigate } from "react-router-dom";

import config from "../../../utils/config.js";
import ForgotPassForm from "../../../components/forms/forgotPassForm";
import AuthenticationLayout from "./Layout";

const ForgotPassword = (props) => {
  const history = useNavigate();
  return (
    <AuthenticationLayout>
      {" "}
      <div className="login">
        <div className="login-inner">
          <p className="login-inner-title text-small">Reset your password</p>

          <p className="login-inner-subtitle">
            Enter your email address to change your password.
          </p>

          <div className="form-container">
            <ForgotPassForm
              {...props}
              handlePush={() => history("/forgot-password")}
            />
          </div>

          <div className="download-apk">
            <p>
              Download APK
              <a
                href={config.oneHospitalAPKUrl}
                rel="noopener noreferrer nofollow"
                target="_blank"
              >
                Here
              </a>
            </p>
          </div>
        </div>
      </div>
    </AuthenticationLayout>
  );
};

export default ForgotPassword;
