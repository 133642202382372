import React, { useState } from "react";
import htmlToDraft from "html-to-draftjs";

import CustomTextEditor from "../blocks/textEditor";

import Button from "../common/button";
import Modal from "../common/modal";
import ProgressLoader from "../common/progressLoader";

import { resolveComplaint } from "../../services/complaintsService";
import toastService from "../../services/toastService";

const ComplaintsModal = ({ selectedComplaint, handleClose }) => {
  const [progress, setProgress] = useState(0);
  const [resolution, setResolution] = useState("");

  const handleProgress = (progress) => setProgress(progress);

  const handleResolve = async () => {
    const returnValue = htmlToDraft(resolution).contentBlocks?.[0]?.text;
    const apiData = { ...selectedComplaint, return: returnValue };

    const response = await resolveComplaint(apiData, handleProgress);

    if (response.data.status !== "success")
      throw new Error(response.data.message);

    handleClose();
  };

  const resolve = () => {
    toastService.promise(handleResolve(), {
      loading: "Resolving Complaint...",
      success: () => "Complaint Resolved Successfully",
      error: (e) =>
        e?.response?.data?.message ||
        e.message ||
        "An unexpected error occurred!",
    });
  };

  return (
    <Modal handleClose={handleClose}>
      <ProgressLoader progress={progress} />

      <h3 className="modal-title">Complaint Information</h3>

      <ul className="complaint-modal-items">
        <li className="complaint-modal-items-single">
          <span>Hospital Name:</span> {selectedComplaint.name}
        </li>
        <li className="complaint-modal-items-single">
          <span>Incident Date:</span> {selectedComplaint.incident_date}
        </li>
        <li className="complaint-modal-items-single">
          <span>Report Date:</span>{" "}
          {selectedComplaint.report_date?.toUpperCase?.()}
        </li>
        <li className="complaint-modal-items-single">
          <span>Contact Name:</span> {selectedComplaint.contact_name}
        </li>
        <li className="complaint-modal-items-single">
          <span>Contact Phone Number:</span>{" "}
          {selectedComplaint.contact_phone_number}
        </li>
        <li className="complaint-modal-items-single">
          <span>Complaint Category:</span> {selectedComplaint.category}
        </li>
        <li className="complaint-modal-items-single">
          <span>Complaint Summary:</span> {selectedComplaint.complain}
        </li>
        <li className="complaint-modal-items-single">
          <span>Resolution: </span>
        </li>
        <CustomTextEditor onChange={(value) => setResolution(value)} />
      </ul>

      <div className="modal-buttons">
        <Button label="Resolve" onClick={resolve} />
      </div>
    </Modal>
  );
};

export default ComplaintsModal;
