import React, { useEffect, useState } from "react";
import { SelectOption } from "../../../ts-utils/interfaces";

import AddComplaintForm from "../../../components/forms/addComplaintForm";
import ComplaintsTable from "../../../components/tables/complaintsTable";
import Button from "../../../components/common/button";
import Header from "../../../components/blocks/header";
import SearchQuery from "../../../components/forms/searchQuery";
import Select from "../../../components/common/select";

import { getComplaintCategories } from "../../../services/complaintsService";
import { getPrivileges } from "../../../services/authService";
import { error as toastError } from "../../../services/toastService";
import { useGetComplaint } from "../../../hooks/useDashboard";
import { useGlobalContext } from "../../../contexts/globalContext";

const ComplaintsPage = (): JSX.Element => {
  const { city } = useGlobalContext();

  const { complaints, isPending } = useGetComplaint(city);
  const [search, setSearch] = useState<string>("");
  const [status, setStatus] = useState<string>("name");
  const [category, setCategory] = useState<string>("");
  const [complaintCategories, setComplaintCategories] = useState<any[]>([]);
  const [addComplaint, setAddComplaint] = useState<boolean>(false);

  const privileges = getPrivileges();

  const handleAddComplaint = (): void => {
    setAddComplaint((prev) => !prev);
  };

  const getComplaintTypes = async (): Promise<void> => {
    try {
      const response = await getComplaintCategories();
      const result: any = response.data;

      const categories = result.status === "success" ? result.categories : [];
      const mappedCategories = categories.map((category: any) => ({
        id: category.id,
        name: category.category,
      }));

      setComplaintCategories(mappedCategories);
    } catch (ex: any) {
      const message: string = ex?.response?.data?.message ?? ex.message;
      toastError(message);
    }
  };

  useEffect(() => {
    getComplaintTypes();

    return () => {
      setComplaintCategories([]);
    };
  }, []);

  return (
    <React.Fragment>
      <Header title="Complaints" />

      {privileges.addComplaint && addComplaint && (
        <AddComplaintForm handleClose={handleAddComplaint} />
      )}

      <div className="hospitals-container">
        <div className="hospitals-container-top">
          <SearchQuery
            name="search"
            placeholder="Search"
            value={search}
            onChange={(e: any) => setSearch(e.currentTarget.value)}
          />

          <div className="right-options">
            <Select
              name="filter"
              placeholder="Search by"
              value={status}
              options={statusCategories}
              onChange={(e: any) => setStatus(e.currentTarget.value)}
            />

            <Select
              name="status"
              placeholder="All Categories"
              value={category}
              options={complaintCategories}
              onChange={(e: any) => setCategory(e.currentTarget.value)}
            />

            {privileges.addComplaint && (
              <Button
                icon="plus"
                label="Add New Complaint"
                onClick={handleAddComplaint}
              />
            )}
          </div>
        </div>

        <div className="hospitals-container-bottom table-responsive">
          <ComplaintsTable
            search={search}
            status={status}
            category={category}
            complaints={complaints}
            loading={isPending}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

const statusCategories: SelectOption[] = [
  { id: "name", name: "Name" },
  { id: "contact_name", name: "Contact Name" },
  { id: "contact_phone_number", name: "Contact Phone Number" },
];

export default ComplaintsPage;
