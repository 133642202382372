// @ts-ignore
import Joi from "joi-browser";

import { ApiResponse, Hospital } from "../../ts-utils/types";
import { SelectOption } from "../../ts-utils/interfaces";

import CustomDatePicker from "../common/datePicker";
import Form from "../common/form";
import InputField from "../common/input";
import Modal from "../common/modal";
import LoadingButton from "../common/loadingButton";
import ProgressLoader from "../common/progressLoader";

import WithHospitals from "../hoc/withHospitals";
import utilities from "../../utils/utilities";

import cs from "../../services/complaintsService";
import ts from "../../services/toastService";

class AddComplaintForm extends Form {
  state: any = {};

  constructor(props: any) {
    super(props);

    this.state = {
      data: {
        hospital_id: "",
        category_id: "",
        contact_name: "",
        contact_phone: "",
        complain: "",
      },
      incidentDate: new Date(),
      today: new Date(),
      errors: {},
      complaintCategories: [],
      progress: 0,
    };

    this.schema = {
      hospital_id: Joi.string().required().label("Hospital Name"),
      category_id: Joi.string().required().label("Complaint Category"),
      contact_name: Joi.string()
        .trim()
        .regex(/^[a-zA-Z_ ]+$/, "valid name")
        .min(4)
        .required()
        .label("Contact Name"),
      contact_phone: Joi.string()
        .trim()
        .regex(this.getPhonePattern(), "valid phone number")
        .label("Contact Phone Number")
        .required(),
      complain: Joi.string().required().label("Complaint Summary"),
    };
  }

  async componentDidMount(): Promise<void> {
    await this.fetchComplaintCategories();
  }

  fetchComplaintCategories = async (): Promise<void> => {
    try {
      this.setState({ loading: true });

      const response: ApiResponse = await cs.getComplaintCategories();
      const result: any = response.data;
      const complaintCategories: any[] = this.mapCategories(result.categories);

      this.setState({ complaintCategories, loading: false });
    } catch (error) {
      ts.error("Could not fetch complaint categories");
      this.setState({ loading: false });
    }
  };

  getHospitalIdMatch = (hospitalName: string) => {
    const hospital = this.props.hospitals.find(
      (h: Hospital) => h.name.toLowerCase() === hospitalName.toLowerCase()
    );

    return hospital ? hospital.id : null;
  };

  getApiData = (): object => {
    const { data, incidentDate } = this.state;
    const errorMessage =
      "Invalid hospital name! Please select a valid hospital!";

    const hospital_id = this.getHospitalIdMatch(data.hospital_id);
    if (!hospital_id) throw new Error(errorMessage);

    const incident_date: string = utilities.formatDate(
      incidentDate,
      "YYYY-MM-DD"
    );
    const apiData = { ...data, hospital_id, incident_date };

    return apiData;
  };

  getErrorMessage = (ex: any): string => {
    return (
      ex?.response?.data?.message || ex?.message || "Could not add complaint"
    );
  };

  handleDateChange = (incidentDate: Date | null): void => {
    this.setState({ incidentDate });
  };

  handleProgressChange = (progress: number): void => {
    this.setState({ progress });
  };

  mapCategories(categories: any[] | null): SelectOption[] {
    if (!categories) return [];

    return categories.map((category) => ({
      id: category.id,
      name: category.category,
    }));
  }

  postData = async (): Promise<any> => {
    const apiData: object = this.getApiData();

    const response = await cs.addComplaint(apiData, this.handleProgressChange);
    const result: any = response.data;

    if (result.status !== "success") throw new Error(result.message);
    this.props.handleClose();
  };

  doSubmit = (): void => {
    ts.promise(this.postData(), {
      loading: "Uploading complaint...",
      success: () => "Complaint Added Successfully!",
      error: this.getErrorMessage,
    });
  };

  render() {
    const { complaintCategories, loading, ...rest } = this.state;
    const { hospitals } = this.props;

    return (
      <Modal handleClose={this.props.handleClose}>
        <ProgressLoader progress={rest.progress} />

        <form onSubmit={this.handleSubmit}>
          <h2 className="form-header">
            Fields marked <i className="fa fa-asterisk error"></i> are
            compulsory
          </h2>

          <div className="row">
            <div className="col-lg">
              {this.renderInput({
                type: "text",
                name: "hospital_id",
                placeholder: "Select Hospital",
                label: "Hospital Name",
                list: "hospitalnames",
                listOptions: hospitals.map((h: Hospital) => h.name),
                compulsory: true,
              })}
            </div>

            <div className="col-lg">
              {this.renderInput({
                type: "text",
                name: "contact_name",
                placeholder: "e.g. John Doe",
                label: "Contact Name",
                compulsory: true,
              })}
            </div>
          </div>

          {this.renderSelect({
            name: "category_id",
            placeholder: "Select Complaint Category",
            label: "Complaint Category",
            options: complaintCategories,
            compulsory: true,
          })}

          <div className="row">
            <div className="col-lg">
              <InputField
                type="date"
                name="report_date"
                value={utilities.formatDate(rest.today, "YYYY-MM-DD")}
                label="Report Date"
                error={null}
                placeholder=""
                readOnly
              />
            </div>

            <div className="col-lg">
              <CustomDatePicker
                label="Incident Date"
                maxDate={rest.today}
                selectedDate={rest.incidentDate}
                onDateChange={this.handleDateChange}
              />
            </div>
          </div>

          {this.renderInput({
            type: "text",
            name: "contact_phone",
            placeholder: "e.g. 07000000000",
            label: "Contact Phone Number",
            compulsory: true,
          })}

          {this.renderTextArea({
            name: "complain",
            label: "Complaint Summary",
            placeholder: "Enter Complaint Summary",
            compulsory: true,
          })}

          <div className="d-flex justify-content-end ">
            {loading ? (
              <LoadingButton visible message="Please wait" />
            ) : (
              this.renderButton("Add")
            )}
          </div>
        </form>
      </Modal>
    );
  }
}

export default WithHospitals(AddComplaintForm);
