/* eslint-disable eqeqeq */
import React from "react";
import BackButton from "../blocks/backbutton";
import Button from "../common/button";
import CustomField from "../common/CustomField";
import LoadingButton from "../common/loadingButton";
import { useCallCount } from "../../hooks/useHospital";
import utilities from "../../utils/utilities";

interface CallHistoryProps {
  setCallHistory: React.Dispatch<React.SetStateAction<string>>;
  handleClose: () => void;
  hospitalId: any;
  hospitalStatus: any;
}
const CallHistory: React.FC<CallHistoryProps> = ({
  handleClose,
  hospitalId,
  setCallHistory,
  hospitalStatus,
}) => {
  const { callHistory, isPending } = useCallCount(
    hospitalId,
    hospitalStatus
  );

  console.log(callHistory);

  return (
    <div className="overflow-y-auto w-full h-full custom-scrollbar p-10">
      <>
        {isPending ? (
          <LoadingButton
            visible={isPending}
            message="Fetching Data... Please wait"
          />
        ) : (
          <React.Fragment>
            <BackButton
              goBack={() => {
                setCallHistory("");
              }}
            />
            <CustomField
              title={<h2 className="font-semibold text-2xl">CALL HISTORY</h2>}
            />
            {callHistory.length > 0 ? (
              <div className="my-8">
                {callHistory?.map(
                  (statusHistoryCall: {
                    id: any;
                    call_date: any;
                    representative: any;
                  }) => (
                    <div key={statusHistoryCall.id}>
                      <CustomField
                        title="Call Date"
                        value={
                          utilities.formatDate(statusHistoryCall?.call_date) ||
                          "N/A"
                        }
                      />
                      <CustomField
                        title="Representative"
                        value={statusHistoryCall?.representative || "N/A"}
                      />
                    </div>
                  )
                )}
              </div>
            ) : (
              <>No Call History</>
            )}
            <div className="flex items-center gap-2">
              <Button label={"Close"} onClick={() => handleClose()} />
            </div>
          </React.Fragment>
        )}
      </>
    </div>
  );
};

export default CallHistory;
