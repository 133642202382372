import React from "react";
import { AddValueData, AddValueKeys } from "./types";
import { Control, FieldErrors } from "react-hook-form";
import BackButton from "../backbutton";
import ReferralCallLog from "./ReferralCallLog";
import NerveCallLog from "./NerveCallLog";
import RewardCallLog from "./RewardCallLog";
import Button from "../../common/button";

type Props = {
  setCallInfo: React.Dispatch<
    React.SetStateAction<{ show: boolean; key: AddValueKeys }>
  >;
  control: {
    referral: Control<AddValueData, any>;
    nervePay: Control<AddValueData, any>;
    reward: Control<AddValueData, any>;
  };
  errors: FieldErrors<AddValueData>;
  objKey: AddValueKeys;
  submit: {
    submitReferral: (data: any) => void;
    submitNervePay: (data: any) => void;
    submitReward: (data: any) => void;
  };
  isLoading: boolean;
};

export default function AddValueMasteryCallLog({
  setCallInfo,
  control,
  errors,
  objKey,
  submit, 
  isLoading,
}: Props) {
  return (
    <React.Fragment>
      <BackButton
        goBack={() => {
          setCallInfo({ show: false, key: objKey });
        }}
      />
      <div className="my-8">
        {objKey === "referral" && (
          <ReferralCallLog
            control={control.referral}
            errors={errors} 
          />
        )}
        {objKey === "nervePay" && (
          <NerveCallLog
            control={control.nervePay}
            errors={errors} 
          />
        )}
        {objKey === "reward" && (
          <RewardCallLog
            control={control.reward}
            errors={errors} 
          />
        )}
      </div>
      <Button
        disabled={isLoading}
        type="button"
        label={isLoading ? "Saving..." : "Save call log"}
        onClick={() => {
          if (objKey === "referral") {
            submit.submitReferral(control);
          }
          if (objKey === "nervePay") {
            submit.submitNervePay(control);
          }
          if (objKey === "reward") {
            submit.submitReward(control);
          }
        }}
      />
    </React.Fragment>
  );
}
