import React, { useEffect, useState } from "react";
import _ from "lodash";
import Pagination from "../common/pagination";
import Table from "../common/table";
import ProspectedInfo from "../modals/prospectedInfo";
import AssignAgentForm from "../forms/assignAgentForm";
import LoadingButton from "../common/loadingButton";

import { getUnassignedHospitals } from "../../services/prospectsService";
import toastService from "../../services/toastService";
import utilities from "../../utils/utilities";
import { useGlobalContext } from "../../contexts/globalContext";

const UnassignedTable = ({ onCountChange, search, status, searchKey }) => {
  const [sortColumn, setSortColumn] = useState({ path: "id", order: "desc" });
  const [currentPage, setCurrentPage] = useState(1);
  const [unassigned, setUnassigned] = useState([]);
  const [loading, setLoading] = useState(false);
  const [prospectToView, setProspectToView] = useState(null);
  const [prospectToAssign, setProspectToAssign] = useState(null);

  const { city } = useGlobalContext();

  const pageSize = 10;

  const columns = [
    {
      path: "id",
      label: "ID",
    },
    {
      path: "name",
      label: "Hospital Name",
    },
    {
      path: "email",
      label: "Email Address",
    },
    {
      path: "phone",
      label: "Phone Number",
    },
    {
      path: "address",
      label: "Address",
    },
    {
      path: "state",
      label: "State",
    },
    {
      path: "_",
      label: "",
      content: (c) => (
        <button className="status view" onClick={() => handleViewSelect(c)}>
          View
        </button>
      ),
    },
    {
      path: "__",
      label: "",
      content: (c) => (
        <button
          className="status primary"
          onClick={() => handleAssignSelect(c)}
        >
          Assign
        </button>
      ),
    },
  ];

  const getUnassigned = async () => {
    try {
      setLoading(true);

      const response = await getUnassignedHospitals(city);
      setUnassigned(response.data.unassigned);

      setLoading(false);
    } catch (error) {
      const errorMessage = error?.response?.data?.description ?? error.message;
      toastService.error(errorMessage);
      setLoading(false);
    }
  };

  const paginatedData = () => {
    let filtered = unassigned;

    const searchBy = searchKey ? searchKey : "name";

    if (search)
      filtered = unassigned.filter((item) =>
        item[searchBy].toLowerCase().startsWith(search.toLowerCase())
      );

    if (status)
      filtered = filtered.filter(
        (item) => item.status?.toLowerCase?.() === status.toLowerCase()
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const paginated = utilities.paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, paginated };
  };

  const handleViewSelect = (prospect) => setProspectToView(prospect);
  const handleAssignSelect = (prospect) => setProspectToAssign(prospect);

  const { totalCount, paginated } = paginatedData();

  useEffect(() => {
    getUnassigned();

    // eslint-disable-next-line
  }, [city, prospectToAssign]);

  useEffect(() => {
    onCountChange(totalCount);

    // eslint-disable-next-line
  }, [totalCount]);

  return (
    <React.Fragment>
      {prospectToView && (
        <ProspectedInfo
          selectedProspect={prospectToView}
          handleClose={() => handleViewSelect(null)}
        />
      )}
      {prospectToAssign && (
        <AssignAgentForm
          selectedProspect={prospectToAssign}
          handleClose={() => handleAssignSelect(null)}
        />
      )}
      <div className="activity-feed fade-in">
        <LoadingButton
          visible={loading}
          message="Fetching unassigned hospitals, please wait"
        />
        {!loading && (
          <React.Fragment>
            <Table
              columns={columns}
              data={paginated}
              sortColumn={sortColumn}
              onSort={setSortColumn}
            />

            <div className="sb-pagination-container">
              <Pagination
                itemsCount={totalCount}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          </React.Fragment>
        )}
        {paginated.length === 0 && (
          <div className="no-items">
            <p>No hospitals to show</p>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default UnassignedTable;
