import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router";

import NavigationItem from "./navigationItem";
import SetTargetsForm from "../forms/setTargets";
import TargetReminder from "../modals/targetReminder";

import logo from "../../images/lb.png";
import lifebank from "../../images/lb-logo-white.png";
import authService from "../../services/authService";
import { useGlobalContext } from "../../contexts/globalContext";
import { useGetComplaint } from "../../hooks/useDashboard";

const LeftNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const privileges = authService.getPrivileges();
  const { user, logout, city } = useGlobalContext();
  const { complaints } = useGetComplaint(city);
  const [openBar, setOpenBar] = useState(false);
  const [showTarget, setShowTarget] = useState(false);
  const [helpTrigger, setHelpTrigger] = useState(false);

  const toggleClassName = openBar ? "left" : "right";
  const sublistIconClassName = helpTrigger ? "up" : "down";

  const isActive = (...paths: string[]) => {
    const activePath = paths.find((path) => location.pathname === path);
    return activePath ? "nav-item active" : "nav-item";
  };

  const handleTarget = () => {
    setShowTarget(!showTarget);
  };

  const handleHelpTrigger = () => {
    setHelpTrigger((prev) => !prev);
  };

  const handleNavigateToScoreCard = () => {
    const agent = user;
    navigate(`/my-score/${user?.id}/${user?.username}`, {
      state: { agent },
    });
  };

  const handleMouseOver = () => {
    setOpenBar(true);
  };

  const handleMouseOut = () => {
    setOpenBar(false);
  };

  return (
    <React.Fragment>
      {privileges.setTargets && (
        <TargetReminder showTargetModal={handleTarget} />
      )}

      {privileges.setTargets && showTarget && (
        <SetTargetsForm handleClose={handleTarget} />
      )}

      <div
        className={openBar ? "dashboard-left !z-20" : "dashboard-left closed"}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <div className="dashboard-left-top">
          <div className="dashboard-left-top-header">
            <img src={lifebank} alt="Lifebank" className="lb-logo" />
            <img src={logo} alt="Lifebank" className="lb-logo-only" />

            <NavigationButton
              className="menu-toggle"
              onClick={() => setOpenBar((prev) => !prev)}
              icon={"angle-double-" + toggleClassName}
              isVisible
            />
          </div>

          <ul className="nav-items">
            <NavigationItem
              className={isActive("/home")}
              onClick={() => navigate("/home")}
              icon="home"
              label="Dashboard"
              isVisible={privileges.viewDashboard as boolean}
            />

            <NavigationItem
              className={isActive("/hospitals")}
              onClick={() => navigate("/hospitals")}
              icon="hospital-o"
              label="Hospitals"
              isVisible={privileges.viewHospitals as boolean}
            />

            <NavigationItem
              className={isActive("/events")}
              onClick={() => navigate("/events")}
              icon="calendar-check-o"
              label="Events"
              isVisible={privileges.events as boolean}
            />

            <NavigationItem
              className={isActive("/appointments")}
              onClick={() => navigate("/appointments")}
              icon="calendar"
              label="Appointments"
              isVisible={privileges.viewAppointments as boolean}
            />

            {/* <NavigationItem
              className={isActive("/requests")}
              onClick={() => navigate("/requests")}
              icon="archive"
              label="Hospitals to close"
              isVisible={privileges.viewRequests}
            /> */}

            <NavigationItem
              className={isActive("/tool")}
              onClick={() => navigate("/tool")}
              icon="gear"
              label="Tools"
              isVisible={privileges.tools as boolean}
            />

            <NavigationItem
              className={isActive("/story")}
              onClick={() => navigate("/story")}
              icon="history"
              label="Hospital Stories"
              isVisible={privileges.story as boolean}
            />

            <NavigationItem
              className={isActive("/dazzles")}
              onClick={() => navigate("/dazzles")}
              icon="bullseye"
              label="Dazzles"
              isVisible={privileges.dazzle as boolean}
            />

            <NavigationItem
              className={isActive("/drip")}
              onClick={() => navigate("/drip")}
              icon="file"
              label="Drips"
              isVisible={privileges.viewDrip as boolean}
            />
            <NavigationItem
              className={isActive("/nerve-usage")}
              onClick={() => navigate("/nerve-usage")}
              icon="newspaper-o"
              label="Nerve Usage"
              isVisible={privileges.nerveUsage as boolean}
            />

            {/* <NavigationItem
              className={isActive("/pitch")}
              onClick={() => navigate("/pitch")}
              icon="newspaper-o"
              label="Pitch"
              isVisible={privileges.myHospitals}
            /> */}
            <NavigationItem
              className={isActive("/purchasing-orders")}
              onClick={() => navigate("/purchasing-orders")}
              icon="newspaper-o"
              label="Purchasing Order(PO)"
              isVisible={privileges.purchasingOrder as boolean}
            />

            {/* <NavigationItem
              className={isActive("/active_hospitals")}
              onClick={() => navigate("/active_hospitals")}
              icon="battery-full"
              label="Active Hospitals"
              isVisible={privileges.activeHospitals}
            /> */}

            {/* <NavigationItem
              className={isActive("/onboard")}
              onClick={() => navigate("/onboard")}
              icon="book"
              label="Onboard"
              isVisible={privileges.viewOnboard}
            /> */}

            <NavigationItem
              className={isActive("/maintenance")}
              onClick={() => navigate("/maintenance")}
              icon="cog"
              label="Maintenance Schedule"
              isVisible={privileges.maintenance as boolean}
            />

            {/* <NavigationItem
              className={isActive("/pipeline")}
              onClick={() => navigate("/pipeline")}
              icon="pied-piper-pp"
              label="Pipelines"
              isVisible={privileges.viewPipelines}
            /> */}

            <NavigationItem
              className={isActive("/complaints")}
              onClick={() => navigate("/complaints")}
              icon="envelope-square"
              label="Complaints"
              isVisible={privileges.viewComplaints as boolean}
              badge={<div className="complaints">{complaints.length}</div>}
            />

            <NavigationItem
              className={isActive("/active-feed")}
              onClick={() => navigate("/active-feed")}
              icon="feed"
              label="Activity Feed"
              isVisible={privileges.viewActivityFeed as boolean}
            />

            <NavigationItem
              className={isActive("/mymessages")}
              onClick={() => navigate("/mymessages")}
              icon="envelope"
              label="My Messages"
              isVisible={privileges.viewMessages as boolean}
              // badge={<div className="complaints">{complaints.length}</div>}
            />
            <NavigationItem
              className={isActive("/cancelled-orders")}
              onClick={() => navigate("/cancelled-orders")}
              icon="circle-o-notch"
              label="cancelled Orders"
              isVisible={privileges.viewComplaints as boolean}
              // badge={<div className="complaints">{complaints.length}</div>}
            />

            <NavigationItem
              className={isActive("/leads")}
              onClick={() => navigate("/leads")}
              icon="lightbulb-o"
              label="Manage Agents"
              isVisible={privileges.viewLeadArena as boolean}
            />
            <NavigationItem
              className={isActive("/cadre-request")}
              onClick={() => navigate("/cadre-request")}
              icon="code-fork"
              label="Cadre Request"
              isVisible={privileges.viewLeadArena as boolean}
            />
            <NavigationItem
              className={isActive("/prospects")}
              onClick={() => navigate("/prospects")}
              icon="plus-circle"
              label="Nerve Request Approval"
              isVisible={privileges.viewProspectsPage as boolean}
            />
            <NavigationItem
              className={isActive("/nerve-claims")}
              onClick={() => navigate("/nerve-claims")}
              icon="medkit"
              label="Nerve Account Claims"
              isVisible={privileges.nerveClaims as boolean}
            />
            <NavigationItem
              className={isActive("/facility")}
              onClick={() => navigate("/facility")}
              icon="bank"
              label="Facility"
              isVisible={privileges.viewFacilities as boolean}
            />

            <NavigationItem
              className={isActive("/products")}
              onClick={() => navigate("/products")}
              icon="th-large"
              label="Products"
              isVisible={privileges.viewProductTypes as boolean}
            />

            <NavigationItem
              className="nav-item"
              onClick={handleNavigateToScoreCard}
              icon="graduation-cap"
              label="Reward"
              isVisible={privileges.viewAllScoresPage as boolean}
            />

            <NavigationItem
              className={
                isActive("/documents", "tutorials") + " sublist-trigger"
              }
              onClick={handleHelpTrigger}
              icon="question-circle"
              label="Help"
              isVisible
              badge={
                <i className={"fa fa-chevron-" + sublistIconClassName}></i>
              }
            />

            {helpTrigger && (
              <ul className="sublist">
                <NavigationItem
                  className={isActive("/documents")}
                  onClick={() => navigate("/documents")}
                  icon="file-o"
                  label="Documents"
                  isVisible
                />

                <NavigationItem
                  className={isActive("/tutorials")}
                  onClick={() => navigate("/tutorials")}
                  icon="video-camera"
                  label="Tutorials"
                  isVisible
                />
              </ul>
            )}
          </ul>
        </div>

        <div className="dashboard-left-bottom">
          {/* <NavigationButton
            className="sign-out"
            onClick={handleNavigateToScoreCard}
            icon="graduation-cap"
            label="Score Card"
            isVisible={privileges.viewMyScoreOnly as boolean}
          /> */}

          <NavigationButton
            className="sign-out"
            onClick={handleTarget}
            icon="bullseye"
            label="Set Targets"
            isVisible={privileges.setTargets as boolean}
          />

          <NavigationButton
            className="sign-out"
            onClick={logout}
            icon="sign-out"
            label="Logout"
            isVisible
          />
          <NavigationButton
            className="sign-out"
            icon="3.0.1"
            label="Version: 3.0.1"
            isVisible
          />
        </div>
      </div>
    </React.Fragment>
  );
};

const NavigationButton = ({
  className,
  onClick,
  icon,
  label,
  isVisible,
}: {
  className: string;
  onClick?: () => void;
  icon: string;
  label?: string;
  isVisible: boolean;
}) => {
  if (!isVisible) return null;

  return (
    <button className={className} onClick={onClick}>
      <i className={"fa fa-" + icon}></i>
      <span>{label}</span>
    </button>
  );
};

export default LeftNavigation;
