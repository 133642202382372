import React, { useEffect, useState } from "react";
import Button from "../../../../components/common/button";
import Modal from "../../../../components/common/modal";
import appointmentService from "../../../../services/appointmentService";
import toastService from "../../../../services/toastService";
import ProgressLoader from "../../../../components/common/progressLoader";
import marketingService from "../../../../services/marketingService";
import LoadingButton from "../../../../components/common/loadingButton";
import { useNavigate } from "react-router-dom";

const Drip = (props) => {
  const { selectedPitch, handlePrev } = props;
  const [mail, setMail] = useState(null);
  const [drips, setDrips] = useState([]);
  const [dripType, setDripType] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [selectedItems] = useState([]);
  const [selectedDrip, setSelectedDrip] = useState("");

  const navigate = useNavigate();

  const handleProgressChange = (progress) => {
    setProgress(progress);
  };

  useEffect(() => {
    const getDrip = async () => {
      setLoading(true);
      const res = await marketingService.getDrips();
      const dripType = await marketingService.getAllDrips();

      setDripType(dripType.data.data);

      setDrips(res.data.data);
      setLoading(false);
    };
    getDrip();
  }, []);

  const handleDripType = (e) => {
    setSelectedDrip(e.currentTarget.value);
  };

  const postData = async () => {
    const res = await appointmentService.completeChecklist(
      selectedPitch.hospital_id,
      handleProgressChange
    );
    if (res.data.status !== "success") throw new Error(res.data.message);
    if (res.data.status === "success") navigate("/pipeline?content=onboard");
  };

  const handleComplete = (e) => {
    e.preventDefault();
    toastService.promise(postData(), {
      loading: "sending mail...",
      success: () => `You have successfully onboard ${selectedPitch.name}!`,
      error: (e) =>
        e?.response?.data?.message || e?.message || "Could not send email!",
    });
  };

  // const handleSend = (e) => {
  //   // Make your API request or handle the selected items array here
  //   e.preventDefault();
  //   // console.log(selectedItems);
  //   navigate("/onboard");
  // };

  // if (selectedDrip) {
  const filteredData = drips.filter((item) => item.driptype === selectedDrip);

  return (
    <div>
      <h3 style={{ textAlign: "center" }}>Select Drip</h3>
      <ProgressLoader progress={progress} />
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div className="form-field">
            <label htmlFor={"drip"} className="form-field-label">
              Drip Type
            </label>

            <select
              name={"drip"}
              id={"drip"}
              onChange={handleDripType}
              className="form-field-input"
            >
              <option value="">Select Drip Type</option>
              {dripType.map((option) => (
                <option key={option.id} value={option.name}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="pitch-data">
        <div className="drip-grid">
          <LoadingButton visible={loading} message="Please wait" />
          {filteredData?.map(({ id, driptype, link }) => (
            <>
              <div
                className={`drip ${
                  selectedItems.includes(id) ? "selected" : ""
                }`}
                key={id}
                onClick={() => handleCopyClick(link)}
              >
                {driptype}
              </div>
            </>
          ))}
        </div>
      </div>
      <div className="checklist-button">
        <Button label="Prev" onClick={handlePrev} />
        <Button label="Complete" onClick={handleComplete} />
      </div>

      {mail && (
        <Modal handleClose={() => setMail(null)}>
          <div className="row">
            <div dangerouslySetInnerHTML={{ __html: mail?.data }} />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Drip;

const handleCopyClick = (url) => {
  navigator.clipboard
    .writeText(url)
    .then(() => {
      toastService.success(
        "URL copied to clipboard. Please share with the hospital"
      );
    })
    .catch((error) => {
      toastService.error("Failed to copy URL to clipboard");
    });
};
