import React, { useCallback, useMemo, useState } from "react";
import Header from "../../../../components/blocks/header";

import { useGetAppointment } from "../../../../hooks/sales/useAppointment";
import DataTable from "../../../../components/tables/tableEvents/data-table";

import toastService from "../../../../services/toastService";
import { MoreVertical, Eye } from "lucide-react";
import { Button } from "../../../../@/components/ui/button";
import { Textarea } from "../../../../@/components/ui/textarea";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../@/components/ui/form";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../@/components/ui/dropdown-menu";
import { Checkbox } from "../../../../@/components/ui/checkbox";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { ReloadIcon } from "@radix-ui/react-icons";
import TransitionsModal from "../../../../components/modals/mod/CustomModal";

import { useSendAcquisition } from "../../../../hooks/marketing/useAcquisition";
import { useGlobalContext } from "../../../../contexts/globalContext";
import SalesAppointments from "../salesAppointment";
import LoadingButton from "../../../../components/common/loadingButton";
import { useQueryClient } from "@tanstack/react-query";
const Appoint = () => {
  const { user } = useGlobalContext();

  const [isOpen, setIsOpen] = useState(false);
  const [viewData, setViewData] = useState<any>(null);
  const [leaveANote, setLeaveANote] = useState(true);
  const { appointment, isPending } = useGetAppointment();

  const queryClient = useQueryClient();
  const { mutate, isPending: isSending } = useSendAcquisition();

  const transforemdAppointment = appointment.map(
    (appointment: { header: any; status: string }) => {
      return {
        ...appointment,
      };
    }
  );

  const openModal = useCallback((data: any) => {
    if (data) {
      setViewData(data);
      setIsOpen(true);
    }
  }, []);
  const columns = useMemo(
    () => [
      {
        id: "select",
        header: ({ table }: { table: any }) => (
          <Checkbox
            checked={
              table.getIsAllPageRowsSelected() ||
              (table.getIsSomePageRowsSelected() && "indeterminate")
            }
            onCheckedChange={(value) => {
              table.toggleAllPageRowsSelected(!!value);
              //   console.log(table.options.data)
            }}
            aria-label="Select all"
          />
        ),
        cell: ({ row }: { row: any }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => {
              row.toggleSelected(!!value);
              //   console.log(row.original);
            }}
            aria-label="Select row"
          />
        ),
        enableSorting: false,
        enableHiding: false,
      },
      {
        accessorKey: "name",
        header: "Hospital Name",
        cell: ({ row }: { row: any }) => (
          <Button variant="ghost" className="text-sm capitalize  ">
            {row.getValue("name")}
          </Button>
        ),
      },
      {
        accessorKey: "email",
        header: "Email Address",
      },
      {
        accessorKey: "phone",
        header: "Phone Number",
      },
      {
        accessorKey: "city",
        header: "Address",
      },
      {
        accessorKey: "appointmentdate",
        header: "Date",
      },
      {
        accessorKey: "time",
        header: "Time",
      },
      {
        accessorKey: "preference",
        header: "Mode",
      },
      // {
      //   accessorKey: "status",
      //   header: "Status",
      //   cell: ({ row }: { row: any }) => (
      //     <div className="">
      //       <div
      //         className={`${
      //           row.getValue("status") === "active"
      //             ? "bg-green-100 text-green-800"
      //             : "bg-yellow-100 text-yellow-800"
      //         } px-2 py-1 rounded-full w-fit ml-auto text-center`}
      //       >
      //         {row.getValue("status") ?? "Pending"}
      //       </div>
      //       {}
      //     </div>
      //   ),
      // },
      {
        accessorKey: "id",
        header: " ",
        cell: ({ row }: { row: any }) => {
          return (
            <div>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" className="h-8 w-8 p-0">
                    <span className="sr-only">Open menu</span>
                    <MoreVertical className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem
                    className="cursor-pointer"
                    onClick={() => {
                      openModal(row.original);
                    }}
                  >
                    <Eye className="mr-2 h-4 w-4" /> View
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          );
        },
      },
    ],
    [openModal]
  );
  const FormSchema = z.object({
    description: z.string().min(10, {
      message: "Description must be at least 10 characters.",
    }),
  });
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    mode: "onChange",
    defaultValues: {
      description: "",
    },
  });

  const { getValues } = form;

  const onSubmit = (data: z.infer<typeof FormSchema>) => {
    setLeaveANote(true);
  };

  const handleAcquisition = () => {
    const note = getValues("description");
    const payload = {
      id: viewData?.id,
      note: note,
    };
    mutate(payload, {
      onSuccess: (response: { data: { message?: string | undefined } }) => {
        const data = response.data as { message?: string };

        toastService.success(
          data.message ?? "Appointment sent to acquisition."
        );
        setIsOpen(false);
        queryClient.invalidateQueries({
          queryKey: ["appointment"],
        });
      },
      onError: (error: any) => {
        console.log(error);
        toastService.error("Failed to send to acquisition. Please try again.");
      },
    });
  };

  const handleCloseModal = () => {
    setLeaveANote(true);
    setIsOpen(false);
  };

  return user?.position === "cceo" ? (
    <>
      {" "}
      {isPending ? (
        <LoadingButton
          visible={isPending}
          message="Fetching Appointments... Please wait"
        />
      ) : (
        <SalesAppointments appointments={appointment.reverse() ?? []} />
      )}
    </>
  ) : (
    <React.Fragment>
      <Header title={`Appointments`} />

      <div className="hospitals-container">
        <div className="hospitals-container-bottom table-responsive">
          {user?.position === "marketing" && (
            <>
              {isPending ? (
                <LoadingButton
                  visible={isPending}
                  message="Fetching Appointments... Please wait"
                />
              ) : (
                <DataTable
                  columns={columns}
                  data={transforemdAppointment.reverse()}
                />
              )}
            </>
          )}
        </div>
      </div>

      {isOpen && (
        <TransitionsModal
          handleCloseModal={handleCloseModal}
          open={isOpen}
          setOpen={setIsOpen}
          title={!leaveANote ? "Leave a note" : "Appointment Information"}
          footer={
            <>
              {leaveANote ? (
                <div className="flex items-center justify-between lg:w-1/2 gap-3">
                  <Button
                    disabled={isSending}
                    className="transition duration-500 ease-in-out transform active:translate-y-2 active:scale-95"
                    variant="default"
                    onClick={() => setLeaveANote(false)}
                  >
                    Leave a note
                  </Button>

                  <Button
                    disabled={isSending}
                    className="transition duration-500 ease-in-out transform active:translate-y-2 active:scale-95"
                    variant="default"
                    onClick={() => {
                      handleAcquisition();
                    }}
                  >
                    {isSending && (
                      <ReloadIcon className="animate-spin mr-2 h-4 w-4" />
                    )}
                    {isSending ? "Please wait..." : "Send to Acquisition"}
                  </Button>
                </div>
              ) : (
                <Button
                  className=" transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-210  active:translate-y-2 active:scale-95"
                  disabled={form.formState.isSubmitting}
                  type="button"
                  onClick={() => {
                    form.handleSubmit(onSubmit)();
                  }}
                >
                  Save Note
                </Button>
              )}
            </>
          }
        >
          <>
            {leaveANote ? (
              <>
                <div className="w-full border rounded-md">
                  <div className="flex items-center justify-between w-full border-b">
                    <p className="px-4 py-3 bg-[#A0A0A0A0] font-bold text-[0.8rem] md:text-base w-1/3 text-start rounded-tl-md">
                      Hospital Name:
                    </p>
                    <p className="px-4 py-3 flex-1 w-full text-start">
                      {viewData?.name}
                    </p>
                  </div>
                  <div className="flex items-center justify-between  w-full border-b ">
                    <p className="px-4 py-3 bg-[#A0A0A0A0] font-bold text-[0.8rem] md:text-base w-1/3 text-start">
                      Facility Name:
                    </p>
                    <p className="px-4 py-3 flex-1 w-full text-start">
                      {viewData?.name}
                    </p>
                  </div>
                  <div className="flex items-center justify-between  w-full border-b ">
                    <p className="px-4 py-3 bg-[#A0A0A0A0] font-bold text-[0.8rem] md:text-base w-1/3 text-start">
                      Email Address:
                    </p>
                    <p className="px-4 py-3 flex-1 w-full text-start">
                      {viewData?.email}
                    </p>
                  </div>
                  <div className="flex items-center justify-between  w-full border-b ">
                    <p className="px-4 py-3 bg-[#A0A0A0A0] font-bold text-[0.8rem] md:text-base w-1/3 text-start">
                      Your phone number:
                    </p>
                    <p className="px-4 py-3 flex-1 w-full text-start">
                      {viewData?.phone}
                    </p>
                  </div>
                  <div className="flex items-center justify-between  w-full border-b ">
                    <p className="px-4 py-3 bg-[#A0A0A0A0] font-bold text-[0.8rem] md:text-base w-1/3 text-start">
                      City of operation:
                    </p>
                    <p className="px-4 py-3 flex-1 w-full text-start">
                      {viewData?.city}
                    </p>
                  </div>
                  <div className="flex items-center justify-between  w-full border-b ">
                    <p className="px-4 py-3 bg-[#A0A0A0A0] font-bold text-[0.8rem] md:text-base w-1/3 text-start">
                      State:
                    </p>
                    <p className="px-4 py-3 flex-1 w-full text-start">
                      {viewData?.state}
                    </p>
                  </div>
                  <div className="flex items-center justify-between  w-full border-b ">
                    <p className="px-4 py-3 bg-[#A0A0A0A0] font-bold text-[0.8rem] md:text-base w-1/3 text-start">
                      Preferred Mode:
                    </p>
                    <p className="px-4 py-3 flex-1 w-full text-start">
                      {viewData?.preference}
                    </p>
                  </div>
                  <div className="flex items-center justify-between  w-full border-b ">
                    <p className="px-4 py-3 bg-[#A0A0A0A0] font-bold text-[0.8rem] md:text-base w-1/3 text-start">
                      Appointment Date:
                    </p>
                    <p className="px-4 py-3 flex-1 w-full text-start">
                      {viewData?.appointmentdate}
                    </p>
                  </div>
                  <div className="flex items-center justify-between  w-full border-b">
                    <p className="px-4 py-3 bg-[#A0A0A0A0] font-bold text-[0.8rem] md:text-base w-1/3 text-start rounded-bl-md">
                      Time:
                    </p>
                    <p className="px-4 py-3 flex-1 w-full text-start">
                      {viewData?.time}
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <div className="w-full px-3">
                <Form {...form}>
                  <form>
                    <FormField
                      control={form.control}
                      name="description"
                      render={({ field }) => (
                        <FormItem>
                          <div className="flex flex-col gap-3 w-full">
                            <FormLabel className=" font-semibold text-start">
                              Note Description
                            </FormLabel>
                            <FormControl>
                              <Textarea
                                placeholder="Describe what's special about your event & other important details."
                                className="resize-none"
                                {...field}
                              />
                            </FormControl>
                          </div>
                          <div className="flex items-center justify-end">
                            <FormMessage />
                          </div>
                        </FormItem>
                      )}
                    />
                  </form>
                </Form>
              </div>
            )}
          </>
        </TransitionsModal>
      )}
    </React.Fragment>
  );
};

export default Appoint;
