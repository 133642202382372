import React, { useState, useMemo } from "react";

import Header from "../../../../components/blocks/header";

import { Button as Btn } from "../../../../@/components/ui/button";
import { ReloadIcon } from "@radix-ui/react-icons";
import DataTable from "../../../../components/tables/tableEvents/data-table";
import { MoreVertical, Eye } from "lucide-react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../@/components/ui/dropdown-menu";
import { Checkbox } from "../../../../@/components/ui/checkbox";
import LoadingButton from "../../../../components/common/loadingButton";
import ProgressLoader from "../../../../components/common/progressLoader";
import TransitionsModal from "../../../../components/modals/mod/CustomModal";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import {
  useGetDazzle,
  useCreateDazzle,
} from "../../../../hooks/marketing/useDazzle";
import CreateDazzle from "./CreateDazzle";

const Dazzle = () => {
  const { dazzle, baseUrl, isPending } = useGetDazzle();
  const [progress, setProgress] = useState(0);
  const handleProgressChange = (progress: any) => {
    setProgress(progress);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [sample, setSample] = useState<File | null>(null);

  const [viewDazzle, setViewDazzle] = useState<any>(null);

  const handleCloseViewTools = () => {
    setViewDazzle(null);
  };

  const columns = useMemo(
    () => [
      {
        id: "select",
        header: ({ table }: { table: any }) => (
          <Checkbox
            checked={
              table.getIsAllPageRowsSelected() ||
              (table.getIsSomePageRowsSelected() && "indeterminate")
            }
            onCheckedChange={(value) => {
              table.toggleAllPageRowsSelected(!!value);
              //   console.log(table.options.data)
            }}
            aria-label="Select all"
          />
        ),
        cell: ({ row }: { row: any }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => {
              row.toggleSelected(!!value);
              //   console.log(row.original);
            }}
            aria-label="Select row"
          />
        ),
        enableSorting: false,
        enableHiding: false,
      },
      {
        accessorKey: "name",
        header: "Dazzle Description",
        cell: ({ row }: { row: any }) => {
          const description = row.getValue("name") as string;
          return (
            <div className="max-h-10 overflow-hidden text-ellipsis">
              {description ?? "N/A"}
            </div>
          );
        },
      },
      {
        accessorKey: "created_at",
        header: "Created Date",
      },
      // {
      //   accessorKey: "clicks",
      //   header: "Number of Views",
      //   cell: ({ row }: { row: any }) => {
      //     const clicks = row.getValue("clicks") as number;
      //     return <div>{clicks ?? "N/A"}</div>;
      //   },
      // },
      // {
      //   accessorKey: "time",
      //   header: "Average time spent",
      //   cell: ({ row }: { row: any }) => {
      //     const time = row.getValue("time") as string;
      //     return <div>{time ?? "N/A"}</div>;
      //   },
      // },

      {
        accessorKey: "id",
        header: " ",
        cell: ({ row }: { row: any }) => {
          return (
            <div>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Btn variant="ghost" className="h-8 w-8 p-0">
                    <span className="sr-only">Open menu</span>
                    <MoreVertical className="h-4 w-4" />
                  </Btn>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem
                    onClick={() => {
                      setViewDazzle(row.original);
                    }}
                    className="cursor-pointer"
                  >
                    <Eye className="mr-2 h-4 w-4" /> View
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          );
        },
      },
    ],
    []
  );

  const schema = z.object({
    description: z.string().min(10, {
      message: "Description must be at least 10 characters.",
    }),
  });

  const form = useForm({
    resolver: zodResolver(schema),
    mode: "onChange",
    defaultValues: {
      name: "",
      duration: "",
      startdate: new Date(),
      type: "quick-guide",
    },
  });
  const createDazzle = () => {
    setIsOpen(true);
  };
  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const { mutate, isPending: isCreating } =
    useCreateDazzle(handleProgressChange);

  const onSubmit = async (data: any) => {
    const formData = new FormData();
    formData.append("name", data.description);
    if (sample) {
      formData.append("image", sample);
    }

    mutate(formData, {
      onSuccess: () => {
        form.reset();
        setSample(null);
        setIsOpen(false);
        setProgress(0);
      },
      onError: () => {
        setProgress(0);
      },
    });
  };

  const handleBackdropClose = () => setViewDazzle(null);
  return (
    <React.Fragment>
      <ProgressLoader progress={progress} />
      <Header title={`Dazzles`} />

      <div className="hospitals-container">
        <div className="">
          {isPending ? (
            <LoadingButton
              visible={isPending}
              message="Fetching Dazzle... Please wait"
            />
          ) : (
            <DataTable
              columns={columns}
              data={dazzle.reverse()}
              onClick={createDazzle}
              btnTitle="ADD NEW DAZZLE"
            />
          )}
        </div>
      </div>

      <TransitionsModal
        open={isOpen}
        setOpen={setIsOpen}
        handleCloseModal={handleCloseModal}
        title="Add new dazzle"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          bgcolor: "white",
          boxShadow: 24,
          borderRadius: "10px",
        }}
        footer={
          <div className="flex items-center justify-center w-1/2 gap-3">
            <Btn
              className="transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-210 w-full active:translate-y-2 active:scale-95"
              disabled={form.formState.isSubmitting || isCreating}
              type="button"
              onClick={() => {
                form.handleSubmit(onSubmit)();
              }}
            >
              {isCreating && (
                <ReloadIcon className="animate-spin mr-2 h-4 w-4" />
              )}
              {isCreating ? "Adding..." : "New Dazzle"}
            </Btn>
          </div>
        }
      >
        <CreateDazzle form={form} setSample={setSample} sample={sample} />
      </TransitionsModal>

      <TransitionsModal
        open={!!viewDazzle}
        setOpen={setViewDazzle}
        handleCloseModal={handleCloseViewTools}
        handleBackdropClose={handleBackdropClose}
        title="Dazzle Information"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "white",
          boxShadow: 24,
          borderRadius: "10px",
        }}
      >
        <div className="w-full border rounded-md">
          <div className="flex items-center justify-between w-full border-b">
            <p className="px-4 py-3 bg-[#A0A0A0A0] font-bold text-[0.8rem] md:text-base w-1/3 text-start rounded-tl-md">
              Dazzle Description:
            </p>
            <p className="px-4 py-3 flex-1 w-full text-start capitalize">
              {viewDazzle?.name}
            </p>
          </div>
          <div className="flex items-center justify-between  w-full border-b ">
            <p className="px-4 py-3 bg-[#A0A0A0A0] font-bold text-[0.8rem] md:text-base w-1/3 text-start">
              Created Date:
            </p>
            <p className="px-4 py-3 flex-1 w-full text-start">
              {viewDazzle?.created_at}
            </p>
          </div>

          <div className="flex items-center justify-between  w-full border-b bg-[#A0A0A0A0] ">
            <p className="font-bold text-[0.8rem] md:text-base w-1/3 text-center">
              Upload Preview:
            </p>
            <div className="p-2 flex-1 w-full text-start bg-white">
              <div className="h-80 max-h-44 flex justify-center ">
                <img
                  className="rounded-md w-full object-fit max-h-full"
                  src={`https://${baseUrl}${viewDazzle?.filename}`}
                  alt="preview"
                />
              </div>
            </div>
          </div>
        </div>
      </TransitionsModal>
    </React.Fragment>
  );
};

export default Dazzle;
