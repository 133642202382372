import React, { useState } from "react";
import AddValueMasteryCallLog from "./AddValueMasteryCallLog";
import BackButton from "../backbutton";
import { success, error } from "../../../services/toastService";
import { useForm } from "react-hook-form";
import { AddValueData, AddValueKeys } from "./types";
import Button from "../../common/button";
import CustomField from "../../common/CustomField";
import {
  useCreateCallLog,
  useCompleteMastery,
} from "../../../hooks/useHospital";

import utilities from "../../../utils/utilities";

import { useGlobalContext } from "../../../contexts/globalContext";

type Props = {
  setShowMastery: React.Dispatch<React.SetStateAction<boolean>>;
  refId: string;
  handleClose: () => void;
  reward: string;
  status: string;
};
const defaultValues: AddValueData = {
  referral: {
    date: "",
    hospital_agent: "",
    questions: "",
    heard_of_referral: "",
    join_referral: "",
    commit_to_referral: "",
    why_not: "",
  },
  nervePay: {
    date: "",
    hospital_agent: "",
    questions: "",
    heard_of_nervePay: "",
    join_nervePay: "",
    commit_to_nervePay: "",
    why_not: "",
  },
  reward: {
    date: "",
    hospital_agent: "",
    questions: "",
    heard_of_reward: "",
    join_reward: "",
    commit_to_reward: "",
    why_not: "",
  },
};
export default function MasterAddValue({
  setShowMastery,
  refId,
  handleClose,
  reward,
  status,
}: Props) {
  const { user } = useGlobalContext();
  const { mutateCallLog, isPending } = useCreateCallLog(refId);
  const { completeMastery } = useCompleteMastery();

  const data: any = JSON.parse(reward);
  const [formCompletion, setFormCompletion] = useState({
    referral: data ? data?.referredothers : false,
    nervePay: data ? data?.nervePay : false,
    reward: data ? data?.onrewardprogramme : false,
  });

  const [callInfo, setCallInfo] = useState<{
    show: boolean;
    key: AddValueKeys;
  }>({ show: false, key: "referral" });

  const {
    handleSubmit: submitReferral,
    control: controlReferral,
    formState: { errors: errorsReferral },
  } = useForm<AddValueData>({
    defaultValues: defaultValues,
  });

  const {
    handleSubmit: submitNervePay,
    control: controlNervePay,
    formState: { errors: errorsNervePay },
  } = useForm<AddValueData>({
    defaultValues: defaultValues,
  });

  const {
    handleSubmit: submitReward,
    control: controlReward,
    formState: { errors: errorsReward },
  } = useForm<AddValueData>({
    defaultValues: defaultValues,
  });

  const onSubmitReferral = (data: any) => {
    const feedback = [];
    if (data.referral.why_not) {
      feedback.push(data.referral.why_not);
    }

    const logPayload = {
      hospital_ref: refId,
      customer_success_rep_ref: user?.id,
      hospital_agent: data.referral.hospital_agent,
      call_date: utilities.formatDate(
        data.referral.date,
        "YYYY-MM-DD HH:mm:ss"
      ),
      actiontype: "add-value",
      log_type: "hospital-referral",
      log_data: {
        joinreferralprogram: data.referral.join_referral,
        committoprogram: data.referral.commit_to_referral,
        feedback,
      },
      joinreferralprogram: data.referral.join_referral,
      committoprogram: data.referral.commit_to_referral,
      feedback,
    };

    mutateCallLog(logPayload, {
      onSuccess: (data) => {
        success(data.data.message, "add-value-mastery-success");
        setCallInfo(
          (prev) =>
            (prev = {
              show: false,
              key: "referral",
            })
        );
        setFormCompletion((prev) => ({
          ...prev,
          referral: true,
        }));
      },
      onError: (err) => {
        console.log(err);
        error("Add value mastery failed", "failed");
      },
    });
  };

  const onSubmitNervePay = (data: any) => {
    const feedback = [];
    if (data.nervePay.why_not) {
      feedback.push(data.nervePay.why_not);
    }

    const logData = {
      hospital_ref: refId,
      customer_success_rep_ref: user?.id,
      hospital_agent: data.nervePay.hospital_agent,
      call_date: utilities.formatDate(
        data.nervePay.date,
        "YYYY-MM-DD HH:mm:ss"
      ),
      actiontype: "add-value",
      log_type: "nerve-pay",
      log_data: {
        heardofnervepay: data.nervePay.heard_of_nervePay,
        joinprogram: data.nervePay.join_nervePay,
        committoprogram: data.nervePay.commit_to_nervePay,
        feedback,
      },
    };

    mutateCallLog(logData, {
      onSuccess: (data) => {
        success(data.data.message, "add-value-mastery-success");
        setCallInfo(
          (prev) =>
            (prev = {
              show: false,
              key: "nervePay",
            })
        );
        setFormCompletion((prev) => ({
          ...prev,
          nervePay: true,
        }));
      },
      onError: (err) => {
        console.log(err);
        error("Add nerve pay value failed", "failed");
      },
    });
  };

  const onSubmitReward = (data: any) => {
    const feedback = [];

    if (data.reward.why_not) {
      feedback.push(data.reward.why_not);
    }

    const logData = {
      hospital_ref: refId,
      customer_success_rep_ref: user?.id,
      hospital_agent: data.reward.hospital_agent,
      call_date: utilities.formatDate(data.reward.date, "YYYY-MM-DD HH:mm:ss"),
      actiontype: "add-value",
      log_type: "rewards-program",
      log_data: {
        heardofprogram: data.reward.heard_of_reward,
        joinprogram: data.reward.join_reward,
        committoprogram: data.reward.commit_to_reward,
        feedback,
      },
    };

    mutateCallLog(logData, {
      onSuccess: (data) => {
        success(data.data.message, "add-value-mastery-success");
        setCallInfo(
          (prev) =>
            (prev = {
              show: false,
              key: "reward",
            })
        );
        setFormCompletion((prev) => ({
          ...prev,
          reward: true,
        }));
      },
      onError: (err) => {
        console.log(err);
        error("Add reward value failed", "failed");
      },
    });
  };

  const onSubmit = () => {
    if (!formCompletion.referral) {
      error("Please Complete Pitch Referral", "incomplete-referral");
      return;
    }
    if (!formCompletion.nervePay) {
      error("Please Complete Pitch Nerve Pay", "incomplete-nervepay");
      return;
    }
    if (!formCompletion.reward) {
      error("Please Complete Pitch Reward", "incomplete-reward");
      return;
    }

    completeMastery(
      {
        hospital_ref: refId,
      },
      {
        onSuccess: (data) => {
          success(
            status === "add-value"
              ? "Add-value mastery successful"
              : status === "check-in"
              ? "Check-in mastery successful"
              : "Up-sell mastery successful",
            "add-value-mastery-success"
          );
          handleClose();
        },
        onError: (err) => {
          console.log(err);
          error(
            status === "add-value"
              ? "Add-value mastery failed"
              : status === "check-in"
              ? "Check-in mastery failed"
              : "Up-sell mastery failed",
            "add-value-mastery-failed"
          );
        },
      }
    );
  };

  return (
    <div>
      {callInfo.show ? (
        <AddValueMasteryCallLog
          isLoading={isPending}
          setCallInfo={setCallInfo}
          control={{
            referral: controlReferral,
            nervePay: controlNervePay,
            reward: controlReward,
          }}
          errors={
            callInfo.key === "referral"
              ? errorsReferral
              : callInfo.key === "nervePay"
              ? errorsNervePay
              : errorsReward
          }
          objKey={callInfo.key}
          submit={{
            submitReferral: submitReferral(onSubmitReferral),
            submitNervePay: submitNervePay(onSubmitNervePay),
            submitReward: submitReward(onSubmitReward),
          }}
        />
      ) : (
        <React.Fragment>
          <BackButton
            goBack={() => {
              setShowMastery(false);
            }}
          />
          <div className="my-8">
            <CustomField
              title={
                <>
                  <span>Has Hospital Referred LifeBank - </span>
                  <span
                    className={`px-1 rounded ${
                      !data?.referredothers
                        ? "text-red-500 bg-red-100"
                        : "text-green-500 bg-green-100"
                    }`}
                  >
                    {data?.referredothers ? 2 : 0}
                  </span>
                </>
              }
              value={
                <Button
                  disabled={data?.referredothers}
                  onClick={() => setCallInfo({ show: true, key: "referral" })}
                  label="Pitch Referral"
                  type="button"
                  style={{ marginTop: "0px" }}
                />
              }
            />
            <CustomField
              title={
                <>
                  <span>Has Hospital used Nerve Pay - </span>
                  <span
                    className={`px-1 rounded ${
                      !data?.usenervepay
                        ? "text-red-500 bg-red-100"
                        : "text-green-500 bg-green-100"
                    }`}
                  >
                    {data?.usenervepay ? 2 : 0}
                  </span>
                </>
              }
              value={
                <Button
                  disabled={data?.usenervepay}
                  onClick={() => setCallInfo({ show: true, key: "nervePay" })}
                  label="Pitch NervePay"
                  type="button"
                  style={{ marginTop: "0px" }}
                />
              }
            />
            <CustomField
              title={
                <>
                  <span>Is Hospital part of Values’/Reward Program - </span>
                  <span
                    className={`px-1 rounded ${
                      !data?.onrewardprogramme
                        ? "text-red-500 bg-red-100"
                        : "text-green-500 bg-green-100"
                    }`}
                  >
                    {data?.onrewardprogramme ? 2 : 0}
                  </span>
                </>
              }
              value={
                <Button
                  disabled={data?.onrewardprogramme}
                  onClick={() => setCallInfo({ show: true, key: "reward" })}
                  label="Pitch Reward Program"
                  type="button"
                  style={{ marginTop: "0px" }}
                />
              }
            />

            <Button onClick={onSubmit} label="Finish Actions" />
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
