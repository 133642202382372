// import { useEffect, useState } from "react";

// import utilities from "../../utils/utilities";

// import toastService from "../../services/toastService";
// import dashboardService from "../../services/dashboardService";

import { useGlobalContext } from "../../contexts/globalContext";
import { useGetDashboardData } from "../../hooks/useDashboard";
function withBreakdown(Component) {
  return function WithBreakdown(props) {
    const { user } = useGlobalContext();
    const { dashboard } = useGetDashboardData(user?.id || "");

    // const [breakdown, setBreakdown] = useState(initialBreakdown);
    // console.log(breakdown);
    const getTargetForTheMonth = () => {
      const month = new Date().getMonth() + 1;
      const targetForTheMonth = dashboard.targets?.find(
        (target) => target.mon === month.toString()
      );

      return targetForTheMonth;
    };

    const getMonthFromBreakdown = (arrayItems) => {
      const currentMonth = arrayItems?.find((item) => {
        const today = new Date();

        return (
          parseInt(item.mon) === today.getMonth() + 1 &&
          parseInt(item.yr) === today.getFullYear()
        );
      });

      return currentMonth ? currentMonth.num : 0;
    };

    const getMonthData = () => {
      return {
        activeCurrent: getMonthFromBreakdown(dashboard.active),
        onboardedCurrent: getMonthFromBreakdown(dashboard.onboarded),
        totalCurrent: getMonthFromBreakdown(dashboard.created_hospitals),
        visitedCurrent: 0,
      };
    };

    const getTargetData = () => {
      const currentMonthTarget = getTargetForTheMonth();
      if (!currentMonthTarget) return {};

      const { activeCurrent, onboardedCurrent, totalCurrent, visitedCurrent } =
        getMonthData();

      const activeTarget = +currentMonthTarget.active_hospitals;
      const onboardedTarget = +currentMonthTarget.onboarded_hospitals;
      const totalTarget = +currentMonthTarget.total_hospitals;
      const visitedTarget = +currentMonthTarget.visited_hospitals;

      const getPercentage = (numerator, denominator) =>
        Math.floor((numerator / denominator) * 100);

      return {
        activeExceeded: activeCurrent >= activeTarget,
        onboardedExceeded: onboardedCurrent >= onboardedTarget,
        totalExceeded: totalCurrent >= totalTarget,
        visitedExceeded: visitedCurrent >= visitedTarget,
        activePercentage: getPercentage(activeCurrent, activeTarget),
        onboardedPercentage: getPercentage(onboardedCurrent, onboardedTarget),
        totalPercentage: getPercentage(totalCurrent, totalTarget),
        visitedPercentage: getPercentage(visitedCurrent, visitedTarget),
      };
    };

    // const getOrderBreakdown = async () => {
    //   try {
    //     const privilegeIndex = utilities.getUserPrivilegeIndex(user.position);
    //     const endpointUserId = privilegeIndex > 1 ? user.id : "0";

    //     const response = await dashboardService.getData(endpointUserId);
    //     console.log(response.data);
    //     setBreakdown(response.data);
    //   } catch (ex) {
    //     const error = ex?.response?.data?.description ?? ex.message;
    //     toastService.error(error);
    //   }
    // };

    // useEffect(() => {
    //   getOrderBreakdown();
    //   return () => {
    //     // Cleanup actions
    //     setBreakdown([]);
    //   };
    //   // eslint-disable-next-line
    // }, []);

    return (
      <Component
        breakdown={dashboard}
        targetData={getTargetData()}
        monthData={getMonthData()}
        targetForTheMonth={getTargetForTheMonth()}
        {...props}
      />
    );
  };
}

// const initialBreakdown = {
//   active: [],
//   created_hospitals: [],
//   onboarded: [],
//   targets: [],
// };

export default withBreakdown;
