import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ExodusHeader from "../../../../components/exodusComponent/ExodusHeader";
import { GoDash } from "react-icons/go";
import SearchInput from "../../../../components/exodusComponent/Search";

import {
  useGetDrip,
  useGetExodus,
  usePostDrip,
} from "../../../../hooks/useExodus";
import ExodusLoading from "../../../../components/exodusComponent/ExodusLoding";
import { useGlobalContext } from "../../../../contexts/globalContext";
import { error, success } from "../../../../services/toastService";
import TransitionsModal from "../../../../components/modals/mod/CustomModal";
import EmailContent from "../drips/EmailContent";
import ExodusButton from "../../../../components/exodusComponent/ExodusButton";
import { PiTelegramLogoThin } from "react-icons/pi";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import RadioButton from "../../../../components/exodusComponent/RadioButton";
import { InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { FaExclamationCircle } from "react-icons/fa";

const SendDrip = () => {
  const navigate = useNavigate();
  const { drip, baseUrl } = useGetDrip();
  const { mutatePostDrip, isPending } = usePostDrip();
  const { user } = useGlobalContext();
  const location = useLocation();
  const { name, id } = location?.state;

  const [isMobile, setIsMobile] = useState(false);
  const [viewDrip, setViewDrip] = useState<any>(null);
  const [sms, setSms] = useState("");
  const [message, setMessage] = useState("");
  const [sanitizedMessage, setSanitizedMessage] = useState("");
  const [sanitizedUrl, setSanitizedUrl] = useState("");
  const [fileType, setFileType] = useState("");
  const [selectedChannel, setSelectedChannel] = useState<string>("");
  const [chanelError, setChanelError] = useState("") as any;
  const [availablePlatforms, setAvailablePlatforms] = useState<string[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedDrip, setSelectedDrip] = useState<{
    id: string;
    title: string;
    description: string;
    message: string;
    email: string;
    whatsapp: string;
    asset: string;
    platform: string;
    type: string;
  }>({
    id: "",
    title: "",
    description: "",
    message: "",
    email: "",
    whatsapp: "",
    asset: "",
    platform: "",
    type: "",
  });
  const [dripError, setDripError] = useState("") as any;

  function extractAndSanitizeLink(message: string) {
    if (!message) {
      error("No message found in the input");
      return;
    }
    const urlPattern = /<img\s+src=['"]([^'"]+)['"]>/;
    const match = message.match(urlPattern);

    if (match && match[1]) {
      let url = match[1];
      url = url.replace(/[^a-zA-Z0-9./:-]/g, "");

      if (!url.startsWith("http://") && !url.startsWith("https://")) {
        url = "https://" + url;
      }

      const extension = url.split(".").pop()?.toLowerCase() || "";
      let fileType;
      if (["jpg", "jpeg", "png", "gif"].includes(extension)) {
        fileType = "image";
      } else if (["mp4", "webm", "ogg"].includes(extension)) {
        fileType = "video";
      } else if (extension === "pdf") {
        fileType = "pdf";
      } else {
        fileType = "unknown";
      }

      const sanitizedMessage = message.split(urlPattern)[0].trim();

      return { sanitizedMessage, sanitizedUrl: url, fileType };
    } else {
      throw new Error("No valid URL found in the input message");
    }
  }
  useEffect(() => {
    if (message !== "") {
      const { sanitizedMessage, sanitizedUrl, fileType } =
        extractAndSanitizeLink(message) ?? {
          sanitizedMessage: message,
          sanitizedUrl: "",
          fileType: "unknown",
        };
      setSanitizedMessage(sanitizedMessage);
      setSanitizedUrl(sanitizedUrl);
      setFileType(fileType);
    }
  }, [message]);

  function stripHtmlTags(html: string) {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  }

  const handleCopy = () => {
    const textToCopy = sms
      ? sms
      : `${stripHtmlTags(sanitizedMessage)} ${sanitizedUrl}`;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        success("Copied to clipboard");
      })
      .catch((err) => {
        console.error("Error copying text: ", err);
      });
  };
  const handleChannelChange = (value: string) => {
    setSelectedChannel(value);
    setChanelError("");
  };

  const handleDripChange = (event: SelectChangeEvent<string>) => {
    const selectedDetails = drip?.find(
      (item: any) => item?.title === event.target.value
    );
    console.log(selectedDetails);
    setSelectedDrip(selectedDetails);
    setAvailablePlatforms(
      selectedDetails.platform ? selectedDetails.platform.split(",") : []
    );
    setDripError("");
  };

  const handleCloseViewDrip = () => {
    setViewDrip(null);
  };

  const handleBackdropClose = () => setViewDrip(null);

  const handlePreview = () => {
    if (!selectedDrip) {
      setDripError("No drip was selected");
      return;
    }

    if (!selectedChannel) {
      setChanelError("No channel was selected");
      return;
    }
    setViewDrip({
      recipient: name,
      title: selectedDrip?.title,
      description: selectedDrip?.description,
      message: selectedDrip?.message,
      email: selectedDrip?.email,
      whatsapp: selectedDrip?.whatsapp,
      asset: selectedDrip?.asset,
      platform: selectedChannel,
      type: selectedDrip?.type,
    });
  };

  const sendDrip = async () => {
    if (!selectedDrip) {
      setDripError("No Drip was selected");
      return;
    }
    if (!selectedChannel) {
      setChanelError("No channel was selected");
      return;
    }

    const formData = new FormData();
    formData.append("hospital_id", id);
    formData.append("drip_id", selectedDrip?.id as string);
    formData.append("channel", selectedChannel);
    formData.append("rep_id", user?.id as string);

    mutatePostDrip(formData, {
      onSuccess: (data: { data: { description: string; Message: string } }) => {
        console.log(data?.data?.Message);
        success(data?.data?.description ?? "Drip sent successfully");
        if (selectedChannel === "SMS") {
          setSms(data?.data?.Message);
          setShowModal(true);
        } else setMessage(data?.data?.Message);

        if (viewDrip) {
          setViewDrip(null);
        }
        setShowModal(true);
      },
      onError: (err: any) => {
        console.log(err);
        error(err?.response?.data?.description ?? "Error sending drip");
      },
    });
  };

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    }
  }, []);

  const renderPreview = (assetType: string, previewURL: string | undefined) => {
    if (!previewURL) return null;

    switch (assetType) {
      case "picture":
        return (
          <img
            className="rounded-md object-fit max-h-full"
            src={`https://${baseUrl}${previewURL}`}
            alt="preview"
          />
        );
      case "pdf":
        return (
          <embed
            src={`https://${baseUrl}${previewURL}`}
            type="application/pdf"
            width="100%"
            height="100%"
          />
        );
      case "video":
        return (
          <video className="rounded-md object-fit max-h-full" controls>
            <source
              src={`https://${baseUrl}${previewURL}`}
              type={`${assetType}/mp4`}
            />
            Your browser does not support the video tag.
          </video>
        );
      default:
        return null;
    }
  };

  return (
    <div className="h-full mb-5">
      <ExodusLoading message={"Loading"} visible={isPending} />
      <ExodusHeader
        Text={"Send Drip"}
        subText={"| Exodus Arena > Send a Drip"}
      />
      <div className="flex items-center">
        <p className="text-8xl text-[#49209F]">
          <GoDash />
        </p>
        <p className="text-2xl">
          Send Drip to <span className="font-semibold">{name}</span>{" "}
        </p>
      </div>

      <div className="w-10/12 mx-auto justify-center my-auto items-center mt-4">
        <p className="font-bold text-2xl mb-4 mt-4">Select hospital value</p>

        <Select
          value={selectedDrip?.title}
          onChange={handleDripChange}
          displayEmpty
          inputProps={{ "aria-label": "Select hospital value" }}
          renderValue={(value) => (
            <InputLabel>{value ? value : "Select a hospital value"}</InputLabel>
          )}
          className="w-full h-[50px]"
          placeholder="select hospital value"
        >
          {drip?.map((item: any) => (
            <MenuItem key={item?.id} value={item?.title}>
              {item?.title}
            </MenuItem>
          ))}
        </Select>
        {dripError && <span className="text-red-500">{dripError}</span>}

        <div className="border outline-dashed outline-2 outline-offset-2 outline-blue-500 md:w-11/12 mx-auto flex h-80 gap-3 max-h-72 justify-center p-3 items-center rounded my-4">
          {renderPreview(
            selectedDrip?.type?.toLowerCase(),
            selectedDrip?.asset as string
          )}
        </div>

        <div className="w-11/12 mx-auto flex mb-2 ml-2 ">
          <p className="text-[#b70303]">
            <FaExclamationCircle
              size={20}
              color={"#b70303"}
              className="text-[#b70303] text-center mb-2 ml-2"
            />
          </p>

          <p className="mr-2 ml-4">
            Video drips can only be sent through Email and Whatsapp
          </p>
        </div>
        <div className="flex flex-col w-11/12 mx-auto">
          <p className="text-2xl mb-2 mt-2">Select channel</p>
          <div className="flex w-8/12 justify-between items-center mb-2 mt-2">
            {availablePlatforms?.map((platform: string, index: number) => (
              <span className="flex justify-center items-center" key={index}>
                <RadioButton
                  id={platform}
                  value={platform}
                  checked={selectedChannel === platform}
                  onChange={() => handleChannelChange(platform)}
                  label={platform}
                />
                <div className="flex">
                  {/* You can add icons for each platform if needed */}
                </div>
              </span>
            ))}
          </div>
          {chanelError && (
            <p className="text-red-500">Please select a channel</p>
          )}

          <div className="flex justify-between gap-8 mt-4">
            <ExodusButton
              text={isMobile ? "Preview" : "Preview Drip"}
              onClick={handlePreview}
              icon={<MdOutlineRemoveRedEye />}
            />
            <ExodusButton
              onClick={sendDrip}
              text={isMobile ? "Send" : "Proceed to Send"}
              icon={<PiTelegramLogoThin />}
            />
          </div>
        </div>

        {/* Preview modal */}
        <TransitionsModal
          open={!!viewDrip}
          setOpen={setViewDrip}
          handleCloseModal={handleCloseViewDrip}
          handleBackdropClose={handleBackdropClose}
          title="Drip Preview"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            bgcolor: "white",
            boxShadow: 24,
            borderRadius: "10px",
          }}
          footer={
            <div className="w-44">
              <ExodusButton
                onClick={sendDrip}
                text={isMobile ? "Send" : "Proceed to Send"}
                icon={<PiTelegramLogoThin />}
              />
            </div>
          }
        >
          <>
            <div className="w-full border rounded-md">
              <div className="bg-[#A0A0A0A0] flex items-start justify-between w-full border-b">
                <div className="w-1/3 min-h-full">
                  <p className="px-4 py-3 font-bold text-[0.8rem] md:text-base w-full text-start rounded-tl-md">
                    Subject:
                  </p>
                </div>

                <div className="bg-white h-full w-full">
                  <p className="px-4 py-3 flex-1 w-full text-start">
                    {viewDrip?.description}
                  </p>
                </div>
              </div>

              <div className="bg-[#A0A0A0A0] flex items-start justify-between w-full border-b">
                <div className="w-1/3 min-h-full">
                  <p className="px-4 py-3 font-bold text-[0.8rem] md:text-base w-full text-start">
                    Message:
                  </p>
                </div>
                <div className="bg-white h-full w-full">
                  <div className="px-4 py-3 flex-1 w-full text-start ">
                    {viewDrip?.platform === "Email" ? (
                      <EmailContent htmlContent={viewDrip?.email} />
                    ) : viewDrip?.platform === "WhatsApp" ? (
                      viewDrip?.whatsapp
                    ) : (
                      viewDrip?.message
                    )}
                  </div>
                </div>
              </div>

              <div className="bg-[#A0A0A0A0] flex items-start justify-between w-full border-b">
                <div className="w-1/3 min-h-full">
                  <p className="px-4 py-3 font-bold text-[0.8rem] md:text-base w-full text-start rounded-bl-md">
                    Asset:
                  </p>
                </div>

                <div className="bg-white h-full w-full">
                  <div className="px-4 py-3 flex-1 w-full">
                    {renderPreview(
                      viewDrip?.type?.toLowerCase(),
                      viewDrip?.asset
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        </TransitionsModal>

        {/* Copy message modal */}
        <TransitionsModal
          open={showModal}
          setOpen={setShowModal}
          handleCloseModal={() => {
            setShowModal(false);
            setSms("");
            setMessage("");
            setSanitizedMessage("");
            setSanitizedUrl("");
            setFileType("");
          }}
          handleBackdropClose={handleBackdropClose}
          title="Copy Drip to send"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            bgcolor: "white",
            boxShadow: 24,
            borderRadius: "10px",
          }}
          footer={
            <div className="w-44">
              <ExodusButton onClick={handleCopy} text={"Copy Message"} />
            </div>
          }
        >
          {
            <div className="w-full border rounded-md">
              <div className="bg-[#A0A0A0A0] flex items-start justify-between w-full border-b">
                <div className="w-1/3 min-h-full">
                  <p className="px-4 py-3 font-bold text-[0.8rem] md:text-base w-full text-start rounded-tl-md">
                    Message:
                  </p>
                </div>

                <div className="bg-white h-full w-full">
                  <div className="px-4 py-3 flex-1 w-full text-start">
                    {sms ? (
                      <p>{sms}</p>
                    ) : (
                      <EmailContent htmlContent={sanitizedMessage} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          }
          {fileType === "image" && (
            <img src={sanitizedUrl} alt="content" style={{ width: "100%" }} />
          )}
          {fileType === "video" && (
            <video src={sanitizedUrl} controls style={{ width: "100%" }} />
          )}
          {fileType === "pdf" && (
            <embed
              src={`${sanitizedUrl}`}
              type="application/pdf"
              width="100%"
              height="100%"
            />
          )}
          {fileType === "unknown" && <p>Cannot preview this file type.</p>}
        </TransitionsModal>
      </div>
    </div>
  );
};

export default SendDrip;
