import React, { useEffect, useState } from "react";
import { orderBy } from "lodash";

import {
  PaginatedData,
  SelectOption,
  SortColumn,
  TableColumn,
} from "../../ts-utils/interfaces";

import LoadingButton from "../common/loadingButton";
import NoItems from "../blocks/noItems";
import Pagination from "../common/pagination";
import Table from "../common/table";

import utilities from "../../utils/utilities";

const FacilitySizeTable = ({
  facilitySize,
  loading,
  search,
  onCountChange,
}: any) => {
  const [sortColumn, setSortColumn] = useState<SortColumn>(initialSortColumn);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const pageSize: number = 10;

  const matchesSearchResult = (size: SelectOption): boolean => {
    return size.name.toLowerCase().startsWith(search.toLowerCase());
  };

  const getPaginatedData = (): PaginatedData => {
    let filtered: SelectOption[] = facilitySize;
    if (search) filtered = facilitySize.filter(matchesSearchResult);

    const sorted: SelectOption[] = orderBy(
      filtered,
      [sortColumn.path],
      [sortColumn.order]
    );
    const paginated: SelectOption[] = utilities.paginate(
      sorted,
      currentPage,
      pageSize
    );

    return { totalCount: filtered.length, paginated };
  };

  const { totalCount, paginated }: PaginatedData = getPaginatedData();

  useEffect(() => {
    onCountChange(totalCount);

    // eslint-disable-next-line
  }, [totalCount]);

  return (
    <React.Fragment>
      <div className="activity-feed fade-in">
        <LoadingButton
          visible={loading}
          message="Fetching Facility Sizes... Please wait"
        />

        {!loading && (
          <React.Fragment>
            <Table
              columns={columns}
              data={paginated}
              sortColumn={sortColumn}
              onSort={setSortColumn}
            />

            <div className="sb-pagination-container">
              <Pagination
                itemsCount={totalCount}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          </React.Fragment>
        )}

        <NoItems isVisible={paginated.length === 0} label="No sizes to show" />
      </div>
    </React.Fragment>
  );
};

const columns: TableColumn[] = [
  {
    path: "id",
    label: "ID",
  },
  {
    path: "name",
    label: "Name",
  },
];

const initialSortColumn: SortColumn = { path: "id", order: "asc" };

export default FacilitySizeTable;
