import httpService from "./httpService";

export const getPipelineData = (city, id) => {
  return httpService.get("/admin/pipeline/data/" + city + "/" + id);
};

export const getMessages = (city) => {
  return httpService.get("/admin/message-lead/" + city);
};

export const sendMessage = (hospitalId, message, user) => {
  const data = {
    hospitalId: hospitalId,
    message: message,
    user: user,
  };

  return httpService.post("/admin/message-lead", data);
};

export const readMessages = (id) => {
  return httpService.get("/admin/message-read/" + id);
};

export const nerveUsageMessages = (id) => {
  return httpService.get("/manage/hospitals/why/nerve/" + id);
};

export const cancelledorders = (id) => {
  return httpService.get("/manage/cancel/orders/" + id);
};

const pipelineService = {
  getPipelineData,
  sendMessage,
  getMessages,
  readMessages,
  nerveUsageMessages,
  cancelledorders,
};

export default pipelineService;
