import React from "react";
import { useNavigate } from "react-router-dom";

const CustomerDashboard = () => {
  const navigate = useNavigate();
  return (
    <div className="customer-main">
      <div className="inner">
        <p className="header">
          Our customer are kings! It is when they are successful,
        </p>
        <p className="header">that we will be successful as well.</p>

        <h3 className="sub-header">You have two jobs</h3>
        <div className="list">
          <div className="maintain-container">
            <div>
              <i
                className="fa fa-dot-circle-o"
                style={{ color: "#2f49d1", marginRight: "1rem" }}
              ></i>{" "}
              Increase Team Score
            </div>
            <button onClick={() => navigate("/active-hospitals")}>
              Maintain
            </button>
          </div>

          <div className="maintain-container">
            <div>
              <i
                className="fa fa-dot-circle-o"
                style={{ color: "#2f49d1", marginRight: "1rem" }}
              ></i>{" "}
              Expand the use of Market place
            </div>
            <button className="grow">Grow</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDashboard;
