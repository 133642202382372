import { useNavigate } from "react-router-dom";

const ViewScoreCardButton = ({
  agent,
}: {
  agent: {
    id: string;
    username: string;
  };
}): JSX.Element | null => {
  const navigate = useNavigate();

  return (
    <button
      className="status primary"
      onClick={() =>
        navigate(`/leads/my-score/${agent.id}/${agent.username}`, {
          state: { agent },
        })
      }
    >
      view score
    </button>
  );
};

export default ViewScoreCardButton;
