import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CustomSlider = ({ children, ...otherSettings }) => {
  return (
    <Slider {...sliderSettings} {...otherSettings}>
      {children}
    </Slider>
  );
};

const sliderSettings = {
  autoplay: true,
  dots: false,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  // responsive: [
  //   {
  //     breakpoint: 1024,
  //     settings: {
  //       slidesToShow: 3,
  //       dots: true,
  //     },
  //   },
  //   {
  //     breakpoint: 768,
  //     settings: {
  //       slidesToShow: 2,
  //       slidesToScroll: 2,
  //       dots: true,
  //     },
  //   },
  //   {
  //     breakpoint: 550,
  //     settings: {
  //       slidesToShow: 1,
  //       slidesToScroll: 1,
  //       dots: true,
  //     },
  //   },
  // ],
};

export default CustomSlider;
