/* eslint-disable eqeqeq */
import React, { useState } from "react";
import BackButton from "../blocks/backbutton";
import Button from "../common/button";
import DangerTrack from "../blocks/DangerTrack/DangerTrack";

import CustomField from "../common/CustomField";

import { useGetSingleHospital } from "../../hooks/useHospital";
import LoadingButton from "../common/loadingButton";

import MasterDanger from "../blocks/DangerMastery/MasterDanger";
import MasterAddValue from "../blocks/AddValue/MasterAddValue";
import MasterPreActive from "../blocks/PreactiveMastery/MasterPreactive";
import MasterNewlyActive from "../blocks/NewlyActiveMastery/MasterNewlyActive";
import MasterLoyal from "../blocks/LoyalMastery/MasterLoyal";
interface HospitalScoreCardProps {
  handleClose: () => void;
  selectedProspect: any;
  children?: React.ReactNode;
}
const HospitalScoreCard: React.FC<HospitalScoreCardProps> = ({
  handleClose,
  selectedProspect,
}) => {
  const { hospital: hospitals, isPending } = useGetSingleHospital(
    selectedProspect.ref_id
  );

  const scoreTrack = hospitals && JSON.parse(hospitals?.score_track || null);

  const hospitalStatus =
    selectedProspect?.current_status === "danger" &&
    selectedProspect?.current_status_open === "0"
      ? "danger-track"
      : selectedProspect?.current_status;

  const status = hospitalStatus || "loyal";

  const isLoyaltyStatus =
    status === "add-value" || status === "check-in" || status === "up-sell";

  const [showMastery, setShowMastery] = useState(false);

  return (
    <div className="bg-white w-[80%] h-[80%] overflow-hidden p-[4px] rounded-xl">
      <div className="overflow-y-auto w-full h-full custom-scrollbar p-10">
        {showMastery ? (
          <>
            {status === "pre-active" && (
              <MasterPreActive
                setShowMastery={setShowMastery}
                refId={hospitals?.ref_id}
                handleClose={handleClose}
              />
            )}
            {status === "newly-active" && (
              <MasterNewlyActive
                setShowMastery={setShowMastery}
                refId={hospitals?.ref_id}
                procurementCycle={hospitals?.procurement_cycle}
                handleClose={handleClose}
              />
            )}
            {status === "danger" && (
              <MasterDanger
                setShowMastery={setShowMastery}
                refId={hospitals?.ref_id}
                handleClose={handleClose}
                score={scoreTrack}
              />
            )}
            {status === "danger-track" && (
              <DangerTrack
                setShowMastery={setShowMastery}
                refId={hospitals?.ref_id}
                handleClose={handleClose}
              />
            )}
            {isLoyaltyStatus && (
              <MasterAddValue
                setShowMastery={setShowMastery}
                refId={hospitals?.ref_id}
                handleClose={handleClose}
                reward={hospitals?.reward_track}
                status={hospitals?.current_status}
              />
            )}
            {status === "loyal" && (
              <MasterLoyal
                setShowMastery={setShowMastery}
                refId={hospitals?.ref_id}
                handleClose={handleClose}
                status={hospitals?.current_status}
              />
            )}
          </>
        ) : (
          <>
            {isPending ? (
              <LoadingButton
                visible={isPending}
                message="Fetching Data... Please wait"
              />
            ) : (
              <React.Fragment>
                <BackButton goBack={handleClose} />
                <div className="my-8">
                  {(status === "danger" ||
                    isLoyaltyStatus ||
                    hospitalStatus) && (
                    <CustomField
                      title="Hospital Health Score"
                      value={hospitals?.health_score || "N/A"}
                    />
                  )}
                  {(status === "danger" ||
                    isLoyaltyStatus ||
                    hospitalStatus) && (
                    <CustomField
                      title="Hospital Loyalty Points"
                      value={hospitals?.reward_score || "N/A"}
                    />
                  )}
                  <CustomField
                    title="Name of Hospital"
                    value={hospitals?.name || "N/A"}
                  />
                  {isLoyaltyStatus && (
                    <CustomField
                      title="Does Hospital Use Nerve?"
                      value={scoreTrack?.usenerve ? "Yes" : "N/A"}
                    />
                  )}
                  {status === "newly-active" && (
                    <CustomField
                      title="Procurement Cycle"
                      value={hospitals?.procurement_cycle || "N/A"}
                    />
                  )}
                  {status !== "add-value" && (
                    <CustomField
                      title="Has the hospital ever placed a Manual Order?"
                      value={scoreTrack?.placedorder ? "Yes" : "N/A"}
                    />
                  )}
                  <CustomField
                    title="Hospital Phone Number"
                    value={hospitals?.phone || "N/A"}
                  />
                  <CustomField
                    title="Hospital Agent"
                    value={hospitals?.agent || "N/A"}
                  />
                  <CustomField
                    title="Hospital Agent Role"
                    value={hospitals?.agentRole || "N/A"}
                  />
                  <CustomField
                    title="Hospital Agent Contact"
                    value={hospitals?.agentContact || "N/A"}
                  />
                  <CustomField
                    title="What does the Hospital Value the most"
                    value={hospitals?.value || "N/A"}
                  />
                  {(status === "danger" || hospitalStatus) && (
                    <CustomField
                      title="Maintenance Schedule"
                      value={hospitals?.maintenance || "N/A"}
                    />
                  )}
                  <CustomField
                    title="Hospital On-Boarded Products"
                    value={hospitals?.productlist || "N/A"}
                  />
                  <CustomField
                    title="Sales Agent information on Hospital"
                    value={hospitals?.agentInfo || "N/A"}
                  />
                  {status === "newly-active" && (
                    <CustomField
                      title="View Maintenance Schedule"
                      value={hospitals?.schedule || "N/A"}
                    />
                  )}
                  {status === "newly-active" && (
                    <CustomField
                      title="Hospital Goals"
                      value={hospitals?.hospital_goal || "N/A"}
                    />
                  )}
                </div>
                <div className="flex items-center gap-2">
                  <Button
                    label={
                      status !== "pre-active"
                        ? "Complete Actions"
                        : "Confirm Mastery"
                    }
                    onClick={() => setShowMastery(true)}
                  />
                </div>
              </React.Fragment>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default HospitalScoreCard;
