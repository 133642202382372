import React, { useState } from "react";
// @ts-ignore
import Joi from "joi-browser";

import LoadingButton from "../common/loadingButton";
import Modal from "../common/modal";
import ts from "../../services/toastService";

import { useAddFacilitySizes } from "../../hooks/useHospital";
interface AddFacilitySizeProps {
  facilitySize: Array<{ name: string }>;
  handleClose: () => void;
}

interface AddFacilitySizeState {
  data: { [key: string]: string };
  errors: { [key: string]: string };
  progress: number;
}

const AddFacilitySize: React.FC<AddFacilitySizeProps> = (props) => {
  const { addFacilitySizes, isPending } = useAddFacilitySizes();
  const [data, setData] = useState<AddFacilitySizeState["data"]>({
    facility_size: "",
  });
  const [errors, setErrors] = useState<AddFacilitySizeState["errors"]>({});

  const schema = {
    facility_size: Joi.string()
      .trim()
      .regex(/^[a-zA-Z_ ]+$/, "facility size")
      .required()
      .label("Facility Size"),
  };

  const getSizeMatch = (value: string) => {
    try {
      return props.facilitySize.find((s) => {
        return s.name.toLowerCase().startsWith(value.toLowerCase());
      });
    } catch (error) {
      return null;
    }
  };

  const getErrorMessage = (ex: any): string => {
    return (
      ex?.response?.data?.message ||
      ex?.message ||
      "Could not add facility size!"
    );
  };

  const validateProperty = (input: { name: string; value: any }) => {
    if (input.name === "facility_size") {
      const { error } = Joi.validate(input.value, schema.facility_size);
      return error ? error.details[0].message : null;
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.currentTarget;

    const newData = { ...data };
    const newErrors = { ...errors };

    const sizeMatch = getSizeMatch(input.value);
    const errorMessage = validateProperty(input);

    newData[input.name] = input.value;
    newErrors[input.name] = sizeMatch
      ? `Match Found: ${sizeMatch.name}!`
      : errorMessage;

    setData(newData);
    setErrors(newErrors);
  };

  const postData = async (): Promise<void> => {
    addFacilitySizes(data, {
      onSuccess: (data) => {
        props.handleClose();
      },
    });
  };

  const doSubmit = (): void => {
    ts.promise(postData(), {
      loading: "Uploading data...",
      success: () => "Size Added Successfully!",
      error: getErrorMessage,
    });
  };
  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(data, schema, options);
    if (!error) return null;

    const errors: { [key: string]: any } = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors || {});
    if (errors) return;

    doSubmit();
  };

  function renderInput({
    type,
    name,
    placeholder,
    label,
    compulsory,
    value,
    onChange,
    error,
  }: {
    type: string;
    name: string;
    placeholder: string;
    label: string;
    compulsory: boolean;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    error: string;
  }): React.ReactNode {
    return (
      <div className="form-group mb-3 space-y-3 px-2">
        <label htmlFor={name}>
          {compulsory && <i className="fa fa-asterisk error"></i>}
          {label}
        </label>
        <input
          type={type}
          name={name}
          className="form-control "
          id={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
        {error && <div className="alert alert-danger mt-3">{error}</div>}
      </div>
    );
  }

  function renderButton(label: string): React.ReactNode {
    return (
      <button disabled={!!validate()} type="submit" className="btn btn-primary">
        {label}
      </button>
    );
  }

  return (
    <Modal handleClose={props.handleClose}>
      <form onSubmit={handleSubmit} className="flex flex-col gap-3">
        <h2 className="form-header">
          Fields marked <i className="fa fa-asterisk error"></i> are compulsory
        </h2>

        {renderInput({
          type: "text",
          name: "facility_size",
          placeholder: "e.g. Extra Large",
          label: "Facility Size",
          compulsory: true,
          value: data.facility_size,
          onChange: handleChange,
          error: errors.facility_size,
        })}

        <div className="d-flex justify-content-end ">
          {isPending ? (
            <LoadingButton visible message="Please wait" />
          ) : (
            renderButton("Add")
          )}
        </div>
      </form>
    </Modal>
  );
};

export default AddFacilitySize;
