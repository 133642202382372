import React from "react";

const RadioButton = ({ name, message, onChange, ...rest }) => {
  return (
    <label className="ohw-radio-container">
      <input type="radio" name={name} onChange={onChange} {...rest} />
      <span className="radio-message">{message}</span>
    </label>
  );
};

export default RadioButton;
